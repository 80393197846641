import React from 'react';
import axios from "axios";
import { API_BASE_URL } from '../../config/api';
import swal from 'sweetalert';
import Header from '../common/header';
import Footer from '../common/footer';
import ReactFlagsSelect from 'react-flags-select';
import Loader from "react-loader-spinner";
export default class BankAccountDetails extends React.Component {
   constructor(props) {
      super(props)
      this.state = {
         bank_account_country: '',
         account_holder_name: '',
         account_holder_type: '',
         routing_number: '',
         country: '',
         account_number: '',
         email: '',
         visible: false,
         selected: 'US',
         phoneCode: [],
         countryCode: [],
         errors: {},
         fields:{},
         
         phonecode1: ['AF', 'AL', 'DZ', 'AS', 'AD', 'AO', 'AI', 'AQ', 'AG', 'AR', 'AM', 'AW', 'AU', 'AT', 'AZ', 'BS', 'BH', 'BD', 'BB', 'BY', 'BE', 'BZ', 'BJ', 'BM', 'BT', 'BO', 'BA', 'BW', 'BV', 'BR', 'IO', 'BN', 'BG', 'BF', 'BI', 'KH', 'CM', 'CA', 'CV', 'KY', 'CF', 'TD', 'CL', 'CN', 'CX', 'CC', 'CO', 'KM', 'CG', 'CD', 'CK', 'CR', 'CI', 'HR', 'CU', 'CY', 'CZ', 'DK', 'DJ', 'DM', 'DO', 'TL', 'EC', 'EG', 'SV', 'GQ', 'ER', 'EE', 'ET', 'XA', 'FK', 'FO', 'FJ', 'FI', 'FR', 'GF', 'PF', 'TF', 'GA', 'GM', 'GE', 'DE', 'GH', 'GI', 'GR', 'GL', 'GD', 'GP', 'GU', 'GT', 'GG', 'GN', 'GW', 'GY', 'HT', 'HM', 'HN', 'HK', 'HU', 'IS', 'IN', 'ID', 'IR', 'IQ', 'IE', 'IL', 'IT', 'JM', 'JP', 'JE', 'JO', 'KZ', 'KE', 'KI', 'KP', 'KR', 'KW', 'KG', 'LA', 'LV', 'LB', 'LS', 'LR', 'LY', 'LI', 'LT', 'LU', 'MO', 'MK', 'MG', 'MW', 'MY', 'MV', 'ML', 'MT', 'IM', 'MH', 'MQ', 'MR', 'MU', 'YT', 'MX', 'FM', 'MD', 'MC', 'MN', 'MS', 'MA', 'MZ', 'MM', 'NA', 'NR', 'NP', 'AN', 'NL', 'NC', 'NZ', 'NI', 'NE', 'NG', 'NU', 'NF', 'MP', 'NO', 'OM', 'PK', 'PW', 'PS', 'PA', 'PG', 'PY', 'PE', 'PH', 'PN', 'PL', 'PT', 'PR', 'QA', 'RE', 'RO', 'RU', 'RW', 'SH', 'KN', 'LC', 'PM', 'VC', 'WS', 'SM', 'ST', 'SA', 'SN', 'RS', 'SC', 'SL', 'SG', 'SK', 'SI', 'XG', 'SB', 'SO', 'ZA', 'GS', 'SS', 'ES', 'LK', 'SD', 'SR', 'SJ', 'SZ', 'SE', 'CH', 'SY', 'TW', 'TJ', 'TZ', 'TH', 'TG', 'TK', 'TO', 'TT', 'TN', 'TR', 'TM', 'TC', 'TV', 'UG', 'UA', 'AE', 'GB', 'US', 'UM', 'UY', 'UZ', 'VU', 'VA', 'VE', 'VN', 'VG', 'VI', 'WF', 'EH', 'YE', 'YU', 'ZM', 'ZW'],
      }
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit=this.handleSubmit.bind(this);
   }
   handleChange(event) {
      console.log('event data', event.target.value)
      this.setState({ [event.target.name]: event.target.value });
      if(event.target.name=="account_holder_name"){
         this.setState({account_holder_nameValidationErrorShow:false})
         }
         if(event.target.name=="account_holder_type"){
         this.setState({account_holder_typeValidationErrorShow:false})
         }
         if(event.target.name=="account_number"){
         this.setState({account_numberValidationErrorShow:false})
         }
         if(event.target.name=="routing_number"){
            this.setState({routing_numberValidationErrorShow:false})
         }
   }


   handleChangeCountry = (phone, country) => {
      console.log('phone', phone, 'country', country.countryCode)
      this.setState({ CountryCode: country.countryCode })
   }



   SweetAlertBankDetails=()=>{
      swal({
         text: "Your account detail successfully added in stripe",
     
         icon: "success",
     
       })
       
   }


   BankDetailValidation=()=>{
      let fields = this.state.fields;
      let errors = {};
      let formIsValid = true;
      fields['bank_account_country']="";
      fields["account_holder_name"] = "";
      fields["account_holder_type"]="";
      // fields["country"]=""
      fields["account_number"]="";
      fields["routing_number"]="";
      if(this.state.account_holder_name===''){
        formIsValid = false;
     this.setState({account_holder_nameValidationErrorShow:'*Please Enter account_holder_name'})
      }


      if(this.state.account_holder_type===''){
         formIsValid = false;
      this.setState({account_holder_typeValidationErrorShow:'*Please Enter account_holder_type '})
       }
  

      // if(this.state.country===''){
      //    formIsValid = false;
      // this.setState({countryValidationErrorShow:'*Please Enter country'})
      //  }

      if(this.state.account_number===''){
         formIsValid = false;
      this.setState({account_numberValidationErrorShow:'*Please Enter account_number'})
       }

      if(this.state.routing_number===''){
         formIsValid = false;
      this.setState({routing_numberValidationErrorShow:'*Please Enter routing_number'})
       }
  

  
      
  
      this.setState({
        errors: errors
      });
      return formIsValid;
  
    }
  

   handleSubmit = event => {
      event.preventDefault();
      this.setState({ visible: true })
   
   if(this.BankDetailValidation()){
     let fields = {};
     fields['bank_account_country']="";
     fields["account_holder_name"] = "";
     fields["account_holder_type"]=""
     fields["country"]=""
     fields["account_number"]=""
     fields["routing_number"]=""
      const UserToken = localStorage.getItem('UserToken');
      if (UserToken != '') {
         const sendData = {
            
            country: this.state.selected,
            
            bank_account_country: this.state.CountryCode,
            account_holder_name: this.state.account_holder_name,
            account_holder_type: this.state.account_holder_type,
            routing_number: this.state.routing_number,
            account_number: this.state.account_number,
         }
        
         axios({
            method: 'post',
            url: API_BASE_URL + `tutor_account_detail/`,
            data: sendData,
            headers: { 'Authorization': "Bearer " + UserToken },
         })
            .then((response) => {
               console.log('data response', response.data)
               this.setState({ visible: false })
               if(response.data.success==200){
                  if(response.data.message=="Your account detail successfully added in stripe"){
                     this.SweetAlertBankDetails()
                     this.props.history.push("/editprofile");
                    
                  }
               }
              
            })
      }
   }
   }
   render() {
      var list = [];
          this.state.phoneCode.map((data) => {
            list.push(data.country_code)
          })
          this.state.phonecode1 = list;
      return (
         <main>
         <Header></Header>
         {/* <section className=""> */}
         <div className="container">
            <div className="row">
               <div className="col-sm-12">
                  <div className="bankdetail_header_footer">
                  <div className="card2 amount_detail_new">
                     <div className="account_datails_heading">
                        <h4>Account Details</h4>
                        <span>Please Provide Your Account Details To Withdraw Your Earning</span>
                     </div>

                     <form onSubmit={this.handleSubmit} method="post">
                        <div className="row">
                           <div className="col-sm-6">
                              <div className="form-group input_container">
                                 <input
                                    type="text"
                                    onChange={this.handleChange}
                                    id="account_holder_name"
                                    value={this.state.account_holder_name}
                                    name="account_holder_name"
                                    className="form-control my-input"
                                    placeholder="Account Holder Name"
                                 />
                              </div>
                              <center><div className="text-danger">{this.state.account_holder_nameValidationErrorShow}</div></center>

                           </div>
                           <div className="col-sm-6">
                              <div className="form-group input_container">
                                 <input
                                    type="text"
                                    onChange={this.handleChange}
                                    id="account_holder_type"
                                    name="account_holder_type"
                                    value={this.state.account_holder_type}
                                    
                                    className="form-control my-input"
                                    placeholder="Account Holder Type"
                                 />
                              </div>
                              <center><div className="text-danger">{this.state.account_holder_typeValidationErrorShow}</div></center>
                           </div>
                        </div>
                        <div className="row">
                           <div className="col-sm-6">
                              <div className="form-group input_container">
                                 <input
                                    type="text"
                                    onChange={this.handleChange}
                                    id="routing_number"
                                    name="routing_number"
                                    maxLength="9"
                                    value={this.state.routing_number}
                                    className="form-control my-input"
                                    placeholder="Routing Number"
                                 />
                              </div>
                              <center><div className="text-danger">{this.state.routing_numberValidationErrorShow}</div></center>

                           </div>
                           <div className="col-sm-6">
                              <div className="form-group input_container">
                                 <input
                                    type="text"
                                    onChange={this.handleChange}
                                    id="account_number"
                                    name="account_number"
                                    value={this.state.account_number}
                                    maxLength="16"
                                    className="form-control my-input"
                                    placeholder="Account Number"
                                 />
                              </div>
                              <center><div className="text-danger">{this.state.account_numberValidationErrorShow}</div></center>

                           </div>
                        </div>
                        <div className="row">
                           <div className="col-sm-12">
                              <div className="form-group input_container"> 
                              <ReactFlagsSelect
                                 autocomplete="false" 
                                 selected={this.state.selected}                          
                                 searchable={true}                           
                                 showOptionLabel={true}     
                                                  
                                 onSelect={code => this.setState({ selected: code })} 
                               
                                 >                       
                              </ReactFlagsSelect> 
              

                              </div>
                              {/* <center><div className="text-danger">{this.state.countryValidationErrorShow}</div></center> */}
                           </div>

                        </div>
                        <Loader
                     // ="please Select Country"
                     visible={this.state.visible}
                     type="Oval"
                     color="#00bacc"
                     height={40}
                     width={40}
                     timeout={3000} //3 secs
                     />



                        <div className="amount_save_btn">
                           <button className="btn  amt_submit_btn" type="submit">Save</button>
                        </div>
                     </form>
                  </div>
                  </div>
               </div>
            </div>
         </div>
        
{/*         
        </section>
      //   </main> */}
      <Footer></Footer>
      </main>
      )
   }
}