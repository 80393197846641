import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Header from '../common/header';
import Footer from'../common/footer';
import girl from "../../assets/img/girl.png";
import mask1 from "../../assets/img/mask1.png";
import mask2 from "../../assets/img/mask2.png";
import mask3 from "../../assets/img/mask3.png";
import mask4 from "../../assets/img/mask4.png";
import mask5 from "../../assets/img/mask5.png";
import search from "../../assets/img/search.png";
import line_green from "../../assets/img/line_green.png";
import line_blue from "../../assets/img/line_blue.png";
import search1 from "../../assets/img/categories/search1.png";
import search2 from "../../assets/img/categories/search2.png";
import search3 from "../../assets/img/categories/search3.png";
import Instructors from'../home/Instructors';
import Testimonial from'../home/testimonial';
import Category from'../home/category';
import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import axios from "axios";
import {API_BASE_URL,BASE_URL} from '../../config/api.js';
import Autocomplete from 'react-autocomplete';

export function renderMovieTitle(state, query) {
 // console.log("state",query);
 
 if(state.sub_category_level_name != null){
  return (
    state.sub_category_level_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
  );
}

}

export default class Home extends React.Component {
  constructor(props){
    super(props);
    this.state={
      search:'',
      subcategary_det: [],
      query: ''
    }
  }

  handleOnInputChange = (event) => {
    const query = event.target.value;
    this.setState({findvalue: event.target.value})

      if ( ! query ) {
        this.setState({ query, results: {}, message: '' } );
      } else {
          this.setState({ query, loading: true, message: '' }, () => {
          this.fetchSearchResults(1, query);   
      });
    }
  };

  fetchSearchResults = (updatedPageNo = '', query ) => {

    const  UserToken = localStorage.getItem('UserToken');
    if(UserToken!=''){

    const find = this.state.findvalue
    const formData = new FormData();
    formData.append("search_input", find);
 
    axios({
    method: 'get',
    url: API_BASE_URL+"tutor_search_subject/?search_input="+find,
    //data: formData,
    header:{
    "content-type": "application/json",
    'Authorization' : "Bearer "+UserToken,   
    }
  })
    .then(response => {
    
        this.setState({subcategary_det:response.data.subcategorylist})
     
    })
  }


    if (this.cancel) {
      // Cancel the previous request before making a new request
      this.cancel.cancel();
    }
    // Create a new CancelToken
    this.cancel = axios.CancelToken.source();
  
  };

  render() {

    const { query } = this.state.query;

    return (
      <main>

      <section className="home_banner">

          <Header></Header>
          
        <div className="container">
          <div className="row custom_height">
            <div className="col-md-12 m-auto text-center">
              {/* <button className="want_teacher_btn">Welcome to Want a Teacher</button> */}
              {/* <h1 className="banner_head"><span>Want</span><span> A Teacher</span></h1> */}
              <span className="searchBox">

              <div className="autocomplete-wrapper">
        
        <Autocomplete
          value={this.state.query}
          items={this.state.subcategary_det}
          getItemValue={item => item.sub_category_level_name}
          shouldItemRender={renderMovieTitle}
          renderInput={(params) => (
          <input
          fullWidth
          {...params}
          variant="outlined"
          placeholder="What Subject do You need help with ? (e.g. Calculus)"
          /> )}
          renderMenu={item => (
            <div className="dropdown">
             {item} 
            </div>
          )}
        
          renderItem={(item, isHighlighted) =>
            <div className={`item ${isHighlighted ? 'selected-item' : ''}`}>
                {/* <Link to={{
                pathname: "/tutorsearch/"+item.category_name.replaceAll(" ","_"),
                params22: item.subcat_id,
                params33: item.id,
                params1:  item.category_name,
                params2:  item.category_description
              }}> */}

              <Link to={"/tutorsearch/"+item.category_name.replaceAll(" ","_")+'/'+item.id}>

                {item.sub_category_level_name+" ("+item.category_name+")"}
                
                </Link>   
            </div>
          }
        onChange={this.handleOnInputChange}/>

      </div>

                  {/* <input name="q" type="text" placeholder="What Subject do You need help with ? (e.g. Calculus)" /> */}
                  <button type="button" className="searchIcon" aria-label="Click to start the search" title="Click to start the search" data-campaign-id="search_button" data-bi-area="section: search box" data-bi-name="search_button" data-bi-id="search_button" disabled="disabled">
                  <svg xmlns="http://www.w3.org/2000/svg" width="28.914" height="28.913" viewBox="0 0 28.914 28.913">
                    <path id="magnifiying-glass" d="M28.584,26.994l-7.056-7.056a12.187,12.187,0,1,0-1.59,1.59l7.056,7.056a1.124,1.124,0,1,0,1.59-1.59ZM2.248,12.17a9.921,9.921,0,1,1,9.921,9.92A9.932,9.932,0,0,1,2.248,12.17Z" fill="#fff"/>
                  </svg>
                  </button> 
              </span>
            </div>
          </div>
        </div>
        <Category></Category>

      </section>
      <section className="process_section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="dark_head">
                <h2><span>We Complete</span><span> Every Step Carefully</span></h2>
              </div>
              <p className="pera_dark text-center">Our Undergraduates, Graduates, Ex/Current School Teacher & Fulltime Tutors are ready to help students realise their full academic potential</p>
            </div>
            <div className="col-md-12">
              <div className="row text-center my-3">
                 <div className="col-md-3">
                   <div className="process_card">
                   <svg xmlns="http://www.w3.org/2000/svg" width="59" height="59" viewBox="0 0 59 59">
                      <path id="magnifiying-glass" d="M58.328,55.084l-14.4-14.4a24.867,24.867,0,1,0-3.244,3.244l14.4,14.4a2.294,2.294,0,1,0,3.244-3.244ZM4.588,24.833A20.244,20.244,0,1,1,24.832,45.076,20.268,20.268,0,0,1,4.588,24.833Z" fill="#8ad75c"/>
                    </svg>
                     <h4>Search</h4>
                     <p>There earth face earth behold she star so made </p>
                   </div>
                 </div>
                 <div className="col-md-3">
                   <div className="process_card">
                   <svg xmlns="http://www.w3.org/2000/svg" width="67.468" height="51.611" viewBox="0 0 67.468 51.611">
                    <g id="Group_931" data-name="Group 931" transform="translate(-500.593 -641.855)">
                      <path id="Path_64" data-name="Path 64" d="M506.889,641.98h-.006a2.367,2.367,0,0,0-1.683.7,2.4,2.4,0,0,0-.708,1.706v36.069a2.415,2.415,0,0,0,2.41,2.406c5.6.013,14.982,1.18,21.452,7.949V653.063a2.3,2.3,0,0,0-.332-1.215c-5.312-8.555-15.52-9.855-21.133-9.868Zm0,0" transform="translate(4.106 0)" fill="#8ad75c" stroke="#fff" stroke-miterlimit="10" stroke-width="0.25"/>
                      <path id="Path_65" data-name="Path 65" d="M541.565,680.454V644.385a2.4,2.4,0,0,0-.708-1.706,2.357,2.357,0,0,0-1.685-.7h-.006c-5.61.013-15.817,1.313-21.127,9.868a2.279,2.279,0,0,0-.33,1.215v37.745c6.467-6.769,15.848-7.937,21.448-7.949a2.416,2.416,0,0,0,2.407-2.406Zm0,0" transform="translate(18.487 0)" fill="#8ad75c" stroke="#fff" stroke-miterlimit="10" stroke-width="0.25"/>
                      <path id="Path_66" data-name="Path 66" d="M545.307,645.964h-1.746v30.156a6.151,6.151,0,0,1-6.133,6.139c-4.748.011-12.578.94-18.124,6.187,9.59-2.347,19.7-.821,25.464.493a2.406,2.406,0,0,0,2.944-2.343V648.367a2.407,2.407,0,0,0-2.405-2.4Zm0,0" transform="translate(20.223 4.335)" fill="#8ad75c" stroke="#fff" stroke-miterlimit="10" stroke-width="0.25"/>
                      <path id="Path_67" data-name="Path 67" d="M504.865,676.119V645.964h-1.744a2.4,2.4,0,0,0-2.4,2.4V686.6a2.4,2.4,0,0,0,2.942,2.341c5.763-1.311,15.871-2.84,25.464-.491-5.546-5.249-13.376-6.176-18.124-6.187a6.152,6.152,0,0,1-6.135-6.139Zm0,0" transform="translate(0 4.335)" fill="#8ad75c" stroke="#fff" stroke-miterlimit="10" stroke-width="0.25"/>
                    </g>
                  </svg>

                     <h4>Book</h4>
                     <p>There earth face earth behold she star so made </p>
                   </div>
                 </div>
                 <div className="col-md-3">
                   <div className="process_card">
                   <svg id="Group_936" data-name="Group 936" xmlns="http://www.w3.org/2000/svg" width="78.059" height="58.707" viewBox="0 0 78.059 58.707">
                    <g id="Group_933" data-name="Group 933" transform="translate(23.235 18.746)">
                      <g id="Group_932" data-name="Group 932">
                        <path id="Path_1101" data-name="Path 1101" d="M192.113,176.681H151.574a7.168,7.168,0,0,0-7.143,7.143V209.5a7.168,7.168,0,0,0,7.143,7.143h40.539a7.168,7.168,0,0,0,7.143-7.143V183.824A7.147,7.147,0,0,0,192.113,176.681Zm3.3,32.914a2.6,2.6,0,0,1-2.59,2.59H151.493a2.6,2.6,0,0,1-2.59-2.59V191.529h46.508Zm0-24.871H148.9v-.981a2.6,2.6,0,0,1,2.59-2.59h41.328a2.6,2.6,0,0,1,2.59,2.59Z" transform="translate(-144.431 -176.681)" fill="#8ad75c"/>
                      </g>
                    </g>
                    <g id="Group_934" data-name="Group 934" transform="translate(0 0)">
                      <path id="Path_1102" data-name="Path 1102" d="M29.863,74.489H55.007l-5.116-10.36A7.157,7.157,0,0,0,40.3,60.9L3.979,78.848A7.157,7.157,0,0,0,.745,88.436l11.39,23.021a7.158,7.158,0,0,0,8.285,3.732,10.754,10.754,0,0,1-1.512-5.518V85.46A10.982,10.982,0,0,1,29.863,74.489Z" transform="translate(0 -60.15)" fill="#8ad75c"/>
                    </g>
                    <g id="Group_935" data-name="Group 935" transform="translate(30.393 42.201)">
                      <path id="Path_1103" data-name="Path 1103" d="M193.564,331.747a4.574,4.574,0,0,0,3.507-1.625,4.633,4.633,0,1,0,0-6.017,4.628,4.628,0,1,0-3.507,7.641Z" transform="translate(-188.931 -322.481)" fill="#8ad75c"/>
                    </g>
                  </svg>
                     <h4>Pay</h4>
                     <p>There earth face earth behold she star so made </p>
                   </div>
                 </div>
                 <div className="col-md-3">
                   <div className="process_card">
                   <svg xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 68 68">
                    <g id="Group_3593" data-name="Group 3593" transform="translate(-1437 -2592)">
                      <g id="Group_3592" data-name="Group 3592">
                        <path id="Path_1104" data-name="Path 1104" d="M17.3,138.268l-5.891.455A5.257,5.257,0,0,0,7.4,141.08L.606,151.356a3.641,3.641,0,0,0,2.48,5.607l5.394.835A48.3,48.3,0,0,1,17.3,138.268Z" transform="translate(1437 2472.096)" fill="#8ad75c"/>
                        <path id="Path_1105" data-name="Path 1105" d="M226.682,390.581l.836,5.394a3.638,3.638,0,0,0,5.607,2.48l10.276-6.794a5.256,5.256,0,0,0,2.357-4.006l.455-5.891A48.3,48.3,0,0,1,226.682,390.581Z" transform="translate(1240.424 2260.939)" fill="#8ad75c"/>
                        <path id="Path_1106" data-name="Path 1106" d="M107.862,259.959a3.421,3.421,0,0,0,.564-.047,39.028,39.028,0,0,0,8.089-2.294l-22.17-22.17a39.026,39.026,0,0,0-2.294,8.089,3.476,3.476,0,0,0,.979,3.025L105.4,258.933A3.489,3.489,0,0,0,107.862,259.959Z" transform="translate(1357.215 2387.822)" fill="#8ad75c"/>
                        <path id="Path_1107" data-name="Path 1107" d="M169.167,30.149c5.425-10.489,5.626-21.573,5.343-26.771a3.492,3.492,0,0,0-3.3-3.3c-.846-.046-1.85-.08-2.98-.08A52.631,52.631,0,0,0,144.44,5.422c-6.978,3.609-16.129,11.557-21.527,22a2,2,0,0,1,.185.161L147,51.491a2,2,0,0,1,.161.185C157.61,46.277,165.557,37.127,169.167,30.149Zm-23.04-15.773a9.961,9.961,0,1,1-2.918,7.043,9.972,9.972,0,0,1,2.918-7.043Z" transform="translate(1330.411 2592)" fill="#8ad75c"/>
                        <path id="Path_1108" data-name="Path 1108" d="M307.481,126.494a5.976,5.976,0,1,0-1.75-4.226,5.983,5.983,0,0,0,1.75,4.226Z" transform="translate(1171.874 2491.151)" fill="#8ad75c"/>
                        <path id="Path_1109" data-name="Path 1109" d="M2.3,353.453a1.985,1.985,0,0,0,1.409-.584l6.5-6.5a1.992,1.992,0,1,0-2.817-2.817l-6.5,6.5a1.992,1.992,0,0,0,1.409,3.4Z" transform="translate(1436.736 2294.585)" fill="#8ad75c"/>
                        <path id="Path_1110" data-name="Path 1110" d="M15.909,388.429a1.992,1.992,0,0,0-2.817,0l-12.5,12.5A1.992,1.992,0,1,0,3.4,403.751l12.5-12.5A1.992,1.992,0,0,0,15.909,388.429Z" transform="translate(1436.997 2255.665)" fill="#8ad75c"/>
                        <path id="Path_1111" data-name="Path 1111" d="M97.154,433.31l-6.5,6.5a1.992,1.992,0,1,0,2.817,2.817l6.5-6.5a1.992,1.992,0,1,0-2.817-2.817Z" transform="translate(1358.895 2216.745)" fill="#8ad75c"/>
                      </g>
                    </g>
                  </svg>

                     <h4>Start</h4>
                     <p>There earth face earth behold she star so made </p>
                   </div>
                 </div>
              </div>
            </div>

            <div className="col-md-12 text-center">          
              <Link to="/dashboard"><button className="btn_reg student_reg">Start Now</button></Link>
            </div>
          </div>
        </div>
      </section>
      <Instructors></Instructors>
      <section className="teacher_section">
       <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="dark_head">
                <h2><span>Our</span><span> Teacher</span></h2>
              </div>
            <p className="pera_dark text-center">Our Undergraduates, Graduates, Ex/Current School Teacher & Fulltime Tutors are ready to help students realise their full academic potential</p>
            </div>
            <div className="col-md-12">
              <div className="mask_group text-center teacher_pro">
                  <img className="p_15px" src={mask1} alt=""/>
                  <img className="p_15px" src={mask2} alt=""/>
                  <img className="p_15px" src={mask3} alt=""/>
                  <img className="p_15px" src={mask4} alt=""/>
                  <img className="p_15px" src={mask5} alt=""/>
                  <img className="p_15px" src={mask3} alt=""/>
                  <img className="p_15px" src={mask2} alt=""/>
              </div>
            </div>
            <div className="col-md-6">
              <img className="img-fluid teacher_search" src={search} alt=""/>
            </div>
            <div className="col-md-6">
              <div className="line_head"><img className="img-fluid" src={line_green} alt="" /><span>Advance Feature</span></div>
              <div className="teacher_search_head">
                <h2><span>Teacher</span><span> Search</span></h2>
              </div>
              <p className="teacher_search_pera">Search for the most suitable tutor using our interactive platform. View Tutor Profiles, Interview Tutors using Chat & finally Select your top pick.</p>
              <div className="row">
                  <div className="col-sm-4">
                    <div className="search_icons search_img"><img className="img-fluid" src={search1} alt="" /></div>
                    <p className="search_peras">Advanced Feature Chat, Call or email teacher before you scheule your lessions</p>
                  </div>
                  <div className="col-sm-4">
                    <div className="search_icons search_img"><img className="img-fluid" src={search2} alt="" /></div>
                    <p className="search_peras">On-demand availability of teachers Build to Prefrence learning curriculum</p>
                  </div>
                  <div className="col-sm-4">
                    <div className="search_icons search_img"><img className="img-fluid" src={search3} alt="" /></div>
                    <p className="search_peras">We offer Flexibility to meet your availability</p>
                  </div>
              </div>
              <Link to="/dashboard"><button className="btn_reg student_reg">Search Now</button></Link>

            </div>
             
          </div>
       </div>
      </section>
      <section className="requirement_section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 my-auto">
              <div className="list_tution_txt">List Your Tuition</div>
              <div className="requirement_line_head"><span>Requirements</span><img className="img-fluid side_line" src={line_blue} alt="" /></div>
              <p className="teacher_search_pera">There are many variations of passages of   rem Ipsum available,  some form, by injected humour.</p>
              <ul>
                <li><i className="check" aria-hidden="true"><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></i> Let us know your requirements & we list these on our platform</li>
                <li><i className="check" aria-hidden="true"><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></i> Interested tutors will respond to your listing</li>
                <li><i className="check" aria-hidden="true"><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></i> Add suitable tutors to your Wish List</li>
                <li><i className="check" aria-hidden="true"><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></i> Interview prospective tutors with Chat with Tutor</li>
                <li><i className="check" aria-hidden="true"><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></i> Finally Book the tutor of your choice</li>
              </ul>
              <Link to="/dashboard"><button className="btn_reg student_reg mr-5 post_btn">Post Now</button></Link>
            </div>
            <div className="col-md-6 m-auto text-center">
              <img className="img-fluid gir_img" src={girl} alt=""/>
            </div>
          </div>
        </div>
      </section>
      <Testimonial></Testimonial>
      <Footer></Footer>
      </main>
    );
  }
}