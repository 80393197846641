import React from 'react';
import Header from "../common/header";
import Footer from "../common/footer";
import axios from 'axios';
import defaultIcon from '../../assets/img/default_male.png';
import {API_BASE_URL,BASE_URL} from'../../config/api.js';
import {Link} from "react-router-dom";

class Edu_list extends React.Component {
  
  constructor(props) {
    super(props)
    this.state= {
    categoryDashboard: [],

    }
  }

  componentDidMount() {

    axios({
      method: 'GET',
      url: API_BASE_URL + "category_list/",
    
      data:{
        'form_status':'Form-3',
      },
    })
    .then(res => {
     // console.log("hellooooo",res.data.result)
    this.setState({ categoryDashboard: res.data.result });
  
    })

  }



  handleButtonNavigate=()=>{
    this.props.history.push({ 
      pathname: '/about_you',
      // state: { id: 1 }
   });
       
  }
render(){
return (
<main>
<Header></Header>
<section className="login_form mx-auto margin_top">
  <div className="container-fluid border-0 width_custom">
    <div className="row">
      <div className="col-md-8 col-sm-12 bg_banner_login login_main_banner"></div>
      <div className="col-md-4 col-sm-12 m-auto">
        <div className="card2 border-0 my-3 loginpage login_card">
       
          <div className="text-center">
            <h2>Area of Teaching </h2> 
          </div>
          <div class="category_btnss">

          {this.state.categoryDashboard.map((data) => {
            return(
              <Link to={"/teaching_skills/"+data.id}>
                <button  style={{background: data.category_color}}>
                  <img src={BASE_URL+data.web_icon} />{data.category_name}</button>
               </Link>
            )})}

          </div>
          {localStorage.getItem('ButtonShow')?
          <button onClick={()=>this.handleButtonNavigate()} className='submit_b'>Next</button>
          :
          null}

        
        </div>
      </div>
    </div>
  </div>
</section>

<Footer></Footer>
</main>);}}

export default Edu_list;