import React from 'react';
import Header from '../common/header';
import Footer from'../common/footer';
import defaultMaleImg from '../../assets/img/default_male.png';
import defaultFemaleImg from '../../assets/img/default_female.png';
import SideBar from '../user/sideBar';
import TutorSideBar from '../tutor/tutorSideBar'
import io from "socket.io-client";
import axios from 'axios';
import { CONVERSATION_BASE_URL,API_BASE_URL, BASE_URL, CHAT_IMAGEURL} from '../../config/api.js';
import Moment from 'moment';
import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
import { faFile, faImage, faImages } from '@fortawesome/free-solid-svg-icons';

var socket = null

class Conversations extends React.Component {

    constructor (props) {
        super (props)
        this.state = {
            currentActiveStatus:'',
            TutorUserId : this.props.location.state.id,
            TutorUser_frontId:"",
            TutorUser_name:"",
            TutorUser_gender:"",
            TutorUser_image:"",
            TutorUser_online_status:"",
            ActivUserList: [],
            ActivTutorList:[],
            usertype_var: '',
            chatHistory: [],
            message: '',
            chatUserfullname: '',
            chatUserId: '',
            chatUserImage: '',
            chatUserGender:'',
            chatUserType:'',
            mimetype: '',
            file: '',
            imageName : ''
        }  
        this.uploadFileChange= this.uploadFileChange.bind(this);
        this.chatActiveUsers= this.chatActiveUsers.bind(this);
        this.chatActiveTutors= this.chatActiveTutors.bind(this);
        this.userChatInfo = this.userChatInfo.bind(this);
    }

    componentDidMount() {
      this.TutorUserChatProfileInfo(this.state.TutorUserId)
      const value = localStorage.getItem('usertype');
      this.setState({usertype_var: value});
      this.chatActiveUsers()
      this.chatActiveTutors()
      this.callGetProfile()
    }

    TutorUserChatProfileInfo(id){
       // alert(id);
        const UserToken = localStorage.getItem('UserToken');
        //console.log(UserToken)
        const formData = new FormData();
        formData.append("user_id", id);
          axios({
          method: 'Post',
          url: API_BASE_URL + 'user_profile_for_chat/',
          headers: { 'Authorization': "Bearer " + UserToken },
          data: formData,
          }).then(response => {
            this.setState({TutorUser_frontId :response.data.front_id}) 
            this.setState({TutorUser_name:response.data.fullname}) 
            this.setState({TutorUser_gender:response.data.gender}) 
            this.setState({TutorUser_image:response.data.image}) 
            this.setState({TutorUser_online_status:response.data.online_status}) 
            console.log("jsndiniweedciejifijc",this.state.TutorUser_image)  
            //alert(this.state.TutorUser_image)
          }).catch((error) => {
            console.log(error)
          });  
    }

    userChatInfo(tutor_id){
      this.state.TutorUserId = tutor_id
      // alert(this.state.TutorUserId)
      this.TutorUserChatProfileInfo(this.state.TutorUserId) 
      this.getChatHistory();
    }

    checkSocket = (socket) => {
        socket.on('connect_error', err => console.log("1>>>", err));
        socket.on('connect_failed', err => console.log("2>>>", err));
        socket.on('disconnect', err => console.log("disconnect---", err));
        socket.on('connectResponse', loginRes => {
          console.log("connectResponse---", loginRes)
          this.getChatHistory()
        });
        socket.on('history', res => {
          console.log("history", res)
        })
        socket.on('receive_message', res => {
          // alert("Hello ", "World");
        console.log("send_message_Response", res.data)
        this.setState(
        {
          chatHistory: [...this.state.chatHistory, res.data],
        })
        })
        socket.on('connect', res => {
          this.socketConnectLogin()
          this.userIsOnline()
        })
        socket.on('getMarkReadResponse', res => {
          console.log("getMarkReadResponse", res)
        })
    }

    socketConnectLogin = () => {
        const profileChatUserId = this.state.chatUserId
        const profileFullname = this.state.chatUserfullname
        var data = {
          user_id: profileChatUserId,
          name: profileFullname
        }
        socket.emit('login', data);
      }

      userIsOnline = () => {
          var data = {
            user_id: this.state.TutorUserId,
          }
          socket.emit('userIsOnline', data, res=> {
            console.log("userIsOnline", res.message)
            this.setState({currentActiveStatus: res.message})
          });
      }
    
      getChatHistory = () => {
        const profileChatUserId = this.state.chatUserId
        var data = {
          page: '1',
          from_id: profileChatUserId,
          to_id: this.state.TutorUserId
        }
        console.log("itra",data)
        socket.emit('history', data, res => {
          console.log("Response ", "==> ", res.data)
          this.setState({ chatHistory: res.data })
        });
        socket.emit('history','1', '255','771');
      }

    sendMessage = (msg_type, doc) => {
    this.refs.someName.value = '';

		const profileChatUserId = this.state.chatUserId
		const profileFullname = this.state.chatUserfullname

		var data = {
		  "from":
		  {
			"id": profileChatUserId,
			"name": profileFullname
		  },
		  "to":
		  {
			"id": this.state.TutorUserId,
			"name": this.state.TutorUser_name
		  },
		  "message": this.state.message,
		  "type": msg_type,
		  "image":this.state.imageName,
		  "mimetype":this.state.mimetype
		}

		console.log("data >>>",data)
		socket.emit('send_message', data, res => {
		console.log("Response ", "==> ", res.data)
	
		this.setState
		({
			chatHistory: [...this.state.chatHistory, res.data],
		})
	    console.log("bhai",this.state.chatHistory)
		  this.setState({ message: '' })
		});

	  }

    callGetProfile = () => {
        const UserToken = localStorage.getItem('UserToken');
        console.log(UserToken)
        if (UserToken != '') {
          axios({
            method: 'POST',
            url: API_BASE_URL + 'chat_user_profile/',
            headers: { 'Authorization': "Bearer " + UserToken },
    
          }).then(response => {

            console.log("user_profile >>",response.data)

            this.setState({ chatUserfullname: response.data.user_fullname,
              chatUserId: response.data.user_id,
              chatUserImage: response.data.profile_image,
              chatUserType: response.data.user_type,
              chatUserGender: response.data.gender})
              console.log("chatUserImage ////", this.state.chatUserImage)

            socket = io.connect("http://173.212.250.62:8085", {
              transports: ['websocket', 'polling']
            });

            //  socket = io.connect("https://chat.wantateacher.com/", {
            //   transports: ['websocket', 'polling']
            // });
    
            console.log(">>>", socket)
            this.checkSocket(socket)
    
          },error => {
            console.log(error);
          });
        }
      }

      MessageState=(e)=>{
        this.setState({ message: e.target.value })
      }
      uploadFileChange = image => {
        this.setState({ file: image.target.files[0] });
      };

    callUploadImage = (type) => {

      this.refs.someName2.value = '';
      
      const profileData = this.state.chatUserId
      const  imageData = this.state.file
     
      console.log("4565454465", imageData.mime)
      var imageName = '';
  
    const formData = new FormData();
      formData.append('filename', this.state.file)
          formData.append('user_id',profileData );
          console.log("formData  >>>>",JSON.stringify(formData))
  
    const UserToken = localStorage.getItem('UserToken');
      if (UserToken != '') {
        axios({
          method: 'POST',
          url: API_BASE_URL + 'user_chat_data/',
          headers: { 'Authorization': "Bearer " + UserToken },
          data: formData,
        }).then(response => {
          console.log("user_chat_data/", response.data)
          this.setState({imageName:response.data.filename,
          mimetype:imageData.mime,image:null
        })
        console.log("8888",this.state.mimetype)
        if(response.data.success==200){
          this.sendMessage(type);
        }   
      },
        error => {
          console.log(error);
        });
    }
    }

    handleSubmit=(event)=>{
      event.preventDefault();
    }

    chatActiveUsers(){

      const UserToken = localStorage.getItem('UserToken');
      if (UserToken != '') {
        axios({
          method: 'Get',
          url: API_BASE_URL + 'user_chat_list_booking/',
          headers: { 'Authorization': "Bearer " + UserToken },
          //data: formData,
          }).then(response => {
              console.log("response active chat user list", response.data.userchatdata[0])
              this.setState({ActivUserList:response.data.userchatdata[0]})
          })
          .catch((error) => {
           
          });
      }

    }

    chatActiveTutors(){
    
      const UserToken = localStorage.getItem('UserToken');
      if (UserToken != '') {
        axios({
          method: 'Get',
          url: API_BASE_URL + 'tutor_chat_list_booking/',
          headers: { 'Authorization': "Bearer " + UserToken },
          //data: formData,
          }).then(response => {
              console.log("response active chat Tutor list", response.data.chat_user_list[0])
              this.setState({ActivTutorList:response.data.chat_user_list[0]})
          })
          .catch((error) => { 
          });
      }
    }

    
render() {
  
  const profileChatUserId = this.state.chatUserId
  //const profileFullname = this.state.chatUserfullname
  const messagedata = this.state.chatHistory.map((Data) => {
    
    return (
        <>
        {profileChatUserId != Data.from_id ?
        <div class="d-flex justify-content-start mb-4">
            <div class="img_cont_msg">
              {this.state.TutorUser_image == '' ?  
              this.state.TutorUser_gender == "female" ?
              <img src={defaultFemaleImg} class="rounded-circle user_img_msg" />
              : <img src={defaultMaleImg} class="rounded-circle user_img_msg" />
              : <img src={CONVERSATION_BASE_URL + this.state.TutorUser_image} class="rounded-circle user_img_msg"></img>
              }
            </div>
            <div class="msg_cotainer">
            {Data.type=="text"?
                <>
                {Data.message}
                </>
                :
                Data.type=="file" ?
                <>
                <img class="Chat_image2" src={CHAT_IMAGEURL + Data.message} alt=""  />
                </>
                : null
                }
                <span class="msg_time">
                {Moment(Data.created_at).format('hh:mm a')}
                </span>
            </div>
        </div>
        :
        <div class="d-flex justify-content-end mb-4">
          
            <div class="msg_cotainer">
            {Data.type=="text"?
                <>
                {Data.message}
                </>
                :
                Data.type=="file" ?
                <>
                <img class="Chat_image2" src={CHAT_IMAGEURL + Data.message} alt=""  />
                </>
                : null
                }
                <span class="msg_time">
                {Moment(Data.created_at).format('hh:mm a')}
                </span>
            </div>
            <div class="img_cont_msg">
              {this.state.chatUserImage == '' ? 
              this.state.chatUserGender == "female" ?
              <img src={defaultFemaleImg} class="rounded-circle user_img_msg" />
              : <img src={defaultMaleImg} class="rounded-circle user_img_msg" />
              : <img src={BASE_URL +"/"+ this.state.chatUserImage} class="rounded-circle user_img_msg"></img>
              }
            </div>
        </div>
        } 
       </>
    )})
 
return (
<main>
<Header></Header>
<section className="margin_top mb-5">
<div class="container-fluid width_custom m-auto">
    <div class="row">
    <div className="col-md-3">
        {this.state.usertype_var == 0 ?
        <SideBar></SideBar> :
        <TutorSideBar></TutorSideBar>}
    </div>
    <div class="col-md-4 col-xl-3 chat">
        <div class="card card_chat mb-sm-3 mb-md-0 contacts_card">
					<div class="card-header">
						{/* <div class="input-group">
							<input type="text" placeholder="Search..." name="" class="form-control search" />
							<div class="input-group-prepend">
								<span class="input-group-text search_btn"><i class="fas fa-search"></i></span>
							</div>
						</div> */}
            <h2>Active User List</h2>
					</div>
          {this.state.usertype_var == "0" ?
					<div class="card-body contacts_body">
						<ui class="contacts">
              {this.state.ActivUserList.map((activelist) => {
              console.log("ttttttttt", activelist);
            return (
						<li onClick={()=>{this.userChatInfo(activelist.tutor_id)}}>
							<div class="d-flex bd-highlight">
								<div class="img_cont">
                {activelist.image == null ?
                  activelist.gender == "female" ?
                  <img class="rounded-circle user_img" src={defaultFemaleImg} alt="" />
                  : <img class="rounded-circle user_img" src={defaultMaleImg} alt="" />
                  : <img class="rounded-circle user_img" src={BASE_URL + activelist.image} alt="" />
                }
                 {activelist.online_status == 1 ? 
                    <span class="online_icon"></span>
                    :<span class="offline_icon"></span>}
								</div>
								<div class="user_info">
									<span>{activelist.fullname}</span>
									<p>({activelist.front_id})</p>
								</div>
							</div>
						</li> )})}

						{/* <li>
							<div class="d-flex bd-highlight">
								<div class="img_cont">
									<img src="https://2.bp.blogspot.com/-8ytYF7cfPkQ/WkPe1-rtrcI/AAAAAAAAGqU/FGfTDVgkcIwmOTtjLka51vineFBExJuSACLcBGAs/s320/31.jpg" class="rounded-circle user_img" />
									<span class="online_icon offline"></span>
								</div>
								<div class="user_info">
									<span>Taherah Big</span>
									<p>Taherah left 7 mins ago</p>
								</div>
							</div>
						</li> */}

						</ui>
					</div>

          :<div class="card-body contacts_body">
						<ui class="contacts">
              {this.state.ActivTutorList.map((activelist) => {
              console.log("ttttttttt2", activelist);
            return (

						<li class="" onClick={()=>{this.userChatInfo(activelist.user_id, activelist.fullname, activelist.image, activelist.gender, activelist.online_status)}}>
							<div class="d-flex bd-highlight">
								<div class="img_cont">
                {activelist.image == null ?
                  activelist.gender == "female" ?
                  <img class="rounded-circle user_img" src={defaultFemaleImg} alt="" />
                  : <img class="rounded-circle user_img" src={defaultMaleImg} alt="" />
                  : <img class="rounded-circle user_img" src={BASE_URL + activelist.image} alt="" />
                }
                 
                 {activelist.online_status == 1 ? 
                    <span class="online_icon"></span>
                    :<span class="offline_icon"></span>}
								</div>
								<div class="user_info">
									<span>{activelist.fullname}</span>
									<p>({activelist.front_id})</p>
								</div>
							</div>
						</li> )})}

						{/* <li>
							<div class="d-flex bd-highlight">
								<div class="img_cont">
									<img src="https://2.bp.blogspot.com/-8ytYF7cfPkQ/WkPe1-rtrcI/AAAAAAAAGqU/FGfTDVgkcIwmOTtjLka51vineFBExJuSACLcBGAs/s320/31.jpg" class="rounded-circle user_img" />
									<span class="online_icon offline"></span>
								</div>
								<div class="user_info">
									<span>Taherah Big</span>
									<p>Taherah left 7 mins ago</p>
								</div>
							</div>
						</li> */}

						</ui>
					</div>}


					<div class="card-footer"></div>
				</div>
      </div>
    <div class="col-md-6 chat">
        <div class="card card_chat">
            <div class="card-header msg_head">
                <div class="d-flex bd-highlight">
                    <div class="img_cont">
                    {this.state.TutorUser_image == '' ?
                       this.state.TutorUser_gender == "female" ?
                       <img class="card_profile_image" src={defaultFemaleImg} alt="" />
                      :<img class="card_profile_image" src={defaultMaleImg} alt="" />
                      :<img src={CONVERSATION_BASE_URL + this.state.TutorUser_image} class="card_profile_image"></img>
                     } 
                     {/* {this.state.TutorOnlineStatus == 1 ?
                      <span class="online_icon"></span>
                      :<span class="offline_icon"></span>} */}
                    </div>
                    <div class="user_info2">
                        <span>{this.state.TutorUser_name} <span> {this.state.chatUserType != "0" ? "(Student)": "(Teacher)"}</span></span>
                        {this.state.currentActiveStatus == "User Online" ? 

                        // <p>online</p>
                        // :<p>offline</p>

                        <div class="status_div set_status">
                        <div className="online_status"></div>
                        ONLINE
                     </div>
                     :
                     <div class="status_div set_status">
                        <div className="offline_status"></div>
                        Offline
                     </div>
                        
                        
                        }
                    </div>
                </div>
            </div>
            <div class="card-body msg_card_body">              
                {messagedata}
            </div>
            <div class="card-footer">
            <form onSubmit={this.handleSubmit}>
                <div class="input-group chat_send_secc">
                    <input type="text" name="message" ref="someName" onChange={this.MessageState} class="type_msg" placeholder="Type your message..."></input>
                    {/* <i className=""><FontAwesomeIcon icon={faImages}></FontAwesomeIcon></i> */}
                    {/* <input type="file" id="file" name="file" ref="someName2" class="file_send_input" onChange={this.uploadFileChange} />
                    <button class="btn btn-primary btn_send_doc"
                      onClick={()=>this.callUploadImage('document')}>
                      Send
                    </button>  */}
                    {this.state.message!=''?
                    <button class="btn btn-primary btn_text_send" onClick={()=>this.sendMessage('text','document')}>send</button>
                      : null
                    }
                </div>
            </form>
            </div>
        </div>
    </div>
    </div>
</div>
</section>
<Footer></Footer>
</main>
);
}
}

export default Conversations


