import React from 'react';
import Slider from "react-slick"; 
import axios from 'axios';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { API_BASE_URL } from '../../config/api';
import { BASE_URL } from '../../config/api';
import { Link } from 'react-router-dom';


class Category extends React.Component {

    state = {
        CategoryListSlider: []
    }
   
    handleClick1 = (id,name,detail) => {
  
      console.log("nm",name)
      console.log('detail',detail)
      this.setState({username:name})
      this.setState({ subdata2:name})
      this.setState({categoriesid:id})
        localStorage.setItem('cat_id', id);
        localStorage.setItem('name',name)
        localStorage.setItem('detail',detail)
    }
    componentDidMount() {
  this.handleClick1()     
      axios.get(API_BASE_URL + `category_list/`)
      .then(res => {
                this.setState({ CategoryListSlider: res.data.result });
                console.log("123",res.data.result)
              
      })
    }



    render() {
        var settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 7,
        slidesToScroll: 7,
        responsive: [
            {
              breakpoint: 1366,
              settings: {
                slidesToShow: 7,
                slidesToScroll: 7
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4
               
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            }]
       };

        const CategoryListSliderData =
            this.state.CategoryListSlider.map((data) => {
                return (
                //   <Link to={{
                //     className:"no_decoration",
                //     pathname: "/tutorsearch/"+data.category_name.replaceAll(" ","_"),
                //     params33: data.id ,
                //     params1:data.category_name,
                //     params2:data.category_description
                // }}>

                <Link to={"/tutorsearch/"+data.category_name.replaceAll(" ","_")+'/'+data.id}>

                  <div onClick={() => { this.handleClick1(data.id,data.category_name,data.category_description) }} value={data.id,data.category_name} className="dfdf">
                      {/* <div className="category_items p15" style={{background:data.category_color}}> */}
                      <div className="category_items p15">
                          <div className="category_imgs">
                            <img className="width20px" src={BASE_URL + data.web_icon} alt="" />
                          </div>
                          <h3 className="">{data.category_name}</h3>
                      </div>
                   </div>
                </Link>
                )
            })

    return (
        
        <div className="container-fluid width_custom">
            <div className="row category_slider">
                <div className="col-md-12">
               
                    <Slider {...settings}>
                        {CategoryListSliderData}
                    </Slider>
                   
                </div>
            </div> 
        </div>
    );
    }
  } 

export default Category
