// import React from 'react';
// import Header from "../common/header";
// import Footer from "../common/footer";
// import axios from 'axios';
// import defaultMaleImg from '../../assets/img/default_male.png';
// import defaultFemaleImg from '../../assets/img/default_female.png';
// import { API_BASE_URL, BASE_URL } from '../../config/api.js';
// import chat_iconn from "../../assets/img/dashboard_icons/chat_icon.png";
// import shortlist from "../../assets/img/dashboard_icons/shortlist.png";
// import removelist from "../../assets/img/dashboard_icons/removelist.png";
// import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
// import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
// import StarRatings from '../../../node_modules/react-star-ratings';
// import { Link } from "react-router-dom";
// import swal from 'sweetalert';
// import {faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
// //import { withRouter } from "react-router";
 
// class Tutorsearch extends React.Component {
// value = ''
// constructor(props) {
// super(props)
// this.state = {
// categoryId : this.props.match.params.categoryId,
// categoryName:  this.props.match.params.categoryName,
// subcategoryId:'',
// subcategoryName:'',
// levelId:'',
// catDescription:[],
// subCategoryLevelList:[],
// usertype_var:"",
// chat_limit:'',
// val: '',
// tutorexdata: [],
// subcatlist: [],
// catdata: [],
// subcatdata: [],
// tutor_cat_subcat_data:[],
// status: '',
// date: new Date(),
// addtitionalInfo: '',
// experiencedata: '',
// RatingFilter: "",
// hourlyratedata: ''
// }
// this.handleSubcategoryData = this.handleSubcategoryData.bind(this);
// this.ExperienceFilter = this.ExperienceFilter.bind(this);
// this.myfav = this.myfav.bind(this);
// this.RatingFilter = this.RatingFilter.bind(this);
// this.HourlyRateFilter = this.HourlyRateFilter.bind(this);
// this.handleChange5 = this.handleChange5.bind(this);
// this.TutorId = this.TutorId.bind(this);
// this.handleChange8 = this.handleChange8.bind(this);
// this.onChatHandle = this.onChatHandle.bind(this);
// }
// sweetAlertFav = () => {
// swal({
// text: "Please login to add tutor in favorite list.",
// // title: "Are you sure?",
// icon: "warning",
// dangerMode: true,
// })
// }
// sweetAlertChat = () => {
// swal({
// text: "Please login to chat with tutor.",
// // title: "Are you sure?",
// icon: "warning",
// dangerMode: true,
// })
// }
// sweetAlertBooking = () => {
//    swal({
//    text: "Please login to continue booking.",
//    icon: "warning",
//    dangerMode: true,
//    })
// }
// sweetAlertValidUser = () => {
//     swal({
//     title: "Sorry!!",
//     text: "You are not a valid user.", 
//     icon: "warning",
//     dangerMode: true,
//     })
// }
// TutorId = (tutor) => {
// this.setState({ tutorid: tutor })
// }
// ExperienceFilter(e) {
// console.log("rahul", e.target.value)
// this.setState({ experiencedata: e.target.value })
// }
// RatingFilter(e) {
// console.log("rahu22222l", e.target.value)
// this.setState({ RatingFilter: e.target.value })
// }
// HourlyRateFilter(e) {
// console.log('hr', e.target.value)
// this.setState({ hourlyratedata: e.target.value })
// }
// myfav() {
// const UserToken = localStorage.getItem('UserToken');
// const formData = new FormData();
// const experienceMax = this.state.experiencedata
// if(this.state.subcategoryId){
//    formData.append('sub_category_id', this.state.subcategoryId);
// }
// formData.append('categories_id',this.props.match.params.categoryId);
// // formData.append('start_rating', this.state.RatingFilter);
// // formData.append('end_rating', this.state.RatingFilter);
// const ratingStar = this.state.RatingFilter
// const hourlyrateMax = this.state.hourlyratedata
// if (experienceMax == 2) {
// formData.append('min_experience', 0)
// formData.append('max_experience', 2)
// }
// else if (experienceMax == 4) {
// formData.append('min_experience', 2)
// formData.append('max_experience', 4)
// }
// else if (experienceMax == 6) {
// formData.append('min_experience', 4)
// formData.append('max_experience', 6)
// }
// else if (experienceMax == 8) {
// formData.append('min_experience', 6)
// formData.append('max_experience', 8)
// }
// else if (experienceMax == 10) {
// formData.append('min_experience', 8)
// formData.append('max_experience', 10)
// }
// else if (experienceMax == 12) {
// formData.append('min_experience', 10)
// formData.append('max_experience', 12)
// }
// else if (experienceMax == 14) {
// formData.append('min_experience', 12)
// formData.append('max_experience', 14)
// }
// else if (experienceMax == 16) {
// formData.append('min_experience', 14)
// formData.append('max_experience', 16)
// }
// else if (experienceMax == 18) {
// formData.append('min_tutor_experience', 18)
// formData.append('max_experience', 20)
// }
// else if (experienceMax == 20) {
// formData.append('min_experience', 18)
// formData.append('max_experience', 20)
// }
// else if (experienceMax == 22) {
// formData.append('min_tutor_experience', 20)
// formData.append('max_tutor_experience', 22)
// }
// else if (experienceMax == 24) {
// formData.append('min_experience', 22)
// formData.append('max_experience', 24)
// }
// else if (experienceMax == 25) {
// formData.append('min_experience', 24)
// formData.append('max_experience', 25)
// }
// else if (experienceMax == 26) {
// formData.append('min_experience', 25)
// formData.append('max_experience', 26)
// }
// if (ratingStar == 1) {
// formData.append('start_rating', 1)
// formData.append('end_rating', 2)
// }
// else if (ratingStar == 2) {
// formData.append('start_rating', 2)
// formData.append('end_rating', 3)
// }
// else if (ratingStar == 3) {
// formData.append('start_rating', 3)
// formData.append('end_rating', 4)
// }
// else if (ratingStar == 4) {
// formData.append('start_rating', 4)
// formData.append('end_rating', 5)
// }
// else if (ratingStar == 5) {
// formData.append('start_rating', 4)
// formData.append('end_rating', 5)
// }
// if (hourlyrateMax == 20) {
// formData.append('min_rate', "5")
// formData.append('max_rate', hourlyrateMax)
// }
// else if (hourlyrateMax == 35) {
// formData.append('min_rate', "20")
// formData.append('max_rate', hourlyrateMax)
// }
// else if (hourlyrateMax == 50) {
// formData.append('min_rate', "35")
// formData.append('max_rate', hourlyrateMax)
// }
// else if (hourlyrateMax == 65) {
// formData.append('min_rate', "50")
// formData.append('max_rate', hourlyrateMax)
// }
// else if (hourlyrateMax == 80) {
// formData.append('min_rate', "65")
// formData.append('max_rate', hourlyrateMax)
// }
// else if (hourlyrateMax == 95) {
// formData.append('min_rate', "80")
// formData.append('max_rate', hourlyrateMax)
// }
// else if (hourlyrateMax == 110) {
// formData.append('min_rate', "95")
// formData.append('max_rate', hourlyrateMax)
// }
// else if (hourlyrateMax == 125) {
// formData.append('min_rate', "110")
// formData.append('max_rate', hourlyrateMax)
// }
// else if (hourlyrateMax == 140) {
// formData.append('min_rate', "125")
// formData.append('max_rate', hourlyrateMax)
// }
// else if (hourlyrateMax == 155) {
// formData.append('min_rate', "140")
// formData.append('max_rate', hourlyrateMax)
// }
// else if (hourlyrateMax == 170) {
// formData.append('min_rate', "155")
// formData.append('max_rate', hourlyrateMax)
// }
// else if (hourlyrateMax == 185) {
// formData.append('min_rate', "170")
// formData.append('max_rate', hourlyrateMax)
// }
// else if (hourlyrateMax == 200) {
// formData.append('min_rate', "185")
// formData.append('max_rate', hourlyrateMax)
// }
// else if (hourlyrateMax == 215) {
// formData.append('min_rate', "200")
// formData.append('max_rate', hourlyrateMax)
// }
// else if (hourlyrateMax == 230) {
// formData.append('min_rate', "215")
// formData.append('max_rate', hourlyrateMax)
// }
// else if (hourlyrateMax == 245) {
// formData.append('min_rate', "230")
// formData.append('max_rate', hourlyrateMax)
// }
// else if (hourlyrateMax == 260) {
// formData.append('min_rate', "245")
// formData.append('max_rate', hourlyrateMax)
// }
// else if (hourlyrateMax == 275) {
// formData.append('min_rate', "260")
// formData.append('max_rate', hourlyrateMax)
// }
// else if (hourlyrateMax == 290) {
// formData.append('min_rate', "275")
// formData.append('max_rate', hourlyrateMax)
// }
// else if (hourlyrateMax == 300) {
// formData.append('min_rate', "290")
// formData.append('max_rate', hourlyrateMax)
// }
// if (UserToken == null) {
// axios({
// method: 'post',
// url: API_BASE_URL + 'tutor_filter_login/',
// data: formData
// }).then(response => {
// console.log("tutorsearch", response.data)
// this.setState({ tutorexdata: response.data.tutorexdata })
// this.setState({ tutor_cat_subcat_data: response.data.tutor_cat_subcat_data })
// this.setState({ subcatdata: response.data.subcatlist })
// this.setState({ catdata: response.data.catlist })
// })
// .catch((error) => {
// console.log(error)
// });
// } else {
// const formData1 = new FormData();
// const experienceMax1 = this.state.experiencedata
// if(this.state.subcategoryId){
//    console.log("hello....")
//    formData1.append('sub_category_id', this.state.subcategoryId);
// }
// formData1.append('categories_id', this.props.match.params.categoryId);
// // formData1.append('start_rating', this.state.RatingFilter)
// // formData1.append('end_rating', this.state.RatingFilter)
// const ratingStar1 = this.state.RatingFilter
// const hourlyrateMax1 = this.state.hourlyratedata
// if (experienceMax1 == 2) {
// formData1.append('min_experience', 0)
// formData1.append('max_experience', 2)
// }
// else if (experienceMax1 == 4) {
// formData1.append('min_experience', 2)
// formData1.append('max_experience', 4)
// }
// else if (experienceMax1 == 6) {
// formData1.append('min_experience', 4)
// formData1.append('max_experience', 6)
// }
// else if (experienceMax1 == 8) {
// formData1.append('min_experience', 6)
// formData1.append('max_experience', 8)
// }
// else if (experienceMax1 == 10) {
// formData1.append('min_experience', 8)
// formData1.append('max_experience', 10)
// }
// else if (experienceMax1 == 12) {
// formData1.append('min_experience', 10)
// formData1.append('max_experience', 12)
// }
// else if (experienceMax1 == 14) {
// formData1.append('min_experience', 12)
// formData1.append('max_experience', 14)
// }
// else if (experienceMax1 == 16) {
// formData1.append('min_experience', 14)
// formData1.append('max_experience', 16)
// }
// else if (experienceMax1 == 18) {
// formData1.append('min_tutor_experience', 16)
// formData1.append('max_experience', 18)
// }
// else if (experienceMax1 == 20) {
// formData1.append('min_experience', 18)
// formData1.append('max_experience', 20)
// }
// else if (experienceMax1 == 22) {
// formData1.append('min_tutor_experience', 20)
// formData1.append('max_tutor_experience', 22)
// }
// else if (experienceMax1 == 24) {
// formData1.append('min_experience', 22)
// formData1.append('max_experience', 24)
// }
// else if (experienceMax1 == 25) {
// formData1.append('min_experience', 24)
// formData1.append('max_experience', 25)
// }
// else if (experienceMax1 == 26) {
// formData1.append('min_experience', 25)
// formData1.append('max_experience', 26)
// }
// if (ratingStar1 == 1) {
// formData1.append('start_rating', 1)
// formData1.append('end_rating', 2)
// }
// else if (ratingStar1 == 2) {
// formData1.append('start_rating', 2)
// formData1.append('end_rating', 3)
// }
// else if (ratingStar1 == 3) {
// formData1.append('start_rating', 3)
// formData1.append('end_rating', 4)
// }
// else if (ratingStar1 == 4) {
// formData1.append('start_rating', 4)
// formData1.append('end_rating', 5)
// }
// else if (ratingStar1 == 5) {
// formData.append('start_rating', 4)
// formData.append('end_rating', 5)
// }
// if (hourlyrateMax1 == 20) {
// formData1.append('min_rate', "5")
// formData1.append('max_rate', hourlyrateMax1)
// }
// else if (hourlyrateMax1 == 35) {
// formData1.append('min_rate', "20")
// formData1.append('max_rate', hourlyrateMax1)
// }
// else if (hourlyrateMax1 == 50) {
// formData1.append('min_rate', "35")
// formData1.append('max_rate', hourlyrateMax1)
// }
// else if (hourlyrateMax1 == 65) {
// formData1.append('min_rate', "50")
// formData1.append('max_rate', hourlyrateMax)
// }
// else if (hourlyrateMax1 == 80) {
// formData1.append('min_rate', "65")
// formData1.append('max_rate', hourlyrateMax1)
// }
// else if (hourlyrateMax1 == 95) {
// formData1.append('min_rate', "80")
// formData1.append('max_rate', hourlyrateMax1)
// }
// else if (hourlyrateMax1 == 110) {
// formData1.append('min_rate', "95")
// formData1.append('max_rate', hourlyrateMax1)
// }
// else if (hourlyrateMax1 == 125) {
// formData1.append('min_rate', "110")
// formData1.append('max_rate', hourlyrateMax1)
// }
// else if (hourlyrateMax1 == 140) {
// formData1.append('min_rate', "125")
// formData1.append('max_rate', hourlyrateMax1)
// }
// else if (hourlyrateMax1 == 155) {
// formData1.append('min_rate', "140")
// formData1.append('max_rate', hourlyrateMax1)
// }
// else if (hourlyrateMax1 == 170) {
// formData1.append('min_rate', "155")
// formData1.append('max_rate', hourlyrateMax1)
// }
// else if (hourlyrateMax1 == 185) {
// formData1.append('min_rate', "170")
// formData1.append('max_rate', hourlyrateMax1)
// }
// else if (hourlyrateMax1 == 200) {
// formData1.append('min_rate', "185")
// formData1.append('max_rate', hourlyrateMax1)
// }
// else if (hourlyrateMax1 == 215) {
// formData1.append('min_rate', "200")
// formData1.append('max_rate', hourlyrateMax1)
// }
// else if (hourlyrateMax1 == 230) {
// formData1.append('min_rate', "215")
// formData1.append('max_rate', hourlyrateMax1)
// }
// else if (hourlyrateMax1 == 245) {
// formData1.append('min_rate', "230")
// formData1.append('max_rate', hourlyrateMax1)
// }
// else if (hourlyrateMax1 == 260) {
// formData1.append('min_rate', "245")
// formData1.append('max_rate', hourlyrateMax1)
// }
// else if (hourlyrateMax1 == 275) {
// formData1.append('min_rate', "260")
// formData1.append('max_rate', hourlyrateMax1)
// }
// else if (hourlyrateMax1 == 290) {
// formData1.append('min_rate', "275")
// formData1.append('max_rate', hourlyrateMax1)
// }
// else if (hourlyrateMax1 == 300) {
// formData1.append('min_rate', "290")
// formData1.append('max_rate', hourlyrateMax1)
// }
// axios({
// method: 'post',
// url: API_BASE_URL + 'tutor_filter_login/',
// data: formData1,
// headers: { 'Authorization': "Bearer " + UserToken },
// }).then(response => {
// this.setState({ tutorexdata: response.data.tutorexdata })
// this.setState({ tutor_cat_subcat_data: response.data.tutor_cat_subcat_data  })
// this.setState({ subcatdata: response.data.subcatlist })
// this.setState({ catdata: response.data.catlist })
// console.log("tutorsearch", this.state.tutor_cat_subcat_data)
// })
// .catch((error) => {
// console.log(error)
// });
// }

// }

// categoryDescription(){
// //const UserToken = localStorage.getItem('UserToken');
// const formData3 = new FormData();
// formData3.append('category_id', this.props.match.params.categoryId);

//    axios({
//    method: 'post',
//    url: API_BASE_URL + 'category_detail_by_categoryid/',
//    data: formData3,
//    //headers: { 'Authorization': "Bearer " + UserToken },
//    }).then(response => {
//    console.log("//////////////", response.data.cat_list[0])
//    this.setState({catDescription:response.data.cat_list})
//    })
//    .catch((error) => {
//    console.log(error)
//    });
   
// }

// static getDerivedStateFromProps(nextProps, prevState) {
// return nextProps.location.pathname.split('/')[2];
// }

// componentDidUpdate(nextProps) {
// if (this.props.match.params.categoryId!==nextProps.match.params.categoryId) {
//    this.myfav()
//    this.categoryDescription()
// }
// }
// componentDidMount() {
// this.setState({usertype_var: localStorage.getItem('usertype')});
// this.myfav()
// this.categoryDescription()
// }

// handleChange8(evt) {
// this.setState({ [evt.target.name]: evt.target.value });
// }
// handleChange5(id) {
// const UserToken = localStorage.getItem('UserToken');
// if (UserToken != '') {
// axios({
// method: 'post',
// url: API_BASE_URL + 'favourite/',
// headers: { 'Authorization': "Bearer " + UserToken },
// data:{
// tutor_id: id
// }
// }).then(response => {
// this.myfav()
// })
// .catch((error) => {
// // console.log(error)
// });
// }
// }
// handleChange8(evt, id) {
// const value = evt.target.value;
// this.setState({ [evt.target.name]: evt.target.value });
// }
// handleSubcategoryData = (id) => {
//    axios({
//       method: 'get',
//       url:API_BASE_URL+ 'sub_category_level_list/?category_id=' + this.props.match.params.categoryId + '&sub_category_id=' + id,
//       }).then(response => {
//          console.log("norml list", response.data);
//          this.setState({ subCategoryLevelList: response.data.result})
//          //this.onSelectedSubject(id)
//          this.setState({subcategoryId:response.data.result[0].subcat_id,subcategoryName: response.data.result[0].sub_category_name})
//          this.onSelectedSubject(this.state.subcategoryId)
         
//       }).catch((error) => {
//       });   
// }
// onSelectedSubject(e){
//    //alert(e)
//    e.preventDefault();
//    console.log("select subject",e.target.value)
//    //this.setState({subcategoryId:e.target.value})
//    this.state.subcategoryId = e.target.value
//    this.myfav()
//    this.handleSubcategoryData(e.target.value);
// }
// onSelectedLevel(e){
//    e.preventDefault();
//    console.log("select level",e.target.value)
//    this.setState({levelId:e.target.value})
// }

// onChatHandle(tutor_id, category_id) {
// const UserToken = localStorage.getItem('UserToken');
// // alert(category_id)
// const filedata = new FormData();
// filedata.append('tutor_id', tutor_id);
// filedata.append('category_id', category_id);
// axios({
// method: 'post',
// url: API_BASE_URL + 'book_tutor_chat/',
// data: filedata,
// headers: { 'Authorization': "Bearer " + UserToken },
// }).then(response => {
// console.log("chat screen navigation", response.data)
// if (response.data.success == 200) {
//    this.setState({chat_limit:response.data.success})
//       this.props.history.push({ 
//          pathname: '/conversations',
//          state: { id: tutor_id }
//       });
// } else {
//    alert(response.data.message)
// }
// })
// .catch((error) => {
// });
// }

// onDirectBooking=(id)=>{
   
// const UserToken = localStorage.getItem('UserToken');
// const BookingData = new FormData();
// BookingData.append('category_id', this.props.match.params.categoryId);
// BookingData.append('sub_category_id', this.state.subcategoryId);
// BookingData.append('sub_category_level', this.state.levelId);
// axios({
// method: 'post',
// url: API_BASE_URL + 'check_subcatlevel_for_booking/',
// data: BookingData,
// headers: { 'Authorization': "Bearer " + UserToken },
// }).then(response => {
// console.log("BOOKING screen navigation", response.data)
// if (response.data.message == 'You can book now') {
//    this.props.history.push({ 
//       pathname: '/booking',
//       state: { id: id,
//       id1: this.props.match.params.categoryId,
//       id2: this.state.subcategoryId}
//    });
// } else {
//    alert(response.data.message)
// }
// })
// .catch((error) => {
// });

// }

// onPostBooking=()=>{

// const UserToken = localStorage.getItem('UserToken');
// const BookingData = new FormData();
// BookingData.append('category_id', this.props.match.params.categoryId);
// BookingData.append('sub_category_id', this.state.subcategoryId);
// BookingData.append('sub_category_level', this.state.levelId);
// axios({
// method: 'post',
// url: API_BASE_URL + 'check_subcatlevel_for_booking/',
// data: BookingData,
// headers: { 'Authorization': "Bearer " + UserToken },
// }).then(response => {
// console.log("BOOKING screen navigation", response.data)
// if (response.data.message == 'You can book now') {
//    this.props.history.push({ 
//       pathname: '/postbooking',
//       state: {
//          id: this.props.match.params.categoryId,
//          id1: this.state.subcategoryId
//       }
//    });
// } else {
//    alert(response.data.message)
// }
// })
// .catch((error) => {
// });

// }

// onViewProfile=(id)=>{
       
//    this.props.history.push({ 
//        pathname: '/tutor_profile',
//        state: { id: id }
//     });
// }

// onMapFlow=(id,name)=>{
//    this.props.history.push({ 
//       pathname: '/map',
//       state: { cat_id: id,
//          cat_name: name}
//       });
// }

// render() {
// const UserToken = localStorage.getItem('UserToken');
// const tutorexDataConst = this.state.tutorexdata.map((tutor) => {
// return (
// <>
// <div class="col-md-6 mb-3">
//    <div class="listing_boxx">
//       <div class="listing_user_img">
//          {tutor.image == null ?
//          tutor.gender == "female" ?
//          <img src={defaultFemaleImg} alt="" />
//          : <img src={defaultMaleImg} alt="" />
//          : <img src={BASE_URL + tutor.image} alt="" />
//          }
//       </div>
//       <div class="tutor_detail_sec">
//          <div class="chat_favrt">
//             <h5>
//                {tutor.front_id}
//                {tutor.rating != '' ?
//                <StarRatings
//                   rating={Number(tutor.rating)}
//                   starRatedColor="#ffc107"
//                   starDimension="18px"
//                   starSpacing="0px"
//                   />
//                : <span>(New User)</span>}
//             </h5>
//             <p>{tutor.postel_code}</p>
//             <div class="user_list_icons">
//                {UserToken == null ?
//                <div className="chat_favrt2 mr-2" onClick={() =>{this.sweetAlertChat()}}>
//                   <img src={chat_iconn} alt="" />
//                </div>
//                :this.state.usertype_var == "1" ?
//                <div className="chat_favrt2 mr-2" onClick={() =>{this.sweetAlertValidUser() }}>
//                   <img src={chat_iconn} alt="" />
//                </div>
//                :<div className="chat_favrt2 mr-2" onClick={() =>this.onChatHandle(tutor.user, tutor.tutor_category_list)}>
//                   <img src={chat_iconn} alt="" />
//                </div>}

//                {UserToken == null ?
//                <div className="chat_favrt2" onClick={() => { this.sweetAlertFav() }}>
//                   {tutor.fav_status != null ?
//                   <img src={shortlist} alt="" />
//                   : <img src={removelist} alt="" />
//                   }
//                </div>
//                :this.state.usertype_var == "1" ?
//                <div value={tutor.user} onClick={() => {this.sweetAlertValidUser() }} className="chat_favrt2">
//                   {tutor.fav_status != null ?
//                   <img src={shortlist} alt="" />
//                   : <img src={removelist} alt="" />
//                   }
//                </div>
//                :
//                <div value={tutor.user} onClick={() => this.handleChange5(tutor.user)} className="chat_favrt2">
//                   {tutor.fav_status != null ?
//                   <img src={shortlist} alt="" />
//                   : <img src={removelist} alt="" />
//                   }
//                </div>
//                }
//             </div> 
//          </div>
//          {tutor.online_status == 1 ?
//          <span>
//             <div className="online_status"></div>
//             ONLINE
//          </span>
//          :
//          <span>
//             <div className="offline_status"></div>
//             OFFLINE
//          </span>
//          }
//          <p>
//             {this.state.tutor_cat_subcat_data.map((subjects) => {
               
//             if (tutor.user == subjects.user_id){
//             return (
//             <>
//             {subjects.sub_category_name}
//             </>
//             )}
//             })}
//          </p>
      
//          <div class="tutor_btnss tutor_btnss_mob">
//             {UserToken == null ?
//             <button class="view_profile" onClick={() => {this.sweetAlertBooking() }}>Book Now</button>
//             :this.state.usertype_var == "1" ?
//             <button class="view_profile" onClick={() => {this.sweetAlertValidUser() }}>Book Now</button>
//             :
//             <button class="view_profile" onClick={() => {this.onDirectBooking(tutor.user) }}>Book Now</button>
//             }
//             <button class="chat_tutor" onClick={() => this.onViewProfile(tutor.user)}>View Profile</button>
//          </div>
//       </div>
//    </div>
// </div>
// </>
// )
// })
// return (
// <main>
//    <Header></Header>
//    <section class="search_tutor_sectionn search_mob margin_top">
//       <div class="container">
//          <div class="row">
//             <div class="col text-center">
//               {this.state.catDescription.map((data)=>{
//                  return(  
//                <div class="tutor_search_area">
//                   <h1>{data.category_name}</h1>
//                   <p>{data.category_description}</p>
//                </div>)})}
//             </div>
//          </div>
//       </div>
//    </section>
//    <section>
//       <div class="container-fluid width_custom">
//          <div class="row my-4">
//             <div class="col-md-3">
//                <div class="tutorfilter_sidebar mb-4">
//                   <h4>Filter by Subjects</h4>
//                   <ul class="category_filter_list">
//                   {this.state.subcatdata.map((sub) => (
//                      this.props.match.params.categoryId == sub.categories ?
//                      <li onMouseOver={()=>{this.handleSubcategoryData(sub.id)}} onClick={()=>{this.handleSubcategoryData(sub.id)}}>
//                      {/* <Link to={"/tutorsearch/"+data.category_name.replaceAll(" ","_")+'/'+this.props.match.params.categoryId}> */}
//                         <Link>{sub.sub_category_name}</Link>
//                         <ul class="subcategory_dropdown">
//                          {this.state.subCategoryLevelList.map((level) => (
//                            <li>
//                               <Link>{level.sub_category_level_name}</Link>
//                            </li>
//                          ))}
//                         </ul>
//                      </li>
//                      :null
//                      ))}
//                   </ul>
//                </div>
//                <div class="tutorfilter_sidebar mb-4 text-center">
//                   <select onClick={this.myfav}
//                      onChange={(e) =>
//                      this.ExperienceFilter(e)}>
//                      <option >Experience</option>
//                      <option value="2">0-2 Years</option>
//                      <option value="4">3-4 Years</option>
//                      <option value="6">5-6 Years</option>
//                      <option value="8">7-8 Years</option>
//                      <option value="10">9-10 Years</option>
//                      <option value="12">11-12 Years</option>
//                      <option value="14">13-14 Years</option>
//                      <option value="16">15-16 Years</option>
//                      <option value="18">17-18 Years</option>
//                      <option value="20">19-20 Years</option>
//                      <option value="22">21-22 Years</option>
//                      <option value="24">23-24 Years</option>
//                      <option value="25">24-25 Years</option>
//                      <option value="26">Above 25 Years</option>
//                   </select>
//                </div>
//                <div class="tutorfilter_sidebar mb-4 text-center">
//                   <select onClick={this.myfav}
//                      onChange={(e) =>
//                      this.RatingFilter(e)}>
//                      <option value="" >Rating</option>
//                      <option value="1">1</option>
//                      <option value="2">2-3</option>
//                      <option value="3">3-4</option>
//                      <option value="4">4-5</option>
//                      <option value="5">5</option>
//                   </select>
//                </div>
//                <div class="tutorfilter_sidebar mb-4 text-center">
//                   <select onClick={this.myfav}
//                      onChange={this.HourlyRateFilter}>
//                      <option >Hourly Rate</option>
//                      <option value="20">5-20</option>
//                      <option value="35">20-35</option>
//                      <option value="50">35-50</option>
//                      <option value="65">50-65</option>
//                      <option value="80">65-80</option>
//                      <option value="95">80-95</option>
//                      <option value="110">95-110</option>
//                      <option value="125">110-125</option>
//                      <option value="140">125-140</option>
//                      <option value="155">140-155</option>
//                      <option value="170">155-170</option>
//                      <option value="185">170-185</option>
//                      <option value="200">185-200</option>
//                      <option value="215">200-215</option>
//                      <option value="230">215-230</option>
//                      <option value="245">230-245</option>
//                      <option value="260">245-260</option>
//                      <option value="275">260-275</option>
//                      <option value="290">275-290</option>
//                      <option value="300">290-300</option>
//                   </select>
//                </div>
//                <div class="post_now_button">
//                {UserToken == null ?
//                   <button onClick={() => {this.sweetAlertBooking() }}>Post Now</button>
//                   :this.state.usertype_var == "1" ?
//                   <button onClick={() => {this.sweetAlertValidUser() }}>Post Now</button>
//                   :<button onClick={this.onPostBooking}>Post Now</button>
//                }
//                </div>
//             </div>
//             <div class="col-md-9">
//                <div class="row">
//                   <div class="col-md-4">
//                      <div class="tutorfilter_sidebar light_box-shadow mb-4 text-center">
//                         <select onClick={(e) => this.onSelectedSubject(e)}>
                        
//                            {this.state.subcategoryName == '' ? 
//                            <option>Subject</option>
//                            :<option selected>{this.state.subcategoryName}</option>
//                            }
//                          {this.state.subcatdata.map((sub,index) => (
                            
//                            this.props.match.params.categoryId == sub.categories?
//                            //(this.state.subcategoryName != sub.sub_category_name ?
//                               <option value={sub.id}>{sub.sub_category_name}</option> 
//                            //: null)
//                            :null
//                         )) }
//                         </select>
//                      </div>
//                   </div>
//                   <div class="col-md-4">
//                      <div class="tutorfilter_sidebar light_box-shadow mb-4 text-center">
//                         <select onClick={(e) => this.onSelectedLevel(e)}>
//                            <option>Level</option>
//                             {this.state.subCategoryLevelList.map((level) => (
//                                <option value={level.subcat_level_id}>{level.sub_category_level_name}</option>
//                              ))}
//                         </select>
//                      </div>
//                   </div>
                  
//                   <div class="col-md-4 text-right">
//                      {/* <Link to={{pathname: "/map"}}> */}
//                         <div class="list_map_buttons" onClick={() => {this.onMapFlow(this.props.match.params.categoryId,this.props.match.params.categoryName) }}>
//                            <i className="">
//                               <FontAwesomeIcon icon={faMapMarkerAlt}></FontAwesomeIcon>
//                            </i> Map view
//                         </div>
//                      {/* </Link> */}
//                   </div>
//                </div>
//                <div class="row">
//                   {this.state.tutorexdata && this.state.tutorexdata.length > 0 ?
//                   tutorexDataConst :
//                   <div class="col-md-12">
//                      <p class="no_record_pera">No record found</p>
//                   </div>
//                   }
//                </div>
//             </div>
//          </div>
//       </div>
//    </section>
//    <Footer></Footer>
// </main>
// );
// }
// }
// export default Tutorsearch;

 

 

import React from 'react';
import Header from "../common/header";
import Footer from "../common/footer";
import axios from 'axios';
import defaultMaleImg from '../../assets/img/default_male.png';
import defaultFemaleImg from '../../assets/img/default_female.png';
import { API_BASE_URL, BASE_URL } from '../../config/api.js';
import chat_iconn from "../../assets/img/dashboard_icons/chat_icon.png";
import shortlist from "../../assets/img/dashboard_icons/shortlist.png";
import removelist from "../../assets/img/dashboard_icons/removelist.png";
import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
import StarRatings from '../../../node_modules/react-star-ratings';
import { Link } from "react-router-dom";
import swal from 'sweetalert';
import {faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
//import { withRouter } from "react-router";
 
class Tutorsearch extends React.Component {
value = ''
constructor(props) {
super(props)
this.state = {
categoryId : this.props.match.params.categoryId,
categoryName:  this.props.match.params.categoryName,
subcategoryId:'',
subcategoryName:'',
levelId:'',
catDescription:[],
subCategoryLevelList:[],
usertype_var:"",
chat_limit:'',
val: '',
tutorexdata: [],
subcatlist: [],
catdata: [],
subcatdata: [],
tutor_cat_subcat_data:[],
status: '',
date: new Date(),
addtitionalInfo: '',
experiencedata: '',
RatingFilter: "",
hourlyratedata: ''
}
this.handleSubcategoryData = this.handleSubcategoryData.bind(this);
this.ExperienceFilter = this.ExperienceFilter.bind(this);
this.myfav = this.myfav.bind(this);
this.RatingFilter = this.RatingFilter.bind(this);
this.HourlyRateFilter = this.HourlyRateFilter.bind(this);
this.handleChange5 = this.handleChange5.bind(this);
this.TutorId = this.TutorId.bind(this);
this.handleChange8 = this.handleChange8.bind(this);
this.onChatHandle = this.onChatHandle.bind(this);
}
sweetAlertFav = () => {
swal({
text: "Please login to add tutor in favorite list.",
// title: "Are you sure?",
icon: "warning",
dangerMode: true,
})
}
sweetAlertChat = () => {
swal({
text: "Please login to chat with tutor.",
// title: "Are you sure?",
icon: "warning",
dangerMode: true,
})
}
sweetAlertBooking = () => {
   swal({
   text: "Please login to continue booking.",
   icon: "warning",
   dangerMode: true,
   })
}
sweetAlertValidUser = () => {
    swal({
    title: "Sorry!!",
    text: "You are not a valid user.", 
    icon: "warning",
    dangerMode: true,
    })
}
TutorId = (tutor) => {
this.setState({ tutorid: tutor })
}
ExperienceFilter(e) {
console.log("rahul", e.target.value)
this.setState({ experiencedata: e.target.value })
}
RatingFilter(e) {
console.log("rahu22222l", e.target.value)
this.setState({ RatingFilter: e.target.value })
}
HourlyRateFilter(e) {
console.log('hr', e.target.value)
this.setState({ hourlyratedata: e.target.value })
}
myfav() {
const UserToken = localStorage.getItem('UserToken');
const formData = new FormData();
const experienceMax = this.state.experiencedata
if(this.state.subcategoryId){
   formData.append('sub_category_id', this.state.subcategoryId);
}
formData.append('categories_id',this.props.match.params.categoryId);
// formData.append('start_rating', this.state.RatingFilter);
// formData.append('end_rating', this.state.RatingFilter);
const ratingStar = this.state.RatingFilter
const hourlyrateMax = this.state.hourlyratedata
if (experienceMax == 2) {
formData.append('min_experience', 0)
formData.append('max_experience', 2)
}
else if (experienceMax == 4) {
formData.append('min_experience', 2)
formData.append('max_experience', 4)
}
else if (experienceMax == 6) {
formData.append('min_experience', 4)
formData.append('max_experience', 6)
}
else if (experienceMax == 8) {
formData.append('min_experience', 6)
formData.append('max_experience', 8)
}
else if (experienceMax == 10) {
formData.append('min_experience', 8)
formData.append('max_experience', 10)
}
else if (experienceMax == 12) {
formData.append('min_experience', 10)
formData.append('max_experience', 12)
}
else if (experienceMax == 14) {
formData.append('min_experience', 12)
formData.append('max_experience', 14)
}
else if (experienceMax == 16) {
formData.append('min_experience', 14)
formData.append('max_experience', 16)
}
else if (experienceMax == 18) {
formData.append('min_tutor_experience', 18)
formData.append('max_experience', 20)
}
else if (experienceMax == 20) {
formData.append('min_experience', 18)
formData.append('max_experience', 20)
}
else if (experienceMax == 22) {
formData.append('min_tutor_experience', 20)
formData.append('max_tutor_experience', 22)
}
else if (experienceMax == 24) {
formData.append('min_experience', 22)
formData.append('max_experience', 24)
}
else if (experienceMax == 25) {
formData.append('min_experience', 24)
formData.append('max_experience', 25)
}
else if (experienceMax == 26) {
formData.append('min_experience', 25)
formData.append('max_experience', 26)
}
if (ratingStar == 1) {
formData.append('start_rating', 1)
formData.append('end_rating', 2)
}
else if (ratingStar == 2) {
formData.append('start_rating', 2)
formData.append('end_rating', 3)
}
else if (ratingStar == 3) {
formData.append('start_rating', 3)
formData.append('end_rating', 4)
}
else if (ratingStar == 4) {
formData.append('start_rating', 4)
formData.append('end_rating', 5)
}
else if (ratingStar == 5) {
formData.append('start_rating', 4)
formData.append('end_rating', 5)
}
if (hourlyrateMax == 20) {
formData.append('min_rate', "5")
formData.append('max_rate', hourlyrateMax)
}
else if (hourlyrateMax == 35) {
formData.append('min_rate', "20")
formData.append('max_rate', hourlyrateMax)
}
else if (hourlyrateMax == 50) {
formData.append('min_rate', "35")
formData.append('max_rate', hourlyrateMax)
}
else if (hourlyrateMax == 65) {
formData.append('min_rate', "50")
formData.append('max_rate', hourlyrateMax)
}
else if (hourlyrateMax == 80) {
formData.append('min_rate', "65")
formData.append('max_rate', hourlyrateMax)
}
else if (hourlyrateMax == 95) {
formData.append('min_rate', "80")
formData.append('max_rate', hourlyrateMax)
}
else if (hourlyrateMax == 110) {
formData.append('min_rate', "95")
formData.append('max_rate', hourlyrateMax)
}
else if (hourlyrateMax == 125) {
formData.append('min_rate', "110")
formData.append('max_rate', hourlyrateMax)
}
else if (hourlyrateMax == 140) {
formData.append('min_rate', "125")
formData.append('max_rate', hourlyrateMax)
}
else if (hourlyrateMax == 155) {
formData.append('min_rate', "140")
formData.append('max_rate', hourlyrateMax)
}
else if (hourlyrateMax == 170) {
formData.append('min_rate', "155")
formData.append('max_rate', hourlyrateMax)
}
else if (hourlyrateMax == 185) {
formData.append('min_rate', "170")
formData.append('max_rate', hourlyrateMax)
}
else if (hourlyrateMax == 200) {
formData.append('min_rate', "185")
formData.append('max_rate', hourlyrateMax)
}
else if (hourlyrateMax == 215) {
formData.append('min_rate', "200")
formData.append('max_rate', hourlyrateMax)
}
else if (hourlyrateMax == 230) {
formData.append('min_rate', "215")
formData.append('max_rate', hourlyrateMax)
}
else if (hourlyrateMax == 245) {
formData.append('min_rate', "230")
formData.append('max_rate', hourlyrateMax)
}
else if (hourlyrateMax == 260) {
formData.append('min_rate', "245")
formData.append('max_rate', hourlyrateMax)
}
else if (hourlyrateMax == 275) {
formData.append('min_rate', "260")
formData.append('max_rate', hourlyrateMax)
}
else if (hourlyrateMax == 290) {
formData.append('min_rate', "275")
formData.append('max_rate', hourlyrateMax)
}
else if (hourlyrateMax == 300) {
formData.append('min_rate', "290")
formData.append('max_rate', hourlyrateMax)
}
if (UserToken == null) {
axios({
method: 'post',
url: API_BASE_URL + 'tutor_filter_login/',
data: formData
}).then(response => {
console.log("tutorsearch", response.data)
this.setState({ tutorexdata: response.data.tutorexdata })
this.setState({ tutor_cat_subcat_data: response.data.tutor_cat_subcat_data })
this.setState({ subcatdata: response.data.subcatlist })
this.setState({ catdata: response.data.catlist })
})
.catch((error) => {
console.log(error)
});
} else {
const formData1 = new FormData();
const experienceMax1 = this.state.experiencedata
if(this.state.subcategoryId){
   console.log("hello....")
   formData1.append('sub_category_id', this.state.subcategoryId);
}
formData1.append('categories_id', this.props.match.params.categoryId);
// formData1.append('start_rating', this.state.RatingFilter)
// formData1.append('end_rating', this.state.RatingFilter)
const ratingStar1 = this.state.RatingFilter
const hourlyrateMax1 = this.state.hourlyratedata
if (experienceMax1 == 2) {
formData1.append('min_experience', 0)
formData1.append('max_experience', 2)
}
else if (experienceMax1 == 4) {
formData1.append('min_experience', 2)
formData1.append('max_experience', 4)
}
else if (experienceMax1 == 6) {
formData1.append('min_experience', 4)
formData1.append('max_experience', 6)
}
else if (experienceMax1 == 8) {
formData1.append('min_experience', 6)
formData1.append('max_experience', 8)
}
else if (experienceMax1 == 10) {
formData1.append('min_experience', 8)
formData1.append('max_experience', 10)
}
else if (experienceMax1 == 12) {
formData1.append('min_experience', 10)
formData1.append('max_experience', 12)
}
else if (experienceMax1 == 14) {
formData1.append('min_experience', 12)
formData1.append('max_experience', 14)
}
else if (experienceMax1 == 16) {
formData1.append('min_experience', 14)
formData1.append('max_experience', 16)
}
else if (experienceMax1 == 18) {
formData1.append('min_tutor_experience', 16)
formData1.append('max_experience', 18)
}
else if (experienceMax1 == 20) {
formData1.append('min_experience', 18)
formData1.append('max_experience', 20)
}
else if (experienceMax1 == 22) {
formData1.append('min_tutor_experience', 20)
formData1.append('max_tutor_experience', 22)
}
else if (experienceMax1 == 24) {
formData1.append('min_experience', 22)
formData1.append('max_experience', 24)
}
else if (experienceMax1 == 25) {
formData1.append('min_experience', 24)
formData1.append('max_experience', 25)
}
else if (experienceMax1 == 26) {
formData1.append('min_experience', 25)
formData1.append('max_experience', 26)
}
if (ratingStar1 == 1) {
formData1.append('start_rating', 1)
formData1.append('end_rating', 2)
}
else if (ratingStar1 == 2) {
formData1.append('start_rating', 2)
formData1.append('end_rating', 3)
}
else if (ratingStar1 == 3) {
formData1.append('start_rating', 3)
formData1.append('end_rating', 4)
}
else if (ratingStar1 == 4) {
formData1.append('start_rating', 4)
formData1.append('end_rating', 5)
}
else if (ratingStar1 == 5) {
formData.append('start_rating', 4)
formData.append('end_rating', 5)
}
if (hourlyrateMax1 == 20) {
formData1.append('min_rate', "5")
formData1.append('max_rate', hourlyrateMax1)
}
else if (hourlyrateMax1 == 35) {
formData1.append('min_rate', "20")
formData1.append('max_rate', hourlyrateMax1)
}
else if (hourlyrateMax1 == 50) {
formData1.append('min_rate', "35")
formData1.append('max_rate', hourlyrateMax1)
}
else if (hourlyrateMax1 == 65) {
formData1.append('min_rate', "50")
formData1.append('max_rate', hourlyrateMax)
}
else if (hourlyrateMax1 == 80) {
formData1.append('min_rate', "65")
formData1.append('max_rate', hourlyrateMax1)
}
else if (hourlyrateMax1 == 95) {
formData1.append('min_rate', "80")
formData1.append('max_rate', hourlyrateMax1)
}
else if (hourlyrateMax1 == 110) {
formData1.append('min_rate', "95")
formData1.append('max_rate', hourlyrateMax1)
}
else if (hourlyrateMax1 == 125) {
formData1.append('min_rate', "110")
formData1.append('max_rate', hourlyrateMax1)
}
else if (hourlyrateMax1 == 140) {
formData1.append('min_rate', "125")
formData1.append('max_rate', hourlyrateMax1)
}
else if (hourlyrateMax1 == 155) {
formData1.append('min_rate', "140")
formData1.append('max_rate', hourlyrateMax1)
}
else if (hourlyrateMax1 == 170) {
formData1.append('min_rate', "155")
formData1.append('max_rate', hourlyrateMax1)
}
else if (hourlyrateMax1 == 185) {
formData1.append('min_rate', "170")
formData1.append('max_rate', hourlyrateMax1)
}
else if (hourlyrateMax1 == 200) {
formData1.append('min_rate', "185")
formData1.append('max_rate', hourlyrateMax1)
}
else if (hourlyrateMax1 == 215) {
formData1.append('min_rate', "200")
formData1.append('max_rate', hourlyrateMax1)
}
else if (hourlyrateMax1 == 230) {
formData1.append('min_rate', "215")
formData1.append('max_rate', hourlyrateMax1)
}
else if (hourlyrateMax1 == 245) {
formData1.append('min_rate', "230")
formData1.append('max_rate', hourlyrateMax1)
}
else if (hourlyrateMax1 == 260) {
formData1.append('min_rate', "245")
formData1.append('max_rate', hourlyrateMax1)
}
else if (hourlyrateMax1 == 275) {
formData1.append('min_rate', "260")
formData1.append('max_rate', hourlyrateMax1)
}
else if (hourlyrateMax1 == 290) {
formData1.append('min_rate', "275")
formData1.append('max_rate', hourlyrateMax1)
}
else if (hourlyrateMax1 == 300) {
formData1.append('min_rate', "290")
formData1.append('max_rate', hourlyrateMax1)
}
axios({
method: 'post',
url: API_BASE_URL + 'tutor_filter_login/',
data: formData1,
headers: { 'Authorization': "Bearer " + UserToken },
}).then(response => {
this.setState({ tutorexdata: response.data.tutorexdata })
this.setState({ tutor_cat_subcat_data: response.data.tutor_cat_subcat_data  })
this.setState({ subcatdata: response.data.subcatlist })
this.setState({ catdata: response.data.catlist })
console.log("tutorsearch", this.state.tutor_cat_subcat_data)
})
.catch((error) => {
console.log(error)
});
}

}

categoryDescription(){
//const UserToken = localStorage.getItem('UserToken');
const formData3 = new FormData();
formData3.append('category_id', this.props.match.params.categoryId);

   axios({
   method: 'post',
   url: API_BASE_URL + 'category_detail_by_categoryid/',
   data: formData3,
   //headers: { 'Authorization': "Bearer " + UserToken },
   }).then(response => {
   console.log("//////////////", response.data.cat_list[0])
   this.setState({catDescription:response.data.cat_list})
   })
   .catch((error) => {
   console.log(error)
   });
   
}

static getDerivedStateFromProps(nextProps, prevState) {
return nextProps.location.pathname.split('/')[2];
}

componentDidUpdate(nextProps) {
if (this.props.match.params.categoryId!==nextProps.match.params.categoryId) {
   this.myfav()
   this.categoryDescription()
}
}
componentDidMount() {
this.setState({usertype_var: localStorage.getItem('usertype')});
this.myfav()
this.categoryDescription()
}

handleChange8(evt) {
this.setState({ [evt.target.name]: evt.target.value });
}
handleChange5(id) {
const UserToken = localStorage.getItem('UserToken');
if (UserToken != '') {
axios({
method: 'post',
url: API_BASE_URL + 'favourite/',
headers: { 'Authorization': "Bearer " + UserToken },
data:{
tutor_id: id
}
}).then(response => {
this.myfav()
})
.catch((error) => {
// console.log(error)
});
}
}
handleChange8(evt, id) {
const value = evt.target.value;
this.setState({ [evt.target.name]: evt.target.value });
}
handleSubcategoryData = (id) => {
   axios({
      method: 'get',
      url:API_BASE_URL+ 'sub_category_level_list/?category_id=' + this.props.match.params.categoryId + '&sub_category_id=' + id,
      }).then(response => {
         console.log("norml list", response.data);
         this.setState({ subCategoryLevelList: response.data.result})
         //this.onSelectedSubject(id)
         this.setState({subcategoryId:response.data.result[0].subcat_id,subcategoryName: response.data.result[0].sub_category_name})
         this.onSelectedSubject(this.state.subcategoryId)
         
      }).catch((error) => {
      });   
}
onSelectedSubject(e){
   //alert(e)
   e.preventDefault();
   console.log("select subject",e.target.value)
   //this.setState({subcategoryId:e.target.value})
   this.state.subcategoryId = e.target.value
   this.myfav()
   this.handleSubcategoryData(e.target.value);
}
onSelectedLevel(e){
   e.preventDefault();
   console.log("select level",e.target.value)
   this.setState({levelId:e.target.value})
}

onChatHandle(tutor_id, category_id) {
const UserToken = localStorage.getItem('UserToken');
// alert(category_id)
const filedata = new FormData();
filedata.append('tutor_id', tutor_id);
filedata.append('category_id', category_id);
axios({
method: 'post',
url: API_BASE_URL + 'book_tutor_chat/',
data: filedata,
headers: { 'Authorization': "Bearer " + UserToken },
}).then(response => {
console.log("chat screen navigation", response.data)
if (response.data.success == 200) {
   this.setState({chat_limit:response.data.success})
      this.props.history.push({ 
         pathname: '/conversations',
         state: { id: tutor_id }
      });
} else {
   alert(response.data.message)
}
})
.catch((error) => {
});
}

onDirectBooking=(id)=>{
   
const UserToken = localStorage.getItem('UserToken');
const BookingData = new FormData();
BookingData.append('category_id', this.props.match.params.categoryId);
BookingData.append('sub_category_id', this.state.subcategoryId);
BookingData.append('sub_category_level', this.state.levelId);
axios({
method: 'post',
url: API_BASE_URL + 'check_subcatlevel_for_booking/',
data: BookingData,
headers: { 'Authorization': "Bearer " + UserToken },
}).then(response => {
console.log("BOOKING screen navigation", response.data)
if (response.data.message == 'You can book now') {
   this.props.history.push({ 
      pathname: '/booking',
      state: { id: id,
      id1: this.props.match.params.categoryId,
      id2: this.state.subcategoryId}
   });
} else {
   if(response.data.success==201){
      if(response.data.message=="Sub category id is required")
    {
   this.SubcategoryErrorResponse()
   }}
   }})
.catch((error) => {
});

}

onPostBooking=()=>{

const UserToken = localStorage.getItem('UserToken');
const BookingData = new FormData();
BookingData.append('category_id', this.props.match.params.categoryId);
BookingData.append('sub_category_id', this.state.subcategoryId);
BookingData.append('sub_category_level', this.state.levelId);
axios({
method: 'post',
url: API_BASE_URL + 'check_subcatlevel_for_booking/',
data: BookingData,
headers: { 'Authorization': "Bearer " + UserToken },
}).then(response => {
console.log("BOOKING screen navigation", response.data)
if (response.data.message == 'You can book now') {
   this.props.history.push({ 
      pathname: '/postbooking',
      state: {
         id: this.props.match.params.categoryId,
         id1: this.state.subcategoryId
      }
   });
} else {
   if(response.data.success==201){
   if(response.data.message=="Sub category id is required")
 {
this.SubcategoryErrorResponse()
}}
}})
.catch((error) => {
});

}

onViewProfile=(id)=>{
       
   this.props.history.push({ 
       pathname: '/tutor_profile',
       state: { id: id }
    });
}

onMapFlow=(id,name)=>{
   this.props.history.push({ 
      pathname: '/map',
      state: { cat_id: id,
         cat_name: name}
      });
}


SubcategoryErrorResponse=()=>{
   swal({
      text: "Sub category id is required",

      icon: "warning",
    dangerMode: true,

    })

}

render() {
const UserToken = localStorage.getItem('UserToken');
const tutorexDataConst = this.state.tutorexdata.map((tutor) => {
return (
<>
<div class="col-md-6 mb-3">
   <div class="listing_boxx">
      <div class="listing_user_img">
         {tutor.image == null ?
         tutor.gender == "female" ?
         <img src={defaultFemaleImg} alt="" />
         : <img src={defaultMaleImg} alt="" />
         : <img src={BASE_URL + tutor.image} alt="" />
         }
      </div>
      <div class="tutor_detail_sec tutor_serch_detail">
         <div class="chat_favrt">
            <div className="postal_status">
            <h5>
               {tutor.front_id}
               {tutor.rating != '' ?
               <StarRatings
                  rating={Number(tutor.rating)}
                  starRatedColor="#ffc107"
                  starDimension="18px"
                  starSpacing="0px"
                  />
               : <span>(New User)</span>}
            </h5>
            </div>
            <div class="user_list_icons">
               {UserToken == null ?
               <div className="chat_favrt2 mr-2" onClick={() =>{this.sweetAlertChat()}}>
                  <img src={chat_iconn} alt="" />
               </div>
               :this.state.usertype_var == "1" ?
               <div className="chat_favrt2 mr-2" onClick={() =>{this.sweetAlertValidUser() }}>
                  <img src={chat_iconn} alt="" />
               </div>
               :<div className="chat_favrt2 mr-2" onClick={() =>this.onChatHandle(tutor.user, tutor.tutor_category_list)}>
                  <img src={chat_iconn} alt="" />
               </div>}

               {UserToken == null ?
               <div className="chat_favrt2" onClick={() => { this.sweetAlertFav() }}>
                  {tutor.fav_status != null ?
                  <img src={shortlist} alt="" />
                  : <img src={removelist} alt="" />
                  }
               </div>
               :this.state.usertype_var == "1" ?
               <div value={tutor.user} onClick={() => {this.sweetAlertValidUser() }} className="chat_favrt2">
                  {tutor.fav_status != null ?
                  <img src={shortlist} alt="" />
                  : <img src={removelist} alt="" />
                  }
               </div>
               :
               <div value={tutor.user} onClick={() => this.handleChange5(tutor.user)} className="chat_favrt2">
                  {tutor.fav_status != null ?
                  <img src={shortlist} alt="" />
                  : <img src={removelist} alt="" />
                  }
               </div>
               }
            </div> 
         </div>
         <div className="postal_status">
            {tutor.online_status == 1 ?
            <span>
               <div className="online_status"></div>
               ONLINE
            </span>
            :
            <span>
               <div className="offline_status"></div>
               OFFLINE
            </span>
            }
            <p>{tutor.postel_code}</p>
         </div>
         <p>
            {this.state.tutor_cat_subcat_data.map((subjects) => {
               
            if (tutor.user == subjects.user_id){
            return (
            <>
            {subjects.sub_category_name}
            </>
            )}
            })}
         </p>
      
         <div class="tutor_btnss tutor_btnss_mob">
            {UserToken == null ?
            <button class="view_profile" onClick={() => {this.sweetAlertBooking() }}>Book Now</button>
            :this.state.usertype_var == "1" ?
            <button class="view_profile" onClick={() => {this.sweetAlertValidUser() }}>Book Now</button>
            :
            <button class="view_profile" onClick={() => {this.onDirectBooking(tutor.user) }}>Book Now</button>
            }
            <button class="chat_tutor" onClick={() => this.onViewProfile(tutor.user)}>View Profile</button>
         </div>
      </div>
   </div>
</div>
</>
)
})
return (
<main>
   <Header></Header>
   <section class="search_tutor_sectionn search_mob margin_top">
      <div class="container">
         <div class="row">
            <div class="col text-center">
              {this.state.catDescription.map((data)=>{
                 return(  
               <div class="tutor_search_area">
                  <h1>{data.category_name}</h1>
                  <p>{data.category_description}</p>
               </div>)})}
            </div>
         </div>
      </div>
   </section>
   <section>
      <div class="container-fluid width_custom">
         <div class="row my-4">
            <div class="col-md-3">
               <div class="tutorfilter_sidebar mb-4">
                  <h4>Filter by Subjects</h4>
                  <ul class="category_filter_list">
                  {this.state.subcatdata.map((sub) => (
                     this.props.match.params.categoryId == sub.categories ?
                     <li onMouseOver={()=>{this.handleSubcategoryData(sub.id)}} onClick={()=>{this.handleSubcategoryData(sub.id)}}>
                     {/* <Link to={"/tutorsearch/"+data.category_name.replaceAll(" ","_")+'/'+this.props.match.params.categoryId}> */}
                        <Link>{sub.sub_category_name}</Link>
                        <ul class="subcategory_dropdown">
                         {this.state.subCategoryLevelList.map((level) => (
                           <li>
                              <Link>{level.sub_category_level_name}</Link>
                           </li>
                         ))}
                        </ul>
                     </li>
                     :null
                     ))}
                  </ul>
               </div>
               <div class="tutorfilter_sidebar mb-4 text-center">
                  <select onClick={this.myfav}
                     onChange={(e) =>
                     this.ExperienceFilter(e)}>
                     <option >Experience</option>
                     <option value="2">0-2 Years</option>
                     <option value="4">3-4 Years</option>
                     <option value="6">5-6 Years</option>
                     <option value="8">7-8 Years</option>
                     <option value="10">9-10 Years</option>
                     <option value="12">11-12 Years</option>
                     <option value="14">13-14 Years</option>
                     <option value="16">15-16 Years</option>
                     <option value="18">17-18 Years</option>
                     <option value="20">19-20 Years</option>
                     <option value="22">21-22 Years</option>
                     <option value="24">23-24 Years</option>
                     <option value="25">24-25 Years</option>
                     <option value="26">Above 25 Years</option>
                  </select>
               </div>
               <div class="tutorfilter_sidebar mb-4 text-center">
                  <select onClick={this.myfav}
                     onChange={(e) =>
                     this.RatingFilter(e)}>
                     <option value="" >Rating</option>
                     <option value="1">1</option>
                     <option value="2">2-3</option>
                     <option value="3">3-4</option>
                     <option value="4">4-5</option>
                     <option value="5">5</option>
                  </select>
               </div>
               <div class="tutorfilter_sidebar mb-4 text-center">
                  <select onClick={this.myfav}
                     onChange={this.HourlyRateFilter}>
                     <option >Hourly Rate</option>
                     <option value="20">5-20</option>
                     <option value="35">20-35</option>
                     <option value="50">35-50</option>
                     <option value="65">50-65</option>
                     <option value="80">65-80</option>
                     <option value="95">80-95</option>
                     <option value="110">95-110</option>
                     <option value="125">110-125</option>
                     <option value="140">125-140</option>
                     <option value="155">140-155</option>
                     <option value="170">155-170</option>
                     <option value="185">170-185</option>
                     <option value="200">185-200</option>
                     <option value="215">200-215</option>
                     <option value="230">215-230</option>
                     <option value="245">230-245</option>
                     <option value="260">245-260</option>
                     <option value="275">260-275</option>
                     <option value="290">275-290</option>
                     <option value="300">290-300</option>
                  </select>
               </div>
               <div class="post_now_button">
               {UserToken == null ?
                  <button onClick={() => {this.sweetAlertBooking() }}>Post Now</button>
                  :this.state.usertype_var == "1" ?
                  <button onClick={() => {this.sweetAlertValidUser() }}>Post Now</button>
                  :<button onClick={this.onPostBooking}>Post Now</button>
               }
               </div>
            </div>
            <div class="col-md-9">
               <div class="row">
                  <div class="col-md-4">
                     <div class="tutorfilter_sidebar light_box-shadow mb-4 text-center">
                        <select onClick={(e) => this.onSelectedSubject(e)}>
                        
                           {this.state.subcategoryName == '' ? 
                           <option>Subject</option>
                           :<option selected>{this.state.subcategoryName}</option>
                           }
                         {this.state.subcatdata.map((sub,index) => (
                            
                           this.props.match.params.categoryId == sub.categories?
                           //(this.state.subcategoryName != sub.sub_category_name ?
                              <option value={sub.id}>{sub.sub_category_name}</option> 
                           //: null)
                           :null
                        )) }
                        </select>
                     </div>
                  </div>
                  <div class="col-md-4">
                     <div class="tutorfilter_sidebar light_box-shadow mb-4 text-center">
                        <select onClick={(e) => this.onSelectedLevel(e)}>
                           <option>Level</option>
                            {this.state.subCategoryLevelList.map((level) => (
                               <option value={level.subcat_level_id}>{level.sub_category_level_name}</option>
                             ))}
                        </select>
                     </div>
                  </div>
                  
                  <div class="col-md-4 text-right">
                     {/* <Link to={{pathname: "/map"}}> */}
                        <div class="list_map_buttons" onClick={() => {this.onMapFlow(this.props.match.params.categoryId,this.props.match.params.categoryName) }}>
                           <i className="">
                              <FontAwesomeIcon icon={faMapMarkerAlt}></FontAwesomeIcon>
                           </i> Map view
                        </div>
                     {/* </Link> */}
                  </div>
               </div>
               <div class="row">
                  {this.state.tutorexdata && this.state.tutorexdata.length > 0 ?
                  tutorexDataConst :
                  <div class="col-md-12">
                     <p class="no_record_pera">No record found</p>
                  </div>
                  }
               </div>
            </div>
         </div>
      </div>
   </section>
   <Footer></Footer>
</main>
);
}
}
export default Tutorsearch;

 

 

