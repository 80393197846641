import React from 'react';
import firebase from '../../firebase';
import axios from "axios";
import { Modal, Card } from "react-bootstrap";
import { API_BASE_URL, BASE_URL ,Google_StreetNameURL} from '../../config/api.js';
// import short from '../../assets/img/Untitled-1.png';
import defaultMaleImg from '../../assets/img/default_male.png';
import defaultFemaleImg from '../../assets/img/default_female.png';
import Header from '../common/header';
import ReactCrop from 'react-image-crop';
import { Button } from "react-bootstrap";
import Footer from '../common/footer';
import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
import { faUser, faEye, faEyeSlash, faAngleUp, faEdit, faPencilAlt, faLocationArrow, faMap, faVoicemail, faMobile, faLock, faCamera, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import SideBar from '../user/sideBar';
import TutorSideBar from '../tutor/tutorSideBar'
import 'react-image-crop/dist/ReactCrop.css';
import swal from 'sweetalert';
import PhoneInput from 'react-phone-input-2'
import validator from 'validator'
var usertype = ''
var auth=firebase.auth()
class Editprofile extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      usertype_var: '',
      NameData: [],
      postalcode:'',
      street_name:'',
      show90:'',
      ProfileImageData: [],
      show77:'',
      student_data: [],
      gender:'',
      old_type: 'password',
      show11: '',
      new_type: 'password',
      confirm_type: 'password',
      MessageUpdateNameSuccesfully: '',
      MessageValidOldPassword: '',
      MessageAlreadyExistEmail: '',
      email: '',
      new_email: '',
      messageEmailverification: '',
      messagemobileverification: '',
      MessageImageRequired: '',
      MessageImageUploaded: '',
      src: null,
      crop: {
        unit: '%',
        width: 30,
        aspect: 16 / 9,
      },
      MessageUpdatePasswordSuccesfully: '',
      resultCropImage: null,
      password:'',
      input:{}, input: {},
      errors: {},
      CountryCodeValue : '',
      SubmitFullname:'',
      SubmitUploadImage:'',
      new_mobile_error:'',
      EmailValidationErrorShow:'',
      errors: {},
      fields:{},
      MobileValidationErrorShow:'',
      new_mobile:'',
      oldPasswordValidationErrorShow:'',
      responseOldMessage:'',
      responseNewMessage:'',
      responseConfirmMessage:'',
      old_password:'',
      newPasswordValidationErrorShow:'',
      new_password:'',
      ConfirmPasswordValidationErrorShow:'',
      confirm_password:''
    }
    this.showHide = this.showHide.bind(this);
    this.showHide1 = this.showHide1.bind(this);
    this.showHide2 = this.showHide2.bind(this);
    this.UpdatePostelCodeChange = this.UpdatePostelCodeChange.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.handleChange3 = this.handleChange3.bind(this);
    this.handleChange5 = this.handleChange5.bind(this);
    this.handleSubmit1 = this.handleSubmit1.bind(this);
    this.handleSubmit3 = this.handleSubmit3.bind(this);
    this.handleSubmitPostelCode = this.handleSubmitPostelCode.bind(this);
    this.handleSubmit5 = this.handleSubmit5.bind(this);
    this.handleSubmit6 = this.handleSubmit6.bind(this);
    this.handleChange6 = this.handleChange6.bind(this);
    this.handleChange4 = this.handleChange4.bind(this);
    this.handleSubmit4 = this.handleSubmit4.bind(this);
    this.GenderChange=this.GenderChange.bind(this);

  }
  refresh = () => {
    window.location.reload();
  };

  sweetAlertUpdatePassword = () => {
    swal({
      text: "Your Password successfully updated.",
      icon: "success",
    }).then(() => {

      this.props.history.push("/login");
    })
  }


  sweetAlertUpdateName = () => {
    swal({
      text: "Your fullname successfully updated",
      icon: "success",
    })
    // .then(()=>{
    //   window.location.reload()
    // })
  
  }


  sweetAlertAlreadyUpdateName=()=>{
    swal({
      text: "Your fullname allready updated",
      icon: "warning",
      dangerMode: true,
    })

  }

  // Your fullname successfully updated

  GenderChange(event){
    console.log("gender check",event.target.checked)

    this.setState({ [event.target.name]: event.target.value });

  }

  sweetAlertSamePasswordError = () => {
    swal({
      text: "New password and confirm password must be same",
      icon: "warning",
      dangerMode: true,
    })

  }

  handleClick2 = () => {
    this.handleModal11()

  }


  sweetAlertPasswordError = () => {
    swal({
      text: "please enter valid password of user",
      icon: "warning",
      dangerMode: true,
    })

  }

  handleChange5(evt) {
    const value = evt.target.value;
    this.setState({ [evt.target.name]: evt.target.value });
    if(evt.target.name=="old_password"){
    this.setState({oldPasswordValidationErrorShow:false})
    }
    if(evt.target.name=="new_password"){
    this.setState({newPasswordValidationErrorShow:false})
    }
    if(evt.target.name=="confirm_password"){
    this.setState({ConfirmPasswordValidationErrorShow:false})
    }
  }


  passwordValidation(){
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if(this.state.old_password===''){
      formIsValid = false;
   this.setState({oldPasswordValidationErrorShow:'*Please Enter Old Password'})
    }


    if(this.state.new_password!==""){
    
     var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
     if (!strongRegex.test(this.state.new_password)) {
       
      
      formIsValid = false;
      this.setState({newPasswordValidationErrorShow:'*Password must contain at least One Number One Upper Case Letter One Lower Case Letter One Special Character.'})
    }
  }


  if(this.state.confirm_password!==""){
    
   var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
   if (!strongRegex.test(this.state.confirm_password)) {
    
    
    formIsValid = false;
    this.setState({ConfirmPasswordValidationErrorShow:'*Password must contain at least One Number One Upper Case Letter One Lower Case Letter One Special Character.'})
  }
}

//   if (typeof input["old_password"] !== "undefined") {
//     var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

//         if (!strongRegex.test(input["old_password"])) {

//         isValid = false;

//         errors["old_password"] = "Password must contain at least One Number One Upper Case Letter One Lower Case Letter One Special Character.";
// }
// }

// if (typeof fields["new_password"] !== "undefined") {
//   if(this.state.new_password!==''){
//     var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

//         if (!strongRegex.test(fields["new_password"])) {

//           formIsValid = false;
//         this.setState({newPasswordValidationErrorShow:'*Password must contain at least One Number One Upper Case Letter One Lower Case Letter One Special Character.'})
//         // errors["new_password"] = "Password must contain at least One Number One Upper Case Letter One Lower Case Letter One Special Character.";
// }
// }

// if (typeof fields["confirm_password"] !== "undefined") {
//   if(this.state.confirm_password!==''){
//   var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

//       if (!strongRegex.test(fields["confirm_password"])) {

//         formIsValid = false;
//         this.setState({ConfirmPasswordValidationErrorShow:'*Password must contain at least One Number One Upper Case Letter One Lower Case Letter One Special Character.'})
//       // errors["confirm_password"] = "Password must contain at least One Number One Upper Case Letter One Lower Case Letter One Special Character.";
// }
// }

     


    if(this.state.new_password===''){
      formIsValid = false;
   this.setState({newPasswordValidationErrorShow:'*Please Enter New Password'})
    }


    // if(this.state.old_password!=='') {

    //       // if (typeof fields["password"] !== "undefined") {
    //         if (!fields["old_password"].match(/^.*(?=.{5,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {
    //           formIsValid = false;
    //           this.setState({newPasswordValidationErrorShow:'Password must contain at least One Number One Upper Case Letter One Lower Case Letter One Special Character.'})
     
    //           // this.setState({})
    //         }
    //       }
         
    if(this.state.confirm_password===''){
      formIsValid = false;
      this.setState({ConfirmPasswordValidationErrorShow:'*Please Enter Confirm Password'})
    }
    

    

    this.setState({
      errors: errors
    });
    return formIsValid;

  }



  handleSubmit5 = event => {
    event.preventDefault();
    if(event.target.name=="old_password"){
      this.setState({oldPasswordValidationErrorShow:false})
      }
      if(event.target.name=="new_password"){
      this.setState({newPasswordValidationErrorShow:false})
      }
      if(event.target.name=="confirm_password"){
      this.setState({ConfirmPasswordValidationErrorShow:false})
      }
      this.setState({responseNewMessage:false})
      this.setState({responseConfirmMessage:false})

if(this.passwordValidation()){
  let fields = {};
  fields['new_password']="";
  fields["old_password"] = "";
  fields["confirm_password"]=""
    const UserToken = localStorage.getItem('UserToken');

    if (UserToken != '') {

      const sendData = {
        old_password: this.state.old_password,
        new_password: this.state.new_password,
        confirm_password: this.state.confirm_password,
      };

      axios({
        method: 'post',
        url: API_BASE_URL + "update_password/",
        headers: { 'Authorization': "Bearer " + UserToken },
        data: sendData

      }).then(response => {

        if (response.data.success == 201) {
          if (response.data.message == "Old password is required") {
            // this.setState({ responseOldMessage: true })
          
          }
           if(response.data.message=='New password and confirm password must be same'){
            this.setState({ responseNewMessage: true })
          }

           if(response.data.message=='Old password not match current user'){
            // this.setState({ responseNewMessage: true })
            this.setState({ responseConfirmMessage: true })
          }
          else {

            if (response.data.message == "New password and confirm password must be same") {
              // this.sweetAlertSamePasswordError()

            }

          }

        }
        else if (response.data.success == 200) {
          if (response.data.message == "Your Password successfully updated.") {
            this.sweetAlertUpdatePassword()
            // this.props.history.push("/mobileverification")

          }
        }

      })
        .catch((error) => {
          console.log(error)

        });
    }
  
  }
  }

  handlePostelcodeUpdate = () => {
    swal({
      text: "Your location succesfully updated.",

      icon: "success",

    })
  }




  handleSubmitPostelCode = (event) => {
  
    console.log("locatiomdatavalue", event.target.value)
    event.preventDefault();

   

    const UserToken = localStorage.getItem('UserToken');

    if (UserToken != '') {

      const sendData = {
        // address:this.state.street_name,
        postel_code: this.state.postalcode,
        // state: 90,
        // city: 5,
        // country: 15,

      };
      console.log("locationapi data",sendData)

      axios({
        method: 'post',
        url: API_BASE_URL + "update_location/",
        headers: { 'Authorization': "Bearer " + UserToken },
        data: sendData

      }).then(response => {
        if (response.data.success == 200) {
          if (response.data.message == "Your location succesfully updated.") {
            this.handleClick2()
            this.handlePostelcodeUpdate()

          }
        console.log("reslocation",response.data)
        this.UpdateLocationSuccesfully()
        


      }})
        .catch((error) => {
          console.log(error)

        });
      }

  }


  handleModal6() {
    this.setState({ show6: !this.state.show6 })
  }

  handleModal11() {
    this.setState({ show11: !this.state.show11 })
  }

  handleModal77(){
    this.setState({show77:!this.state.show77})
  }

  handleModal3() {
    this.setState({ show3: !this.state.show3 })
  }

  handleModal4() {
    this.setState({ show4: !this.state.show4 })
  }
handleModal90(){
  this.setState({show90:!this.state.show90})
}

  UpdatePostelCodeChange(evt) {
    const value = evt.target.value;
    this.setState({ [evt.target.name]: evt.target.value });
  }
  PostelCodeChange=(e)=> {
    this.setState({ postelcode: e.target.value });
    this.postalcodefromgoogle(e.target.value)
    this.setState({street_name:''})
  }

  showStreetName() {
    this.state.streetName.map((data, indexSub) => {
        if (indexSub == 1) {
            this.setState({ street_name: data.long_name })
            console.log("Guru",this.state.street_name)
        
        }
    })
}


getStreetName() {
  axios({
      method: 'GET',

      url: Google_StreetNameURL + this.state.postal_code + "&key=AIzaSyCGCUu9JeXCpiqRI_dFUlFQKEGdatvz96Q",

  }).then(response => {
      if (response.data.results.length != 0) {
          this.setState({ streetName: response.data.results[0].address_components })
          this.showStreetName()
      } 

  }).catch(error=>{
    // alert("Something Went Wrong")
    this.Somethingwrong()
    })

}

showStreetName() {
  this.state.streetName.map((data, indexSub) => {
      if (indexSub == 1) {
          this.setState({ street_name: data.long_name })
      
      }
  })
} 
  handleChange1(evt) {
    console.log("poste", evt.target.value)
    this.setState({ [evt.target.name]: evt.target.value });
    global.fullname = evt.target.value
  
    let input = this.state.input;
    input[evt.target.name] = evt.target.value;
    this.setState({ input });
    // this.postalcodefromgoogle(evt.target.value)
  }

  handleChange3(e) {
    console.log("email",e.target.value)
    this.setState({new_email:e.target.value})
    this.setState({password:e.target.value})
    // this.setState({ [evt.target.name]: evt.target.value });
  }


  postalcodefromgoogle =(postalcode)=> {
    console.log("postalcodefromgoogle",postalcode)
    this.setState({postalcode:postalcode})
    
    axios({
      method: 'GET',
      url: Google_StreetNameURL +postalcode+ "&key=AIzaSyBlVFdLcA2L_aB29DFJEdyJB6YoC2ErgQQ",
  
    }).then(response => {
      console.log(response.data)
  
      if (response.data.results.length != 0) {
        this.setState({ streetName: response.data.results[0].address_components })
        this.showStreetName()
      }
    }).catch(error=>{
  
    })
  }

  sweetAlertEmail(){
    swal({
      text: "Please Check Your Email Id",

      icon: "success",

    })

  }


  sweetAlertEmailAlreadyExist(){
    swal({
      text: "The email address is already in use by another account",

      icon: "warning",
    dangerMode: true,

    })

  }



  Emailvalidation=()=>{


    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if(this.state.new_email===''){
      formIsValid = false;
   this.setState({EmailValidationErrorShow:'*Please Enter Email_id'})
    }
     if(isNaN(this.state.new_email)){
      if(!validator.isEmail(this.state.new_email)){
        formIsValid = false;
        this.setState({EmailValidationErrorShow:'*Please Enter Valid Email'})

        
      }

    }

    this.setState({
      errors: errors
    });
    return formIsValid;

  }
  handleSubmit3 = event => {
    event.preventDefault();
    this.setState({EmailValidationErrorShow:''})
    if(this.Emailvalidation()){
      let fields = {};
      fields["new_email"] = "";
    const UserToken = localStorage.getItem('UserToken');

    if (UserToken != '') {

      const sendData = {
        new_email: this.state.new_email

      };

      axios({
        method: 'post',
        url: API_BASE_URL + "update_email/",
        headers: { 'Authorization': "Bearer " + UserToken },
        data: sendData

      }).then(response => {
        if (response.data.success == 201) {

          if (response.data.message == "This email allready registered.") {
            // this.setState({ MessageAlreadyExistEmail: true })
            
          this.sweetAlertEmailAlreadyExist()
          this.handleModal3()

          }
        }
        else if (response.data.success == 200) {
          
          if(response.data.message=="Otp send successfully on your mail"){


          localStorage.setItem('email', this.state.new_email)
          
          this.sweetAlertEmailsend()
          this.handleModal3()
          
          // alert('please check Your Email')
          this.props.history.push("/emailverification")


        }
      }


      })
        .catch((error) => {


        });
    }
  }
    // auth.createUserWithEmailAndPassword(this.state.email , this.state.password)
    // .then((userCredential)=>{
    //   console.log("usercredential",userCredential)
    //   this.setState({UserCredential:userCredential})
    //     // send verification mail.
    //   userCredential.user.sendEmailVerification();
    //   auth.signOut();
    //   this.sweetAlertEmail()
      // alert("Email sent");
      // this.handleModal3()
    // })
    // // The email address is already in use by another account.
    // .catch((error)=>{
    //      this.sweetAlertEmailAlreadyExist()
    //      this.handleModal3()
    // });

  }


  handleChange4(evt) {
    console.log("evtmobile",evt.target.value)
    this.setState({new_mobile:evt.target.value})
    this.setState({ [evt.target.name]: evt.target.value });
  }

  // validate4() {
  //   let input = this.state.input;
  //   let errors = {};
  //   let isValid = true;
  
  //  if (!input["new_mobile"]) {
  //         isValid = false;
  //         errors["new_mobile"] = "Phone Number is required.";
  
  //     }
  
  //     if(typeof input["new_mobile"] !== "undefined"){
  //         if(!input["new_mobile"].match(/^[0-9\b]+$/)){
              
  //            isValid = false;
  //            errors["new_mobile"] = "Please enter only numbers.";
  //         }        
  //      }
  
  //   this.setState({ errors: errors });
  //   return isValid;
  // }


  Mobilevalidation=()=>{


    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if(this.state.new_mobile===''){
      formIsValid = false;
   this.setState({MobileValidationErrorShow:'*Please Enter Mobile Number'})
    }
     if(isNaN(this.state.new_mobile)){
   
        formIsValid = false;
        this.setState({MobileValidationErrorShow:'*Character Not Allowed'})

        
      

    }

    //  if(this.state.new_mobile.length<=8){
    //   formIsValid = false;
    //   this.setState({MobileValidationErrorShow:'*Please Enter Mobile Number More then 8 digit'})

    // }

    this.setState({
      errors: errors
    });
    return formIsValid;

  }

  handleSubmit4 = event => {
    event.preventDefault();
    // event.target.reset();
    this.setState({MobileValidationErrorShow:''})
    if(this.Mobilevalidation()){
      let fields = {};
      fields["new_mobile"] = "";
    
    // if(this.state.new_mobile==''){
    //   this.setState({new_mobile_error:'please enter MObile'})
    
    const UserToken = localStorage.getItem('UserToken');

    if (UserToken != '') {
      const NewMobile =  this.state.new_mobile
      const NewMobile2 =  this.state.new_mobile

      // const sendData = {
      //   new_mobile: this.state.new_mobile

      // };
      const sendData = new FormData();
      if(this.state.CountryCodeValue!=''){
      sendData.append('new_mobile', NewMobile);
      }
      else {
        sendData.append('new_mobile', NewMobile2);
      }

      axios({
        method: 'post',
        url: API_BASE_URL + "update_mobile/",
        headers: { 'Authorization': "Bearer " + UserToken },
        data: sendData

      }).then(response => {
        if (response.data.success == 201) {
          if (response.data.message == "Alredy exist number.") {
          }
        }
        else if (response.data.success == 200) {
          if(this.state.CountryCodeValue!=''){
            localStorage.setItem('new_mobile', NewMobile)
            localStorage.setItem("country_code",this.state.CountryCodeValue)
          }
          else {
              localStorage.setItem('new_mobile', NewMobile2)
          } 


          localStorage.setItem('username', this.state.new_mobile)
          // this.setState({new_email:response.data.student_mobile})
          localStorage.setItem("email", this.state.new_email)
          this.props.history.push("/mobileverification")

        }


      })
        .catch((error) => {

        });
    }
  }

  }

  componentDidMount() {

    const value = localStorage.getItem('usertype');
    //alert(value);

    this.setState({ usertype_var: value });

    this.TeacherProfileBYToken();


  }

  TeacherProfileBYToken() {

    const UserToken = localStorage.getItem('UserToken');

    axios({
      method: 'GET',
      url: API_BASE_URL + "student_profile/",
      headers: { 'Authorization': "Bearer " + UserToken },


    }).then(response => {
      console.log("res edit profile", response.data.student_data[0].gender)
      this.setState({ fullname: response.data.student_data[0].fullname })
      this.setState({gender:response.data.student_data[0].gender})
      this.setState({ new_email: response.data.student_mobile })
      this.setState({ new_mobile: response.data.student_email })
      this.setState({ ProfileImageData: response.data.student_data })

    })

  }

  handleChange6(evt) {

    const files = evt.target.files;
    this.setState({
      [evt.target.name]: evt.target.files[0]
    })
  }

  showHide(e) {
    this.setState({
      old_type: this.state.old_type === 'input' ? 'password' : 'input'
    })
  }
  showHide1(e) {
    this.setState({
      new_type: this.state.new_type === 'input' ? 'password' : 'input'
    })
  }
  showHide2(e) {
    this.setState({
      confirm_type: this.state.confirm_type === 'input' ? 'password' : 'input'
    })
  }

  validate2() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;
     if (this.state.fullname==='') {
          isValid = false;
          errors["fullname"] = "Fullname is required.";
        }
        
        // if(typeof input["fullname"] !== "undefined"){
        //   if(!input["fullname"].match(/^[aA-zZ\s]+$/)){
              
        //      isValid = false;
        //      errors["fullname"] = "Please enter only characters.";
        //   }        
      //  }

      else if(!isNaN(this.state.fullname)){
        
             isValid = false;
             errors["fullname"] = "Please enter only characters.";
          
      }
  
    this.setState({ errors: errors });
    return isValid;
  }

  handleSubmit1 = event => {
    event.preventDefault();
    // window.location.reload(false);
    event.target.reset();
 
    // this.props.handlerFromParant(this.state.fullname);
    if (this.validate2()) {
      let input = {};
      input["fullname"] = "";
    const UserToken = localStorage.getItem('UserToken');

    if (UserToken != '') {
      // debugger
      const sendData = {
        fullname: this.state.fullname,
        gender: this.state.gender
      };

      axios({
        method: 'post',
        url: API_BASE_URL + "update_name/",
        headers: { 'Authorization': "Bearer " + UserToken },
        data: sendData

      }).then(response => {
        if (response.data.success == 200) {
          if (response.data.message == "Your gender successfully updated") {

            this.TeacherProfileBYToken()
            this.sweetAlertUpdateName();
           
            this.handleModal77()
            this.setState({SubmitFullname:this.state.fullname})
            localStorage.setItem('datafinal',this.state.SubmitFullname)
            // this.refresh();
            

          }
         
        }

         if(response.data.success==201){
          if (response.data.message == "Your fullname allready updated") {
            this.handleModal77()
            this.sweetAlertAlreadyUpdateName()
            
          }

        }
      })
        .catch((error) => {

        });
    }
  }

  }
  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result })
      );
      this.setState({
        [e.target.name]: e.target.files[0]
      })
      reader.readAsDataURL(e.target.files[0]);
    }

  };

  handleSubmit6 = event => {
    console.log("ooooooojiiiiii")
    event.preventDefault();
    event.target.reset();
    event.target.value = null;


    const UserToken = localStorage.getItem('UserToken');

    if (UserToken != '') {
      const sendData = new FormData();
      sendData.append("profile_image", this.state.resultCropImage);

      axios({
        method: 'post',
        url: API_BASE_URL + "upload_profile_image/",
        headers: { 'Authorization': "Bearer " + UserToken },
        data: sendData

      }).then(response => {

        console.log("dddwdfs", response.data)
        if (response.data.success == 200) {
          this.handleModal6()
          if(response.data.message=='Profile image uploaded'){
          
        

this.TeacherProfileBYToken()
        
          this.profileImageResponse()
          
          
          this.setState({SubmitUploadImage:this.state.resultCropImage})
          console.log("updateimage",this.state.SubmitUploadImage)
          localStorage.setItem('imageupdate',this.state.SubmitUploadImage)
          this.handleModal6()  

                    this.refresh();

          this.setState({ MessageImageRequired: true })
          }

        }

      })
        .catch((error) => {

          // console.log(error)

        });
    }

  }





  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result })
      );
      this.setState({
        [e.target.name]: e.target.files[0]
      })
      reader.readAsDataURL(e.target.files[0]);
    }

  };

  // If you setState the crop in here you should return false.
  c

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      console.log(resolve)
      canvas.toBlob(blob => {
        this.setState({ resultCropImage: blob })
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }


  sweetAlertEmailsend=()=>{
    swal({
      text: "Otp send successfully on your mail",

      icon: "success",

    })


  }


  sweetAlertAlreadyEmailsend=()=>{
    swal({
      text: "Otp send successfully on your mail",

      icon: "success",

    })

  }

  
PhoneCode = (id) => {
  console.log("gurubhai",id)
  // console.log("gurubhai",countryCode.countryCode)
  this.setState({ CountryCodeValue:id })
}


profileImageResponse=()=>{
  swal({
    text: "Profile image  uploaded successfully.",

    icon: "success",

  })

}
  render() {

    const { crop, croppedImageUrl, src } = this.state;
    const ProfileImageDataConst =

      this.state.ProfileImageData.map((Data) => {
        if (Data.image == null) {
          if (Data.gender == "female") {
            return (
              <div id="imagePreview">
                <img src={defaultFemaleImg} alt="" />
              </div>
            )
          } else {
            return (
              <div id="imagePreview">
                <img src={defaultMaleImg} alt="" />
              </div>
            )
          }

        } else {
          return (
            <div id="imagePreview">
              <img className="" src={BASE_URL + Data.image} alt="" />
            </div>

          )
        }

      })



    return (
      <main>
        <Header></Header>
        <section className="">
          <div className="container-fluid margin_top width_custom">
            <div className="row">

              <div className="col-md-3 mt30">

                {this.state.usertype_var == 0 ?
                  <SideBar data={this.state.SubmitFullname} ></SideBar> :
                  <TutorSideBar></TutorSideBar>}
              </div>

              <div className="col-md-9 mt30">
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="dashboard_headings">Edit Profile</h2>
                  </div>
                </div>
                <div className="row box_custom set_for_mob">
                  <div className="col-md-12">
                    <div class="edit_profile_box">
                      <div class="edit_name">
                        <div class="avatar-upload m-0">
                          <div class="avatar-edit">
                            <input type="file" id="fileinput" name="profile_image" onChange={this.onSelectFile} onClick={() => this.handleModal6()} />


                            <label for="fileinput">
                              <i class="iconn_greyy"><FontAwesomeIcon icon={faCamera} /></i>
                            </label>

                            <Modal show={this.state.show6} onHide={() => this.handleModal6()}>
                              <Modal.Header className="allmodal_header modal-header" closeButton>
                                <h2> Upload Your Profile</h2>
                              </Modal.Header>

                              <Modal.Body>
                                <form class="new_modal_form" onSubmit={this.handleSubmit6} method="post">
                                  {src && (
                                    <ReactCrop
                                      src={src}
                                      crop={crop}
                                      ruleOfThirds
                                      onImageLoaded={this.onImageLoaded}
                                      onComplete={this.onCropComplete}
                                      onChange={this.onCropChange}
                                    />
                                  )}
                                  {croppedImageUrl && (
                                    <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />
                                  )}

                                  <div class="text-center all_popups_buttonss custom_modal_footer">
                                    <button class="submit_bb" type="submit"> Save</button>
                                  </div>

                                </form>
                              </Modal.Body>
                            </Modal>
                          </div>
                          <div class="avatar-preview">

                            {ProfileImageDataConst}

                          </div>
                        </div>

                      </div>
                      <div class="edit_all">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="edit_field">
                              <div class="img_pro_edit">
                                <i><FontAwesomeIcon icon={faUser} /></i>
                              </div>
                              <div class="edit_field_sec">
                                <h5>Edit Name</h5>
                              </div>
                              <div class="edit_icon_pro" onClick={() => this.handleModal77()}>
                                <i class="iconn_greyy"><FontAwesomeIcon icon={faEdit} /></i>
                              </div>
                            </div>

                            <div class="edit_field">
                              <div class="img_pro_edit">
                                <i><FontAwesomeIcon icon={faMap} /></i>
                              </div>
                              <div class="edit_field_sec">
                                <h5>Edit Location</h5>
                              </div>
                              <div class="edit_icon_pro" onClick={() => this.handleModal11()}>
                                <i class="iconn_greyy"><FontAwesomeIcon icon={faEdit} /></i>
                              </div>
                            </div>
                            <div class="edit_field">
                              <div class="img_pro_edit">
                                <i><FontAwesomeIcon icon={faEnvelope} /></i>
                              </div>
                              <div class="edit_field_sec">
                                <h5>Edit Email</h5>
                              </div>
                              <div class="edit_icon_pro">
                                <i onClick={() => this.handleModal3()} class="iconn_greyy"><FontAwesomeIcon icon={faEdit} /></i>
                              </div>
                            </div>
                            <div class="edit_field">
                              <div class="img_pro_edit">
                                <i   ><FontAwesomeIcon icon={faMobile} /></i>
                              </div>
                              <div class="edit_field_sec">
                                <h5>Edit Mobile</h5>

                              </div>
                              <div class="edit_icon_pro" data-toggle="modal" data-target="#edit_pro_modal3">
                                <i onClick={() => this.handleModal4()} class="iconn_greyy"><FontAwesomeIcon icon={faEdit} /></i>
                              </div>
                            </div>
                            <div class="edit_field">
                              <div class="img_pro_edit">
                                <i><FontAwesomeIcon icon={faLock} /></i>
                              </div>
                              <div class="edit_field_sec">
                                <h5>Edit Password</h5>
                                <span>********</span>
                              </div>
                              <div class="edit_icon_pro" >
                                <i onClick={() => this.handleModal90()} class="iconn_greyy"><FontAwesomeIcon icon={faEdit} /></i>
                              </div>
                            </div>
                          </div>
                          <div>
                            {/* <div class="modal" id="edit_pro_modal" >
                              <div class="modal-dialog">
                                <div class="modal-content">
                                  <div class="allmodal_header modal-header">
                                    <h2>Edit your Name</h2>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                                  </div>
                                  <div class="modal-body"> */}
                                   <Modal show={this.state.show77} onHide={() => this.handleModal77()}>
                              <Modal.Header className="allmodal_header modal-header" >
                                <h2>Edit your Name</h2>
                              </Modal.Header>

                              <Modal.Body>

                                    <form class="in-modal booktutorr_form" onSubmit={this.handleSubmit1} method="post">
                                      <div class="row">

                                        <div class="col-md-12 text-left">
                                          <label>First Name</label><br></br>
                                          <input 
                                          type="text" 
                                          name="fullname"
                                           defaultValue={this.state.fullname} 
                                           value={this.state.input.fullname} 
                                           onChange={this.handleChange1}
                                            placeholder="Enter First Name"  />
                                          <center><div className="text-danger">{this.state.errors.fullname}</div></center> 
                                        </div>

                                        <div class="col-md-12">
                                          <div class="learning_skills_modal">
                                            <h5>Select Gender</h5>
                                            <div class="form-group">
                                              <input 
                                              onChange={this.GenderChange} 
                                              type="radio" 
                                              checked={this.state.gender=="male"} 
                                              id="male" 
                                              name="gender" 
                                              value="male"  />
                                              <label class="label_age" for="male">Male</label>
                                            </div>
                                            <div class="form-group">
                                              <input onChange={this.GenderChange}  checked={this.state.gender=="female"}  type="radio" id="female" name="gender"  value="female"  />
                                              <label class="label_age" for="female">Female</label>
                                            </div>
                                            <div class="form-group">
                                              <input  onChange={this.GenderChange}  checked={this.state.gender=="other"}  type="radio" id="other" name="gender"  value="other" />
                                              <label class="label_age" for="other">Other</label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-md-12 text-center form-group">
                                          <div className="text-success"> {this.state.MessageUpdateNameSuccesfully ? "Your Name has been Updated Succesfully." : null} </div>
                                        </div>

                                        <div class="col-md-12 text-center all_popups_buttonss custom_modal_footer">
                                          <button  class="submit_bb" type="submit"> Save</button>
                                        </div>

                                      </div>

                                    </form>
                                    </Modal.Body>
                                    </Modal>

                                  {/* </div>

                                </div>
                              </div>
                            </div> */}

                            {/* <div class="modal" id="edit_pro_modal1" >
                              <div class="modal-dialog">
                                <div class="modal-content">
                                  <div class="allmodal_header modal-header">
                                    <h2>Edit your Location</h2>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                                  </div>
                                  <div class="modal-body"> */}

                            <Modal show={this.state.show11} onHide={() => this.handleModal11()}>
                              <Modal.Header className="allmodal_header modal-header" >
                                <h2> Edit your Location</h2>
                              </Modal.Header>

                              <Modal.Body>
                                <form onSubmit={this.handleSubmitPostelCode} method="post" class="in-modal booktutorr_form">
                                  <div class="row">
                                    <div class="col-md-12 text-left form-group">
                                      <label>Enter Your Location</label><br></br>
                                      <input value={this.state.postalcode} maxLength="6" onChange={this.PostelCodeChange} type="text" name="postalcode" placeholder="Enter PostalCode " required />
                                    </div>
                                    <div class="col-md-12 text-left form-group">
                                    <input type="text" id="streetname" name="street_name"
                                                 onChange={this.UpdatePostelCodeChange} 
                                                 value={this.state.street_name}  placeholder="Street Name" required />
                                                 </div>
                                    <div class="col-md-12 text-center all_popups_buttonss custom_modal_footer">
                                      <Button  onClick={this.handleClick2} className="cancel_bb"> Cancel</Button>
                                      <Button  className="submit_bb" type="submit"> Update</Button>
                                    </div>
                                  </div>

                                </form>
                              </Modal.Body>
                            </Modal>







                            <div class="col-md-12">
                              <div class="edit_field">

                                <Modal show={this.state.show3} onHide={() => this.handleModal3()}>
                                  <Modal.Header className="allmodal_header modal-header" closeButton>

                                    <h2>Change your email</h2>

                                  </Modal.Header>

                                  <Modal.Body>

                                    <form class="in-modal booktutorr_form" onSubmit={this.handleSubmit3}>
                                      <div className="form-group">
                                        <input value={this.state.fields.new_email} defaultValue={this.state.new_email} onChange={this.handleChange3} type="text" name="new_email" placeholder="Enter Your Email" />
                                      </div>

                                      <div class="text-center all_popups_buttonss custom_modal_footer">
                                        <button class="submit_bb" type="submit"> Save</button>
                                      </div>
                                      <div className="form-group">
                                    <div className="text-danger">{this.state.EmailValidationErrorShow}</div>  
                                      </div>
                                    </form>
                                  </Modal.Body>

                                </Modal>
                              </div>
                            </div>

                            <Modal show={this.state.show4} onHide={() => this.handleModal4()}>

                              <Modal.Header className="allmodal_header modal-header" closeButton>
                                <h2>Change your Contact Number</h2>
                              </Modal.Header>
                              <Modal.Body>
                                <form class="in-modal booktutorr_form" onSubmit={this.handleSubmit4}>
                                <PhoneInput
                                                  country={'sg'}
                                                  value={this.state.phone}
                                                  onChange={this.PhoneCode}
                                                  enableSearch={true}                                          
                                                />
                                  <div class="row">
                                    <div class="col-md-12 text-left form-group">
                                      <input   defaultValue={this.state.new_mobile} type="text" id="Mobile" name="new_mobile" onChange={this.handleChange4} minlength="0" className="" maxlength="10" placeholder="Enter New Mobile"  />
                                    </div>
                                  </div>

                                  <div class="text-center all_popups_buttonss custom_modal_footer">
                                    <button class="submit_bb" type="submit"> Save</button>
                                  </div>
                               <div className="form-group">
                            <div className="text-danger">{this.state.MobileValidationErrorShow}</div>   
                                 </div>   

                                </form>
                              </Modal.Body>

                            </Modal>

                            {/* <div class="modal" id="edit_pro_modal4" >
                              <div class="modal-dialog">
                                <div class="modal-content">
                                  <div class="allmodal_header modal-header">
                                    <h2>Edit your Password</h2>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                                  </div>
                                  <div class="modal-body"> */}
                        <Modal show={this.state.show90} onHide={() => this.handleModal90()}>

                        <Modal.Header className="allmodal_header modal-header" closeButton>
                              <h2>Edit your Password</h2>
                                          </Modal.Header>
                                          <Modal.Body>

                                    <form class="in-modal booktutorr_form" onSubmit={this.handleSubmit5} method="POST">
                                      <div class="row text-left">
                                        <div class="col-md-12">
                                          <div class="form-group eye_iconn_show">
                                            <label>Old Password</label><br></br>
                                            <input type={this.state.old_type} id="Password1" name="old_password" onChange={this.handleChange5} minlength="0" className="" maxlength="" placeholder="Enter Old Password"  />
                                            <center> <span className="password__show2" onClick={this.showHide}>
                                              {this.state.old_type === 'input' ?
                                                <i class="fa fa-eye" aria-hidden="true"><FontAwesomeIcon icon={faEye} /></i>
                                                :
                                                <i class="fa fa-eye" aria-hidden="true"><FontAwesomeIcon icon={faEyeSlash} /></i>
                                              }
                                            </span></center>
                                            <center><div className="text-danger">{this.state.oldPasswordValidationErrorShow}</div></center>
                                          </div>
                                        </div>
                                        {/* <div className="text-danger">{this.state.oldPasswordValidationErrorShow}</div>  */}
                                        {/* <div className="text-danger">{this.state.responseOldMessage ?'Old password is required':null}</div> */}
                                        <div class="col-md-12">
                                          <div class="form-group eye_iconn_show">
                                            <label>New Password</label><br></br>
                                            <input type={this.state.new_type} value={this.state.fields.new_password} id="Password2" name="new_password" minlength="0" onChange={this.handleChange5} className="" maxlength="" placeholder="Enter New Password"  />
                                            <center><span className="password__show2" onClick={this.showHide1}>
                                              {this.state.new_type === 'input' ?
                                                <i class="fa fa-eye" aria-hidden="true"><FontAwesomeIcon icon={faEye} /></i>
                                                :
                                                <i class="fa fa-eye" aria-hidden="true"><FontAwesomeIcon icon={faEyeSlash} /></i>
                                              }

                                            </span></center>
                                            <center><div className="text-danger">{this.state.newPasswordValidationErrorShow}</div></center>
                                            
                                          </div>
                                        </div>
                                        {/* <div className="text-danger">{this.state.newPasswordValidationErrorShow}</div>  */}
                                        {/* <div className="text-danger">{this.state.responseNewMessage ?'New password is required':null}</div> */}
                                        <div class="col-md-12">
                                          <div class="form-group eye_iconn_show">
                                            <label>Confirm Password</label><br></br>
                                            <input type={this.state.confirm_type} value={this.state.fields.confirm_password} id="Password3" name="confirm_password" minlength="0" className="" onChange={this.handleChange5} maxlength="" placeholder="Confirm Your Password"  />
                                            <center><span className="password__show2" onClick={this.showHide2}>
                                              {this.state.confirm_type === 'input' ?
                                                <i class="fa fa-eye" aria-hidden="true"><FontAwesomeIcon icon={faEye} /></i>
                                                :
                                                <i class="fa fa-eye" aria-hidden="true"><FontAwesomeIcon icon={faEyeSlash} /></i>
                                              }
                                            </span></center>
                                            <center><div className="text-danger">{this.state.ConfirmPasswordValidationErrorShow}</div></center>
                                            
                                          </div>
                                        </div>
                                        {/* <div className="text-danger">{this.state.ConfirmPasswordValidationErrorShow}</div> */}
                                        {/* <div className="text-danger">{this.state.responseConfirmMessage ?'Confirm password is required':null}</div> */}

                                      </div>
                                      <div className="form-group">
                                        <center>
                                          {
                                            this.state.MessageNewConfirmPasswordMatch && !this.state.MessageValidOldPassword ?
                                              <div className="form-group">
                                                <div className="text-danger"> {this.state.MessageNewConfirmPasswordMatch ? "New and Confirm Password Don't Match." : null} </div>
                                              </div>

                                              : null

                                          }
                                          {
                                            this.state.MessageValidOldPassword && !this.state.MessageUpdatePasswordSuccesfully ?
                                              <div className="form-group">
                                                <div className="text-danger">  {this.state.MessageValidOldPassword ? "Please Enter Valid Old Password." : null} </div>
                                              </div>
                                              : null
                                          }

                                          {
                                            this.state.MessageUpdatePasswordSuccesfully ?
                                              <div className="form-group">
                                                <div className="text-success">  {this.state.MessageUpdatePasswordSuccesfully ? "Your Password has been Changed Succesfully." : null} </div>
                                              </div>
                                              : null
                                          }
                                        </center>
                                      </div>
                                      <div className="text-danger">{this.state.responseNewMessage ?'New password and confirm password must be same':null}</div>
                                     <div className="text-danger">{this.state.responseConfirmMessage ?'Old password not match current user':null}</div> 
                                      <div class="text-center all_popups_buttonss custom_modal_footer">
                                        <button class="submit_bb" type="submit"> Save</button>
                                      </div>
                                    </form>
                                    </Modal.Body>
                                    </Modal>

                                  {/* </div> */}

                                {/* </div> */}
                              {/* </div> */}
                            {/* </div> */}

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
        <Footer></Footer>
      </main>
    );
  }
}

export default Editprofile;