import React from 'react';
import ReactDOM from 'react-dom';
import Header from '../common/header';
import Footer from '../common/footer.js';
import SideBar from "./sideBar.js";
import { faFilter, faLowVision } from '@fortawesome/free-solid-svg-icons';

class CardDetail extends React.Component {

render(){
return(
<main>
<Header></Header>
<section className="">
    <div className="container-fluid margin_top width_custom">
        <div className="row">
            <div className="col-md-3 mt30">
                <SideBar></SideBar>
            </div>

            <div className="col-md-9 mt30">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="dashboard_headings">Card Detail</h2>
                    </div>
                </div>
                <div class="row box_custom">
                    <div class="col-md-12">
                        <form class="in-modal booktutorr_form loginpage" onSubmit={this.handleSubmit8} >
                            <div class="row text-left">
                                <div class="col-md-6">
                                    <label>Card Holder Name</label><br></br>
                                    <input type="text" placeholder="Card holder name" required />
                                </div>
                                <div class="col-md-6">
                                    <label>Card Number</label><br></br>
                                    <input type="text" placeholder="Card number" required />
                                </div>
                                <div class="col-md-6">
                                    <label>Expiry Date</label><br></br>
                                    <input type="text" placeholder="Expiry date" required />
                                </div>
                                <div class="col-md-6">
                                    <label>CVV</label><br></br>
                                    <input type="text" placeholder="Cvv" required />
                                </div>
                                <div class="col-md-3 m-auto">
                                    <button class="btn  btn-block send-button border_radius_20 tx-tfm mb-4" type="submit">Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<Footer></Footer>
</main>
)}}

export default CardDetail;

