// import React from 'react';
// import Header from '../common/header';
// import StarRatings from 'react-star-ratings';
// import Footer from '../common/footer';
// import axios from 'axios';
// import { Link } from "react-router-dom";
// import defaultMaleImg from '../../assets/img/default_male.png';
// import defaultFemaleImg from '../../assets/img/default_female.png';
// import { Tabs, Tab } from 'react-bootstrap-tabs';
// import moment from 'moment';
// import 'moment-timezone';
// import { API_BASE_URL, BASE_URL } from '../../config/api.js';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faStar } from '@fortawesome/free-solid-svg-icons';
// import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
// import SideBar from "./sideBar.js";
// class User_Booking extends React.Component {
// constructor(props) {
// super(props)
// this.state = {
// booking_tutor_list_direct:[],
// booking_null_tutor_list: [],
// booking_tutor_list: [],
// booking_tutor_list2: [],
// booking_tutor_list3: [],
// booking_tutor_list4: [],
// booking_tutor_list5: []
// }
// this.handleId = this.handleId.bind(this);
// this.onChatHandle = this.onChatHandle.bind(this);
// }
// handleId(id, tutorid) {
// const val = localStorage.setItem("country_code", id);
// const tutor_id = localStorage.setItem("tutor_id", tutorid);
// }
// onChatHandle(tutorId) {
// this.props.history.push({ 
// pathname: '/conversations',
// state: { id: tutorId }
// });
// }
// componentDidMount() {
// const UserToken = localStorage.getItem('UserToken');
// if (UserToken != '') {
// const sendData = {
// booking_status: 'pending',
// }
// axios({
// method: 'post',
// url: API_BASE_URL + "user_booking_list/",
// headers: { 'Authorization': "Bearer " + UserToken },
// data: sendData
// }).then(response => {
// this.setState({
// booking_tutor_list_direct: response.data.booking_tutor_list,
// booking_null_tutor_list:response.data.booking_null_tutor_list})
// }).catch((error) => {
// console.log(error)
// })
// }
// if (UserToken != '') {
// const sendData3 = {
// booking_status: 'upcoming',
// }
// axios({
// method: 'post',
// url: API_BASE_URL + "user_booking_list/",
// headers: { 'Authorization': "Bearer " + UserToken },
// data: sendData3
// }).then(response => {
// this.setState({ booking_tutor_list: response.data.booking_tutor_list, })
// //console.log("shubham", response.data)
// })
// }
// if (UserToken != '') {
// const sendData2 = {
// booking_status: 'completed',
// }
// axios({
// method: 'post',
// url: API_BASE_URL + "user_booking_list/",
// headers: { 'Authorization': "Bearer " + UserToken },
// data: sendData2
// }).then(response => {
// this.setState({ booking_tutor_list3: response.data.booking_tutor_list })
// })
// }
// if (UserToken != '') {
// const sendData4 = {
// booking_status: "waiting for payment"
// }
// axios({
// method: 'post',
// url: API_BASE_URL + "user_booking_list/",
// headers: { 'Authorization': "Bearer " + UserToken },
// data: sendData4
// }).then(response => {
// this.setState({ booking_tutor_list4: response.data.booking_tutor_list })
// //console.log("shubham", response.data)
// })
// }
// if (UserToken != '') {
// const sendData5 = {
// booking_status: 'cancelled',
// }
// axios({
// method: 'post',
// url: API_BASE_URL + "user_booking_list/",
// headers: { 'Authorization': "Bearer " + UserToken },
// data: sendData5
// }).then(response => {
// this.setState({ booking_tutor_list5: response.data.booking_tutor_list })
// })
// }
// }
// render() {
// const BookingPendingListConst = this.state.booking_null_tutor_list.map((booking) => {
// return (
// this.state.booking_null_tutor_list.length != 0 ?
// <div className="row box_custom_booking">
//    <div className="col-md-6 col-6 upper_booking_heads">
//       <h6>{moment(booking.booking_start).format("DD MMM YYYY")+", "}<span class="booking_id_color">{booking.id}</span></h6>
//       <h4>{booking.category_name}</h4>
//       <p className="sub_b">Subject :  <b>{booking.sub_category_name}</b></p>
//    </div>
//    <div className="col-md-6 col-6 text-right">
//       <button className="pending_btn">{booking.booking_status}</button><br />
//       <Link onClick={() =>
//       this.handleId(booking.id, booking.tutor)} className="no_underline"
//       to={{
//       pathname: "userbookingdetail",
//       params: booking.id,
//       tutor_params_id: booking.tutor
//       }}>
//       <div className="arrow_left mt-3">&#8594;</div>
//       </Link>
//    </div>
// </div>
// : null
// )
// })
// const BookingPendingDirectListConst  = this.state.booking_tutor_list_direct.map((booking) => {
// return (
// this.state.booking_tutor_list_direct.length != 0 ?
// <div className="row box_custom_booking">
//    <div className="col-md-6 col-6 upper_booking_heads">
//       <h6>{moment(booking.booking_start).format("DD MMM YYYY")+", "}<span class="booking_id_color">{booking.id}</span></h6>
//       <h4>{booking.category_name}</h4>
//       <p className="sub_b">Subject :  <b>{booking.sub_category_name}</b></p>
//    </div>
//    <div className="col-md-6 col-6 text-right">
//       <button className="pending_btn">{booking.booking_status}</button><br />
//       <Link onClick={() =>
//       this.handleId(booking.id, booking.tutor)} className="no_underline"
//       to={{
//       pathname: "userbookingdetail",
//       params: booking.id,
//       tutor_params_id: booking.tutor
//       }}>
//       <div className="arrow_left mt-3">&#8594;</div>
//       </Link>
//    </div>
//    <div className="col-md-12">
//       <div className="border_l"></div>
//    </div>
//    <div className="col-md-6 col-6">
//       <div className="booking_info">
//          {booking.image == null ?
//          booking.gender == "female" ?
//          <img class="booking_user_img" src={defaultFemaleImg} alt="" />
//          : <img class="booking_user_img" src={defaultMaleImg} alt="" />
//          : <img class="booking_user_img" src={BASE_URL + booking.image} alt="" />
//          }
//          <div className="info_pera">
//             <h2>{booking.fullname} </h2>
//             <div>
//                {
//                booking.qualification == '' || booking.qualification == null ?
//                null
//                : <button>{booking.qualification}</button>}
//                {booking.rating != '' ?
//                <StarRatings
//                   rating={Number(booking.rating)}
//                   starRatedColor="#ffc107"
//                   starDimension="18px"
//                   starSpacing="0px"
//                   />
//                : 
//                <p>New User</p>
//                }
//             </div>
//          </div>
//       </div>
//    </div>
//    <div className="col-md-6 col-6 text-right my-auto">
//    {booking.online_status=='1' ? 
//       <div class="status_div set_status">
//       <div className="online_status"></div>
//       ONLINE
//       </div>
//        :
//       <div class="status_div left_justify">
//       <div className="offline_status"></div>
//       Offline
//          </div>
//    }
//       <button onClick={() =>
//          this.onChatHandle(booking.tutor)} className="chat_btn st">

//          <i className="fa fa-star" aria-hidden="true">
//             <FontAwesomeIcon icon={faFacebookMessenger}></FontAwesomeIcon>
//          </i>
//          Chat
//       </button>
//    </div>
// </div>
// : null
// )
// })
// const booking_tutor_listListConst = this.state.booking_tutor_list.map((booking) => {
// return (
// this.state.booking_tutor_list.length != 0 ?
// <div className="row box_custom_booking">
//    <div className="col-md-6 col-6 upper_booking_heads">
//       <h6>{moment(booking.booking_start).format("DD MMM YYYY")+", "}<span class="booking_id_color">{booking.id}</span></h6>
//       <h4>{booking.category_name}</h4>
//       <p className="sub_b">Subject :  <b>{booking.sub_category_name}</b></p>
//    </div>
//    <div className="col-md-6 col-6 text-right">
//       <button className="accepted_btn">{booking.booking_status}</button><br />
//       <Link onClick={() =>
//       this.handleId(booking.id, booking.tutor)} className="no_underline"
//       to={{
//       pathname: "userbookingdetail",
//       params: booking.id,
//       tutor_params_id: booking.tutor
//       // params1:booking.user,
//       // params2:booking.id
//       }}
//       >
//       <div className="arrow_left mt-3">&#8594;</div>
//       </Link>
//    </div>
//    <div className="col-md-12">
//       <div className="border_l"></div>
//    </div>
//    <div className="col-md-6">
//       <div className="booking_info">
//          {booking.image == null ?
//          booking.gender == "female" ?
//          <img class="booking_user_img" src={defaultFemaleImg} alt="" />
//          : <img class="booking_user_img" src={defaultMaleImg} alt="" />
//          : <img class="booking_user_img" src={BASE_URL + booking.image} alt="" />
//          }
//          <div className="info_pera">
//             <h2>{booking.fullname} </h2>
//             <div>
//                {booking.qualification == '' || booking.qualification == null ?
//                null
//                : <button>{booking.qualification}</button>}
//                {booking.rating != '' ?
//                <StarRatings
//                   rating={Number(booking.rating)}
//                   starRatedColor="#ffc107"
//                   starDimension="18px"
//                   starSpacing="0px"
//                   />
//                : 
//                <p>New User</p>
//                }
//                {/* <button>Graduate</button> */}
//             </div>
//          </div>
//       </div>
//    </div>
//    <div className="col-md-6 text-right my-auto">
//       {/* 
//       <button className="chat_btn">
//          <i className="fa fa-star" aria-hidden="true">
//             <FontAwesomeIcon icon={faFacebookMessenger}></FontAwesomeIcon>
//          </i>
//          Chat
//       </button>
//       */}

// {booking.online_status=='1' ? 
//                                        <div class="status_div left_justify">
//                                           <div className="online_status"></div>
//                                           ONLINE
//                                        </div>
//                                        :
//                                        <div class="status_div left_justify">
//                                           <div className="offline_status"></div>
//                                           Offline
//                                        </div>
//                                        }
//       <button onClick={() =>
//          this.onChatHandle(booking.tutor)} className="chat_btn">
//          <i className="fa fa-star" aria-hidden="true">
//             <FontAwesomeIcon icon={faFacebookMessenger}></FontAwesomeIcon>
//          </i>
//          Chat
//       </button>
//    </div>
//    {/* 
//    <div className="col-md-12">
//       <div className="border_l"></div>
//    </div>
//    */}
// </div>
// :
// <div></div>
// )
// })
// const booking_tutor_list3ListConst = this.state.booking_tutor_list3.map((booking) => {
// return (
// this.state.booking_tutor_list3.length != 0 ?
// <div className="row box_custom_booking">
//    <div className="col-md-6 col-6 upper_booking_heads">
//       <h6>{moment(booking.booking_start).format("DD MMM YYYY")+", "}<span class="booking_id_color">{booking.id}</span></h6>
//       <h4>{booking.category_name}</h4>
//       <p className="sub_b">Subject :  <b> {booking.sub_category_name}</b></p>
//    </div>
//    <div className="col-md-6 col-6 text-right">
//       <button className="accepted_btn">{booking.booking_status}</button><br />
//       <Link onClick={() =>
//       this.handleId(booking.id, booking.tutor)} className="no_underline"
//       to={{
//       pathname: "userbookingdetail",
//       params: booking.id,
//       tutor_params_id: booking.tutor
//       }}>
//       <div className="arrow_left mt-3">&#8594;</div>
//       </Link>
//    </div>
//    <div className="col-md-12">
//       <div className="border_l"></div>
//    </div>
//    <div className="col-md-6 col-6">
//       <div className="booking_info">
//          {booking.image == null ?
//          booking.gender == "female" ?
//          <img class="booking_user_img" src={defaultFemaleImg} alt="" />
//          : <img class="booking_user_img" src={defaultMaleImg} alt="" />
//          : <img class="booking_user_img" src={BASE_URL + booking.image} alt="" />
//          }
//          {/* <img className="profile_b" src={user} alt="" /> */}
//          <div className="info_pera">
//             <h2>{booking.fullname} </h2>
//             <div>
//                {booking.qualification == null || booking.qualification == '' ?
//                null :
//                <button>{booking.qualification}</button>}
//                {booking.rating != '' ?
//                <StarRatings
//                   rating={Number(booking.rating)}
//                   starRatedColor="#ffc107"
//                   starDimension="18px"
//                   starSpacing="0px"
//                   />
//                : 
//                <p>New User</p>
//                }
//             </div>
//          </div>
//       </div>
//    </div>
//    <div className="col-md-6 col-6 text-right my-auto">
//       <button onClick={() =>
//          this.onChatHandle(booking.tutor)} className="chat_btn">
//          <i className="fa fa-star" aria-hidden="true">
//             <FontAwesomeIcon icon={faFacebookMessenger}></FontAwesomeIcon>
//          </i>
//          Chat
//       </button>
//    </div>
// </div>
// :
// <div>
// </div>
// )
// })
// const booking_tutor_list4ListConst = this.state.booking_tutor_list4.map((booking) => {
// return (
// this.state.booking_tutor_list4.length != 0 ?
// <div className="row box_custom_booking">
//    <div className="col-md-6 upper_booking_heads">
//       <h6>{moment(booking.booking_start).format("DD MMM YYYY")+", "}<span class="booking_id_color">{booking.id}</span></h6>
//       <h4>{booking.category_name}</h4>
//       <p className="sub_b">Subject :  <b> {booking.sub_category_name}</b></p>
//    </div>
//    <div className="col-md-6 set_waiting text-right">
//       <button className="accepted_btn pp_pending">payment pending</button><br />
//       <Link onClick={() =>
//       this.handleId(booking.id, booking.tutor)} className="no_underline"
//       to={{
//       pathname: "userbookingdetail",
//       params: booking.id,
//       tutor_params_id: booking.tutor
//       }}>
//       <div className="arrow_left mt-3">&#8594;</div>
//       </Link>
//    </div>
//    <div className="col-md-12">
//       <div className="border_l"></div>
//    </div>
//    <div className="col-md-6 col-6">
//       <div className="booking_info">
//          {booking.image == null ?
//          booking.gender == "female" ?
//          <img class="booking_user_img" src={defaultFemaleImg} alt="" />
//          : <img class="booking_user_img" src={defaultMaleImg} alt="" />
//          : <img class="booking_user_img" src={BASE_URL + booking.image} alt="" />
//          }
//          <div className="info_pera">
//             <h2>{booking.fullname} </h2>
//             <div>
//                {booking.qualification == '' || booking.qualification == null ?
//                // <button>Graduate</button>
//                null :
//                <button>{booking.qualification}</button>}
//                {booking.rating != '' ?
//                <StarRatings
//                   rating={Number(booking.rating)}
//                   starRatedColor="#ffc107"
//                   starDimension="18px"
//                   starSpacing="0px"
//                   />
//                : 
//                <p>New User</p>
//                }
//             </div>
//          </div>
//       </div>
//    </div>
//    <div className="col-md-6 col-6 text-right my-auto">
//       <button onClick={() =>
//          this.onChatHandle(booking.tutor)} className="chat_btn">
//          <i className="fa fa-star" aria-hidden="true">
//             <FontAwesomeIcon icon={faFacebookMessenger}></FontAwesomeIcon>
//          </i>
//          Chat
//       </button>
//    </div>
// </div>
// :
// <div>
// </div>
// )
// })
// const booking_tutor_list5ListConst = this.state.booking_tutor_list5.map((booking) => {
// return (
// this.state.booking_tutor_list5.length != 0 ?
// <div className="row box_custom_booking">
//    <div className="col-md-6 col-6 upper_booking_heads">
//       <h6>{moment(booking.booking_start).format("DD MMM YYYY")+", "}<span class="booking_id_color">{booking.id}</span></h6>
//       <h4>{booking.category_name}</h4>
//       <p className="sub_b">Subject :  <b> {booking.sub_category_name}</b></p>
//    </div>
//    <div className="col-md-6 col-6 text-right">
//       <button className="accepted_btn">{booking.booking_status}</button><br />
//       <Link onClick={() =>
//       this.handleId(booking.id, booking.tutor)} className="no_underline"
//       to={{
//       pathname: "userbookingdetail",
//       params: booking.id,
//       tutor_params_id: booking.tutor
//       }}
//       >
//       <div className="arrow_left mt-3">&#8594;</div>
//       </Link>
//    </div>
//    <div className="col-md-12">
//       <div className="border_l"></div>
//    </div>
//    <div className="col-md-6 col-6">
//       <div className="booking_info">
//          {booking.image == null ?
//          booking.gender == "female" ?
//          <img class="booking_user_img" src={defaultFemaleImg} alt="" />
//          : <img class="booking_user_img" src={defaultMaleImg} alt="" />
//          : <img class="booking_user_img" src={BASE_URL + booking.image} alt="" />
//          }
//          <div className="info_pera">
//             <h2>{booking.fullname} </h2>
//             <div>
//                {booking.qualification == null || booking.qualification == '' ?
//                null :
//                <button>{booking.qualification}</button>}
//                {booking.rating != '' ?
//                <StarRatings
//                   rating={Number(booking.rating)}
//                   starRatedColor="#ffc107"
//                   starDimension="18px"
//                   starSpacing="0px"
//                   />
//                : 
//                <p>New User</p>
//                }
//             </div>
//          </div>
//       </div>
//    </div>
//    <div className="col-md-6 col-6 text-right my-auto">
//       <button onClick={() =>
//          this.onChatHandle(booking.tutor)} className="chat_btn">
//          <i className="fa fa-star" aria-hidden="true">
//             <FontAwesomeIcon icon={faFacebookMessenger}></FontAwesomeIcon>
//          </i>
//          Chat
//       </button>
//    </div>
// </div>
// :
// <div>
// </div>
// )
// })
// return (
// <main>
//    <Header></Header>
//    <section className="">
//       <div className="container-fluid margin_top width_custom">
//          <div className="row">
//             <div className="col-md-3 mt30">
//                <SideBar></SideBar>
//             </div>
//             <div className="col-md-9 mt30">
//                <div className="row">
//                   <div className="col-md-12">
//                      <h2 className="dashboard_headings">Booking</h2>
//                   </div>
//                </div>
//                <div className="row tab_header">
//                   <div className="col-md-12">
//                      <ul className="nav nav-pills custom_nav2 user_anv_mob border-0" role="tablist">
//                         <li className="nav-item width_li">
//                            <Tabs className="border-0 noborder_div">
//                               <Tab label="New">
//                                  <div className="col-md-12">
//                                     <div className="row booking_sec">
//                                        <div className="col-md-12" id="pending_section">
//                                           {BookingPendingListConst}
//                                           {BookingPendingDirectListConst}
//                                        </div>
//                                     </div>
//                                  </div>
//                               </Tab>
//                               <Tab label="payment pending">
//                                  <div className="col-md-12">
//                                     <div className="row  booking_sec">
//                                        <div className="col-md-12" id="upcoming_section">
//                                           {this.state.booking_tutor_list4 && this.state.booking_tutor_list4.length > 0 ?
//                                           booking_tutor_list4ListConst
//                                           :
//                                           <div class="no_booking_msg"> You have not bookings yet.</div>
//                                           }
//                                        </div>
//                                     </div>
//                                  </div>
//                               </Tab>
//                               <Tab label="Upcoming">
//                                  <div className="col-md-12">
//                                     <div className="row  booking_sec">
//                                        <div className="col-md-12" id="upcoming_section">
//                                           {this.state.booking_tutor_list && this.state.booking_tutor_list.length > 0 ?
//                                           booking_tutor_listListConst
//                                           :
//                                           <div class="no_booking_msg"> You have not bookings yet.</div>
//                                           }
//                                        </div>
//                                     </div>
//                                  </div>
//                               </Tab>
//                               <Tab label="Completed">
//                                  <div className="col-md-12">
//                                     <div className="row  booking_sec">
//                                        <div className="col-md-12" id="completed_section">
//                                           {this.state.booking_tutor_list3 && this.state.booking_tutor_list3.length > 0 ?
//                                           booking_tutor_list3ListConst
//                                           :
//                                           <div class="no_booking_msg"> You have not bookings yet.</div>
//                                           }
//                                        </div>
//                                     </div>
//                                  </div>
//                               </Tab>
//                               <Tab label="Rejected">
//                                  <div className="col-md-12">
//                                     <div className="row  booking_sec">
//                                        <div className="col-md-12" id="completed_section">
//                                           {this.state.booking_tutor_list5 && this.state.booking_tutor_list5.length > 0 ?
//                                           booking_tutor_list5ListConst
//                                           :
//                                           <div class="no_booking_msg"> You have not bookings yet.</div>
//                                           }
//                                        </div>
//                                     </div>
//                                  </div>
//                               </Tab>
//                            </Tabs>
//                         </li>
//                      </ul>
//                   </div>
//                </div>
//             </div>
//          </div>
//       </div>
//    </section>
//    <Footer></Footer>
// </main>
// );
// }
// }
// export default User_Booking;


import React from 'react';
import Header from '../common/header';
import StarRatings from 'react-star-ratings';
import Footer from '../common/footer';
import axios from 'axios';
import { Link } from "react-router-dom";
import defaultMaleImg from '../../assets/img/default_male.png';
import defaultFemaleImg from '../../assets/img/default_female.png';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import moment from 'moment';
import 'moment-timezone';
import { API_BASE_URL, BASE_URL } from '../../config/api.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
import SideBar from "./sideBar.js";
class User_Booking extends React.Component {
constructor(props) {
super(props)
this.state = {
booking_tutor_list_direct:[],
booking_null_tutor_list: [],
booking_tutor_list: [],
booking_tutor_list2: [],
booking_tutor_list3: [],
booking_tutor_list4: [],
booking_tutor_list5: []
}
this.handleId = this.handleId.bind(this);
this.onChatHandle = this.onChatHandle.bind(this);
}
handleId(id, tutorid) {
const val = localStorage.setItem("country_code", id);
const tutor_id = localStorage.setItem("tutor_id", tutorid);
}
onChatHandle(tutorId) {
this.props.history.push({ 
pathname: '/conversations',
state: { id: tutorId }
});
}
componentDidMount() {
const UserToken = localStorage.getItem('UserToken');
if (UserToken != '') {
const sendData = {
booking_status: 'pending',
}
axios({
method: 'post',
url: API_BASE_URL + "user_booking_list/",
headers: { 'Authorization': "Bearer " + UserToken },
data: sendData
}).then(response => {
this.setState({
booking_tutor_list_direct: response.data.booking_tutor_list,
booking_null_tutor_list:response.data.booking_null_tutor_list})
}).catch((error) => {
console.log(error)
})
}
if (UserToken != '') {
const sendData3 = {
booking_status: 'upcoming',
}
axios({
method: 'post',
url: API_BASE_URL + "user_booking_list/",
headers: { 'Authorization': "Bearer " + UserToken },
data: sendData3
}).then(response => {
this.setState({ booking_tutor_list: response.data.booking_tutor_list, })
//console.log("shubham", response.data)
})
}
if (UserToken != '') {
const sendData2 = {
booking_status: 'completed',
}
axios({
method: 'post',
url: API_BASE_URL + "user_booking_list/",
headers: { 'Authorization': "Bearer " + UserToken },
data: sendData2
}).then(response => {
this.setState({ booking_tutor_list3: response.data.booking_tutor_list })
})
}
if (UserToken != '') {
const sendData4 = {
booking_status: "waiting for payment"
}
axios({
method: 'post',
url: API_BASE_URL + "user_booking_list/",
headers: { 'Authorization': "Bearer " + UserToken },
data: sendData4
}).then(response => {
this.setState({ booking_tutor_list4: response.data.booking_tutor_list })
//console.log("shubham", response.data)
})
}
if (UserToken != '') {
const sendData5 = {
booking_status: 'cancelled',
}
axios({
method: 'post',
url: API_BASE_URL + "user_booking_list/",
headers: { 'Authorization': "Bearer " + UserToken },
data: sendData5
}).then(response => {
this.setState({ booking_tutor_list5: response.data.booking_tutor_list })
})
}
}
render() {
const BookingPendingListConst = this.state.booking_null_tutor_list.map((booking) => {
return (
this.state.booking_null_tutor_list.length != 0 ?
<div className="row box_custom_booking">
   <div className="col-md-6 col-6 upper_booking_heads">
      <h6>{moment(booking.booking_start).format("DD MMM YYYY")+", "}<span class="booking_id_color">{booking.id}</span></h6>
      <h4>{booking.category_name}</h4>
      <p className="sub_b">Subject :  <b>{booking.sub_category_name}</b></p>
   </div>
   <div className="col-md-6 col-6 text-right">
      <button className="pending_btn">{booking.booking_status}</button><br />
      <Link onClick={() =>
      this.handleId(booking.id, booking.tutor)} className="no_underline"
      to={{
      pathname: "userbookingdetail",
      params: booking.id,
      tutor_params_id: booking.tutor
      }}>
      <div className="arrow_left mt-3">&#8594;</div>
      </Link>
   </div>
</div>
: null
)
})
const BookingPendingDirectListConst  = this.state.booking_tutor_list_direct.map((booking) => {
return (
this.state.booking_tutor_list_direct.length != 0 ?
<div className="row box_custom_booking">
   <div className="col-md-6 col-6 upper_booking_heads">
      <h6>{moment(booking.booking_start).format("DD MMM YYYY")+", "}<span class="booking_id_color">{booking.id}</span></h6>
      <h4>{booking.category_name}</h4>
      <p className="sub_b">Subject :  <b>{booking.sub_category_name}</b></p>
   </div>
   <div className="col-md-6 col-6 text-right">
      <button className="pending_btn">{booking.booking_status}</button><br />
      <Link onClick={() =>
      this.handleId(booking.id, booking.tutor)} className="no_underline"
      to={{
      pathname: "userbookingdetail",
      params: booking.id,
      tutor_params_id: booking.tutor
      }}>
      <div className="arrow_left mt-3">&#8594;</div>
      </Link>
   </div>
   <div className="col-md-12">
      <div className="border_l"></div>
   </div>
   <div className="col-md-6 col-6">
      <div className="booking_info">
         {booking.image == null ?
         booking.gender == "female" ?
         <img class="booking_user_img" src={defaultFemaleImg} alt="" />
         : <img class="booking_user_img" src={defaultMaleImg} alt="" />
         : <img class="booking_user_img" src={BASE_URL + booking.image} alt="" />
         }
         <div className="info_pera">
            <h2>{booking.fullname} </h2>
            <div>
               {
               booking.qualification == '' || booking.qualification == null ?
               null
               : <button>{booking.qualification}</button>}
               {booking.rating != '' ?
               <StarRatings
                  rating={Number(booking.rating)}
                  starRatedColor="#ffc107"
                  starDimension="18px"
                  starSpacing="0px"
                  />
               : 
               <p>New User</p>
               }
            </div>
         </div>
      </div>
   </div>
   <div className="col-md-6 col-6 text-right my-auto">
   {booking.online_status=='1' ? 
      <div class="status_div set_status">
      <div className="online_status"></div>
      ONLINE
      </div>
       :
      <div class="status_div set_status">
      <div className="offline_status"></div>
      Offline
         </div>
   }
      <button onClick={() =>
         this.onChatHandle(booking.tutor)} className="chat_btn st">

         <i className="fa fa-star" aria-hidden="true">
            <FontAwesomeIcon icon={faFacebookMessenger}></FontAwesomeIcon>
         </i>
         Chat
      </button>
   </div>
</div>
: null
)
})
const booking_tutor_listListConst = this.state.booking_tutor_list.map((booking) => {
return (
this.state.booking_tutor_list.length != 0 ?
<div className="row box_custom_booking">
   <div className="col-md-6 col-6 upper_booking_heads">
      <h6>{moment(booking.booking_start).format("DD MMM YYYY")+", "}<span class="booking_id_color">{booking.id}</span></h6>
      <h4>{booking.category_name}</h4>
      <p className="sub_b">Subject :  <b>{booking.sub_category_name}</b></p>
   </div>
   <div className="col-md-6 col-6 text-right">
      <button className="accepted_btn upcoming_btn">{booking.booking_status}</button><br />
      <Link onClick={() =>
      this.handleId(booking.id, booking.tutor)} className="no_underline"
      to={{
      pathname: "userbookingdetail",
      params: booking.id,
      tutor_params_id: booking.tutor
      // params1:booking.user,
      // params2:booking.id
      }}
      >
      <div className="arrow_left mt-3">&#8594;</div>
      </Link>
   </div>
   <div className="col-md-12">
      <div className="border_l"></div>
   </div>
   <div className="col-md-6">
      <div className="booking_info">
         {booking.image == null ?
         booking.gender == "female" ?
         <img class="booking_user_img" src={defaultFemaleImg} alt="" />
         : <img class="booking_user_img" src={defaultMaleImg} alt="" />
         : <img class="booking_user_img" src={BASE_URL + booking.image} alt="" />
         }
         <div className="info_pera">
            <h2>{booking.fullname} </h2>
            <div>
               {booking.qualification == '' || booking.qualification == null ?
               null
               : <button>{booking.qualification}</button>}
               {booking.rating != '' ?
               <StarRatings
                  rating={Number(booking.rating)}
                  starRatedColor="#ffc107"
                  starDimension="18px"
                  starSpacing="0px"
                  />
               : 
               <p>New User</p>
               }
               {/* <button>Graduate</button> */}
            </div>
         </div>
      </div>
   </div>
   <div className="col-md-6 text-right my-auto">
      {/* 
      <button className="chat_btn">
         <i className="fa fa-star" aria-hidden="true">
            <FontAwesomeIcon icon={faFacebookMessenger}></FontAwesomeIcon>
         </i>
         Chat
      </button>
      */}

{booking.online_status=='1' ? 
                                       <div class="status_div set_status">
                                          <div className="online_status"></div>
                                          ONLINE
                                       </div>
                                       :
                                       <div class="status_div set_status">
                                          <div className="offline_status"></div>
                                          Offline
                                       </div>
                                       }
      <button onClick={() =>
         this.onChatHandle(booking.tutor)} className="chat_btn">
         <i className="fa fa-star" aria-hidden="true">
            <FontAwesomeIcon icon={faFacebookMessenger}></FontAwesomeIcon>
         </i>
         Chat
      </button>
   </div>
   {/* 
   <div className="col-md-12">
      <div className="border_l"></div>
   </div>
   */}
</div>
:
<div></div>
)
})
const booking_tutor_list3ListConst = this.state.booking_tutor_list3.map((booking) => {
return (
this.state.booking_tutor_list3.length != 0 ?
<div className="row box_custom_booking">
   <div className="col-md-6 col-6 upper_booking_heads">
      <h6>{moment(booking.booking_start).format("DD MMM YYYY")+", "}<span class="booking_id_color">{booking.id}</span></h6>
      <h4>{booking.category_name}</h4>
      <p className="sub_b">Subject :  <b> {booking.sub_category_name}</b></p>
   </div>
   <div className="col-md-6 col-6 text-right">
      <button className="accepted_btn">{booking.booking_status}</button><br />
      <Link onClick={() =>
      this.handleId(booking.id, booking.tutor)} className="no_underline"
      to={{
      pathname: "userbookingdetail",
      params: booking.id,
      tutor_params_id: booking.tutor
      }}>
      <div className="arrow_left mt-3">&#8594;</div>
      </Link>
   </div>
   <div className="col-md-12">
      <div className="border_l"></div>
   </div>
   <div className="col-md-6 col-6">
      <div className="booking_info">
         {booking.image == null ?
         booking.gender == "female" ?
         <img class="booking_user_img" src={defaultFemaleImg} alt="" />
         : <img class="booking_user_img" src={defaultMaleImg} alt="" />
         : <img class="booking_user_img" src={BASE_URL + booking.image} alt="" />
         }
         {/* <img className="profile_b" src={user} alt="" /> */}
         <div className="info_pera">
            <h2>{booking.fullname} </h2>
            <div>
               {booking.qualification == null || booking.qualification == '' ?
               null :
               <button>{booking.qualification}</button>}
               {booking.rating != '' ?
               <StarRatings
                  rating={Number(booking.rating)}
                  starRatedColor="#ffc107"
                  starDimension="18px"
                  starSpacing="0px"
                  />
               : 
               <p>New User</p>
               }
            </div>
         </div>
      </div>
   </div>
   <div className="col-md-6 col-6 text-right my-auto">
      <button onClick={() =>
         this.onChatHandle(booking.tutor)} className="chat_btn">
         <i className="fa fa-star" aria-hidden="true">
            <FontAwesomeIcon icon={faFacebookMessenger}></FontAwesomeIcon>
         </i>
         Chat
      </button>
   </div>
</div>
:
<div>
</div>
)
})
const booking_tutor_list4ListConst = this.state.booking_tutor_list4.map((booking) => {
return (
this.state.booking_tutor_list4.length != 0 ?
<div className="row box_custom_booking">
   <div className="col-md-6 upper_booking_heads">
      <h6>{moment(booking.booking_start).format("DD MMM YYYY")+", "}<span class="booking_id_color">{booking.id}</span></h6>
      <h4>{booking.category_name}</h4>
      <p className="sub_b">Subject :  <b> {booking.sub_category_name}</b></p>
   </div>
   <div className="col-md-6 set_waiting text-right">
      <button className="accepted_btn pp_pending">payment pending</button><br />
      <Link onClick={() =>
      this.handleId(booking.id, booking.tutor)} className="no_underline"
      to={{
      pathname: "userbookingdetail",
      params: booking.id,
      tutor_params_id: booking.tutor
      }}>
      <div className="arrow_left mt-3">&#8594;</div>
      </Link>
   </div>
   <div className="col-md-12">
      <div className="border_l"></div>
   </div>
   <div className="col-md-6 col-6">
      <div className="booking_info">
         {booking.image == null ?
         booking.gender == "female" ?
         <img class="booking_user_img" src={defaultFemaleImg} alt="" />
         : <img class="booking_user_img" src={defaultMaleImg} alt="" />
         : <img class="booking_user_img" src={BASE_URL + booking.image} alt="" />
         }
         <div className="info_pera">
            <h2>{booking.fullname} </h2>
            <div>
               {booking.qualification == '' || booking.qualification == null ?
               // <button>Graduate</button>
               null :
               <button>{booking.qualification}</button>}
               {booking.rating != '' ?
               <StarRatings
                  rating={Number(booking.rating)}
                  starRatedColor="#ffc107"
                  starDimension="18px"
                  starSpacing="0px"
                  />
               : 
               <p>New User</p>
               }
            </div>
         </div>
      </div>
   </div>
   <div className="col-md-6 col-6 text-right my-auto">
      <button onClick={() =>
         this.onChatHandle(booking.tutor)} className="chat_btn">
         <i className="fa fa-star" aria-hidden="true">
            <FontAwesomeIcon icon={faFacebookMessenger}></FontAwesomeIcon>
         </i>
         Chat
      </button>
   </div>
</div>
:
<div>
</div>
)
})
const booking_tutor_list5ListConst = this.state.booking_tutor_list5.map((booking) => {
return (
this.state.booking_tutor_list5.length != 0 ?
<div className="row box_custom_booking">
   <div className="col-md-6 col-6 upper_booking_heads">
      <h6>{moment(booking.booking_start).format("DD MMM YYYY")+", "}<span class="booking_id_color">{booking.id}</span></h6>
      <h4>{booking.category_name}</h4>
      <p className="sub_b">Subject :  <b> {booking.sub_category_name}</b></p>
   </div>
   <div className="col-md-6 col-6 text-right">
      <button className="accepted_btn cancel_btn">{booking.booking_status}</button><br />
      <Link onClick={() =>
      this.handleId(booking.id, booking.tutor)} className="no_underline"
      to={{
      pathname: "userbookingdetail",
      params: booking.id,
      tutor_params_id: booking.tutor
      }}
      >
      <div className="arrow_left mt-3">&#8594;</div>
      </Link>
   </div>
   <div className="col-md-12">
      <div className="border_l"></div>
   </div>
   <div className="col-md-6 col-6">
      <div className="booking_info">
         {booking.image == null ?
         booking.gender == "female" ?
         <img class="booking_user_img" src={defaultFemaleImg} alt="" />
         : <img class="booking_user_img" src={defaultMaleImg} alt="" />
         : <img class="booking_user_img" src={BASE_URL + booking.image} alt="" />
         }
         <div className="info_pera">
            <h2>{booking.fullname} </h2>
            <div>
               {booking.qualification == null || booking.qualification == '' ?
               null :
               <button>{booking.qualification}</button>}
               {booking.rating != '' ?
               <StarRatings
                  rating={Number(booking.rating)}
                  starRatedColor="#ffc107"
                  starDimension="18px"
                  starSpacing="0px"
                  />
               : 
               <p>New User</p>
               }
            </div>
         </div>
      </div>
   </div>
   <div className="col-md-6 col-6 text-right my-auto">
      <button onClick={() =>
         this.onChatHandle(booking.tutor)} className="chat_btn">
         <i className="fa fa-star" aria-hidden="true">
            <FontAwesomeIcon icon={faFacebookMessenger}></FontAwesomeIcon>
         </i>
         Chat
      </button>
   </div>
</div>
:
<div>
</div>
)
})
return (
<main>
   <Header></Header>
   <section className="">
      <div className="container-fluid margin_top width_custom">
         <div className="row">
            <div className="col-md-3 mt30">
               <SideBar></SideBar>
            </div>
            <div className="col-md-9 mt30">
               <div className="row">
                  <div className="col-md-12">
                     <h2 className="dashboard_headings">Booking</h2>
                  </div>
               </div>
               <div className="row tab_header">
                  <div className="col-md-12">
                     <ul className="nav nav-pills custom_nav2 user_anv_mob border-0" role="tablist">
                        <li className="nav-item width_li">
                           <Tabs className="border-0 noborder_div">
                              <Tab label="New">
                                 <div className="col-md-12">
                                    <div className="row booking_sec">
                                       <div className="col-md-12" id="pending_section">
                                          {BookingPendingListConst}
                                          {BookingPendingDirectListConst}
                                       </div>
                                    </div>
                                 </div>
                              </Tab>
                              <Tab label="payment pending">
                                 <div className="col-md-12">
                                    <div className="row  booking_sec">
                                       <div className="col-md-12" id="upcoming_section">
                                          {this.state.booking_tutor_list4 && this.state.booking_tutor_list4.length > 0 ?
                                          booking_tutor_list4ListConst
                                          :
                                          <div class="no_booking_msg"> You have not bookings yet.</div>
                                          }
                                       </div>
                                    </div>
                                 </div>
                              </Tab>
                              <Tab label="Upcoming">
                                 <div className="col-md-12">
                                    <div className="row  booking_sec">
                                       <div className="col-md-12" id="upcoming_section">
                                          {this.state.booking_tutor_list && this.state.booking_tutor_list.length > 0 ?
                                          booking_tutor_listListConst
                                          :
                                          <div class="no_booking_msg"> You have not bookings yet.</div>
                                          }
                                       </div>
                                    </div>
                                 </div>
                              </Tab>
                              <Tab label="Completed">
                                 <div className="col-md-12">
                                    <div className="row  booking_sec">
                                       <div className="col-md-12" id="completed_section">
                                          {this.state.booking_tutor_list3 && this.state.booking_tutor_list3.length > 0 ?
                                          booking_tutor_list3ListConst
                                          :
                                          <div class="no_booking_msg"> You have not bookings yet.</div>
                                          }
                                       </div>
                                    </div>
                                 </div>
                              </Tab>
                              <Tab label="Rejected">
                                 <div className="col-md-12">
                                    <div className="row  booking_sec">
                                       <div className="col-md-12" id="completed_section">
                                          {this.state.booking_tutor_list5 && this.state.booking_tutor_list5.length > 0 ?
                                          booking_tutor_list5ListConst
                                          :
                                          <div class="no_booking_msg"> You have not bookings yet.</div>
                                          }
                                       </div>
                                    </div>
                                 </div>
                              </Tab>
                           </Tabs>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <Footer></Footer>
</main>
);
}
}
export default User_Booking;