import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Header from '../common/header';
import Footer from'../common/footer';
import about1 from "../../assets/img/about_1.png";
import bs_1 from "../../assets/img/bs_1.png";
import about5 from "../../assets/img/about_5.png";
import become_2 from "../../assets/img/become_2.png";
import become_4 from "../../assets/img/become_4.png";
import become_5 from "../../assets/img/become_5.png";
import about2 from "../../assets/img/about_2.png";
// import Testimonial from'../home/testimonial';
import Category from'./category';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCheck } from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import { API_BASE_URL } from '../../config/api';
import axios from 'axios';

export default class how_works extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      howitworksContent : []
    }
  }

  componentDidMount(){
    axios({
      method: 'get',
      url: API_BASE_URL + `content_list/?content_type=how_it_works`,
      headers:{'Content-Type': 'how_it_works'}, 
      
      }).then(response => {
           // console.log("about api",response.data)
          this.setState({howitworksContent:response.data.content_data})
         // console.log("about", this.state.howitworksContent );
      }).catch((error) => {
          console.log(error);
      })
  
  }

  render() {
    return (
      <main>
      <Header></Header>

      <section className="inner_banner section_padding">
        <div className="container">
          <div className="row">
            <div className="inner_contain">
            <h2>How It Works</h2>
            <ul>
              <li><Link to="/">Home</Link></li> <i><FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon> &nbsp;</i>
              <li><a href="#"> How It Works</a></li>
            </ul>
            </div>
          </div>
        </div>
      </section>

      {this.state.howitworksContent.map((data)=>{
        console.log("how_it_works", data.title);  
        return(
          <>
            <div dangerouslySetInnerHTML={{__html: data.description }}>
             </div>

      {/* <section className="inner_banner section_padding">
        <div className="container">
          <div className="row">
            <div className="inner_contain">
            <h2>How It Works</h2>
            <ul>
              <li><Link to="/">Home</Link></li> <i><FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon> &nbsp;</i>
              <li><a href="#"> How It Works</a></li>
            </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="about_contain section_padding">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
            </div>
            <div className="col-sm-6">
                <img className="abt_cont_img img-fluid" src={bs_1} alt=""/>
            </div>
          </div>
        </div>
      </section>
      <section className="steps_section2 section_padding">
        <div className="container">
          <div className="col-sm-12">
            <div class="dark_head"><h2><span>Lorem</span><span> Ipsum</span></h2></div>
            <p class="pera_dark text-center">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="work_aside">
                  <div className="Workaside_contain">
                    <h5>Lorem Ipsum</h5>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                  </div>
              </div>
            </div>
            <div className="col-sm-6 m-auto">
              <div className="work_bside">
                <h4>Lorem Ipsum is simply</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever simply dummy text of the printing and typesetting industry</p>
                <div className="work_list">
                  <div className="work1">
                    <ul>
                      <li>Lorem Ipsum is simply</li>
                      <li>Lorem Ipsum is simply</li>
                      <li>Lorem Ipsum is simply</li>
                    </ul>
                  </div>
                  <div className="work2">
                    <ul>
                      <li>Lorem Ipsum is simply</li>
                      <li>Lorem Ipsum is simply</li>
                      <li>Lorem Ipsum is simply</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="process_section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="dark_head">
                <h2><span>We Complete</span><span> Every Step carefully</span></h2>
              </div>
              <p className="pera_dark text-center">Our Undergraduates, Graduates, Ex/Current School Teacher & Fulltime Tutors are ready to help students realise their full academic potential</p>
            </div>
            <div className="col-md-12">
              <div className="row text-center my-3">
                 <div className="col-md-3">
                   <div className="process_card">
                   <svg xmlns="http://www.w3.org/2000/svg" width="59" height="59" viewBox="0 0 59 59">
                      <path id="magnifiying-glass" d="M58.328,55.084l-14.4-14.4a24.867,24.867,0,1,0-3.244,3.244l14.4,14.4a2.294,2.294,0,1,0,3.244-3.244ZM4.588,24.833A20.244,20.244,0,1,1,24.832,45.076,20.268,20.268,0,0,1,4.588,24.833Z" fill="#8ad75c"/>
                    </svg>
                     <h4>Search</h4>
                     <p>There earth face earth behold she star so made </p>
                   </div>
                 </div>
                 <div className="col-md-3">
                   <div className="process_card">
                   <svg xmlns="http://www.w3.org/2000/svg" width="67.468" height="51.611" viewBox="0 0 67.468 51.611">
                    <g id="Group_931" data-name="Group 931" transform="translate(-500.593 -641.855)">
                      <path id="Path_64" data-name="Path 64" d="M506.889,641.98h-.006a2.367,2.367,0,0,0-1.683.7,2.4,2.4,0,0,0-.708,1.706v36.069a2.415,2.415,0,0,0,2.41,2.406c5.6.013,14.982,1.18,21.452,7.949V653.063a2.3,2.3,0,0,0-.332-1.215c-5.312-8.555-15.52-9.855-21.133-9.868Zm0,0" transform="translate(4.106 0)" fill="#8ad75c" stroke="#fff" stroke-miterlimit="10" stroke-width="0.25"/>
                      <path id="Path_65" data-name="Path 65" d="M541.565,680.454V644.385a2.4,2.4,0,0,0-.708-1.706,2.357,2.357,0,0,0-1.685-.7h-.006c-5.61.013-15.817,1.313-21.127,9.868a2.279,2.279,0,0,0-.33,1.215v37.745c6.467-6.769,15.848-7.937,21.448-7.949a2.416,2.416,0,0,0,2.407-2.406Zm0,0" transform="translate(18.487 0)" fill="#8ad75c" stroke="#fff" stroke-miterlimit="10" stroke-width="0.25"/>
                      <path id="Path_66" data-name="Path 66" d="M545.307,645.964h-1.746v30.156a6.151,6.151,0,0,1-6.133,6.139c-4.748.011-12.578.94-18.124,6.187,9.59-2.347,19.7-.821,25.464.493a2.406,2.406,0,0,0,2.944-2.343V648.367a2.407,2.407,0,0,0-2.405-2.4Zm0,0" transform="translate(20.223 4.335)" fill="#8ad75c" stroke="#fff" stroke-miterlimit="10" stroke-width="0.25"/>
                      <path id="Path_67" data-name="Path 67" d="M504.865,676.119V645.964h-1.744a2.4,2.4,0,0,0-2.4,2.4V686.6a2.4,2.4,0,0,0,2.942,2.341c5.763-1.311,15.871-2.84,25.464-.491-5.546-5.249-13.376-6.176-18.124-6.187a6.152,6.152,0,0,1-6.135-6.139Zm0,0" transform="translate(0 4.335)" fill="#8ad75c" stroke="#fff" stroke-miterlimit="10" stroke-width="0.25"/>
                    </g>
                  </svg>

                     <h4>Book</h4>
                     <p>There earth face earth behold she star so made </p>
                   </div>
                 </div>
                 <div className="col-md-3">
                   <div className="process_card">
                   <svg id="Group_936" data-name="Group 936" xmlns="http://www.w3.org/2000/svg" width="78.059" height="58.707" viewBox="0 0 78.059 58.707">
                    <g id="Group_933" data-name="Group 933" transform="translate(23.235 18.746)">
                      <g id="Group_932" data-name="Group 932">
                        <path id="Path_1101" data-name="Path 1101" d="M192.113,176.681H151.574a7.168,7.168,0,0,0-7.143,7.143V209.5a7.168,7.168,0,0,0,7.143,7.143h40.539a7.168,7.168,0,0,0,7.143-7.143V183.824A7.147,7.147,0,0,0,192.113,176.681Zm3.3,32.914a2.6,2.6,0,0,1-2.59,2.59H151.493a2.6,2.6,0,0,1-2.59-2.59V191.529h46.508Zm0-24.871H148.9v-.981a2.6,2.6,0,0,1,2.59-2.59h41.328a2.6,2.6,0,0,1,2.59,2.59Z" transform="translate(-144.431 -176.681)" fill="#8ad75c"/>
                      </g>
                    </g>
                    <g id="Group_934" data-name="Group 934" transform="translate(0 0)">
                      <path id="Path_1102" data-name="Path 1102" d="M29.863,74.489H55.007l-5.116-10.36A7.157,7.157,0,0,0,40.3,60.9L3.979,78.848A7.157,7.157,0,0,0,.745,88.436l11.39,23.021a7.158,7.158,0,0,0,8.285,3.732,10.754,10.754,0,0,1-1.512-5.518V85.46A10.982,10.982,0,0,1,29.863,74.489Z" transform="translate(0 -60.15)" fill="#8ad75c"/>
                    </g>
                    <g id="Group_935" data-name="Group 935" transform="translate(30.393 42.201)">
                      <path id="Path_1103" data-name="Path 1103" d="M193.564,331.747a4.574,4.574,0,0,0,3.507-1.625,4.633,4.633,0,1,0,0-6.017,4.628,4.628,0,1,0-3.507,7.641Z" transform="translate(-188.931 -322.481)" fill="#8ad75c"/>
                    </g>
                  </svg>
                     <h4>Pay</h4>
                     <p>There earth face earth behold she star so made </p>
                   </div>
                 </div>
                 <div className="col-md-3">
                   <div className="process_card">
                   <svg xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 68 68">
                    <g id="Group_3593" data-name="Group 3593" transform="translate(-1437 -2592)">
                      <g id="Group_3592" data-name="Group 3592">
                        <path id="Path_1104" data-name="Path 1104" d="M17.3,138.268l-5.891.455A5.257,5.257,0,0,0,7.4,141.08L.606,151.356a3.641,3.641,0,0,0,2.48,5.607l5.394.835A48.3,48.3,0,0,1,17.3,138.268Z" transform="translate(1437 2472.096)" fill="#8ad75c"/>
                        <path id="Path_1105" data-name="Path 1105" d="M226.682,390.581l.836,5.394a3.638,3.638,0,0,0,5.607,2.48l10.276-6.794a5.256,5.256,0,0,0,2.357-4.006l.455-5.891A48.3,48.3,0,0,1,226.682,390.581Z" transform="translate(1240.424 2260.939)" fill="#8ad75c"/>
                        <path id="Path_1106" data-name="Path 1106" d="M107.862,259.959a3.421,3.421,0,0,0,.564-.047,39.028,39.028,0,0,0,8.089-2.294l-22.17-22.17a39.026,39.026,0,0,0-2.294,8.089,3.476,3.476,0,0,0,.979,3.025L105.4,258.933A3.489,3.489,0,0,0,107.862,259.959Z" transform="translate(1357.215 2387.822)" fill="#8ad75c"/>
                        <path id="Path_1107" data-name="Path 1107" d="M169.167,30.149c5.425-10.489,5.626-21.573,5.343-26.771a3.492,3.492,0,0,0-3.3-3.3c-.846-.046-1.85-.08-2.98-.08A52.631,52.631,0,0,0,144.44,5.422c-6.978,3.609-16.129,11.557-21.527,22a2,2,0,0,1,.185.161L147,51.491a2,2,0,0,1,.161.185C157.61,46.277,165.557,37.127,169.167,30.149Zm-23.04-15.773a9.961,9.961,0,1,1-2.918,7.043,9.972,9.972,0,0,1,2.918-7.043Z" transform="translate(1330.411 2592)" fill="#8ad75c"/>
                        <path id="Path_1108" data-name="Path 1108" d="M307.481,126.494a5.976,5.976,0,1,0-1.75-4.226,5.983,5.983,0,0,0,1.75,4.226Z" transform="translate(1171.874 2491.151)" fill="#8ad75c"/>
                        <path id="Path_1109" data-name="Path 1109" d="M2.3,353.453a1.985,1.985,0,0,0,1.409-.584l6.5-6.5a1.992,1.992,0,1,0-2.817-2.817l-6.5,6.5a1.992,1.992,0,0,0,1.409,3.4Z" transform="translate(1436.736 2294.585)" fill="#8ad75c"/>
                        <path id="Path_1110" data-name="Path 1110" d="M15.909,388.429a1.992,1.992,0,0,0-2.817,0l-12.5,12.5A1.992,1.992,0,1,0,3.4,403.751l12.5-12.5A1.992,1.992,0,0,0,15.909,388.429Z" transform="translate(1436.997 2255.665)" fill="#8ad75c"/>
                        <path id="Path_1111" data-name="Path 1111" d="M97.154,433.31l-6.5,6.5a1.992,1.992,0,1,0,2.817,2.817l6.5-6.5a1.992,1.992,0,1,0-2.817-2.817Z" transform="translate(1358.895 2216.745)" fill="#8ad75c"/>
                      </g>
                    </g>
                  </svg>

                     <h4>Start</h4>
                     <p>There earth face earth behold she star so made </p>
                   </div>
                 </div>
              </div>
            </div>
            <div className="col-md-12 text-center">
              <Link to="tutorsearch"><button className="btn_reg student_reg mr-5">Start Now</button></Link>
            </div>
          </div>
        </div>
      </section> */}

      </>

)})}

      <Footer></Footer>
      </main>
    );
  }
}