import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Header from '../common/header';
import Footer from'../common/footer';
import about1 from "../../assets/img/about_1.png";
import become_1 from "../../assets/img/become_1.png";
import become_2 from "../../assets/img/become_2.png";
import become_4 from "../../assets/img/become_4.png";
import become_5 from "../../assets/img/become_5.png";
import about2 from "../../assets/img/about_2.png";
// import Testimonial from'../home/testimonial';
import Category from'../home/category';
import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
import { faAngleRight, faCheck } from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import { API_BASE_URL } from '../../config/api';
import axios from 'axios';

export default class terms extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      termsConditionContent : []
    }
  }

  componentDidMount(){
    axios({
      method: 'get',
      url: API_BASE_URL + `content_list/?content_type=term_conditions`,
      headers:{'Content-Type' : 'term_conditions'}, 
      
      }).then(response => {
           // console.log("about api",response.data)
          this.setState({termsConditionContent:response.data.content_data})
         // console.log("about", this.state.termsConditionContent );
      }).catch((error) => {
          console.log(error);
      })

  }

  render() {
    return (
      <main>
      <Header></Header>

      <section className="inner_banner section_padding">
        <div className="container">
          <div className="row">
            <div className="inner_contain">
            <h2>Terms & Conditions</h2>
            <ul>
              <li><Link to="/">Home</Link></li> <i><FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon> &nbsp;</i>
              <li><a href="#"> Terms & Conditions</a></li>
            </ul>
            </div>
          </div>
        </div>
      </section>

      {this.state.termsConditionContent.map((data)=>{
        console.log("terms", data.title);  
        return(
          
        <>

        <div dangerouslySetInnerHTML={{__html: data.description }}></div>


      {/* <section className="about_contain section_padding requirement_section terms">
        <div className="container">
            <div className="row">
            <div className="col-sm-12">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
            </div>
          </div>
        </div>
      </section> */}

      </>

      )})}
     
      <Footer></Footer>
      </main>
    );
  }
}