import React from 'react';
import logo from "../../assets/img/logo1.png";
import logo2 from "../../assets/img/logo1.png";
import {Link} from "react-router-dom";
import { Modal, Card,Button } from "react-bootstrap";
import { Redirect } from 'react-router';
import "../../assets/js/main.js";
import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
import { faUser, faAngleUp, faSearch } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import {API_BASE_URL,BASE_URL} from '../../config/api.js';
import Autocomplete from 'react-autocomplete';
import firebase from '../../firebase.js';

export function renderMovieTitle(state, query) {
 // console.log("state",query);
 //alert(state.sub_category_level_name)

 if(state.sub_category_level_name != null){
    return (
      state.sub_category_level_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
 } 
}

var usertype=''
export default class Header extends React.Component {

  constructor (props) {
    super (props);
    this.state = {
      val: '',
      subcat_name_searchlist : [],
      redirect : false,
      show90:'',
      TokenState:'',
      headerStatus:false,
      is_visible: false,
      query: '',
      usertype: '',
      results: {},
      loading: false,
         notificationId: '',
      message: '',
      findvalue:"",
      subcategary_det: [],
      hello:'',
      CategoryListSlider: []
    }
    this.handleLogoutClick = this.handleLogoutClick.bind(this);
    this.handleCancel=this.handleCancel.bind(this);
    //this.handleClick=this.handleClick.bind(this);
    // this.handleChange=this.handleChange.bind(this);

    this.cancel = '';
  }



handleCancel(){
  this.handleModal90()
}


  handleModal90(){
    this.setState({show90:!this.state.show90})
  }


  handleOnInputChange = (event) => {
    const query = event.target.value;
    this.setState({findvalue: event.target.value})

      if ( ! query ) {
        this.setState({ query, results: {}, message: '' } );
      } else {
          this.setState({ query, loading: true, message: '' }, () => {
          this.fetchSearchResults(1, query);   
      });
    }
  };

  fetchSearchResults = (updatedPageNo = '', query ) => {

    const  UserToken = localStorage.getItem('UserToken');
    if(UserToken!=''){

    const find = this.state.findvalue
    const formData = new FormData();
    formData.append("search_input", find);
 
    axios({
    method: 'get',
    url: API_BASE_URL+"tutor_search_subject/?search_input="+find,
    //url:"https://api.wantateacher.com/tutor_search_subject/?search_input="+find, 
    header:{
    "content-type": "application/json",
    'Authorization' : "Bearer "+UserToken,   
    }
  })
    .then(response => {
    
        this.setState({subcategary_det:response.data.subcategorylist})
     
    })
  }


    if (this.cancel) {
      // Cancel the previous request before making a new request
      this.cancel.cancel();
    }
    // Create a new CancelToken
    this.cancel = axios.CancelToken.source();
  
  };

// handleClick=(id,id1)=>{
 
//   localStorage.setItem('sub_categories_id',id);
//   localStorage.setItem('categories_id',id1);


//   const values=localStorage.getItem('sub_categories_id');
//   const value1444=localStorage.getItem('categories_id');
 
//   const formData = new FormData();

//       formData.append('sub_categories_id', values);
//       formData.append('categories_id',value1444);
   
// }



  handleLogoutClick() {
    const UserToken = localStorage.getItem('UserToken');
 
        const sendData = {
          notification_id:this.state.notificationId,
            device_type:"web"
            }
      axios({
        method: 'POST',

        url: API_BASE_URL + 'logout_user/',
        headers: { 'Authorization': "Bearer " + UserToken },
        data: sendData,
        }).then(response => {
            
            if(response.data.success==200){
                if(response.data.message=="User successfully logout"){
                    // this.sweetAlertLogout()
                    // window.open('/')
                }
            }

           
        })
        .catch((error) => {
          console.log(error)
         
        });
    
    localStorage.getItem('UserToken')
    localStorage.clear();
    this.setState({ redirect: true });
  }

 componentDidMount(){

console.log("this my notifications id",this.state.notificationId)
  const user = localStorage.getItem('UserToken') 
  if (user && user !== 'undefined') {            
      // this.props.history.push('/')             
  }
  const messaging = firebase.messaging();


  

  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("../firebase-messaging-sw.js")
      .then((registration)=> {
        console.log("Registration successful, scope is:", registration);
        messaging.getToken({vapidKey: 'BFHdJVqtAg6uSQvDTrpjZHCmK7VcoAHjdWa8eF1NFmZzWxMhxNlG8L8iiHvp296ii0Aj3J0jV5yqig5OwclgIl8'})
          .then((currentToken) => {
            if (currentToken) {
              console.log('current token for client: ', currentToken);
              this.setState({notificationId:currentToken}) 
            } else {
              console.log('No registration token available. Request permission to generate one.');
            }
          }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            // catch error while creating client token
          });  
        })
        .catch(function(err) {
          console.log("Service worker registration failed, error:"  , err );
      }); 
    }


  axios.get(API_BASE_URL + `category_list/`)
       .then(res => {
                this.setState({ CategoryListSlider: res.data.result });
                console.log("123",res.data.result)
              
            })

   usertype= localStorage.getItem('usertype');
      //alert(usertype);
   var scrollComponent = this;
   document.addEventListener("scroll", function(e) {
     scrollComponent.toggleVisibility();
   });
   const  UserToken =  localStorage.getItem('UserToken')
 
   if(UserToken)
    {
    this.setState({TokenState:UserToken,headerStatus:true}) 

   }

   }


   toggleVisibility() {
       if (window.pageYOffset > 300) {
         this.setState({
           is_visible: true
         });
       } else {
         this.setState({
           is_visible: false
         });
       }
     }

     scrollToTop() {
       window.scrollTo({
         top: 0,
         behavior: "smooth"
       });
     }

     handlelocal(){
       localStorage.setItem('cat_id',"");
       localStorage.setItem('name',"")
       localStorage.setItem('detail',"")
     }

  render() {



    const { query } = this.state.query;
    
    const { is_visible } = this.state;

    if (this.state.redirect) {
      return (
          <Redirect to={'/'} />
      )
  }

    return(

<header className="Header">

{this.state.headerStatus == false? 

<div>

<div className="scroll-to-top">
       
       <div onClick={() => this.scrollToTop()}>
       <div>
       {is_visible && (
       <div>
       <button className="scroll-top scroll-to-target open" data-target="html">
       <i className="fa fa-angle-up" aria-hidden="true"><FontAwesomeIcon icon={faAngleUp}/></i>
       </button> 
       </div>      
       )} 
  
      
      <div className="container-fluid width_custom m12">
        <nav className="navbar navbar-expand-lg navbar-light custom_p">
          <a className="navbar-brand" href="#">
            <img className="header_logo1" src={logo} alt=""/>
            <img className="header_logo2" src={logo2} alt=""/>
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
        
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto custom_navbar_nav">
              <li className="nav-item active">
                <a className="nav-link" href="/">Home</a>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="how_works">How It Works </Link>
              </li>
            </ul>
            <div className="autocomplete-wrapper">
        
        <Autocomplete
          
          value={this.state.query}
          items={this.state.subcategary_det}
          getItemValue={item => item.sub_category_level_name}
          shouldItemRender={renderMovieTitle}
          renderInput={(params) => (
          <input
          fullWidth
          {...params}
          variant="outlined"
          placeholder="Search.."
          /> )}
          renderMenu={item => (
            <div className="dropdown">
              {item} 
            </div>
          )}
        
          renderItem={(item, isHighlighted) =>
            <div className={`item ${isHighlighted ? 'selected-item' : ''}`}>

                {/* <Link to={{
                pathname: "/tutorsearch/"+item.category_name.replaceAll(" ","_"),
                params22: item.subcat_id,
                params33: item.id,
                params1:  item.category_name,
                params2:  item.category_description
              }}> */}
            
            <Link to={"/tutorsearch/"+item.category_name.replaceAll(" ","_")+'/'+item.id}>

            {item.sub_category_level_name+"  ("+item.category_name+")"}

            </Link>    
               
            </div>
          }
          
          onChange={this.handleOnInputChange}
      
          />

      </div>
      <form className="form-inline my-2 my-lg-0">
      <Link className="sign_in_btn mr20" to="/login" type="submit"><i className="signin_user" aria-hidden="true"><FontAwesomeIcon icon={faUser}></FontAwesomeIcon></i>Sign in</Link>
      
      <Link className="header_btn_reg student_reg1 mr20" to={{
              
              pathname: "/sign_up",
             
              userType: 0,
              
          }}>Student Register</Link>
      
      <Link className="header_btn_reg teacher_reg" to={{
              
              pathname: "/t_sign_up",
             
              userType: 1,
              
          }}>Teacher Register</Link>
      
      </form>
          </div>
        </nav>
        </div>
                </div>
            </div>
        </div>
    </div>
  
    :    
    
      
<div className="scroll-to-top">
       
       <div onClick={() => this.scrollToTop()}>
       <div>
       {is_visible && (
       <div>
       <button className="scroll-top scroll-to-target open" data-target="html">
       <i className="fa fa-angle-up" aria-hidden="true"><FontAwesomeIcon icon={faAngleUp}/></i>
       </button> 
       </div>      
       )} 
     <div className="container-fluid width_custom m12 custom_mob">
     <nav className="navbar navbar-expand-lg navbar-light custom_p set_header set_mob_header">
       <a className="navbar-brand" href="#">
         <img className="header_logo1" src={logo} alt=""/>
         <img className="header_logo2" src={logo2} alt=""/>
       </a>
       <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
         <span className="navbar-toggler-icon"></span>
       </button>
     
       <div className="collapse navbar-collapse" id="navbarSupportedContent">
         <ul className="navbar-nav mr-auto custom_navbar_nav">
           <li className="nav-item active">
             <Link className="nav-link" to="/">Home</Link>
           </li>
           <li className="nav-item">
                <Link className="nav-link" to="/how_works">How It Works </Link>
              </li>
         </ul>
         <div className="autocomplete-wrapper">
        
          <Autocomplete
            value={this.state.query}
            items={this.state.subcategary_det}
            getItemValue={item => item.sub_category_level_name}
            shouldItemRender={renderMovieTitle}
            renderInput={(params) => (
              <input
              fullWidth
              {...params}
              variant="outlined"
              placeholder="Search.."
              /> )}
              renderMenu={item => (
                <div className="dropdown">
                 {item} 
                </div>
              )}
            renderMenu={item => (
              <div className="dropdown">
               {item} 
              </div>
            )}
            renderItem={(item, isHighlighted) =>
              <div className={`item ${isHighlighted ? 'selected-item' : ''}`}>
                   {/* <Link to={{
                    pathname: "/tutorsearch/"+item.category_name.replaceAll(" ","_"),
                    params22: item.subcat_id,
                    params33: item.id,
                    params1:  item.category_name,
                    params2:  item.category_description
                  }}> */}

                <Link to={"/tutorsearch/"+item.category_name.replaceAll(" ","_")+'/'+item.id}>

                {item.sub_category_level_name+"  ("+item.category_name+")"}
                
                </Link>    
              </div>
            }
            
            onChange={this.handleOnInputChange}
            // onSelect={this.handleOnInputChange}
            
            />

        </div>
        <form className="form-inline my-2 my-lg-0">
         
        {usertype==0?
       
          <Link  className="header_btn_reg student_reg1 mr20"  to="/dashboard" >My Account</Link>
          
          :<Link  className="header_btn_reg student_reg1 mr20"  to="/tutor_home" >My Account</Link>
        }
        <Link onClick={() => this.handleModal90()} className="header_btn_reg teacher_reg log_out_mob">Logout</Link>
          
         </form>

       </div>
     </nav>
    </div>
    
    </div>
            
        
    </div>
   
    </div>

    
    
     }
     <Modal show={this.state.show90} onHide={() => this.handleModal90()}>

<Modal.Header className="allmodal_header modal-header" >
      <h2>Are you Sure You Want To Logout?</h2>
                  </Modal.Header>
                  <Modal.Body>

            
              
              
            
            </Modal.Body>
            <Modal.Footer className="text-center all_popups_buttonss custom_modal_footer">
            <Button onClick={this.handleLogoutClick} class="submit_bb" type="submit">Yes</Button>
                <Button onClick={this.handleCancel} class="cancel_bb" type="submit">No</Button>
              </Modal.Footer>
            </Modal>
         

</header>

    )
  }
}