import React from 'react';
import axios from "axios";
import Header from "../common/header";
import firebase from '../../firebase';
import Footer from "../common/footer";
import PhoneInput from "react-phone-input-2";
import { API_BASE_URL } from '../../config/api';
import swal from 'sweetalert';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import validator from 'validator'

export default class Send_otp extends React.Component {
  constructor() {
    super();
    this.state = {
      visible: false,
      username: '',
      otp: '',
      phone: '',
      country: '',
      data:'',
      MessageUsername: '',
      usernamevalidation: false,
      input: {},
      errors: {},
      email: '',
      password: '',
      usernameError:false,
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);

  }

  handleChange(evt) {
    console.log("check1",isNaN(evt.target.value))

    const value = evt.target.value;
    console.log("phone number", value)
    this.setState({ [evt.target.name]: evt.target.value });
    let input = this.state.input;
    input[evt.target.name] = evt.target.value;
    this.setState({ email: evt.target.value })
    this.setState({ password: evt.target.value })
    this.setState({username:value})
    this.setState({ usernamevalidation: false })
    this.setState({ phone: evt.target.value })

    this.setState({ input });
  }




 



  sweetAlertMessageCountryCode = () => {
    swal({
      text: "This is invailid user ",

      icon: "warning",
      dangerMode: true,

    })


  }
  sweetAlertEmailVerification = () => {
    swal({
      text: "Please Check Your Email",

    })


  }
  sweetAlertUserRequiredError=()=>{
    swal({
    text: "Username/Email is required.",

    icon: "warning",
    dangerMode: true,
  })

    
  }


  // validator=()=>{

  // }

  handleSubmit = event => {
    event.preventDefault();

   


 
    if(this.state.username===''){
      this.setState({usernamevalidation:"*Please Enter Email/Phone"})
    }
     
    if(isNaN(this.state.username)){
      if(!validator.isEmail(this.state.username)){
        this.setState({usernamevalidation:"Please Enter Valid Email"})
      }
      

  }
  else if(!isNaN(this.state.username)){

if((this.state.username.length<=8) && (this.state.username!=='')){
  this.setState({usernamevalidation:"Please Enter More then 8 Number "})

}

  }


    const data = this.state.country
    const finaldatavalue = this.state.username
    console.log('usrerr',this.state.username)
  
    //
 


    const countryCodeData = data.dialCode
    console.log("phonecodedata",countryCodeData)
    localStorage.setItem('country_code',countryCodeData)
   
    const formData = new FormData();
    if (!isNaN(this.state.username)) {
      var number = this.state.username.replace(/\D/g, '').slice(-10);
      formData.append('username', number);
      localStorage.setItem('username',number)
      console.log('11234',localStorage.setItem('username',number))

     
    }
    else if(isNaN(this.state.username) ){
      formData.append('username', this.state.username);

    }
    if (!isNaN(this.state.username)) {
      formData.append('type', 'phone');
    }
    else if(isNaN(this.state.username)) {
      formData.append('type', 'email');
    }



    this.setState({ visible: true })

    axios({
      method: 'post',
      url: API_BASE_URL + `forgot_password/`,
      data: formData

    }).then(response => {

      this.setState({ visible: false })

      if (response.data.success == 201) {
        if (response.data.message == "This is invailid user") {
          this.sweetAlertMessageCountryCode()

        }
         if(response.data.message=='username is required.'){
          // this.setState({usernameError:true})
          this.sweetAlertUserRequiredError()

        }
      }
      else if (response.data.success == 200) {
        // localStorage.setItem('username',number)
        localStorage.setItem("phone_code", countryCodeData)
        if (!isNaN(this.state.username)) {
          this.props.history.push("/verify_otp")
        }
        else if(isNaN(this.state.username)) {

          this.sweetAlertEmailVerification()


        }
      }
    })
  

      .catch((error) => {
        console.log('error', error)

      });
    
    
}


  handleChange1(evt) {

    const value = evt.target.value;
    this.setState({ [evt.target.name]: evt.target.value });


  }


  handleChangePhoneNumber = (phone, country, event) => {
    console.log('123455', phone)
    this.setState({
      phone: phone,
      country: country
    })
    var username = event.target.value

    this.setState({ username: phone })

  }



  render() {
    return (
      <main>
        <Header></Header>
        <section className="login_form mx-auto margin_top">
          <div className="container-fluid width_custom border-0">
            <div className="row">
              <div className="col-md-8 col-sm-12 bg_banner_login">

              </div>
              <div className="col-md-4 col-sm-12 m-auto">
                <div className="card2 border-0 my-3">
                  <form className=" text-center loginpage" onSubmit={this.handleSubmit} type='post'>
                    <h2>Verification</h2>

                    <div className="form-group input_container">
                      {Number(this.state.phone) ?
                        <>
                          <PhoneInput
                            inputProps={{

                              required: true,
                              autoFocus: true
                            }}
                            country={'us'}

                            enableSearch={true}
                            onChange={this.handleChangePhoneNumber}
                            placeholder="Phone"
                          />

                        </>
                        :
                        <>

                          <input
                            type="username"
                            id="defaultUsernameForm"
                            onChange={this.handleChange}
                            name="username"
                            value={this.state.input.username}
                            className="form-control my-input"
                            placeholder="email"
                             />
                        </>
                      }

                       <div className="text-danger">
                      
                         {this.state.usernamevalidation}
                      </div> 
                      {/* <div className="text-danger">
                        {this.state.usernameError ? <p className="text-danger">Please Enter Valid Username</p> : null}

                      </div> */}
                    </div>
                    <Loader
                      visible={this.state.visible}
                      type="Oval"
                      color="#00bacc"
                      height={40}
                      width={40}
                      timeout={3000} //3 secs
                    />
                    <div className="form-group">
                      <button

                        className="btn btn-block send-button border_radius_20 tx-tfm" type="submit">Send OTP</button>
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer></Footer>

      </main>

    );
  }
}