import { faBell, faCheckCircle, faHeart, faComment, faFileInvoice, faQuestionCircle, faSignOutAlt, faTachometerAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Redirect } from 'react-router';
import { Modal, Card,Button } from "react-bootstrap";
import axios from 'axios'
import defaultMaleImg from '../../assets/img/default_male.png';
// import Editprofile from '../common/editprofile';
import defaultFemaleImg from '../../assets/img/default_female.png';
import { API_BASE_URL, BASE_URL } from '../../config/api.js'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
import { withRouter } from 'react-router';
import firebase from '../../firebase.js';

// import swal from 'sweetalert';
var datafullname=''
 class SideBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            student: '',
            show90:'',
            student_data: [],
            conversationId:'',
            notificationId: '',
        }

        this.handleLogoutClick = this.handleLogoutClick.bind(this);
        this.studentProfile = this.studentProfile.bind(this);
        this.onChatHandle = this.onChatHandle.bind(this);
        this.handleData=this.handleData.bind(this);
    }




    // sweetAlertLogout(){
    //     swal({
    //         text: "Logout SuccessFully",
    //         icon: "success",
    //     })
    // }


    handleLogoutClick() {

        const UserToken = localStorage.getItem('UserToken');
        if (UserToken != '') {
            const sendData = {
                notification_id:this.state.notificationId,
                device_type:"web"
                }
          axios({
            method: 'POST',

            url: API_BASE_URL + 'logout_user/',
            headers: { 'Authorization': "Bearer " + UserToken },
            data: sendData,
            }).then(response => {
                console.log("res",response)
                if(response.data.success==200){
                    if(response.data.message=="User successfully logout"){
                        // this.sweetAlertLogout()
                    }
                }

               
            })
            .catch((error) => {
             
            });
            localStorage.getItem('UserToken')
            localStorage.clear();
            this.setState({ redirect: true });
        }
  

        // localStorage.getItem('UserToken')
        // localStorage.clear();
        // this.setState({ redirect: true });
    }



    componentDidUpdate(prevProps) {
        console.log('pre',prevProps.data)
        datafullname=prevProps.data
        
        console.log('dataaa',this.props.data)
        if(this.props.data!==prevProps.data){
            this.setState({datafullname:prevProps.data})
        }
        else{

        }
      }


    componentDidMount() {
    console.log("finallocal",localStorage.getItem('datafinal'))
        console.log("propd data",this.props.data)
       

            const user = localStorage.getItem('UserToken') 
            if (user && user !== 'undefined') {            
                // this.props.history.push('/')             
            }
            const messaging = firebase.messaging();
          
          
            
          
            if ("serviceWorker" in navigator) {
              navigator.serviceWorker
                .register("../firebase-messaging-sw.js")
                .then((registration)=> {
                  console.log("Registration successful, scope is:", registration);
                  messaging.getToken({vapidKey: 'BFHdJVqtAg6uSQvDTrpjZHCmK7VcoAHjdWa8eF1NFmZzWxMhxNlG8L8iiHvp296ii0Aj3J0jV5yqig5OwclgIl8',})
                    .then((currentToken) => {
                      if (currentToken) {
                        console.log('current token for client: ', currentToken);
                        this.setState({notificationId:currentToken}) 
                      } else {
                        console.log('No registration token available. Request permission to generate one.');
                      }
                    }).catch((err) => {
                      console.log('An error occurred while retrieving token. ', err);
                      // catch error while creating client token
                    });  
                  })
                  .catch(function(err) {
                    console.log("Service worker registration failed, error:"  , err );
                }); 
              }
          
              // onMessageListener().then(payload => {
              //   // setShow(true);
              //   // setNotification({title: payload.notification.title, body: payload.notification.body})
              //   console.log("ioooooooooo",payload);
              // }).catch(err => console.log('failed: ', err));
         
       
        // if(UserToken!=''){

            this.studentProfile();
            this.chatActiveUsers();
 
    }

    chatActiveUsers(){

        const UserToken = localStorage.getItem('UserToken');
        if (UserToken != '') {
          axios({
            method: 'Get',
            url: API_BASE_URL + 'user_chat_list_booking/',
            headers: { 'Authorization': "Bearer " + UserToken },
            //data: formData,
            }).then(response => {
               // console.log("response active chat user list", response.data.userchatdata[0][0].tutor_id)
                this.setState({conversationId:response.data.userchatdata[0][0].tutor_id})
            })
            .catch((error) => {
             
            });
        }
  
      }


    studentProfile(){

    // alert("updated")

        const UserToken = localStorage.getItem('UserToken');

        axios({
            method: 'GET',
            url: API_BASE_URL + "student_profile/",
            headers: { 'Authorization': "Bearer " + UserToken },
    
    
        }).then(response => {
            console.log("res edit profile", response.data)
            this.setState({ student: response.data })
            this.setState({ student_data: response.data.student_data })
    
        })

    }

    onChatHandle(id) {
       // alert(id)
        this.props.history.push({ 
            pathname: '/conversations',
            state: { id: id }
        });
    }
    handleData(data) {
        this.setState({
          fromChild: data
        });
      }
      handleCancel=()=>{
        this.handleModal90()
      }

      handleModal90(){
        this.setState({show90:!this.state.show90})
      }
    render() {

        const student = this.state.student
        if (this.state.redirect) {
            return (
                <Redirect to={'/'} />
            )
        }
        return (
         
            <div className="box_custom side_panel_dashboard sidebar_mobile">
                <div className="profile_dashboard">

                    {this.state.student_data.map((img) =>

                        <>
                            {img.image == null ?
                                img.gender == "female" ?
                                <img src={defaultFemaleImg} alt="" />
                                :<img src={defaultMaleImg} alt="" />
                                : <img src={BASE_URL + img.image} alt="" />
                            }

                            {/* {img.image == null?
                            img.gender == "female" ?
                            <img src={defaultFemaleImg} alt="" />
                            :<img src={defaultMaleImg} alt="" />
                            
                            :localStorage.getItem('imageupdate')!==null?
                            <img src={BASE_URL + img.image} alt="" />

                         

                            : <img src={BASE_URL +localStorage.getItem('imageupdate')} alt=""/>} */}
                           
                            <div className="pro_detail">
                                 {/* <Editprofile handlerFromParant={this.handleData} />  */}
                                {/* {img.fullname!=''?
                                <h6>{img.fullname}</h6>
                                : */}
                                {/* { this.props.data===''? */}
                                {/* {/* <h6>{img.fullname}</h6> */}
                                 
                                 {/* <h6>{datafullname}</h6> */}
                                 {/* <h6>{this.props.data}</h6>} */}
                                  {/* } */}
                                {/* <h1>{localStorage.getItem('username')}</h1> */}
                                {/* {this.props.data===''?
                                <h6>{img.fullname}2</h6>:
                                <h6>{this.props.data}1</h6>} */}
                                {localStorage.getItem('datafinal')==null?
                                <h6>{img.fullname}</h6>
                                :<h6>{localStorage.getItem('datafinal')}</h6>}
                                <span>{student.student_mobile}</span>

                            </div>
                        </>
                    )}

                </div>
                <div className="dashboard_sidebar text-center">
                    <ul>
                        <Link to="/dashboard"><li>
                            <i aria-hidden="true"><FontAwesomeIcon icon={faTachometerAlt}></FontAwesomeIcon></i>
                        Dashboard
                        </li></Link>
                        <Link to="/userbooking"> <li>
                            <i aria-hidden="true"><FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon></i>
                        Sessions
                        </li></Link>
                        <Link to="/editprofile"> <li>
                            <i aria-hidden="true"><FontAwesomeIcon icon={faUser}></FontAwesomeIcon></i>
                        Edit Account
                        </li></Link>
                        {/* <Link> */}
                        <li onClick={() =>this.onChatHandle(this.state.conversationId)}>
                            <i aria-hidden="true"><FontAwesomeIcon icon={faComment}></FontAwesomeIcon></i>
                        Conversations
                        </li>
                        {/* </Link> */}
                        <Link to="/notifications"><li>
                            <i aria-hidden="true"><FontAwesomeIcon icon={faBell}></FontAwesomeIcon></i>
                        Notifications
                        </li></Link>
                        <Link to="/helpSupport"><li>
                            <i aria-hidden="true"><FontAwesomeIcon icon={faBell}></FontAwesomeIcon></i>
                        Help & Support
                        </li></Link>
                        <Link to="/favlist"><li>
                            <i aria-hidden="true"><FontAwesomeIcon icon={faHeart}></FontAwesomeIcon></i>
                            Favourite List
                        </li></Link>
                        <Link  onClick={() => this.handleModal90()}>  <li>
                            <i aria-hidden="true"><FontAwesomeIcon icon={faSignOutAlt}></FontAwesomeIcon></i>
                        Logout
                        </li></Link>
                    </ul>

                    <Modal show={this.state.show90} onHide={() => this.handleModal90()}>

<Modal.Header className="allmodal_header modal-header" >
      <h2>Are you Sure You Want To Logout?</h2>
                  </Modal.Header>
                  <Modal.Body>

            
              
              
            
            </Modal.Body>
            <Modal.Footer className="text-center all_popups_buttonss custom_modal_footer">
            <Button onClick={this.handleLogoutClick} class="submit_bb" type="submit">Yes</Button>
                <Button onClick={()=>this.handleCancel()} class="cancel_bb" type="submit">No</Button>
              </Modal.Footer>
            </Modal>
         
                </div>
            </div>
           
            );
    }
}


//export default SideBar
export default withRouter(SideBar);