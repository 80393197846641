import React from 'react';
import Header from './header';
import Footer from './footer';
import SideBar from '../user/sideBar';
import axios from 'axios';
import { API_BASE_URL, BASE_URL } from '../../config/api.js';
import moment from "moment";
import { Button, Modal } from "react-bootstrap";
import 'moment-timezone'
import { Multiselect } from 'multiselect-react-dropdown';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from 'sweetalert';
var time_schedule = ""

class postBooking extends React.Component {
constructor(props) {
    super(props)
        this.state = {
            id: this.props.location.state.id,
            MorningtimeLAST:[],
            AfternoontimeLast:[],
            EveningtimeLast:[],
            show:"",
            catgoryIdparams: this.props.location.state.id,
            subcategoryIdparams: this.props.location.state.id1,
            selectectedItems: [],
            ArraycheckedData:[],
            checkedId:[],
            selectectedItems2: [],
            selectectedItems3: [],
            toggleCheckBoxA2: false,
            allcheckbox1: false,
            allcheckbox2: false,
            individualMonCheck1: false,
            individualMonCheck2: false,
            individualMonCheck3: false,
            categoryDashboard: [],
            individualTueCheck1: false,
            individualWedCheck1: false,
            individualThusCheck1: false,
            individualFriCheck1: false,
            individualSatCheck1: false,
            individualSunCheck1: false,
            address: '',
            individualTueCheck2: false,
            individualWedCheck2: false,
            individualThusCheck2: false,
            individualFriCheck2: false,
            individualSatCheck2: false,
            individualSunCheck2: false,
            MessageOfferPriceAdded: '',
            MessageOfferPriceAlreadyExist: '',
            individualTueCheck3: false,
            individualWedCheck3: false,
            individualThusCheck3: false,
            individualFriCheck3: false,
            individualSatCheck3: false,
            individualSunCheck3: false,
            toggleCheckBoxA3: false,
            toggleCheckBoxB1: false,
            toggleCheckBoxB2: false,
            toggleCheckBoxB3: false,
            toggleCheckBoxC1: false,
            toggleCheckBoxC2: false,
            toggleCheckBoxC3: false,
            toggleCheckBoxD1: false,
            toggleCheckBoxD2: false,
            toggleCheckBoxD3: false,
            toggleCheckBoxE1: false,
            toggleCheckBoxE2: false,
            toggleCheckBoxE3: false,
            toggleCheckBoxF1: false,
            toggleCheckBoxF2: false,
            toggleCheckBoxF3: false,
            toggleCheckBoxG1: false,
            toggleCheckBoxG2: false,
            toggleCheckBoxG3: false,
            anyWeekdayAfternoon: 0,
            anyWeekdayNight: 0, 
            eckBoxG2: false,
            toggleCheckBoxG3: false,
            prefferedDatetime: [],
            date: '',
            prevDateDisabled: '',
            category_id: '',
            Day1: '',
            Day2: '',
            Day3: '',
            Day4: '',
            Day5: '',
            Day6: '',
            Day7: '',
            DayAfternoon1: '',
            DayAfternoon2: '',
            DayAfternoon3: '',
            DayAfternoon4: '',
            DayAfternoon5: '',
            DayAfternoon6: '',
            DayAfternoon7: '',
            DayEvening1: '',
            DayEvening2: '',
            DayEvening3: '',
            DayEvening4: '',
            DayEvening5: '',
            DayEvening6: '',
            DayEvening7: '',
            Morningtime: [],
            Afternoontime: [],
            Eveningtime: [],
            itemsM: 
           
           [ { name: '5AM-6AM', value: 6 },
            { name: '6AM-7AM', value: 7 },
            { name: '7AM-8AM', value: 8 },
            { name: '8AM-9AM', value: 9 },
            { name: '9AM-10AM', value: 10 },
            { name: '10AM-11AM', value: 11 },
            { name: "11AM-12PM", value: 12 },
          
            ],
            itemsM: 
          
           [ 
               { name: '5AM-6AM', value: 6 },
            { name: '6AM-7AM', value: 7 },
            { name: '7AM-8AM', value: 8 },
            { name: '8AM-9AM', value: 9 },
            { name: '9AM-10AM', value: 10 },
            { name: '10AM-11AM', value: 11 },
            { name: "11AM-12PM", value: 12 },
      
            ],
            itemsA : [
             { name: "12PM-1PM", value: 13 },
            { name: "1PM-2PM", value: 14 },
            { name: "2PM-3PM", value: 15 },
            { name: "3PM-4PM", value: 16 },

            ],
            itemsE :[
             { name: "5PM-6PM", value: 18 },
             { name: "6PM-7PM", value: 19 },
             { name: "7PM-8PM", value: 20 },
             { name: "8PM-9PM", value: 21 },
             { name: "9PM-10PM", value: 22 },
             { name: "10PM-11PM", value: 23 },
             { name: "11PM-12PM", value: 24 },

            ],

            frequencySessionID: '',
            Morningtime: [],
            Afternoontime: [],
            Eveningtime: []

        }
        this.checkBoxHandlerAnyWeekdayNight = this.checkBoxHandlerAnyWeekdayNight.bind(this);
        this.checkBoxHandlerAnyWeekdayAfternoon = this.checkBoxHandlerAnyWeekdayAfternoon.bind(this);
        this.anyWeekDayprefferedDatetime = this.anyWeekDayprefferedDatetime.bind(this);
        this.handleChange8 = this.handleChange8.bind(this);
        this.handleChangeFrequency = this.handleChangeFrequency.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.dateLimitation = this.dateLimitation.bind(this);
       
    }

    handleDateChange(date) {

        let dateValue = moment(date).format('MM-DD-YYYY hh:mm A [CST]') ;
        this.setState({ booking_start: dateValue });
        // this.setState({ booking_start: date })
        // console.log(moment(date).tz('America/Chicago').format("MM/DD/YY hh:mm:ss A "))
    }
    handleSave=()=>{
       this.handleClick1()
        

    }


    componentDidMount() {
    const UserToken = localStorage.getItem('UserToken');

    if (UserToken != '') {
            const filedata = new FormData();
            filedata.append('tutor_id', this.state.id);
            axios({
                method: 'POST',
                url: API_BASE_URL + 'tutor_session_rate_list/',
                data: filedata,
                headers: {
                    'Authorization': 'Bearer ' + UserToken,
                    'Content-Type': 'multipart/form-data'
                },
            }).then((response) => {
                console.log('tutor_session_rate_list/', response.data)
                this.setState({ TutorRateShow: response.data.rate_list })
                this.state.TutorRateShow.map((rate)=>{
                    if(rate.tutor_sub_category_id == this.state.subcategoryId){
                        console.log("all ratesss",rate.one_hours)
                        this.setState({tutorRate:rate.one_hours})
                    }
                })
            }).catch((error) => {
                
            })
        }
    }
    handleCancel=()=>{
        this.setState({ ArraycheckedData: "" })
        this.setState({
            anyWeekdayAfternoon: 0,
            anyWeekdayNight: 0,
        individualMonCheck1: false,
        individualTueCheck1: false,
        individualWedCheck1: false,
        individualThusCheck1: false,
        individualFriCheck1: false,
        individualSatCheck1: false,
        individualSunCheck1: false,
        individualMonCheck2: false,
        individualTueCheck2: false,
        individualWedCheck2: false,
        individualThusCheck2: false,
        individualFriCheck2: false,
        individualSatCheck2: false,
        individualSunCheck2: false,
        individualMonCheck3: false,
        individualTueCheck3: false,
        individualWedCheck3: false,
        individualThusCheck3: false,
        individualFriCheck3: false,
        individualSatCheck3: false,
        individualSunCheck3: false,
        })
        this.handleClick1()
    }
    handleClick1 = () => {
        this.setState({show: !this.state.show})
        }
    dateLimitation() {
        let newDate = new Date()
        const year = newDate.getFullYear();
        const month = newDate.getMonth();
        const day = newDate.getDate();
        const c = new Date(year, month, day)
        this.setState({ prevDateDisabled: c })
    }


    handleTimeCancel(active) {
        if (active == 'Morning') {
            this.setState({ isShownPicker: '' })
            this.setState({ selectectedItems: '' })
        }
        if (active == 'Afternoon') {
            this.setState({ isShownPicker: '' })
            this.setState({ selectectedItems2: '' })
        }
        if (active == 'Evening') {
            this.setState({ isShownPicker: '' })
            this.setState({ selectectedItems3: '' })
        }


    }

    checkBoxHandlerAnyWeekdayNight = (e) => {
        this.setState({anyweekdaynightdata:e.target.checked})

        let id = e.target.id
        if (id == 1) {

        if (this.state.allcheckbox1) {
            this.setState({
                allcheckbox2: 0,
                            allcheckbox1: 0,
                            individualMonCheck1: false,
                            individualMonCheck2: false,
                            individualMonCheck3: false,
                            individualTueCheck1: false,
                            individualTueCheck2: false,
                            individualTueCheck3: false,
                            individualWedCheck1: false,
                            individualWedCheck2: false,
                            individualWedCheck3: false,
                            individualThusCheck1: false,
                            individualThusCheck2: false,
                            individualThusCheck3: false,
                            individualFriCheck1: false,
                            individualFriCheck2: false,
                            individualFriCheck3: false,
                            individualSatCheck1: false,
                            individualSatCheck2: false,
                            individualSatCheck3: false,
                            individualSunCheck1: false,
                            individualSunCheck2: false,
                            individualSunCheck3: false,
                            ArraycheckedData: []
            })
        }else{
            this.setState({
                allcheckbox2: 0,
                allcheckbox1: 1,

                individualMonCheck1: false,
                individualMonCheck2: false,
                individualMonCheck3: true,

                individualTueCheck1: false,
                individualTueCheck2: false,
                individualTueCheck3: true,

                individualWedCheck1: false,
                individualWedCheck2: false,
                individualWedCheck3: true,

                individualThusCheck1: false,
                individualThusCheck2: false,
                individualThusCheck3: true,

                individualFriCheck1: false,
                individualFriCheck2: false,
                individualFriCheck3: true,

                individualSatCheck1: false,
                individualSatCheck2: false,
                individualSatCheck3: true,

                individualSunCheck1: false,
                individualSunCheck2: false,
                individualSunCheck3: true,
            })
            this.anyWeekDayprefferedDatetime('monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', 'evening')

        }
    }
        else
            if (id == 2) {
                if (this.state.allcheckbox2) {

                    this.setState({
                        allcheckbox2: 0,
                        allcheckbox1: 0,
                        individualMonCheck1: false,
                            individualMonCheck2: false,
                            individualMonCheck3: false,
                            individualTueCheck1: false,
                            individualTueCheck2: false,
                            individualTueCheck3: false,
                            individualWedCheck1: false,
                            individualWedCheck2: false,
                            individualWedCheck3: false,
                            individualThusCheck1: false,
                            individualThusCheck2: false,
                            individualThusCheck3: false,
                            individualFriCheck1: false,
                            individualFriCheck2: false,
                            individualFriCheck3: false,
                            individualSatCheck1: false,
                            individualSatCheck2: false,
                            individualSatCheck3: false,
                            individualSunCheck1: false,
                            individualSunCheck2: false,
                            individualSunCheck3: false,
                        ArraycheckedData: [],


                    })


                }
                else {

                                this.setState({
                                    allcheckbox2: true,
                                    allcheckbox1: false,
            
                                    individualMonCheck1: false,
                                    individualMonCheck2: true,
                                    individualMonCheck3: false,
            
                                    individualTueCheck1: false,
                                    individualTueCheck2: true,
                                    individualTueCheck3: false,
            
                                    individualWedCheck1: false,
                                    individualWedCheck2: true,
                                    individualWedCheck3: false,
            
                                    individualThusCheck1: false,
                                    individualThusCheck2: true,
                                    individualThusCheck3: false,
            
                                    individualFriCheck1: false,
                                    individualFriCheck2: true,
                                    individualFriCheck3: false,
            
                                    individualSatCheck1: false,
                                    individualSatCheck2: true,
                                    individualSatCheck3: false,
            
                                    individualSunCheck1: false,
                                    individualSunCheck2: true,
                                    individualSunCheck3: false,
            
            
            
                                })
            
                                this.anyWeekDayprefferedDatetime('monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', 'afternoon')
            
                            }
                        
            }

          
    
    }



    checkBoxHandlerAnyWeekdayAfternoon = (e) => {
        console.log("anyweekafternoon",e.target.checked)
        this.setState({anyweekfternoondata:e.target.checked})
        let id = e.target.id
        if (id == 1) {

        if (this.state.allcheckbox1) {
            this.setState({
                allcheckbox2: 0,
                            allcheckbox1: 0,
                            individualMonCheck1: false,
                            individualMonCheck2: false,
                            individualMonCheck3: false,
                            individualTueCheck1: false,
                            individualTueCheck2: false,
                            individualTueCheck3: false,
                            individualWedCheck1: false,
                            individualWedCheck2: false,
                            individualWedCheck3: false,
                            individualThusCheck1: false,
                            individualThusCheck2: false,
                            individualThusCheck3: false,
                            individualFriCheck1: false,
                            individualFriCheck2: false,
                            individualFriCheck3: false,
                            individualSatCheck1: false,
                            individualSatCheck2: false,
                            individualSatCheck3: false,
                            individualSunCheck1: false,
                            individualSunCheck2: false,
                            individualSunCheck3: false,
                            ArraycheckedData: []
            })
        }else{
            this.setState({
                allcheckbox2: 0,
                allcheckbox1: 1,

                individualMonCheck1: false,
                individualMonCheck2: false,
                individualMonCheck3: true,

                individualTueCheck1: false,
                individualTueCheck2: false,
                individualTueCheck3: true,

                individualWedCheck1: false,
                individualWedCheck2: false,
                individualWedCheck2: true,

                individualThusCheck1: false,
                individualThusCheck2: false,
                individualThusCheck3: true,

                individualFriCheck1: false,
                individualFriCheck2: false,
                individualFriCheck3: true,

                individualSatCheck1: false,
                individualSatCheck2: false,
                individualSatCheck3: true,

                individualSunCheck1: false,
                individualSunCheck2: false,
                individualSunCheck3: true,
            })
            this.anyWeekDayprefferedDatetime('monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', 'evening')

        }
    }
        else
            if (id == 2) {
                if (this.state.allcheckbox2) {

                    this.setState({
                        allcheckbox2: 0,
                        allcheckbox1: 0,
                        individualMonCheck1: false,
                            individualMonCheck2: false,
                            individualMonCheck3: false,
                            individualTueCheck1: false,
                            individualTueCheck2: false,
                            individualTueCheck3: false,
                            individualWedCheck1: false,
                            individualWedCheck2: false,
                            individualWedCheck3: false,
                            individualThusCheck1: false,
                            individualThusCheck2: false,
                            individualThusCheck3: false,
                            individualFriCheck1: false,
                            individualFriCheck2: false,
                            individualFriCheck3: false,
                            individualSatCheck1: false,
                            individualSatCheck2: false,
                            individualSatCheck3: false,
                            individualSunCheck1: false,
                            individualSunCheck2: false,
                            individualSunCheck3: false,
                        ArraycheckedData: [],


                    })


                }
                else {

                                this.setState({
                                    allcheckbox2: true,
                                    allcheckbox1: false,
            
                                    individualMonCheck1: false,
                                    individualMonCheck2: true,
                                    individualMonCheck3: false,
            
                                    individualTueCheck1: false,
                                    individualTueCheck2: true,
                                    individualTueCheck3: false,
            
                                    individualWedCheck1: false,
                                    individualWedCheck2: true,
                                    individualWedCheck3: false,
            
                                    individualThusCheck1: false,
                                    individualThusCheck2: true,
                                    individualThusCheck3: false,
            
                                    individualFriCheck1: false,
                                    individualFriCheck2: true,
                                    individualFriCheck3: false,
            
                                    individualSatCheck1: false,
                                    individualSatCheck2: true,
                                    individualSatCheck3: false,
            
                                    individualSunCheck1: false,
                                    individualSunCheck2: true,
                                    individualSunCheck3: false,
            
            
            
                                })
            
                                this.anyWeekDayprefferedDatetime('monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', 'afternoon')
            
                            }
                        
       
                        }

    }


    checkBoxHandler = (day,name,checked,time) => {
        
        if (checked == true) {
             console.log("in true")
 this.state.ArraycheckedData = this.state.ArraycheckedData.concat({ "day": day, "time": time  });
 }
         else if (checked == false){
           
         const filtered =  this.state.ArraycheckedData.filter(e => e.day===day && e.time===time)
         
         filtered.forEach(element =>  this.state.ArraycheckedData.splice(this.state.ArraycheckedData.findIndex(e => e.day===day && e.time===time ),1))
             
          }
       console.log("resul", this.state.ArraycheckedData)
         }
 
 
 
     checkBoxHandler_A = (e) => {
         const name =e.target.name
         const day = e.target.value
         var time = ''
         const checked = e.target.checked



         if (e.target.id == "1") {
            this.setState({ individualMonCheck1: e.target.checked })
            time = 'morning'
        }

        else if (e.target.id == "2") {
            this.setState({ individualMonCheck2: e.target.checked })
            time = 'afternoon'
        }
        else if (e.target.id == "3") {
            this.setState({ individualMonCheck3: e.target.checked })
            time = 'evening'
        }
        this.checkBoxHandler(day,name,checked,time)

         if (this.state.frequencySessionID == 'OneTime') {
            if (e.target.id == "1") {
          
                if (this.state.individualMonCheck1) {
                    this.setState({
                        individualMonCheck1: false,
                        individualMonCheck2: false,
                        individualMonCheck3: false,
                        individualTueCheck1: false,
                        individualTueCheck2: false,
                        individualTueCheck3: false,
                        individualWedCheck1: false,
                        individualWedCheck2: false,
                        individualWedCheck3: false,
                        individualThusCheck1: false,
                        individualThusCheck2: false,
                        individualThusCheck3: false,
                        individualFriCheck1: false,
                        individualFriCheck2: false,
                        individualFriCheck3: false,
                        individualSatCheck1: false,
                        individualSatCheck2: false,
                        individualSatCheck3: false,
                        individualSunCheck1: false,
                        individualSunCheck2: false,
                        individualSunCheck3: false,
                      
                        })

                } else {
                    this.setState({ 
                        individualMonCheck1: true,
                        individualMonCheck2: false,
                        individualMonCheck3: false,
                        individualTueCheck1: false,
                        individualTueCheck2: false,
                        individualTueCheck3: false,
                        individualWedCheck1: false,
                        individualWedCheck2: false,
                        individualWedCheck3: false,
                        individualThusCheck1: false,
                        individualThusCheck2: false,
                        individualThusCheck3: false,
                        individualFriCheck1: false,
                        individualFriCheck2: false,
                        individualFriCheck3: false,
                        individualSatCheck1: false,
                        individualSatCheck2: false,
                        individualSatCheck3: false,
                        individualSunCheck1: false,
                        individualSunCheck2: false,
                        individualSunCheck3: false,
                 
                     })
                   


                   
                }

            } else
                if (e.target.id == "2") {
                  
                    if (this.state.individualMonCheck2) {
                        this.setState({ 
                            individualMonCheck1: false,
                            individualMonCheck2: false,
                            individualMonCheck3: false,
                            individualTueCheck1: false,
                            individualTueCheck2: false,
                            individualTueCheck3: false,
                            individualWedCheck1: false,
                            individualWedCheck2: false,
                            individualWedCheck3: false,
                            individualThusCheck1: false,
                            individualThusCheck2: false,
                            individualThusCheck3: false,
                            individualFriCheck1: false,
                            individualFriCheck2: false,
                            individualFriCheck3: false,
                            individualSatCheck1: false,
                            individualSatCheck2: false,
                            individualSatCheck3: false,
                            individualSunCheck1: false,
                            individualSunCheck2: false,
                            individualSunCheck3: false,
                         
                        })


                    } else {
                        this.setState({ 
                            individualMonCheck1: false,
                            individualMonCheck2: true,
                            individualMonCheck3: false,
                            individualTueCheck1: false,
                            individualTueCheck2: false,
                            individualTueCheck3: false,
                            individualWedCheck1: false,
                            individualWedCheck2: false,
                            individualWedCheck3: false,
                            individualThusCheck1: false,
                            individualThusCheck2: false,
                            individualThusCheck3: false,
                            individualFriCheck1: false,
                            individualFriCheck2: false,
                            individualFriCheck3: false,
                            individualSatCheck1: false,
                            individualSatCheck2: false,
                            individualSatCheck3: false,
                            individualSunCheck1: false,
                            individualSunCheck2: false,
                            individualSunCheck3: false,
                            
                         })

                       
                    }
                } else

                    if (e.target.id == "3") {
                       
                        if (this.state.individualMonCheck3) {
                            this.setState({
                                individualMonCheck1: false,
                                individualMonCheck2: false,
                                individualMonCheck3: false,
                                individualTueCheck1: false,
                                individualTueCheck2: false,
                                individualTueCheck3: false,
                                individualWedCheck1: false,
                                individualWedCheck2: false,
                                individualWedCheck3: false,
                                individualThusCheck1: false,
                                individualThusCheck2: false,
                                individualThusCheck3: false,
                                individualFriCheck1: false,
                                individualFriCheck2: false,
                                individualFriCheck3: false,
                                individualSatCheck1: false,
                                individualSatCheck2: false,
                                individualSatCheck3: false,
                                individualSunCheck1: false,
                                individualSunCheck2: false,
                                individualSunCheck3: false,
                        
                                 })


                        } else {
                            this.setState({ 
                                individualMonCheck1: false,
                                individualMonCheck2: false,
                                individualMonCheck3: true,
                                individualTueCheck1: false,
                                individualTueCheck2: false,
                                individualTueCheck3: false,
                                individualWedCheck1: false,
                                individualWedCheck2: false,
                                individualWedCheck3: false,
                                individualThusCheck1: false,
                                individualThusCheck2: false,
                                individualThusCheck3: false,
                                individualFriCheck1: false,
                                individualFriCheck2: false,
                                individualFriCheck3: false,
                                individualSatCheck1: false,
                                individualSatCheck2: false,
                                individualSatCheck3: false,
                                individualSunCheck1: false,
                                individualSunCheck2: false,
                                individualSunCheck3: false,
                              
                             })

                          
                        }
                    }
                    this.setState({onefreq_day:e.target.value,one_time:time})
        }
         
         
         
    
 
          }
 
 
 
 
     checkBoxHandler_B = (e) => {
         const name =e.target.name
         const day = e.target.value
         var time = ''
         const checked = e.target.checked
         
         if (e.target.id === "1") {
             this.setState({ individualTueCheck1: e.target.checked })
             time = 'morning'
         } else if (e.target.id === "2") {
             this.setState({ individualTueCheck2: e.target.checked })
             time = 'afternoon'
         } else {
             this.setState({ individualTueCheck3: e.target.checked })
             time = 'evening'
         }
         this.checkBoxHandler(day,name,checked,time)
         if (this.state.frequencySessionID == 'OneTime') {
            if (e.target.id == "1") {
          
                if (this.state.individualTueCheck1) {
                    this.setState({
                        individualMonCheck1: false,
                        individualMonCheck2: false,
                        individualMonCheck3: false,
                        individualTueCheck1: false,
                        individualTueCheck2: false,
                        individualTueCheck3: false,
                        individualWedCheck1: false,
                        individualWedCheck2: false,
                        individualWedCheck3: false,
                        individualThusCheck1: false,
                        individualThusCheck2: false,
                        individualThusCheck3: false,
                        individualFriCheck1: false,
                        individualFriCheck2: false,
                        individualFriCheck3: false,
                        individualSatCheck1: false,
                        individualSatCheck2: false,
                        individualSatCheck3: false,
                        individualSunCheck1: false,
                        individualSunCheck2: false,
                        individualSunCheck3: false,
                      
                        })

                } else {
                    this.setState({ 
                        individualMonCheck1: false,
                        individualMonCheck2: false,
                        individualMonCheck3: false,
                        individualTueCheck1: true,
                        individualTueCheck2: false,
                        individualTueCheck3: false,
                        individualWedCheck1: false,
                        individualWedCheck2: false,
                        individualWedCheck3: false,
                        individualThusCheck1: false,
                        individualThusCheck2: false,
                        individualThusCheck3: false,
                        individualFriCheck1: false,
                        individualFriCheck2: false,
                        individualFriCheck3: false,
                        individualSatCheck1: false,
                        individualSatCheck2: false,
                        individualSatCheck3: false,
                        individualSunCheck1: false,
                        individualSunCheck2: false,
                        individualSunCheck3: false,
                 
                     })
                 


                   
                }

            } else
                if (e.target.id == "2") {
                  
                    if (this.state.individualTueCheck2) {
                        this.setState({ 
                            individualMonCheck1: false,
                            individualMonCheck2: false,
                            individualMonCheck3: false,
                            individualTueCheck1: false,
                            individualTueCheck2: false,
                            individualTueCheck3: false,
                            individualWedCheck1: false,
                            individualWedCheck2: false,
                            individualWedCheck3: false,
                            individualThusCheck1: false,
                            individualThusCheck2: false,
                            individualThusCheck3: false,
                            individualFriCheck1: false,
                            individualFriCheck2: false,
                            individualFriCheck3: false,
                            individualSatCheck1: false,
                            individualSatCheck2: false,
                            individualSatCheck3: false,
                            individualSunCheck1: false,
                            individualSunCheck2: false,
                            individualSunCheck3: false,
                         
                        })


                    } else {
                        this.setState({ 
                            individualMonCheck1: false,
                            individualMonCheck2: false,
                            individualMonCheck3: false,
                            individualTueCheck1: false,
                            individualTueCheck2: true,
                            individualTueCheck3: false,
                            individualWedCheck1: false,
                            individualWedCheck2: false,
                            individualWedCheck3: false,
                            individualThusCheck1: false,
                            individualThusCheck2: false,
                            individualThusCheck3: false,
                            individualFriCheck1: false,
                            individualFriCheck2: false,
                            individualFriCheck3: false,
                            individualSatCheck1: false,
                            individualSatCheck2: false,
                            individualSatCheck3: false,
                            individualSunCheck1: false,
                            individualSunCheck2: false,
                            individualSunCheck3: false,
                            
                         })

                       
                    }
                } else

                    if (e.target.id == "3") {
                       
                        if (this.state.individualTueCheck3) {
                            this.setState({
                                individualMonCheck1: false,
                                individualMonCheck2: false,
                                individualMonCheck3: false,
                                individualTueCheck1: false,
                                individualTueCheck2: false,
                                individualTueCheck3: false,
                                individualWedCheck1: false,
                                individualWedCheck2: false,
                                individualWedCheck3: false,
                                individualThusCheck1: false,
                                individualThusCheck2: false,
                                individualThusCheck3: false,
                                individualFriCheck1: false,
                                individualFriCheck2: false,
                                individualFriCheck3: false,
                                individualSatCheck1: false,
                                individualSatCheck2: false,
                                individualSatCheck3: false,
                                individualSunCheck1: false,
                                individualSunCheck2: false,
                                individualSunCheck3: false,
                        
                                 })


                        } else {
                            this.setState({ 
                                individualMonCheck1: false,
                                individualMonCheck2: false,
                                individualMonCheck3: false,
                                individualTueCheck1: false,
                                individualTueCheck2: false,
                                individualTueCheck3: true,
                                individualWedCheck1: false,
                                individualWedCheck2: false,
                                individualWedCheck3: false,
                                individualThusCheck1: false,
                                individualThusCheck2: false,
                                individualThusCheck3: false,
                                individualFriCheck1: false,
                                individualFriCheck2: false,
                                individualFriCheck3: false,
                                individualSatCheck1: false,
                                individualSatCheck2: false,
                                individualSatCheck3: false,
                                individualSunCheck1: false,
                                individualSunCheck2: false,
                                individualSunCheck3: false,
                              
                             })

                          
                        }
                    }
                    this.setState({onefreq_day:e.target.value,one_time:time})
        }
     }
     checkBoxHandler_C = (e) => {
         const name =e.target.name
         const day = e.target.value
         var time = ''
         const checked = e.target.checked
         if (e.target.id === "1") {
             this.setState({ individualWedCheck1: e.target.checked })
             time = 'morning'
 
         } else if (e.target.id === "2") {
             this.setState({ individualWedCheck2: e.target.checked })
             time = 'afternoon'
 
         } else {
             this.setState({ individualWedCheck3: e.target.checked })
             time = 'evening'
         }
         this.checkBoxHandler(day,name,checked,time)

         if (this.state.frequencySessionID == 'OneTime') {
            if (e.target.id == "1") {
          
                if (this.state.individualWedCheck1) {
                    this.setState({
                        individualMonCheck1: false,
                        individualMonCheck2: false,
                        individualMonCheck3: false,
                        individualTueCheck1: false,
                        individualTueCheck2: false,
                        individualTueCheck3: false,
                        individualWedCheck1: false,
                        individualWedCheck2: false,
                        individualWedCheck3: false,
                        individualThusCheck1: false,
                        individualThusCheck2: false,
                        individualThusCheck3: false,
                        individualFriCheck1: false,
                        individualFriCheck2: false,
                        individualFriCheck3: false,
                        individualSatCheck1: false,
                        individualSatCheck2: false,
                        individualSatCheck3: false,
                        individualSunCheck1: false,
                        individualSunCheck2: false,
                        individualSunCheck3: false,
                      
                        })

                } else {
                    this.setState({ 
                        individualMonCheck1: false,
                        individualMonCheck2: false,
                        individualMonCheck3: false,
                        individualTueCheck1: false,
                        individualTueCheck2: false,
                        individualTueCheck3: false,
                        individualWedCheck1: true,
                        individualWedCheck2: false,
                        individualWedCheck3: false,
                        individualThusCheck1: false,
                        individualThusCheck2: false,
                        individualThusCheck3: false,
                        individualFriCheck1: false,
                        individualFriCheck2: false,
                        individualFriCheck3: false,
                        individualSatCheck1: false,
                        individualSatCheck2: false,
                        individualSatCheck3: false,
                        individualSunCheck1: false,
                        individualSunCheck2: false,
                        individualSunCheck3: false,
                 
                     })
                    


                   
                }

            } else
                if (e.target.id == "2") {
                  
                    if (this.state.individualWedCheck2) {
                        this.setState({ 
                            individualMonCheck1: false,
                            individualMonCheck2: false,
                            individualMonCheck3: false,
                            individualTueCheck1: false,
                            individualTueCheck2: false,
                            individualTueCheck3: false,
                            individualWedCheck1: false,
                            individualWedCheck2: false,
                            individualWedCheck3: false,
                            individualThusCheck1: false,
                            individualThusCheck2: false,
                            individualThusCheck3: false,
                            individualFriCheck1: false,
                            individualFriCheck2: false,
                            individualFriCheck3: false,
                            individualSatCheck1: false,
                            individualSatCheck2: false,
                            individualSatCheck3: false,
                            individualSunCheck1: false,
                            individualSunCheck2: false,
                            individualSunCheck3: false,
                         
                        })


                    } else {
                        this.setState({ 
                            individualMonCheck1: false,
                            individualMonCheck2: false,
                            individualMonCheck3: false,
                            individualTueCheck1: false,
                            individualTueCheck2: false,
                            individualTueCheck3: false,
                            individualWedCheck1: false,
                            individualWedCheck2: true,
                            individualWedCheck3: false,
                            individualThusCheck1: false,
                            individualThusCheck2: false,
                            individualThusCheck3: false,
                            individualFriCheck1: false,
                            individualFriCheck2: false,
                            individualFriCheck3: false,
                            individualSatCheck1: false,
                            individualSatCheck2: false,
                            individualSatCheck3: false,
                            individualSunCheck1: false,
                            individualSunCheck2: false,
                            individualSunCheck3: false,
                            
                         })

                       
                    }
                } else

                    if (e.target.id == "3") {
                       
                        if (this.state.individualWedCheck3) {
                            this.setState({
                                individualMonCheck1: false,
                                individualMonCheck2: false,
                                individualMonCheck3: false,
                                individualTueCheck1: false,
                                individualTueCheck2: false,
                                individualTueCheck3: false,
                                individualWedCheck1: false,
                                individualWedCheck2: false,
                                individualWedCheck3: false,
                                individualThusCheck1: false,
                                individualThusCheck2: false,
                                individualThusCheck3: false,
                                individualFriCheck1: false,
                                individualFriCheck2: false,
                                individualFriCheck3: false,
                                individualSatCheck1: false,
                                individualSatCheck2: false,
                                individualSatCheck3: false,
                                individualSunCheck1: false,
                                individualSunCheck2: false,
                                individualSunCheck3: false,
                        
                                 })


                        } else {
                            this.setState({ 
                                individualMonCheck1: false,
                                individualMonCheck2: false,
                                individualMonCheck3: false,
                                individualTueCheck1: false,
                                individualTueCheck2: false,
                                individualTueCheck3: false,
                                individualWedCheck1: false,
                                individualWedCheck2: false,
                                individualWedCheck3: true,
                                individualThusCheck1: false,
                                individualThusCheck2: false,
                                individualThusCheck3: false,
                                individualFriCheck1: false,
                                individualFriCheck2: false,
                                individualFriCheck3: false,
                                individualSatCheck1: false,
                                individualSatCheck2: false,
                                individualSatCheck3: false,
                                individualSunCheck1: false,
                                individualSunCheck2: false,
                                individualSunCheck3: false,
                              
                             })

                          
                        }
                    }
                }
                    this.setState({onefreq_day:e.target.value,one_time:time})
         
        
     }
     checkBoxHandler_D = (e) => {
         const name =e.target.name
         const day = e.target.value
         var time = ''
         const checked = e.target.checked
         if (e.target.id === "1") {
             this.setState({ individualThusCheck1: e.target.checked })
             time = 'morning'
         } else if (e.target.id === "2") {
             this.setState({ individualThusCheck2: e.target.checked })
             time = 'afternoon'
         } else {
             this.setState({ individualThusCheck3: e.target.checked })
             time = 'evening'
         }
         this.checkBoxHandler(day,name,checked,time)

         if (this.state.frequencySessionID == 'OneTime') {
            if (e.target.id == "1") {
          
                if (this.state.individualThusCheck1) {
                    this.setState({
                        individualMonCheck1: false,
                        individualMonCheck2: false,
                        individualMonCheck3: false,
                        individualTueCheck1: false,
                        individualTueCheck2: false,
                        individualTueCheck3: false,
                        individualWedCheck1: false,
                        individualWedCheck2: false,
                        individualWedCheck3: false,
                        individualThusCheck1: false,
                        individualThusCheck2: false,
                        individualThusCheck3: false,
                        individualFriCheck1: false,
                        individualFriCheck2: false,
                        individualFriCheck3: false,
                        individualSatCheck1: false,
                        individualSatCheck2: false,
                        individualSatCheck3: false,
                        individualSunCheck1: false,
                        individualSunCheck2: false,
                        individualSunCheck3: false,
                      
                        })

                } else {
                    this.setState({ 
                        individualMonCheck1: false,
                        individualMonCheck2: false,
                        individualMonCheck3: false,
                        individualTueCheck1: false,
                        individualTueCheck2: false,
                        individualTueCheck3: false,
                        individualWedCheck1: false,
                        individualWedCheck2: false,
                        individualWedCheck3: false,
                        individualThusCheck1: true,
                        individualThusCheck2: false,
                        individualThusCheck3: false,
                        individualFriCheck1: false,
                        individualFriCheck2: false,
                        individualFriCheck3: false,
                        individualSatCheck1: false,
                        individualSatCheck2: false,
                        individualSatCheck3: false,
                        individualSunCheck1: false,
                        individualSunCheck2: false,
                        individualSunCheck3: false,
                 
                     })
                   

                   
                }

            } else
                if (e.target.id == "2") {
                  
                    if (this.state.individualThusCheck2) {
                        this.setState({ 
                            individualMonCheck1: false,
                            individualMonCheck2: false,
                            individualMonCheck3: false,
                            individualTueCheck1: false,
                            individualTueCheck2: false,
                            individualTueCheck3: false,
                            individualWedCheck1: false,
                            individualWedCheck2: false,
                            individualWedCheck3: false,
                            individualThusCheck1: false,
                            individualThusCheck2: false,
                            individualThusCheck3: false,
                            individualFriCheck1: false,
                            individualFriCheck2: false,
                            individualFriCheck3: false,
                            individualSatCheck1: false,
                            individualSatCheck2: false,
                            individualSatCheck3: false,
                            individualSunCheck1: false,
                            individualSunCheck2: false,
                            individualSunCheck3: false,
                         
                        })


                    } else {
                        this.setState({ 
                            individualMonCheck1: false,
                            individualMonCheck2: false,
                            individualMonCheck3: false,
                            individualTueCheck1: false,
                            individualTueCheck2: false,
                            individualTueCheck3: false,
                            individualWedCheck1: false,
                            individualWedCheck2: false,
                            individualWedCheck3: false,
                            individualThusCheck1: false,
                            individualThusCheck2: true,
                            individualThusCheck3: false,
                            individualFriCheck1: false,
                            individualFriCheck2: false,
                            individualFriCheck3: false,
                            individualSatCheck1: false,
                            individualSatCheck2: false,
                            individualSatCheck3: false,
                            individualSunCheck1: false,
                            individualSunCheck2: false,
                            individualSunCheck3: false,
                            
                         })

                       
                    }
                } else

                    if (e.target.id == "3") {
                       
                        if (this.state.individualThusCheck3) {
                            this.setState({
                                individualMonCheck1: false,
                                individualMonCheck2: false,
                                individualMonCheck3: false,
                                individualTueCheck1: false,
                                individualTueCheck2: false,
                                individualTueCheck3: false,
                                individualWedCheck1: false,
                                individualWedCheck2: false,
                                individualWedCheck3: false,
                                individualThusCheck1: false,
                                individualThusCheck2: false,
                                individualThusCheck3: false,
                                individualFriCheck1: false,
                                individualFriCheck2: false,
                                individualFriCheck3: false,
                                individualSatCheck1: false,
                                individualSatCheck2: false,
                                individualSatCheck3: false,
                                individualSunCheck1: false,
                                individualSunCheck2: false,
                                individualSunCheck3: false,
                        
                                 })


                        } else {
                            this.setState({ 
                                individualMonCheck1: false,
                                individualMonCheck2: false,
                                individualMonCheck3: false,
                                individualTueCheck1: false,
                                individualTueCheck2: false,
                                individualTueCheck3: false,
                                individualWedCheck1: false,
                                individualWedCheck2: false,
                                individualWedCheck3: false,
                                individualThusCheck1: false,
                                individualThusCheck2: false,
                                individualThusCheck3: true,
                                individualFriCheck1: false,
                                individualFriCheck2: false,
                                individualFriCheck3: false,
                                individualSatCheck1: false,
                                individualSatCheck2: false,
                                individualSatCheck3: false,
                                individualSunCheck1: false,
                                individualSunCheck2: false,
                                individualSunCheck3: false,
                              
                             })

                          
                        }
                    }
                }
                    this.setState({onefreq_day:e.target.value,one_time:time})
         
        
     }
     checkBoxHandler_E = (e) => {
         const name =e.target.name
         const day = e.target.value
         var time = ''
         const checked = e.target.checked
         if (e.target.id === "1") {
             this.setState({ individualFriCheck1: e.target.checked })
             time = 'morning'
         } else if (e.target.id === "2") {
             this.setState({ individualFriCheck2: e.target.checked })
             time = 'afternoon'
         } else {
             this.setState({ individualFriCheck3: e.target.checked })
             time = 'evening'
         }
         this.checkBoxHandler(day,name,checked,time)
         if (this.state.frequencySessionID == 'OneTime') {
            if (e.target.id == "1") {
          
                if (this.state.individualFriCheck1) {
                    this.setState({
                        individualMonCheck1: false,
                        individualMonCheck2: false,
                        individualMonCheck3: false,
                        individualTueCheck1: false,
                        individualTueCheck2: false,
                        individualTueCheck3: false,
                        individualWedCheck1: false,
                        individualWedCheck2: false,
                        individualWedCheck3: false,
                        individualThusCheck1: false,
                        individualThusCheck2: false,
                        individualThusCheck3: false,
                        individualFriCheck1: false,
                        individualFriCheck2: false,
                        individualFriCheck3: false,
                        individualSatCheck1: false,
                        individualSatCheck2: false,
                        individualSatCheck3: false,
                        individualSunCheck1: false,
                        individualSunCheck2: false,
                        individualSunCheck3: false,
                      
                        })

                } else {
                    this.setState({ 
                        individualMonCheck1: false,
                        individualMonCheck2: false,
                        individualMonCheck3: false,
                        individualTueCheck1: false,
                        individualTueCheck2: false,
                        individualTueCheck3: false,
                        individualWedCheck1: false,
                        individualWedCheck2: false,
                        individualWedCheck3: false,
                        individualThusCheck1: false,
                        individualThusCheck2: false,
                        individualThusCheck3: false,
                        individualFriCheck1: true,
                        individualFriCheck2: false,
                        individualFriCheck3: false,
                        individualSatCheck1: false,
                        individualSatCheck2: false,
                        individualSatCheck3: false,
                        individualSunCheck1: false,
                        individualSunCheck2: false,
                        individualSunCheck3: false,
                 
                     })
                    

                   
                }

            } else
                if (e.target.id == "2") {
                  
                    if (this.state.individualFriCheck2) {
                        this.setState({ 
                            individualMonCheck1: false,
                            individualMonCheck2: false,
                            individualMonCheck3: false,
                            individualTueCheck1: false,
                            individualTueCheck2: false,
                            individualTueCheck3: false,
                            individualWedCheck1: false,
                            individualWedCheck2: false,
                            individualWedCheck3: false,
                            individualThusCheck1: false,
                            individualThusCheck2: false,
                            individualThusCheck3: false,
                            individualFriCheck1: false,
                            individualFriCheck2: false,
                            individualFriCheck3: false,
                            individualSatCheck1: false,
                            individualSatCheck2: false,
                            individualSatCheck3: false,
                            individualSunCheck1: false,
                            individualSunCheck2: false,
                            individualSunCheck3: false,
                         
                        })


                    } else {
                        this.setState({ 
                            individualMonCheck1: false,
                            individualMonCheck2: false,
                            individualMonCheck3: false,
                            individualTueCheck1: false,
                            individualTueCheck2: false,
                            individualTueCheck3: false,
                            individualWedCheck1: false,
                            individualWedCheck2: false,
                            individualWedCheck3: false,
                            individualThusCheck1: false,
                            individualThusCheck2: false,
                            individualThusCheck3: false,
                            individualFriCheck1: false,
                            individualFriCheck2: true,
                            individualFriCheck3: false,
                            individualSatCheck1: false,
                            individualSatCheck2: false,
                            individualSatCheck3: false,
                            individualSunCheck1: false,
                            individualSunCheck2: false,
                            individualSunCheck3: false,
                            
                         })

                       
                    }
                } else

                    if (e.target.id == "3") {
                       
                        if (this.state.individualFriCheck3) {
                            this.setState({
                                individualMonCheck1: false,
                                individualMonCheck2: false,
                                individualMonCheck3: false,
                                individualTueCheck1: false,
                                individualTueCheck2: false,
                                individualTueCheck3: false,
                                individualWedCheck1: false,
                                individualWedCheck2: false,
                                individualWedCheck3: false,
                                individualThusCheck1: false,
                                individualThusCheck2: false,
                                individualThusCheck3: false,
                                individualFriCheck1: false,
                                individualFriCheck2: false,
                                individualFriCheck3: false,
                                individualSatCheck1: false,
                                individualSatCheck2: false,
                                individualSatCheck3: false,
                                individualSunCheck1: false,
                                individualSunCheck2: false,
                                individualSunCheck3: false,
                        
                                 })


                        } else {
                            this.setState({ 
                                individualMonCheck1: false,
                                individualMonCheck2: false,
                                individualMonCheck3: false,
                                individualTueCheck1: false,
                                individualTueCheck2: false,
                                individualTueCheck3: false,
                                individualWedCheck1: false,
                                individualWedCheck2: false,
                                individualWedCheck3: false,
                                individualThusCheck1: false,
                                individualThusCheck2: false,
                                individualThusCheck3: false,
                                individualFriCheck1: false,
                                individualFriCheck2: false,
                                individualFriCheck3: true,
                                individualSatCheck1: false,
                                individualSatCheck2: false,
                                individualSatCheck3: false,
                                individualSunCheck1: false,
                                individualSunCheck2: false,
                                individualSunCheck3: false,
                              
                             })

                          
                        }
                    }
                }
                    this.setState({onefreq_day:e.target.value,one_time:time})
     }
 
 
     checkBoxHandler_F = (e) => {
         const name =e.target.name
         const day = e.target.value
         var time = ''
         const checked = e.target.checked
         if (e.target.id === "1") {
             this.setState({ individualSatCheck1: e.target.checked })
             time = 'morning'
         } else if (e.target.id === "2") {
             this.setState({ individualSatCheck2: e.target.checked })
             time = 'afternoon'
         } else {
             this.setState({ individualSatCheck3: e.target.checked })
             time = 'evening'
         }
         this.checkBoxHandler(day,name,checked,time)
         if (this.state.frequencySessionID == 'OneTime') {
            if (e.target.id == "1") {
          
                if (this.state.individualSatCheck1) {
                    this.setState({
                        individualMonCheck1: false,
                        individualMonCheck2: false,
                        individualMonCheck3: false,
                        individualTueCheck1: false,
                        individualTueCheck2: false,
                        individualTueCheck3: false,
                        individualWedCheck1: false,
                        individualWedCheck2: false,
                        individualWedCheck3: false,
                        individualThusCheck1: false,
                        individualThusCheck2: false,
                        individualThusCheck3: false,
                        individualFriCheck1: false,
                        individualFriCheck2: false,
                        individualFriCheck3: false,
                        individualSatCheck1: false,
                        individualSatCheck2: false,
                        individualSatCheck3: false,
                        individualSunCheck1: false,
                        individualSunCheck2: false,
                        individualSunCheck3: false,
                      
                        })

                } else {
                    this.setState({ 
                        individualMonCheck1: false,
                        individualMonCheck2: false,
                        individualMonCheck3: false,
                        individualTueCheck1: false,
                        individualTueCheck2: false,
                        individualTueCheck3: false,
                        individualWedCheck1: false,
                        individualWedCheck2: false,
                        individualWedCheck3: false,
                        individualThusCheck1: false,
                        individualThusCheck2: false,
                        individualThusCheck3: false,
                        individualFriCheck1: false,
                        individualFriCheck2: false,
                        individualFriCheck3: false,
                        individualSatCheck1: true,
                        individualSatCheck2: false,
                        individualSatCheck3: false,
                        individualSunCheck1: false,
                        individualSunCheck2: false,
                        individualSunCheck3: false,
                 
                     })
                 


                   
                }

            } else
                if (e.target.id == "2") {
                  
                    if (this.state.individualSatCheck2) {
                        this.setState({ 
                            individualMonCheck1: false,
                            individualMonCheck2: false,
                            individualMonCheck3: false,
                            individualTueCheck1: false,
                            individualTueCheck2: false,
                            individualTueCheck3: false,
                            individualWedCheck1: false,
                            individualWedCheck2: false,
                            individualWedCheck3: false,
                            individualThusCheck1: false,
                            individualThusCheck2: false,
                            individualThusCheck3: false,
                            individualFriCheck1: false,
                            individualFriCheck2: false,
                            individualFriCheck3: false,
                            individualSatCheck1: false,
                            individualSatCheck2: false,
                            individualSatCheck3: false,
                            individualSunCheck1: false,
                            individualSunCheck2: false,
                            individualSunCheck3: false,
                         
                        })


                    } else {
                        this.setState({ 
                            individualMonCheck1: false,
                            individualMonCheck2: false,
                            individualMonCheck3: false,
                            individualTueCheck1: false,
                            individualTueCheck2: false,
                            individualTueCheck3: false,
                            individualWedCheck1: false,
                            individualWedCheck2: false,
                            individualWedCheck3: false,
                            individualThusCheck1: false,
                            individualThusCheck2: false,
                            individualThusCheck3: false,
                            individualFriCheck1: false,
                            individualFriCheck2: false,
                            individualFriCheck3: false,
                            individualSatCheck1: false,
                            individualSatCheck2: true,
                            individualSatCheck3: false,
                            individualSunCheck1: false,
                            individualSunCheck2: false,
                            individualSunCheck3: false,
                            
                         })

                       
                    }
                } else

                    if (e.target.id == "3") {
                       
                        if (this.state.individualSatCheck3) {
                            this.setState({
                                individualMonCheck1: false,
                                individualMonCheck2: false,
                                individualMonCheck3: false,
                                individualTueCheck1: false,
                                individualTueCheck2: false,
                                individualTueCheck3: false,
                                individualWedCheck1: false,
                                individualWedCheck2: false,
                                individualWedCheck3: false,
                                individualThusCheck1: false,
                                individualThusCheck2: false,
                                individualThusCheck3: false,
                                individualFriCheck1: false,
                                individualFriCheck2: false,
                                individualFriCheck3: false,
                                individualSatCheck1: false,
                                individualSatCheck2: false,
                                individualSatCheck3: false,
                                individualSunCheck1: false,
                                individualSunCheck2: false,
                                individualSunCheck3: false,
                        
                                 })


                        } else {
                            this.setState({ 
                                individualMonCheck1: false,
                                individualMonCheck2: false,
                                individualMonCheck3: false,
                                individualTueCheck1: false,
                                individualTueCheck2: false,
                                individualTueCheck3: false,
                                individualWedCheck1: false,
                                individualWedCheck2: false,
                                individualWedCheck3: false,
                                individualThusCheck1: false,
                                individualThusCheck2: false,
                                individualThusCheck3: false,
                                individualFriCheck1: false,
                                individualFriCheck2: false,
                                individualFriCheck3: false,
                                individualSatCheck1: false,
                                individualSatCheck2: false,
                                individualSatCheck3: true,
                                individualSunCheck1: false,
                                individualSunCheck2: false,
                                individualSunCheck3: false,
                              
                             })

                          
                        }
                    }
                }
                    this.setState({onefreq_day:e.target.value,one_time:time})
     }
 
 
     checkBoxHandler_G = (e) => {
        
         const name =e.target.name
         const day = e.target.value
         var time = ''
         const checked = e.target.checked
         if (e.target.id === "1") {
             this.setState({ individualSunCheck1: e.target.checked })
             time = 'morning'
 
         } else if (e.target.id === "2") {
             this.setState({ individualSunCheck2: e.target.checked })
             time = 'afternoon'
 
         } else {
             this.setState({ individualSunCheck3: e.target.checked })
             time = 'evening'
 
         }
        
 
         this.checkBoxHandler(day,name,checked,time)
         if (this.state.frequencySessionID == 'OneTime') {
            if (e.target.id == "1") {
          
                if (this.state.individualSunCheck1) {
                    this.setState({
                        individualMonCheck1: false,
                        individualMonCheck2: false,
                        individualMonCheck3: false,
                        individualTueCheck1: false,
                        individualTueCheck2: false,
                        individualTueCheck3: false,
                        individualWedCheck1: false,
                        individualWedCheck2: false,
                        individualWedCheck3: false,
                        individualThusCheck1: false,
                        individualThusCheck2: false,
                        individualThusCheck3: false,
                        individualFriCheck1: false,
                        individualFriCheck2: false,
                        individualFriCheck3: false,
                        individualSatCheck1: false,
                        individualSatCheck2: false,
                        individualSatCheck3: false,
                        individualSunCheck1: false,
                        individualSunCheck2: false,
                        individualSunCheck3: false,
                      
                        })

                } else {
                    this.setState({ 
                        individualMonCheck1: false,
                        individualMonCheck2: false,
                        individualMonCheck3: false,
                        individualTueCheck1: false,
                        individualTueCheck2: false,
                        individualTueCheck3: false,
                        individualWedCheck1: false,
                        individualWedCheck2: false,
                        individualWedCheck3: false,
                        individualThusCheck1: false,
                        individualThusCheck2: false,
                        individualThusCheck3: false,
                        individualFriCheck1: false,
                        individualFriCheck2: false,
                        individualFriCheck3: false,
                        individualSatCheck1: false,
                        individualSatCheck2: false,
                        individualSatCheck3: false,
                        individualSunCheck1: true,
                        individualSunCheck2: false,
                        individualSunCheck3: false,
                 
                     })
                  


                   
                }

            } else
                if (e.target.id == "2") {
                  
                    if (this.state.individualSunCheck2) {
                        this.setState({ 
                            individualMonCheck1: false,
                            individualMonCheck2: false,
                            individualMonCheck3: false,
                            individualTueCheck1: false,
                            individualTueCheck2: false,
                            individualTueCheck3: false,
                            individualWedCheck1: false,
                            individualWedCheck2: false,
                            individualWedCheck3: false,
                            individualThusCheck1: false,
                            individualThusCheck2: false,
                            individualThusCheck3: false,
                            individualFriCheck1: false,
                            individualFriCheck2: false,
                            individualFriCheck3: false,
                            individualSatCheck1: false,
                            individualSatCheck2: false,
                            individualSatCheck3: false,
                            individualSunCheck1: false,
                            individualSunCheck2: false,
                            individualSunCheck3: false,
                         
                        })


                    } else {
                        this.setState({ 
                            individualMonCheck1: false,
                            individualMonCheck2: false,
                            individualMonCheck3: false,
                            individualTueCheck1: false,
                            individualTueCheck2: false,
                            individualTueCheck3: false,
                            individualWedCheck1: false,
                            individualWedCheck2: false,
                            individualWedCheck3: false,
                            individualThusCheck1: false,
                            individualThusCheck2: false,
                            individualThusCheck3: false,
                            individualFriCheck1: false,
                            individualFriCheck2: false,
                            individualFriCheck3: false,
                            individualSatCheck1: false,
                            individualSatCheck2: false,
                            individualSatCheck3: false,
                            individualSunCheck1: false,
                            individualSunCheck2: true,
                            individualSunCheck3: false,
                            
                         })

                       
                    }
                } else

                    if (e.target.id == "3") {
                       
                        if (this.state.individualSunCheck3) {
                            this.setState({
                                individualMonCheck1: false,
                                individualMonCheck2: false,
                                individualMonCheck3: false,
                                individualTueCheck1: false,
                                individualTueCheck2: false,
                                individualTueCheck3: false,
                                individualWedCheck1: false,
                                individualWedCheck2: false,
                                individualWedCheck3: false,
                                individualThusCheck1: false,
                                individualThusCheck2: false,
                                individualThusCheck3: false,
                                individualFriCheck1: false,
                                individualFriCheck2: false,
                                individualFriCheck3: false,
                                individualSatCheck1: false,
                                individualSatCheck2: false,
                                individualSatCheck3: false,
                                individualSunCheck1: false,
                                individualSunCheck2: false,
                                individualSunCheck3: false,
                        
                                 })


                        } else {
                            this.setState({ 
                                individualMonCheck1: false,
                                individualMonCheck2: false,
                                individualMonCheck3: false,
                                individualTueCheck1: false,
                                individualTueCheck2: false,
                                individualTueCheck3: false,
                                individualWedCheck1: false,
                                individualWedCheck2: false,
                                individualWedCheck3: false,
                                individualThusCheck1: false,
                                individualThusCheck2: false,
                                individualThusCheck3: false,
                                individualFriCheck1: false,
                                individualFriCheck2: false,
                                individualFriCheck3: false,
                                individualSatCheck1: false,
                                individualSatCheck2: false,
                                individualSatCheck3: false,
                                individualSunCheck1: false,
                                individualSunCheck2: false,
                                individualSunCheck3: true,
                              
                             })

                          
                        }
                    }
                }
                    this.setState({onefreq_day:e.target.value,one_time:time})
     }

   
    anyWeekDayprefferedDatetime = (Day1, Day2, Day3, Day4, Day5, Day6, Day7, Time) => {
        


        const weekDay = [Day1, Day2, Day3, Day4, Day5, Day6, Day7]
        console.log("irrr",weekDay)
        var array = []

        var isSame = false



        if (weekDay.length > 0) {

            for (let i = 0; i < weekDay.length; i++) {
                var ite = {
                    day: weekDay[i], time: Time
                }
                array.push(ite)

            }


            this.setState(
                {
                    ArraycheckedData: array,
                })

        }


    }
    check = () => {

        if (this.state.allcheckbox2) {
            this.setState({
                anyWeekdayAfternoon: 0,
                anyWeekdayNight: 0,
                toggleCheckBoxA1: false,
                toggleCheckBoxA2: false,
                toggleCheckBoxA3: false,
                toggleCheckBoxB1: false,
                toggleCheckBoxB2: false,
                toggleCheckBoxB3: false,
                toggleCheckBoxC1: false,
                toggleCheckBoxC2: false,
                toggleCheckBoxC3: false,
                toggleCheckBoxD1: false,
                toggleCheckBoxD2: false,
                toggleCheckBoxD3: false,
                toggleCheckBoxE1: false,
                toggleCheckBoxE2: false,
                toggleCheckBoxE3: false,
                toggleCheckBoxF1: false,
                toggleCheckBoxF2: false,
                toggleCheckBoxF3: false,
                toggleCheckBoxG1: false,
                toggleCheckBoxG2: false,
                toggleCheckBoxG3: false,
                ArraycheckedData: []

            })
        }
        if (this.state.allcheckbox1) {
            this.setState({
                anyWeekdayAfternoon: 0,
                anyWeekdayNight: 0,
                toggleCheckBoxA1: false,
                toggleCheckBoxA2: false,
                toggleCheckBoxA3: false,
                toggleCheckBoxB1: false,
                toggleCheckBoxB2: false,
                toggleCheckBoxB3: false,
                toggleCheckBoxC1: false,
                toggleCheckBoxC2: false,
                toggleCheckBoxC3: false,
                toggleCheckBoxD1: false,
                toggleCheckBoxD2: false,
                toggleCheckBoxD3: false,
                toggleCheckBoxE1: false,
                toggleCheckBoxE2: false,
                toggleCheckBoxE3: false,
                toggleCheckBoxF1: false,
                toggleCheckBoxF2: false,
                toggleCheckBoxF3: false,
                toggleCheckBoxG1: false,
                toggleCheckBoxG2: false,
                toggleCheckBoxG3: false,
                ArraycheckedData: []

            })
        }
    }

    // sweetAlertDirectBooking = () => {
    //     swal({
    //         text: "Booking Request successfully sent",

    //         icon: "success",

    //     }).then(() => {
    //         this.props.history.push("/userbooking");
    //     })
    // }

    sweetAlertPostBooking = () => {
        swal({
            text: "Booking Posted",
            icon: "success",
        }).then(() => {
            this.props.history.push("/userbooking");
        })
    }
  

    handleSubmit8 = (event) => {
        event.preventDefault();
        const UserToken = localStorage.getItem('UserToken');

        if (UserToken != '') {

            // if (this.state.selectectedItems.length != 0) {

            //     this.state.selectectedItems.map((data, index) => {
                   
            //         return (
            //             this.state.Morningtime.push(data.name)
            //         )
            //     })
            // }


            // if (this.state.selectectedItems2.length != 0) {

            //     this.state.selectectedItems2.map((data, index) => {
                   
            //         return (
            //             this.state.Afternoontime.push(data.name)
            //         )
            //     })
            // }

            // if (this.state.selectectedItems3.length != 0) {

            //     this.state.selectectedItems3.map((data, index) => {
          
            //         return (
            //             this.state.Eveningtime.push(data.name)
            //         )
            //     })
            // }
        //     this.state.selectectedItems2.map((data, index) => {
                   
        //         return (
        //             this.state.AfternoontimeLast.push(data.name)
        //         )
        //     })
        // // }

        // // if (this.state.selectectedItems3.length != 0) {

        //     this.state.selectectedItems3.map((data, index) => {
      
        //         return (
        //             this.state.EveningtimeLast.push(data.name)
        //         )
        //     })
        // // }
        // this.state.selectectedItems.map((res)=>{
        //     return(
        //         this.state.MorningtimeLAST.push(res.name)
        //     )

        // })

//         this.state.selectectedItems2.map((data, index) => {
                   
//             return (
//                 this.state.selectectedItems2.length==index+1?
//                 this.state.AfternoontimeLast.push(data.name)
//                 :
//                 this.state.AfternoontimeLast.push(data.name)
//             )
//         })
//     // }

//     // if (this.state.selectectedItems3.length != 0) {

//         this.state.selectectedItems3.map((data, index) => {
  
//             return (
//                 this.state.selectectedItems3.length==index+1?
//                 this.state.EveningtimeLast.push(data.name)
//                 :
//                 this.state.EveningtimeLast.push(data.name)
//             )
//         })
//     // }
//     // (this.state.selectectedItems )
//     {(this.state.selectectedItems|| []).map((res,index)=>{
//                         return(
//             this.state.selectectedItems.length==index+1?
//             this.state.Morningtime.push(res.name)
//             :
//             this.state.Morningtime.push(res.name)
//         )
        

//     })
// }



if (this.state.selectectedItems.length != 0) {

    this.state.selectectedItems.map((data, index) => {
        console.log('dataaaaaaaaaaaa',data.id)
        console.log('filteredataa',this.state.selectectedItems.filter((data)=>data.id==index+1))
        return (
            this.state.Morningtime.push(data.name)
        )
    })


    console.log("filterdatafinal",this.state.Morningtime)

  
}


if (this.state.selectectedItems2.length != 0) {

    this.state.selectectedItems2.map((data, index) => {
       
        return (
            this.state.Afternoontime.push(data.name)
        )
    })
}

if (this.state.selectectedItems3.length != 0) {

    this.state.selectectedItems3.map((data, index) => {

        return (
            this.state.Eveningtime.push(data.name)
        )
    })
}



            time_schedule = {
                "morning": [
                    {

                        "time": this.state.Morningtime,
                        "total_time": this.state.selectectedItems.length,


                    }
                ],
                "afternoon": [
                    {

                        "time": this.state.Afternoontime,
                        "total_time": this.state.selectectedItems2.length,


                    }
                ],
                "evening": [
                    {

                        "time": this.state.Eveningtime,
                        "total_time": this.state.selectectedItems3.length


                    }
                ]
            }

            console.log('time array: ', JSON.stringify(time_schedule))
            var Timearray = JSON.stringify(time_schedule)


        var weeknight = ""
        var weekafternoon = ""

        if (this.state.anyWeekdayNight == true) {
            weeknight = 1
        } else
            weeknight = 0

        if (this.state.anyWeekdayAfternoon == true) {
            weekafternoon = 1
        } else
            weekafternoon = 0



if(this.state.frequencySessionID=="OneTime"){
    
    let day = this.state.onefreq_day
    let time= this.state.one_time
   
    var json = {
        "date_schedule": [
            {
                "any_weekday_night": weeknight,
                "any_weekday_afternoon": weekafternoon,
                
                "weekday_time": [{day,time}]
                
            }
        ]
    }
}
else{
        var json = {
            "date_schedule": [
                {
                    "any_weekday_night": weeknight,
                    "any_weekday_afternoon": weekafternoon,
                    
                    "weekday_time": this.state.ArraycheckedData
                    
                }
            ]
        }
    }

       

        var Send = JSON.stringify(json)
        console.log("scheduleshowdata",Send)

            const formData = new FormData();
            formData.append('tuition_type', this.state.tuition_type);
            formData.append('category_id', this.state.catgoryIdparams);
            formData.append('sub_category_id', this.state.subcategoryIdparams)
            formData.append('schedule', Send);
            formData.append('time_schedule', Timearray);
            formData.append('booking_offer_price', this.state.booking_offer_price);
            formData.append('frequency_of_session', this.state.frequency_of_session);
          
            formData.append('booking_start', moment(this.state.booking_start).format("YYYY-MM-DD hh:mm:ss"));

            // if (this.state.id) {
            //     formData.append('tutor_id', this.state.id)
            // }
            formData.append('address', this.state.address);
            
            axios({
                method: 'post',
                url: API_BASE_URL + "add_booking/",
                headers: { 'Authorization': "Bearer " + UserToken, 
                'Content-Type': 'application/json' },
                data: formData
            }).then(response => {
                if (response.data.success == 201) {
                //   this.sweetAlertDirectBooking()
                this.sweetAlertPostBooking()
                }
            }).catch((error) => {
                console.log(error)
            })
        }
    
    }

    handleChangeFrequency(evt) {
        this.setState({ frequencySessionID: evt.target.value })
        this.setState({ [evt.target.name]: evt.target.value });
    }

    handleChange8(evt, id) {
        const value = evt.target.value;
        this.setState({ [evt.target.name]: evt.target.value });
    }
    
//     MorningSelectData= async(ele)=>{
//         this.setState({selectectedItems:ele})
//        if (this.state.selectectedItems.length != 0) {
//      this.state.selectectedItems.map((data, index) => {
//         return ele.map((data1)=>{})
//         })
//             }
// this.setState({Morningtime:[...this.state.selectectedItems] ,ele})
//  }

//  AfternoonSelectData=async(ele)=>{
//      this.setState({selectectedItems2:ele})
//      if(this.state.selectectedItems2.length!=0){
//          this.state.selectectedItems2.map((data1)=>{
//             return ele.map((data1)=>{})
//          })
//      }
//      this.setState({Afternoontime:[...this.state.selectectedItems2] ,ele})

//  }

//  EveningSelectData=async(ele)=>{
//      this.setState({selectectedItems3:ele})
//      if(this.state.selectectedItems3.length!=0){
//          this.state.selectectedItems3.map((data1)=>{
// return ele.map((data1)=>{})
//      })
//     }
//     this.setState({Eveningtime:[...this.state.selectectedItems3] ,ele})

// }

// onRemove= async(removedata,removeid)=>{

// await this.setState({dataaremove:removeid.value})

// const highdata=this.state.selectectedItems
// var index = highdata.indexOf(removeid.value)
// console.log('index data',index)
// highdata.splice(index, 1);
// } 



// onRemove= async(removedata,removeid)=>{

//     await this.setState({dataaremove:removeid.value})
    
//     const highdata=this.state.selectectedItems2
//     var index = highdata.indexOf(removeid.value)
//     console.log('index data',index)
//     highdata.splice(index, 1);
//     } 
    
// onRemove= async(removedata,removeid)=>{

//     await this.setState({dataaremove:removeid.value})
    
//     const highdata=this.state.selectectedItems3
//     var index = highdata.indexOf(removeid.value)
//     console.log('index data',index)
//     highdata.splice(index, 1);
//     } 

    render() {

        return (
            <main>
                <Header></Header>
                <section className="">
                    <div className="container-fluid margin_top width_custom">
                        <div className="row">
                            <div className="col-md-3 mt30">
                                <SideBar></SideBar>
                            </div>

                            <div className="col-md-9 mt30">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h2 className="dashboard_headings">Booking</h2>
                                    </div>
                                </div>
                                <div className="row box_custom">
                                    <div class="col-md-12">
                                        <form class="in-modal booktutorr_form loginpage" onSubmit={this.handleSubmit8} >
                                            <div class="row text-left">
                                            {/* <div class="col-md-6">  
                                                    <label>Tutor Rate : </label><br></br>
                                                    <h6>${this.state.tutorRate} /Hour</h6>
                                                </div> */}
                                                <div class="col-md-6">  
                                                    <label>Offered Price</label><br></br>
                                                    <input type="text" maxLength='3' name="booking_offer_price" onChange={this.handleChange8} placeholder="$" required />
                                                </div>
                                                <div class="col-md-6">
                                                    <label>Frequency of Session</label>
                                                    <select name="frequency_of_session" onChange={this.handleChangeFrequency} required>
                                                        <option hidden value="">Frequency of Session </option>
                                                        <option value="OneTime">OneTime</option>
                                                        {/* <option value="Every Day">Every Day</option> */}
                                                        <option value="Every Week">Every week</option>
                                                        <option value="Every Month">Every Month</option>
                                                    </select>
                                                </div>

                                                {this.state.frequencySessionID == "OneTime" || this.state.frequencySessionID == '' ?
                                                    null
                                                    :
                                                    <div class="col-md-6">
                                                        <label>Tuition Type</label>
                                                        <select name="tuition_type" onChange={this.handleChange8}>
                                                            <option hidden value="">Tuition Type</option>
                                                            <option value="Group Tuition">Group Tuition</option>
                                                            <option value="Online Tuition">Online Tuition</option>
                                                            <option value="Ondemand Tuition">Ondemand Tuition</option>
                                                        </select>
                                                    </div>
                                                }
                                                {this.state.ArraycheckedData == 0 ?

                                                    <div class="col-md-6">
                                                        <label>Select Schedule</label>
                                                        <div class="book_tutor_chart"  onClick={() => this.handleClick1()} >Schedule</div>
                                                    </div>
                                                    :
                                                    <div class="col-md-6">
                                                        <label>Select Schedule</label>
                                                        <div class="book_tutor_chart"   onClick={() => this.handleClick1()}>Schedule is selected</div>
                                                    </div>
                                                }

                                                <div class="col-md-6">
                                                    <label>Start/Date</label>
{/* 
                                                    <DatePicker selected={this.state.booking_start}
                                                        onChange={date => this.handleDateChange(date)}
                                                        dateFormat='MMMM d, yyyy h:mm aa '
                                                        isClearable
                                                        placeholderText="Date"
                                                        showTimeSelect
                                                        dropdownMode="select"
                                                        onCalendarOpen={this.dateLimitation}
                                                        minDate={this.state.prevDateDisabled}
                                                    /> */}
                                                        {this.state.booking_start?
                                                    <input defaultValue={this.state.booking_start}/>
                                                    :
                                                    <DatePicker selected={this.state.booking_start}
                                                        onChange={this.handleDateChange}
                                                        dateFormat='MMMM d, yyyy h:mm aa '
                                                        onCalendarOpen={this.dateLimitation}
                                                        minDate={this.state.prevDateDisabled}
                                                        
                                                        // defaultDate={this.state.booking_start}
                                                        // includeTimes={[
                                                        //     this.state.setHours(this.state.setMinutes(new Date(), 0), 17),
                                                        //     this.state.setHours(this.state.setMinutes(new Date(), 30), 18),
                                                        //     this.state.setHours(this.state.setMinutes(new Date(), 30), 19),
                                                        //     this.state.setHours(this.state.setMinutes(new Date(), 30), 17),
                                                        //   ]}
                                                       
                                                        // showTimeSelect
                                                        placeholderText='Date'
                                                        dropdownMode="select"
                                                    
                                                        // maxDate={new Date()}
                                                    />
    }



                                                </div>
                                                <div class="col-md-6">
                                                    <label>Address</label>
                                                    <textarea rows="1" cols="50" type="text" name="address" placeholder="Address" onChange={this.handleChange8} required></textarea>
                                                </div>


                                                {/* {this.state.Day1 == 'Monday' || this.state.Day2 == 'Tuesday' || this.state.Day3 == 'Wednesday' || this.state.Day4 == 'Thursday' || this.state.Day5 == 'Friday' || this.state.Day6 == 'Saturday' || this.state.Day7 == 'Sunday' ? */}
                                                {this.state.individualMonCheck1 || this.state.individualThusCheck1  || this.state.individualWedCheck1 || this.state.individualTueCheck1  || this.state.individualFriCheck1 || this.state.individualSatCheck1  || this.state.individualSunCheck1?
                                                    <>

                                                        <div class="col-md-6">
                                                            <label>Morning Prefferd Time</label>
                                                            <Multiselect
                                                                options={this.state.itemsM}
                                                                selectedValues={this.state.selectectedItems}
                                                                displayValue="name"
                                                                onSelect={(ele) => { this.setState({ selectectedItems: ele }) }}
                                                        


                                                            />
                                                        </div>
                                                    </>
                                                    : null}


                                                {/* {this.state.anyweekfternoondata ||this.state.DayAfternoon1 == 'Monday' || this.state.DayAfternoon2 == 'Tuesday' || this.state.DayAfternoon3 == 'Wednesday' || this.state.DayAfternoon4 == 'Thursday' || this.state.DayAfternoon5 == 'Friday' || this.state.DayAfternoon6 == 'Saturday' || this.state.DayAfternoon7 == 'Sunday' ? */}
                                                {this.state.allcheckbox2|| this.state.individualMonCheck2 || this.state.individualThusCheck2 || this.state.individualWedCheck2 || this.state.individualTueCheck2 || this.state.individualFriCheck2 || this.state.individualSatCheck2 || this.state.individualSunCheck2  ?
                                                    <>

                                                        <div class="col-md-6">
                                                            <label>Afternoon Prefferd Time</label>
                                                            <Multiselect
                                                                options={this.state.itemsA}
                                                                selectedValues={this.state.selectectedItems2}


                                                                displayValue="name"
                                                                onSelect={(ele) => { this.setState({ selectectedItems2: ele }) }}
                                                           


                                                            />
                                                        </div>
                                                    </>
                                                    : null}



                                                {/* {this.state.anyweekdaynightdata||this.state.DayEvening1 == 'Monday' || this.state.DayEvening2 == 'Tuesday' || this.state.DayEvening3 == 'Wednesday' || this.state.DayEvening4 == 'Thursday' || this.state.DayEvening5 == 'Friday' || this.state.DayEvening6 == 'Saturday' || this.state.DayEvening7 == 'Sunday' ? */}
                                                {this.state.allcheckbox1|| this.state.individualMonCheck3|| this.state.individualThusCheck3 || this.state.individualWedCheck3 || this.state.individualTueCheck3 || this.state.individualFriCheck3 || this.state.individualSatCheck3 || this.state.individualSunCheck3 ?
                                                    <>
                                                        <div class="col-md-6">
                                                            <label>Evening Prefferd Time</label>

                                                            <Multiselect
                                                                options={this.state.itemsE}
                                                                selectedValues={this.state.selectectedItems3}
                                                                displayValue="name"
                                                               
                                                                onSelect={(ele) => { this.setState({ selectectedItems3: ele }) }}


                                                            />

                                                        </div>
                                                    </>
                                                    : null}

                                                {/* <div class="col-md-6">
                                                    <label>Address</label>
                                                    <textarea rows="1" cols="50" type="text" name="address" placeholder="Address" onChange={this.handleChange8} required></textarea>
                                                </div> */}
                                                <div class="col-md-12">
                                                    <div class="row">
                                                        <div class="col-md-3 m-auto">
                                                            <button class="btn  btn-block send-button border_radius_20 tx-tfm mb-4" type="submit">Save</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal  show={this.state.show} onHide={()=>this.handleClick1()}>
                    <Modal.Header className="allmodal_header modal-header" ><h2>Select Schedule</h2>
                    </Modal.Header>
                    <Modal.Body >
                    <form class="in-modal">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <table class="table table-bordered book_tutor_table">
                                                    <thead>
                                                        <tr class="heading">
                                                            <th scope="col"></th>
                                                            <th scope="col"><h1></h1>Mon </th>
                                                            <th scope="col"><h1></h1>Tue  </th>
                                                            <th scope="col"><h1></h1>Wed  </th>
                                                            <th scope="col"><h1></h1>Thurs  </th>
                                                            <th scope="col"><h1></h1>Fri  </th>
                                                            <th scope="col"><h1></h1>Sat  </th>
                                                            <th scope="col"><h1></h1>Sun</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">Morning</th>
                                                            <td> <input onChange={this.checkBoxHandler_A} id="1" checked={this.state.individualMonCheck1} type="checkbox" name="vehicle1" value="monday" /></td>
                                                            <td> <input onChange={this.checkBoxHandler_B} id="1" checked={this.state.individualTueCheck1} type="checkbox" name="vehicle2" value="tuesday" /></td>
                                                            <td> <input onChange={this.checkBoxHandler_C} id="1" checked={this.state.individualWedCheck1} type="checkbox" name="vehicle3" value="wednesday" /></td>
                                                            <td> <input onChange={this.checkBoxHandler_D} id="1" checked={this.state.individualThusCheck1} type="checkbox" name="vehicle4" value="thursday" /></td>
                                                            <td> <input onChange={this.checkBoxHandler_E} id="1" checked={this.state.individualFriCheck1} type="checkbox" name="vehicle5" value="friday" /></td>
                                                            <td> <input onChange={this.checkBoxHandler_F} id="1" checked={this.state.individualSatCheck1} type="checkbox" name="vehicle6" value="saturday" /></td>
                                                            <td> <input onChange={this.checkBoxHandler_G} id="1" checked={this.state.individualSunCheck1} type="checkbox" name="vehicle7" value="sunday" /></td>

                                                        
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Afternoon</th>
                                                            <td> <input onChange={this.checkBoxHandler_A} id="2" value="monday" checked={this.state.individualMonCheck2} type="checkbox" name="vehicle1" /></td>
                                                            <td> <input onChange={this.checkBoxHandler_B} id="2" value="tuesday" checked={this.state.individualTueCheck2} type="checkbox" name="vehicle2" /></td>
                                                            <td> <input onChange={this.checkBoxHandler_C} id="2" value="wednesday" checked={this.state.individualWedCheck2} type="checkbox" name="vehicle3" /></td>
                                                            <td> <input onChange={this.checkBoxHandler_D} id="2" value="thursday" checked={this.state.individualThusCheck2} type="checkbox" name="vehicle4" /></td>
                                                            <td> <input onChange={this.checkBoxHandler_E} id="2" value="friday" checked={this.state.individualFriCheck2} type="checkbox" name="vehicle5" /></td>
                                                            <td> <input onChange={this.checkBoxHandler_F} id="2" value="saturday" checked={this.state.individualSatCheck2} type="checkbox" name="vehicle6" /></td>
                                                            <td> <input onChange={this.checkBoxHandler_G} id="2" value="sunday" checked={this.state.individualSunCheck2} type="checkbox" name="vehicle7" /></td>
                                                         
                                                            
                                                             

                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Night</th>

                                                            <td> <input onChange={this.checkBoxHandler_A} id="3" checked={this.state.individualMonCheck3} value="monday" type="checkbox" name="vehicle1" /></td>
                                                            <td> <input onChange={this.checkBoxHandler_B} id="3" checked={this.state.individualTueCheck3} value="tuesday" type="checkbox" name="vehicle2" /></td>
                                                            <td> <input onChange={this.checkBoxHandler_C} id="3" checked={this.state.individualWedCheck3} value="wednesday" type="checkbox" name="vehicle3" /></td>
                                                            <td> <input onChange={this.checkBoxHandler_D} id="3" checked={this.state.individualThusCheck3} value="thursday" type="checkbox" name="vehicle4" /></td>
                                                            <td> <input onChange={this.checkBoxHandler_E} id="3" checked={this.state.individualFriCheck3} value="friday" type="checkbox" name="vehicle5" /></td>
                                                            <td> <input onChange={this.checkBoxHandler_F} id="3" checked={this.state.individualSatCheck3} value="saturday" type="checkbox" name="vehicle6" /></td>
                                                            <td> <input onChange={this.checkBoxHandler_G} id="3" checked={this.state.individualSunCheck3} type="checkbox" name="vehicle7" value="sunday" /></td>
                                                          

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            {this.state.frequencySessionID=="OneTime"?
                                            null:
                                            <>
                                            <div class="col-md-12 check_schedule">
                                                <input onChange={(e)=>{this.checkBoxHandlerAnyWeekdayNight(e)}} value={this.state.anyWeekdayNight} checked={this.state.allcheckbox1} type="checkbox" id="1" name="vehicle1" />
                                                <label for="vehicle1">Any weekday night</label><br />
                                            </div>
                                            <div class="col-md-12 check_schedule">
                                                <input onChange={(e)=>{this.checkBoxHandlerAnyWeekdayAfternoon(e)}} checked={this.state.allcheckbox2} type="checkbox" id="2" name="vehicle1" value={this.state.anyWeekdayAfternoon} />
                                                <label for="vehicle1">Any weekday Afternoon</label><br />
                                            </div>
                                            </>
                                            }
                                          
                                        </div>

                                    </form>
                                    </Modal.Body>
                                    <Modal.Footer className="text-center all_popups_buttonss custom_modal_footer">
                                    {this.state.individualMonCheck1|| this.state.individualMonCheck2|| this.state.individualMonCheck3|| this.state.individualThusCheck1|| this.state.individualThusCheck2|| this.state.individualThusCheck3 || this.state.individualWedCheck1||this.state.individualWedCheck2|| this.state.individualWedCheck3 || this.state.individualTueCheck1|| this.state.individualTueCheck2|| this.state.individualTueCheck3 || this.state.individualFriCheck1|| this.state.individualFriCheck2 || this.state.individualFriCheck3 || this.state.individualSatCheck1 || this.state.individualSatCheck2 || this.state.individualSatCheck3 || this.state.individualSunCheck1 || this.state.individualSunCheck2 || this.state.individualSunCheck3 ?
                                        <Button onClick={this.handleSave} className="submit_bb"> Save</Button>
                                        :
                                        <Button disabled='true' onClick={this.handleSave} className="submit_bb"> Save</Button>
                                    }
                                    <Button  onClick={() => this.handleCancel() } className="cancel_bb" >Cancel</Button>
                                               
                                    </Modal.Footer>
                                    </Modal>
                

                 
                    

                </section>
                <Footer></Footer>
            </main>
        );
    }
}

export default postBooking;




 









