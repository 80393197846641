import React, { Component } from "react";
import axios from "axios";
import OtpInput from "react-otp-input";
import { API_BASE_URL } from '../../config/api.js';
import firebase from '../../firebase.js';
import Header from "../common/header";
import Footer from "../common/footer";
import otp_img from "../../assets/img/categories/Icon/otp.png";
import { Link } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import swal from 'sweetalert';
var appVerifier = ''
var usertype_var = ''
var grecaptcha = ''
// var recaptchaResponse=''

class Verify_user_login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: [],
      i: 0,
      tempo: 0,
      dta: '',
      verifyStatus: '',
      ConfirmationResult: '',
      visible: false,
      username: '',
      email: '',
      seconds:'',
      otp: "",
      val: "",
      val1: '',
      value: '',
      OTPError: '',
      MessageOTP: false,
      MessageSendOTP: false,
      myvalue: '',
      time: [],
      i: 0,
      tempo: 0,
      newI: '',
      time: {}, seconds: 26,
      times: {}, seconds2: 26,
    }
    this.handleChange = otp => this.setState({ otp });
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resenddd = this.resenddd.bind(this);
    // this.timer = 0;
    // this.countDown = this.countDown.bind(this);
    // this.timer2 = 0;
    // this.countDown2 = this.countDown2.bind(this);
    // this.handleResendOtp=this.handleResendOtp.bind(this);
  }

  handleChange(evt) {
    this.setState({ [evt.target.name]: evt.target.value });
  }

  sweetAlertInValidPhone = () => {
    swal({
      text: "Invalid Attempt",
      icon: "warning",
      dangerMode: true,
    })
  }

  sweetAlertVerificationInvalidUser = () => {
    swal({
      text: "Invalid User",
      icon: "warning",
      dangerMode: true,
    })
  }

  sweetAlertVerificationSuccess = () => {
    swal({
      text: "Verification Successful",
      icon: "success",
    }).then(() => {
      if (usertype_var == '0') {
        this.props.history.push("/dashboard");
      }
      else {
        this.props.history.push("/personal_Detail");
      }
    })
  }

  handleSubmit = event => {
    event.preventDefault();
    this.otpIntegration();
  }




  otpIntegration = () => {
    const code = this.state.otp
    var verifyOtp = this.state.ConfirmationResult
    if (verifyOtp) {
      this.setState({ verifyStatus: 'verified' })
      verifyOtp.confirm(code).then((result) => {
        const user = result.user;

        console.log("heyyyyyaaaaaaaa", result)
        this.handleverifyotp()
      }).catch((error) => {

        this.setState({ verifyStatus: 'unverified' })
        this.handleverifyotp()
      });
    } else {
      this.sweetAlertInValidPhone()
    }
  }

  setUpRecaptcha = () => {


    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: 'invisible',
        callback: ((response) => {
          console.log("capthaffffffff resolved", response)







        })


      }





    )

  }







  sendOtp = () => {

    var phoneNumber = '+' + localStorage.getItem('country_code') + localStorage.getItem('username');
   
    appVerifier = window.recaptchaVerifier;
    this.setState({ dta: appVerifier })


    firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)

      .then((confirmationResult) => {
        console.log("confirmationResult", confirmationResult)
        this.setState({ ConfirmationResult: confirmationResult })

        // Or, if you haven't stored the widget ID:
        appVerifier.render().then((widgetId) => {
          console.log('12345566', widgetId)
          // const dataaaaaa = grecaptcha.reset(widgetId);
          // console.log('dataaaaaa>>>>>>', dataaaaaa)
          // this.setState({ VALDATA: dataaaaaa })
          
          // let timeLeftVar = this.secondsToTime(this.state.seconds);
          // this.setState({ time: timeLeftVar });
      
          // if (this.timer == 0 && this.state.seconds > 0) {
          //   this.timer = setInterval(this.countDown, 1000);
          // }
        }
        )


      }).catch((error) => {
        if(error.message=="TOO_LONG"){
          this.InvalidPhoneNo()
        }
        else if(error.message=="We have blocked all requests from this device due to unusual activity. Try again later."){
          this.TooManyAttempts()
        }
        else if(error.message=="Invalid format."){
          this.InvalidPhoneNo()
        }

      })
    // this.handletimer()
  }



  InvalidOTP = () => {
    swal({
      text: "OTP Is Invalid.Please Enter Valid OTP.",
      icon: "warning",
      dangerMode: true,
    })
  }
  InvalidPhoneNo = () => {
    swal({
      text: "Mobile No Is Invalid",
      icon: "warning",
      dangerMode: true,
    })
  }
  TooManyAttempts = () => {
    swal({
      text: "Too Many Attempts Try Later",
      icon: "warning",
      dangerMode: true,
    })
  }
  // secondsToTime2(secs) {
  //   let hours = Math.floor(secs / (60 * 60));

  //   let divisor_for_minutes = secs % (60 * 60);
  //   let minutes = Math.floor(divisor_for_minutes / 60);

  //   let divisor_for_seconds = divisor_for_minutes % 60;
  //   let seconds2 = Math.ceil(divisor_for_seconds);

  //   let obj = {
  //     "h": hours,
  //     "m": minutes,
  //     "s": seconds2
  //   };
  //   return obj;
  // }

  // countDown() {
  //   // Remove one second, set state so a re-render happens.
  //   let seconds = this.state.seconds - 1;
  //   this.setState({
  //     time: this.secondsToTime(seconds),
  //     seconds: seconds,
  //   });

  //   // Check if we're at zero.
  //   if (seconds == 0) {
  //     clearInterval(this.timer);
  //   }
  // }

  // countDown2() {
  //   // Remove one second, set state so a re-render happens.
  //   let seconds2 = this.state.seconds2 - 1;
  //   this.setState({
  //     times: this.secondsToTime(seconds2),
  //     seconds2: seconds2,
  //   });

  //   // Check if we're at zero.
  //   if (seconds2 == 0) {
  //     clearInterval(this.timer2);
  //   }
  // }


  // secondsToTime(secs) {
  //   let hours = Math.floor(secs / (60 * 60));

  //   let divisor_for_minutes = secs % (60 * 60);
  //   let minutes = Math.floor(divisor_for_minutes / 60);

  //   let divisor_for_seconds = divisor_for_minutes % 60;
  //   let seconds = Math.ceil(divisor_for_seconds);

  //   let obj = {
  //     "h": hours,
  //     "m": minutes,
  //     "s": seconds
  //   };
  //   return obj;
  // }



 

  resenddd = () => {


    this.setState({otp:''})

this.interval()
    this.sendOtp()

    // let timeLeftVar = this.secondsToTime2(this.state.seconds2);
    //         this.setState({ times: timeLeftVar });
    //         if (this.timer2 == 0 && this.state.seconds2 > 0) {
    //           this.timer2 = setInterval(this.countDown2, 1000);
    //         }

  }

  handleverifyotp() {
    const sendData = {
      username: localStorage.getItem('username'),
      email: localStorage.getItem('email'),
      verify_status: this.state.verifyStatus,
    };

    axios({
      method: 'post',
      url: API_BASE_URL + "verify_otp_by_firebase/",
      data: sendData
    }).then(response => {
      console.log("verify_otp_by_firebase/ working", response.data)
      if (response.data.success == 200) {
        if (response.data.message == "Verification successful.") {
          localStorage.setItem('UserToken', response.data.access)
          localStorage.setItem("verified", this.state.verify_status)
          usertype_var = response.data.user_type
          console.log("hjdisofosi", usertype_var)
          this.sweetAlertVerificationSuccess()
        } else {
          this.sweetAlertVerificationInvalidUser()
        }
      } else {
        this.sweetAlertInValidPhone()
      }
    })
      .catch((error) => {
        console.log(error)
      });
  }

  componentDidMount() {
    this.setUpRecaptcha()
    this.interval()
    this.sendOtp()
    

  }

  interval() {

    this.setState({ seconds: 26 });

    let myInterval = setInterval(() => {
        if (this.state.seconds == 0) {
            clearInterval(myInterval);
        } else {
            this.setState({ seconds: this.state.seconds - 1 });
        }
    }, 1000)
}

  // timer() {  
  //   return (
  //     <>{this.state.time[this.state.i]}</>
  //   )
  // }

  // handletimer() {
  //   for (let i = 26; i >= 0; i--) {
  //       this.state.time.push(i)
  //   }
  //   //console.log("dddhgdhd",this.state.time)
  //   setInterval(() => {

  //       if (this.state.i < 26) {
  //           this.setState({ newI: this.state.i })
  //           this.timer()
  //           this.state.i = this.state.i + 1
  //       } else {
  //           this.setState({ tempo: this.state.i + 1 });
  //       }

  //   }, 1000);
  // }

  render() {

    // grecaptcha.reset(appVerifier)

    return (
      <main>
        <Header></Header>

        <section className="login_form mx-auto margin_top otp_page">
          <div className="container-fluid width_custom border-0">
            <div className="row">
              <div className="col-md-7 col-sm-12 bg_banner_login login_main_banner">

              </div>
              <div className="col-md-5 col-sm-12 m-auto">
                <div className="card2 border-0 my-3 login_card">

                  <form className=" text-center loginpage cont_btn otp_verify_btn" onSubmit={this.handleSubmit} method="post">
                    <div id="recaptcha-container"></div>
                    <h2 >Verify Account</h2>
                    <div className="logo" >
                      <Link to="#">
                        <img src={otp_img} alt="Logo"></img>
                      </Link>
                    </div>
                    <p className="otp_peraa">OTP has been send to your registered mobile number - {localStorage.getItem('username')}</p>
                    <p className="otp_peraa margin0">Please enter it below</p>
                    <div className="form-group otp_input_section otp_mob">
                      <OtpInput
                        value={this.state.otp}
                        onChange={this.handleChange}
                        numInputs={6}
                        name="otp"
                        isInputNum
                        separator={<span></span>} />
                    </div>

                    <Loader
                      visible={this.state.visible}
                      type="Oval"
                      color="#00bacc"
                      height={40}
                      width={40}
                    />

                    <div className="form-group mt-5">
                      {this.state.otp.length >= 6?
                        <button className="btn btn-block send-button border_radius_20 tx-tfm" type="submit">Verify</button>
                        :
                        <button className="btn btn-block send-button border_radius_20 tx-tfm" disabled="true" type="submit">Verify</button>
                      }
                    </div>


          
{this.state.seconds== 0 ?

<p className="resendotpbtn" >
  <button  onClick={() => this.resenddd()}>Resend Security Code</button>
  {this.state.seconds} sec left


</p>
:
<p className="resendotpbtn" >
  <button type="submit" disabled='true' >Resend Security Code</button>
  {this.state.seconds} sec left
</p>}
                    {/* <p className="resendotpbtn" disabled="true">
                      <span onClick={() => this.sendOtp()}>Resend Security Code</span>
                      <span>{this.timer()} sec left</span>
                    </p> */}

                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer></Footer>
      </main>
    );
  }
}
export default Verify_user_login;




