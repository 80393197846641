import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Home from './components/home/Home';
import about from './components/home/about';
import contact from './components/home/contact';
import Become_teacher from './components/home/become_teacher';
import how_works from './components/home/how_works';
import HelpSupport from './components/common/helpSupport';
import terms from './components/home/terms';
import policy from './components/home/policy';
import on_demand from './components/home/on_demand';
import group_tuition from './components/home/group_tuition';
import online_tuition from './components/home/online_tuition';
import Become_student from './components/home/become_student';
import Login from './components/auth/login';
import startconferencingsession from './components/common/startconferencingsession';
import sign_up from './components/auth/sign_up';
import t_sign_up from './components/auth/t_sign_up';
import otp from './components/auth/otp';
import Editprofile from './components/common/editprofile';
import Emailverification from './components/auth/emailverification';
import Mobileverification from './components/auth/mobileverification';
import verifyAccount from './components/auth/verifyAccount';
import Dashboard from './components/user/dashboard';
import SideBar from './components/user/sideBar';
import CardDetail from './components/user/card_detail';
import EditBooking from './components/user/editbooking';
import Booking from './components/common/booking';
import notificationHandle from './components/common/notificationhandler'
import Postbooking from './components/common/postbooking';
import TutorBooking from './components/tutor/tutorbooking';
import TutorSideBar from './components/tutor/tutorSideBar';
import TutorHome from './components/tutor/tutor_home';
import Userbooking from './components/user/Userbooking';
import Notifications from './components/common/notifications';
import Conversations from './components/common/conversations';
import Bookingdetail from './components/tutor/tutorbookingdetail';
import UserBookingdetail from './components/user/UserBookingdetail';
import personal_Detail from './components/tdetails/personal_Detail';
import certificate from './components/tdetails/certificate';
import About_you from './components/tdetails/about_you';
import Send_otp from './components/auth/send_otp';
import Verify_otp from './components/auth/verify_otp';
import Add_new_password from './components/auth/add_new_password';
import Edu_list from './components/tdetails/edu_list';
import TeachingSkills from './components/tdetails/teaching_skills';
import Tutorsearch from './components/common/tutorsearch';
import demo from './components/user/demo';
import Tutorprofile from './components/common/tutor_profile';
import Availability from './components/tdetails/availability';
import Favlist from './components/common/favlist';
import MapContainer from './components/common/map';
import BankAccountDetails from './components/tutor/BankAccountDetails';
import Payment  from './components/common/payment';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../src/index.css";
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { withRouter } from 'react-router';


class ScrollToTop extends Component {

  constructor(props){
    super(props)
    this.state= {
    }
  }
  componentDidMount(){

      
  } 

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      // alert("sdfs");
      window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    }
  }

  render() {
    return this.props.children;
  }
}
  
const Scroll = withRouter(ScrollToTop);

class Root extends React.Component {

render() {
    return(
    <BrowserRouter basename={'/'} >
    <Scroll>
    <Switch>
          <Route exact={true} path="/" component={Home} />
          <Route path="/about" component={about} />
          <Route path="/contact" component={contact} />
          <Route path="/terms" component={terms} />
          <Route path="/policy" component={policy} />
          <Route path="/how_works" component={how_works} />
          <Route path="/helpSupport" component={HelpSupport} />
          <Route path="/on_demand" component={on_demand} />
          <Route path="/group_tuition" component={group_tuition} />
          <Route path="/online_tuition" component={online_tuition} />
          <Route path="/become_teacher" component={Become_teacher} />
          <Route path="/become_student" component={Become_student} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/tutorbooking" component={TutorBooking} />
          <Route path='/notificationHandle' component={notificationHandle}/>
          <Route path="/booking" component={Booking} />
          <Route path='/postbooking' component={Postbooking} />
          <Route path="/editprofile" component={Editprofile} />
          <Route path="/emailverification" component={Emailverification} />
          <Route path="/mobileverification" component={Mobileverification} />
          <Route path="/notifications" component={Notifications} />
          <Route path="/conversations" component={Conversations} /> 
          <Route path="/tutor_home" component={TutorHome} />   
          <Route path="/tutorbookingdetail" component={Bookingdetail} />
          <Route path="/card_detail" component={CardDetail} /> 
          <Route path="/userbookingdetail" component={UserBookingdetail} /> 
          <Route path="/editbooking" component={EditBooking}/>
          <Route path="/login" component={Login} />
          <Route path="/sign_up" component={sign_up} />
          <Route path="/t_sign_up" component={t_sign_up}/>
          <Route path="/startconferencingsession" component={startconferencingsession}/>
          <Route path="/otp" component={otp} />
          <Route path="/verifyAccount" component={verifyAccount} />
          <Route path="/sideBar" component={SideBar} />
          <Route path="/tutorSideBar" component={TutorSideBar} />
          <Route path="/personal_Detail" component={personal_Detail} />
          <Route path="/certificate" component={certificate} />
          <Route path="/about_you" component={About_you} />
          <Route path="/send_otp" component={Send_otp }/>
          <Route path="/Verify_otp" component={Verify_otp}/>
          <Route  path="/Add_new_password/" component={Add_new_password}/>
          <Route path="/Edu_list" component={Edu_list}/>
          <Route exact={true} path="/teaching_skills/:id" component={TeachingSkills} />
          <Route exact={true} path="/tutorsearch/:categoryName/:categoryId" component={Tutorsearch} />
          {/* <Route path="/tutorsearch" component={Tutorsearch}/> */}
          <Route  path="/bankaccountdetail" component={BankAccountDetails}/>
          <Route path="/demo"  component={demo}/>
          <Route path="/tutor_profile" component={Tutorprofile}/>
          <Route path="/Availability" component={Availability}/>
          <Route path="/Userbooking" component={Userbooking}/>
          <Route path="/favlist" component={Favlist}/>
          <Route path="/map" component={MapContainer}/>
          <Route path="/payment" component={Payment}/>
      </Switch>
      </Scroll>
      </BrowserRouter>
  );
}
}

ReactDOM.hydrate(<Root />, document.getElementById('root'))
