import React from 'react';
import Header from '../common/header';
import Footer from '../common/footer';
import axios from 'axios';
import moment from 'moment';
import  'moment-timezone';
import StarRatings from 'react-star-ratings';
import defaultMaleImg from '../../assets/img/default_male.png';
import defaultFemaleImg from '../../assets/img/default_female.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
import { API_BASE_URL, BASE_URL, API_IMAGE_URL_MEDIA } from '../../config/api.js'
import { Button, Modal } from "react-bootstrap";
import TutorSideBar from "./tutorSideBar.js";
import swal from 'sweetalert';
var value = ''
var FilterMornigResult = ''
var FilterAfternoonResult = ''
var FilterEveningResult = ''
var pickdata = '';
var pickdatamorning='';
var pickdataevening=''
const days = [
{ label: "S", value: "sunday" },
{ label: "M", value: "monday" },
{ label: "T", value: "tuesday" },
{ label: "W", value: "wednesday" },
{ label: "T", value: "thursday" },
{ label: "F", value: "friday" },
{ label: "S", value: "saturday" },
]
var DaysM = []
var DaysA = []
var DaysE = []
var result = []
var result1 = []
var result2 = []
class Bookingdetail extends React.Component {
constructor(props) {
super(props)
this.state = {
show: "",
bookingdetailList: [],
preDateTime: [],
offer_price: '',
Update_offer_price: '',
samedatasend:[],
val: '',
val1: '',
val2: '',
offerstatus: false,
val3: '',
offer: '',
tutor: '',
tutor_rating: '',
bookingid: '',
userid: '',
TutorOfferList: [],
MessageUpdateOfferPrice:'',
radioBuutonHandler:'',
RejectButton:''
// bookingIdParam: this.props.location.state.bookingId
}
this.handleSubmit = this.handleSubmit.bind(this);
this.handleofferUpdate = this.handleofferUpdate.bind(this);

this.onChatHandle=this.onChatHandle.bind(this);
this.handleChange = this.handleChange.bind(this);
this.proposed_schedule = this.proposed_schedule.bind(this);
this.handleBookingAction = this.handleBookingAction.bind(this);
this.RejectOffer = this.RejectOffer.bind(this);
this.handleClick1 = this.handleClick1.bind(this)
this.handleOfferPrice=this.handleOfferPrice.bind(this);
}
sweetAlertBookingAccepted = () => {
swal({
text: "Booking successfully accepted",
icon: "success",
})
}
sweetAlertCancelBooking = () =>{
swal({
text: "Booking cancelled",
icon: "success",
})
}
sweetAlertOfferSent = () => {
swal({
text: "Offer Sent Successfully",
icon: "success",
})
}
sweetAlertBookingAlreadyAccpted = () => {
   swal({
       text: "Booking allready accepted or rejected.",
       icon: "warning",
       dangerMode: true,
   })
}
sweetAlertOfferAlreadySent = () => {
swal({
text: "Offer Already Sent",
icon: "warning",
dangerMode: true,
})
}

sweetValid=()=>{
   swal({
      text: "Invalid User",
      icon: "warning",
      dangerMode: true,
      })
}
sweetAlertUpdatedOffer = () => {
swal({
text: "Offer Updated Successfully",
icon: "success",
})
}
handleChange(e){
   
this.setState({radioBuutonHandler: e.target.value });
}
handleOfferPrice(e){
console.log("ddfdyd",e.target.value)
this.setState({ [e.target.name]: e.target.value });
}
handleClick1 = () => {
this.setState({show: !this.state.show})
}
handleBookingAction(userId,bookingId) {
const UserToken = localStorage.getItem('UserToken');
if (UserToken != '') {
const sendData = {
user_id: userId,
booking_id: bookingId,
status: "waiting for payment"
}
axios({
method: 'post',
url: API_BASE_URL + "booking_action/",
headers: { 'Authorization': "Bearer " + UserToken },
data: sendData
}).then(response => {
if (response.data.success == 200) {
if (response.data.message == "You are successfully accepted booking and waiting for payment") {
this.sweetAlertBookingAccepted();
}
else if (response.data.message == "Booking allready accepted or rejected.") {
   this.sweetAlertBookingAlreadyAccpted()
}
}

console.log("detail", response.data)
}).catch(error => {
console.log(error)
})
}
}
RejectOffer = (userId, bookingId) => {
const UserToken = localStorage.getItem('UserToken');
if (UserToken != '') {

   // const sendData1 = new FormData();
   // sendData1.append('booking_id', bookingId);
   // sendData1.append('user_id',userId);
   // sendData1.append('status',"cancelled")

const sendData1 = {
user_id: userId,
booking_id: bookingId,
status: "cancelled"
}
axios({
method: 'post',
url: API_BASE_URL + "booking_action/",
headers: { 'Authorization': "Bearer " + UserToken },
data: sendData1
}).then(response => {
if (response.data.success == 200) {
if (response.data.message == "You are successfully rejected this booking ") {
this.sweetAlertCancelBooking();
this.setState({RejectButton:true})
}
else if(response.data.message=="Booking allready accepted or rejected."){
   this.sweetAlertBookingAlreadyAccpted()

}
}
console.log("detail", response.data)
}).catch(error => {
console.log(error)
})
}
}
handleSubmit=(bookingId,userId,event)=> {
const UserToken = localStorage.getItem('UserToken');
if (UserToken != '') {
   const sendData = new FormData();
   if(this.state.radioBuutonHandler=="1"){
      console.log("sameas",this.state.radioBuutonHandler)
      sendData.append('booking_id', bookingId);
      sendData.append("offer_price",this.state.samedatasend);
      sendData.append("user_id",userId)

   }
   else{
      sendData.append('booking_id', bookingId);
      sendData.append("offer_price",this.state.offer_price);
      sendData.append("user_id",userId)


   }
axios({
method: 'post',
url: API_BASE_URL + "add_offer/",
headers: { 'Authorization': "Bearer " + UserToken },
data: sendData
}).then(response => {
this.setState({
offer: response.data.offer_id,
tutor: response.data.tutor_id
})
console.log("tytye", response.data.offer_id)
console.log('kkkff' + this.state.tutor)
if (response.data.success == 200) {
this.setState({offerupdate:true})
if (response.data.message == "Your offer successfully send") {
this.Bookingdetail()
this.handleClick1()
this.sweetAlertOfferSent()
}
}
else if (response.data.success == 201) {
if (response.data.message == "You are already offerd  this booking") {
this.handleClick1()
this.sweetAlertOfferAlreadySent()
}
if(response.data.message=='You are not a valid student'){
   this.handleClick1()
this.sweetValid()
}
}
console.log("detail", response.data)
// this.UpdateData()
}).catch(error => {
console.log(error)
})
// :
}}
handleofferUpdate=(offerId,bookingId,userId,tutorId)=>{
const UserToken = localStorage.getItem('UserToken');
if (UserToken != '') {
   const sendData1 = new FormData();
   if(this.state.radioBuutonHandler=="1"){
      console.log("sameas",this.state.radioBuutonHandler)
      sendData1.append('booking_id', bookingId);
      sendData1.append("offer_price",this.state.samedatasend);
      sendData1.append("user_id",userId)
      sendData1.append("offer_id",offerId)
      sendData1.append("tutor_id",tutorId)


   }
   else{
      sendData1.append('booking_id', bookingId);
      sendData1.append("offer_price",this.state.offer_price);
      sendData1.append("user_id",userId)
      sendData1.append("offer_id",offerId)
      sendData1.append("tutor_id",tutorId)



   }
axios({
method: 'post',
url: API_BASE_URL + "update_offer/",
headers: { 'Authorization': "Bearer " + UserToken },
data: sendData1
}).then(response => {
if (response.data.success == 200) {
if (response.data.message == "Your offer successfully updated.") {
this.Bookingdetail()
this.handleClick1()
this.sweetAlertUpdatedOffer()
}
}
else if(response.data.success==201){
}
}).catch(error => {
console.log(error)
})
}
}
componentDidUpdate(prevProps, nextProps) {
   console.log("pre",prevProps.history.location.params)
   console.log("nextprops",nextProps.bookingdetailList)
   const filterdata=nextProps.bookingdetailList.map(val=>val.id)
   console.log("filterdata",filterdata)
   const bookidataaa=localStorage.getItem('country_code')
   if(bookidataaa!=filterdata){
      console.log("lastiddata",this.state.bookingiddata)
      this.Bookingdetail()

   }
   else{
      
   }
// this.Bookingdetail()

   }
componentDidMount() {
this.Bookingdetail()
// this.proposed_schedule()
}
Bookingdetail(){
   const country_code = localStorage.getItem("country_code")
const UserToken = localStorage.getItem('UserToken');
// console.log("yrrrr",this.state.bookingIdParam)
if (UserToken != '') {
axios({
method: 'post',
url: API_BASE_URL + "booking_detail_tutor/",
headers: { 'Authorization': "Bearer " + UserToken },
data: {
booking_id: country_code
}
}).then(response => {
console.log("detail", response.data.booking_detail)
this.setState({
bookingdetailList: response.data.booking_detail,
TutorOfferList:response.data.offer_list_booking,
samedatasend:response.data.booking_detail[0].booking_offer_price
})
var data2 = (response.data.booking_detail[0].time_schedule)
const c = data2.replace(/'/g, '"');
const parsedata = JSON.parse(c)
const AfternoontimeSlot = parsedata.afternoon
{
AfternoontimeSlot.map((data) => {
const pick = data.time
return (
pick.map((pickle) => {
   // if(pickdata!=''){
   //    pickdata = pickdata+","+pickle
   // }else{
   //    pickdata = pick
   // }
return (
this.setState({ timervalueAfternoon: pick })
)
}
)
)
})
}
const MorningtimeSlot = parsedata.morning
{
MorningtimeSlot.map((data) => {
const pick = data.time
return (
pick.map((pickle) => {
   // if(pickdatamorning!=''){
   //    pickdatamorning=pickdatamorning+","+pickle
   // }
   // else{
   //    pickdatamorning=pickle
   // }
return (
this.setState({ timervalueMorning: pick })
)
})
)
})
}
const EveningTimeSlot = parsedata.evening
{
EveningTimeSlot.map((data) => {
const pick = data.time
return (
pick.map((pickle) => {
   // if(pickdataevening!=''){
   //    pickdataevening=pickdataevening+","+pickle
   // }
   // else{
   //    pickdataevening=pickdataevening+","+pickle
   // }
   
console.log("pickvalue", pickle)
return (
this.setState({ timervalueEvening: pick })
)
})
)
})
}
// var preDateTime = [];
var data1 = (response.data.booking_detail[0].schedule);
console.log("yes",data1)
const b = data1.replace(/'/g, '"');
const datalist = JSON.parse(b);
const datalistvalue = datalist.date_schedule;
const finalscheduledata=datalistvalue[0].weekday_time ;

this.setState({ preDateTime: finalscheduledata });
this.proposed_schedule()

}).catch(error => {
console.log(error)
})
}
}

proposed_schedule = async() => {

   const result = this.state.preDateTime.filter(x => x.time === 'morning');

   const result1 = this.state.preDateTime.filter(x => x.time === 'afternoon');
   const result2 = this.state.preDateTime.filter(x => x.time === 'evening');
   FilterMornigResult = result
   FilterAfternoonResult = result1
   FilterEveningResult = result2
   DaysM = result.map((data) => {
       return (
           data.day
       )
   })
   DaysE = result2.map((data) => {
       return (
           data.day
       )
   })
   DaysA = result1.map((data) => {
       return (
           data.day)
   })
}



onChatHandle(tutorId){
   this.props.history.push({ 
       pathname: '/conversations',
       state: { id: tutorId }
    });
}
render() {
const lastschedule=FilterAfternoonResult
const lastMorningSchedule=FilterMornigResult
const lastEveningSchedule=FilterEveningResult

return (
<main>
   <Header></Header>
   <section className="">
      <div className="container-fluid margin_top width_custom">
         <div className="row">
            <div className="col-md-3 mt30">
               <TutorSideBar></TutorSideBar>
            </div>
            <div className="col-md-9 mt30">
               <div className="row">
                  <div className="col-md-12">
                     <h2 className="dashboard_headings">Booking Detail</h2>
                  </div>
               </div>
               {this.state.bookingdetailList.map((booking) => {
               return (
               <div className="row m-0">
                  <div className="col-md-12 box_custom upcoming_session booking_detail_seccc">
                     <div className="row">
                        <div className="col-md-6 col-6">
                        {/* <span>{moment(booking.booking_start).format("DD MMM YYYY")}</span> */}
                        <span>{moment(booking.dob).tz('America/Chicago').format("DD MMM YYYY")}</span>
                           {booking.category_name != '' ?
                           <h4>{booking.category_name}</h4>
                           : null}
                           {booking.sub_category_name != '' ?
                           <p className="sub_b">Subject :  <b>{booking.sub_category_name}</b></p>
                           : null}
                           {booking.tuition_type != '' ?
                           <p className="sub_b"><b>{booking.tuition_type}</b></p>
                           : null}
                        </div>
                        <div className="col-md-6 col-6 text-right">
                        {booking.booking_status == 'pending' ?
                           <button className="pending_btn">{booking.booking_status}</button>
                           :
                        
                           booking.booking_status=="waiting for payment"?
                           <button className="accepted_btn">Payment Pending</button>
                           :
                           booking.booking_status=='upcoming'?
                           
                           <button className="accepted_btn upcoming_btn">{booking.booking_status}</button>
                           :
                           booking.booking_status=='completed'?
                           <button className="accepted_btn">{booking.booking_status}</button>
                           :
                           <button className="accepted_btn cancel_btn">{booking.booking_status}</button>
                         
                           } <br/>
                           {booking.id != '' ?
                           <h6 class="m-2">{booking.id}</h6>
                           : null}
                        </div>
                        <div class="col-md-12">
                           <div class="row box_custom_booking margin0">
                              <div class="col-md-6">
                                 <div class="booking_info">
                                    {booking.image == null ?
                                    booking.gender == "female" ?
                                    <img class="booking_user_img" src={defaultFemaleImg} alt="" />
                                    :<img class="booking_user_img" src={defaultMaleImg} alt="" />
                                    : <img class="booking_user_img" src={BASE_URL + booking.image} alt="" />
                                    }
                                    <div class="info_pera">
                                       <h2>{booking.fullname}<span>(New User)</span></h2>
                                       {booking.online_status=='1' ? 
                                       <div class="status_div left_justify">
                                          <div className="online_status"></div>
                                          ONLINE
                                       </div>
                                       :
                                       <div class="status_div left_justify">
                                          <div className="offline_status"></div>
                                          Offline
                                       </div>
                                       }
                                    </div>
                                 </div>
                              </div>
                              <div class="col-md-6 text-right set_waiting">
                                 <button onClick={() => this.onChatHandle(booking.user)} className="chat_btn">
                                    <i className="fa fa-star" aria-hidden="true">
                                       <FontAwesomeIcon icon={faFacebookMessenger}></FontAwesomeIcon>
                                    </i>
                                    Chat
                                 </button>
                              </div>
                           </div>
                           <div class="row booking_box">
                              <div class="col-md-12 booking_detail_info">
                                 {booking.booking_start != ''  && booking.booking_start != "undefined" && booking.booking_start != undefined?
                                 <div class="address_info"><span>Start Date/Time : </span> 
                                    <span>{moment(booking.booking_start).format("DD MMM YYYY")}</span>
                                 </div>
                                 : null}
                                 {booking.frequency_of_session != ''   && booking.frequency_of_session != "undefined" && booking.frequency_of_session != undefined ?
                                 <div class="address_info">
                                    <span>Frequency : </span> <span>{booking.frequency_of_session}</span>
                                 </div>
                                 : null}
                                 <div class="address_info">
                                    <h1>Proposed Schedule : </h1>
                                 </div>
                                 {this.state.timervalueMorning ?
                                 <>
                                 <div className="address_info">
                                    <span>Morning :</span> <span>{this.state.timervalueMorning.join(",")}</span>
                                 </div>
                                 {lastMorningSchedule.length>0?
                                 <div className="schedule_day">
                                    {days.map((data, index1) => {
                                    console.log("morningschedule",data)
                                    return(
                                    <>
                                    {DaysM.includes(data.value) ?
                                    <button className="active_day">{data.label}</button>
                                    :
                                    <button >{data.label}</button>}
                                    </>
                                    )})}
                                 </div>
                                 :null}
                                 </>
                                 :null}
                                 {this.state.timervalueAfternoon ?
                                 <>
                                 <div className="address_info">
                                    <span>Afternoon :</span> <span>{this.state.timervalueAfternoon.join(",")}</span>
                                 </div>
                                 {lastschedule.length>0?
                                 <div className="schedule_day">
                                    {days.map((data, index1) => {
                                    console.log("afternoon",data)
                                    return (
                                    <>
                                    {DaysA.includes(data.value) ?
                                    <button className="active_day">{data.label}</button>
                                    : <button >{data.label}</button>}
                                    </>
                                    )})}
                                 </div>
                                 :null}
                                 </>
                                 :null}
                                 {this.state.timervalueEvening ?
                                 <>
                                 <div className="address_info">
                                    <span>Evening :</span> <span>{this.state.timervalueEvening.join(",")}</span>
                                 </div>
                                 {lastEveningSchedule.length > 0 >0?
                                 <div className="schedule_day">
                                    {days.map((data, index1) => {
                                    //  console.log("eveningschedule",capitalize(data.value))
                                    return (
                                    <>
                                    {DaysE.includes(data.value) ?
                                    <button className="active_day">{data.label}</button>
                                    :
                                    <button >{data.label}</button>
                                    }
                                    </>
                                    )})}
                                 </div>
                                 :null} 
                                 </>
                                 :null}
                                 <div class="address_info">
                                    <h1>Payment : </h1>
                                 </div>
                                 {booking.booking_offer_price !='' && booking.booking_offer_price!='undefined' && booking.booking_offer_price!=undefined || booking.booking_type=='post' || booking.booking_type=='direct' ?   
                                <div class="address_info payment_detail">
                                {/* <span>Offer Rate : </span>
                                <span>${booking.booking_offer_price} Rate per Hour </span> */}
                                <div className="payment_detail">
                                   <span>Booking Rate: </span>
                                   <span>${booking.booking_offer_price} Rate per Hour </span>
                                </div>
                                <div className="payment_detail">
                                   <span>Total Hours:</span>
                                   <span>{booking.total_hours} Hour</span>
                                </div>
                                <div className="payment_detail">
                                   <span>Total Payable Ammount:</span>
                                   <span>${booking.total_payble}</span>
                                </div>
                             </div>
                                 : null}
                                 {booking.booking_status == "completed" ?
                                 <>
                                 <div class="address_info">
                                    <h1>
                                       Rating : 
                                       <StarRatings
                                          rating={Number(booking.student_rating)}
                                          starRatedColor="#ffc107"
                                          starDimension="18px"
                                          starSpacing="0px"
                                          />
                                    </h1>
                                 </div>
                                 {booking.student_rating != null && booking.student_review != '' ?
                                 <div class="address_info">
                                    <h1>Review : <span>{booking.student_review}</span></h1>
                                 </div>
                                 : 
                                 <div class="address_info">
                                    <span>No Data</span>
                                 </div>
                                 }
                                 </>
                                 : null}
                              </div>
                           </div>
                        </div>
                     </div>
                     {booking.booking_status != "completed" && booking.booking_status != "upcoming"  && booking.booking_status!='cancelled'?
                     <>
                     <div className="row">
                        <div class="col-md-12 right_btns text-center all_popups_buttonss my-3 detail_mob">
                           {booking.booking_type == "direct" ?
                           <>
                           {booking.booking_status == "waiting for payment" ?
                           <button class="cancel_bb btn btn-primary mx-2" onClick={() => this.RejectOffer(booking.user,booking.id)}>REJECT</button>
                           :
                           
                           <>
                           {this.state.RejectButton==false?
                           <>
                           <button onClick={() => this.RejectOffer(booking.user,booking.id)} class="cancel_bb btn btn-primary mx-2"> Cancel</button>
                           <button onClick={() => this.handleBookingAction(booking.user, booking.id)} class="submit_bb btn btn-primary mx-2"> Accept</button>
                          </>
                          :null}
                           </>
                           }
                           </> 
                           :
                           <div class="col-md-3 m-auto loginpage">
                              {this.state.TutorOfferList == '' ?
                              <button class="btn  btn-block send-button border_radius_20 tx-tfm mb-4" onClick={() => this.handleClick1()}>Send Offer</button>
                              :<button class="btn  btn-block send-button border_radius_20 tx-tfm mb-4" onClick={() => this.handleClick1()}>Update Offer</button>
                              }
                           </div>
                           }
                           <Modal show={this.state.show} onHide={()=>
                              this.handleClick1()}>
                              <Modal.Header className="allmodal_header modal-header" closeButton>
                                 <h2>Send Offer</h2>
                                 {/* 
                                 <div class="allmodal_header modal-header">
                                    <h2>Edit Name</h2>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                                 </div>
                                 */}
                              </Modal.Header>
                              <Modal.Body className="all_popupModal">
                                 <div class="row">
                                    <div class="col-md-12">
                                       <h3 class="sendbox_offer_head">Client Budget <span>${booking.booking_offer_price}</span></h3>
                                       <div class="send_offer_Box">
                                          <div className="offer_radio_sec">
                                             <label for="offer1">Same As</label>
                                             <input 
                                                value={1}
                                                onChange={this.handleChange} 
                                                type="radio" 
                                                id="offer1" 
                                                name="offer"   
                                                required>
                                             </input>
                                          </div>
                                          <div className="offer_radio_sec">
                                             <label for="offer2">Make Offer</label>
                                             <input 
                                                value={2}
                                                onChange={this.handleChange}
                                                type="radio" 
                                                id="offer2" 
                                                name="offer"   
                                                required>
                                             </input>
                                          </div>
                                       </div>
                                       {this.state.radioBuutonHandler==2?
                                       <div class="send_offer_Box">
                                          <div className="offer_radio_sec">
                                             <label>Your Offer</label>
                                          </div>
                                          <div className="enter_amount_input">
                                             <input  placeholder="$" maxLength='3' type="text" onChange={this.handleOfferPrice}  name="offer_price" ></input>
                                          </div>
                                       </div>
                                       :null}
                                    </div>
                                 </div>
                              </Modal.Body>
                              <Modal.Footer className="text-center all_popups_buttonss custom_modal_footer">
                                 {this.state.TutorOfferList == '' ?
                                 <Button className="submit_bb my-3" onClick={()=>{this.handleSubmit(booking.id,booking.user)}}> Submit</Button>
                                 :this.state.TutorOfferList.map((data)=>{
                                 return(
                                 <Button className="submit_bb my-3" onClick={()=>{this.handleofferUpdate(data.id,data.booking,data.user,data.tutor)}}> update</Button>
                                 )
                                 })}
                              </Modal.Footer>
                           </Modal>
                        </div>
                     </div>
                     </>: null}
                  </div>
               </div>
               )})}
            </div>
         </div>
      </div>
   </section>
   <Footer></Footer>
</main>
);
}
}
export default Bookingdetail