// import React from 'react';
// import Header from "../common/header";
// import Footer from "../common/footer";
// import axios from 'axios';
// import { API_BASE_URL, BASE_URL } from '../../config/api.js';
// import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
// import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
// import { Button, Modal } from "react-bootstrap";

// var cid = ''
// var subject = ''
// class TeachingSkills extends React.Component {

//   constructor(props) {
//     super(props)
//     this.state = {
//       store_values: false,
//       show: "",
//       year: '',
//       catid: '',
//       subcatData: [],
//       subject1: [],
//       rateData: [],
//       experiaceData: [],
//       HomeTutionData: [],
//       monthData: '',
//       totalPrice1: [],
//       totalPrice2: [],
//       totalPrice3: [],
//       subject2: [],
//       id: this.props.match.params.id,
//       sessinoRateList: [],
//       isselectB:false,
//       show90:''
//     }
//     this.handleChange = this.handleChange.bind(this);
//     this.handleSubmit = this.handleSubmit.bind(this);
//     this.handleChangeId = this.handleChangeId.bind(this);
//     this.handleRateChange = this.handleRateChange.bind(this);
//     this.handleData = this.handleData.bind(this);
//     this.handleBackButton = this.handleBackButton.bind(this);
//   }

//   handleModal() {
//     this.setState({ subject2: this.state.subject1 })
//     this.setState({ show: !this.state.show })
//   }

//   cancelModal = () => {
//     this.setState({ subject1: [] })
//     this.setState({ show: !this.state.show })
//   }



//   handleChange(evt) {

//     this.setState({ [evt.target.name]: evt.target.value });
//     this.setState({ year: evt.target.value })


//   }


//   handleChangeId1(id){
//     this.state.subject2.map((data, index) => {
//       if (data.id == id) {
//     var values = this.state.subject2.findIndex(obj => obj.id === id)//1

//     console.log('index value of : ', this.state.subject2.findIndex(obj => obj.id === id))
//     this.state.subject2.splice(values, 1);
//       }})
//   }

//   handleChangeId(id, name) {


//     let isChecked = name.target.checked;
//     this.setState({ isChecked: name.target.checked })



//     const subject = { 'id': id, 'name': name.target.checked }

//     this.setState({ store_values: subject })

//     let s1 = 0;

//     if (this.state.subject1.length > 0) {

//       this.state.subject1.map((data, index) => {

//         if (data.id == id ) {
//           s1 = 1;

//           var checked = this.state.subject1;

//           var values = checked.indexOf(index)
//           checked.splice(values, 1);
//           // this.setState({ subject1: checked });

//         }
//       })
//     }
//     if (s1 == 0) {

//       this.setState(
//         {
//           subject1: [...this.state.subject1, subject],
//         })
//     }


//   }



//   handleBackButton() {
//     this.props.history.push('/edu_list');

//   }

//   handleData(price, id) {

//     // On Demand Section ////
//     if (isNaN(price)) {



//     } else {
//       const persent = price * 0.15
//       const totalAmount = price - persent

//       const item1 =
//         { tid: id, tprice: totalAmount }

//       let s1 = 0;

//       this.state.totalPrice1.map((data, index) => {

//         if (id == data.tid) {
//           s1 = 1;

//           data.tprice = totalAmount
//         }
//       })
//       if (s1 == 0) {
//         this.setState({ totalPrice1: [...this.state.totalPrice1, item1] })
//       }
//     }



//   }



//   handleRateChange(event, id) {
//     console.log('lavesh>>>>.',id)
//     this.handleData(event.target.value, id)

//     if (event.target.value == '0' || event.target.value == '00' || event.target.value == '000') {
//       alert("Invalid Rate", 'Please Enter Non-Zero Price.');

//     }
//     if (event.target.value.indexOf('.') !== -1) {
//       alert("Error", 'Invalid Rate');

//     }

//     const price = event.target.value
//     const item =
//       { price: price, sub_category_id: id }
//     this.setState({ newlistData: [item] })


//     let s1 = 0;
//     if (this.state.HomeTutionData.length > 0) {

//       this.state.HomeTutionData.map((data, index) => {
//         if (data.sub_category_id == id) {

//           s1 = 1;
//           data.price = price
//         }
//       })
//     }

//     if (s1 == 0) {

//       this.setState(

//         {
//           HomeTutionData: [...this.state.HomeTutionData, item],
//         })
//     }




//   }


//   componentDidMount() {



//     axios({
//       method: 'get',
//       url: API_BASE_URL + `sub_category_list/?category_id=` + this.state.id,

//     }).then(response => {



//       this.setState({ subcatData: response.data.result })
//     }).catch((error) => {
//       console.log(error)
//     });



//     const UserToken = localStorage.getItem('UserToken');
//     if (UserToken != '') {
//       axios({
//         method: 'GET',

//         url: API_BASE_URL + "tutor_session_rate_tutorside/",
//         headers: {
//           'Authorization': 'Bearer ' + UserToken
//         },
//       }).then(response => {
//         response.data.subject_list.map((data) => {
//           console.log('yesss',data)
//           var sub1 = []
//           var sub2 = []
//           this.setState({ sessinoRateList: "" })
//           let year = ""
//           // if (data.tutor_category_id == this.state.id) {
//             this.setState({ sessinoRateList: response.data.subject_list })
//             console.log('data>>>>111',this.state.sessinoRateList)
//             this.state.sessinoRateList.map((updatedata)=>{
//               console.log('update',updatedata.category_name)
//               let updateCategory=updatedata.category_name
//               const item =
//               { price: updatedata.one_hours, sub_category_id: updatedata.tutor_sub_category_id }
//               console.log('1234555????',item)
//              this.state.HomeTutionData.push(item)
//              let value = { 'id': data.tutor_sub_category_id, 'name': data.sub_category_name }
//              sub1.push(value)
//              sub2.push(value)
//              year = data.experience
//             console.log("sun......",value)
//             return(
//                <>
//                </>
//             )
//             })
//           //   var item = {}
//           //   item =
//           //     { price: data.one_hours, sub_category_id: data.tutor_sub_category_id }
//           //   console.log("items", item)
//           //   this.state.HomeTutionData.push(item)
//           //   let value = { 'id': data.tutor_sub_category_id, 'name': data.sub_category_name }
//           //  console.log('sub1',sub1.push(value))

//           //  console.log('sub2',sub2.push(value))
//           //   year = data.experience
//           //   console.log('dddd..>>>',sub2)
//           // // }

//           this.setState({ subject1: sub1 })
//           this.setState({ subject2: sub2 })
//           this.setState({ year: year })

//         })
//       })
//     }

//   }



//   handleSubmit = (id, event) => {
   


//     if(!this.state.year){
//       alert("Please Select Your Experience")
//     }
// if(!this.state.HomeTutionData){
//   alert("Please Select Leaning skill")
// }
//     const sendData =

//     {
//       "items": [

//         {
//           'form_status': 'Form-3',
//           "category": this.state.id,
//           "experiace": this.state.year,

//           "rate": this.state.HomeTutionData

//         }
//       ]


//     }

//     const UserToken = localStorage.getItem('UserToken');
//     if (UserToken != '') {

//       const jsonData = JSON.stringify(sendData)


//       axios({
//         method: 'post',
//         url: API_BASE_URL + "add_tutor_session_rate/",
//         headers: {
//           'Authorization': "Bearer " + UserToken,
//           'Content-Type': 'application/json'
//         },
//         data: jsonData,
//       })


//         .then(res => {



//           if (res.data.success === 201) {
//             if (res.data.type === "user_exist_error") {

//               this.setState({ resMessageUsername: true })

//             }
//           }

//           if (res.data.success === 200) {
//             // this.props.history.push("/about_you");
//             localStorage.setItem('ButtonShow',1)
//           }

//         }).catch((error) => {
//           console.log(error)
//         })
//     }
//   }


//   handleModal90=()=>{
//     this.setState({show90:!this.state.show90})
//   }

//   handleLoadMore=()=>{
//     this.handleSubmit()
//     this.props.history.push("/edu_list");
//   }

// handleNextButton=()=>{
  
//   this.handleSubmit()
//   this.props.history.push("/about_you");
// }
  

//   render() {
//     let flag = 0

//     // const { paramCatId } = this.props.location

//     // this.state.catid = paramCatId

//     return (
//       <main>
//         <Header></Header>
//         <section className="login_form mx-auto margin_top">
//           <div className="container-fluid border-0 width_custom">
//             <div className="row">
//               <div className="col-md-7 col-sm-12 bg_banner_login"></div>
//               <div className="col-md-5 col-sm-12 m-auto">
//                 <div className="card2 border-0 my-3 loginpage">
//                   <div className="text-center">
//                     <h2>Teaching Skills Details</h2>
//                   </div>
//                   <div class="teaching-text-box teaching_edit" onClick={() => this.handleModal(this.state.subject1)}>
//                     {this.state.subject2.length == 0 ?
//                       <p>Choose Your Selected Area</p>
//                       : <p>
//                         {this.state.subject2.map((data12, index) => {

//                           return this.state.subcatData.map((datanew) => {

//                             if (datanew.id == data12.id) {
//                               if (index == this.state.subject1.length - 1) {
//                                 return (
//                                   <>{datanew.sub_category_name}</>
//                                 )
//                               } else {
//                                 return (
//                                   <>{datanew.sub_category_name}, </>
//                                 )
//                               }
//                             }
//                           })
//                         })}
//                       </p>}
//                     <FontAwesomeIcon icon={faPencilAlt}></FontAwesomeIcon>
//                   </div>
//                   {this.state.subject1.length > 0 ?
//                     <>
//                       <div class="teaching_show">
//                         <h3>My Experience</h3>


//                         <div class="teaching-text-box">

//                           <select onChange={this.handleChange}   >
//                             {/* {this.state.year?
//                             <optipn value={this.state.year}>{this.state.year}</optipn>
//                             : */}
//                             {/* <option>Select Year</option> */}
//                             <option hidden value={this.state.year}>{this.state.year} Year</option>
//                             <option value="0">0 Year</option>
//                             <option value="1">1 Year </option>
//                             <option value="2">2  Year </option>
//                             <option value="3">3 Year </option>
//                             <option value="4">4 Year </option>
//                             <option value="5">5 Year </option>
//                             <option value="6">6 Year </option>
//                             <option value="7">7 Year </option>
//                             <option value='8'>8 Year</option>
//                             <option valur="9">9 Year </option>
//                             <option value="11">10 Year</option>
//                             <option value="11">11 Year</option>
//                             <option value="12" >12 Year </option>
//                             <option value='12+' >12+ Year </option>
//                           </select>
//                         </div>


//                       </div>


//                       <div class="teaching_show">
//                         <h3>Add Rate Per Hour</h3>
//                         {/* {this.state.subcatData.map((items)=>{ */}
//                         {/* {this.state.sessinoRateList.length > 0 ?
//                           <>
//                             {this.state.sessinoRateList.map((data) => {
//                               return this.state.subject2.map((data23) => {

//                                 if (data23.id == data.tutor_sub_category_id) {
//                                   return (
//                                     <>
//                                       <h3>{data.sub_category_name}111</h3>
//                                       <div class="rate_inputt teaching_edit">


                                    
//                                         <label>Rate (in $)</label>
//                                         {this.state.rateData.length == 0 ?
//                                         <>
//                                          <input type='text'
                                         
//                                          onChange={(e) => { this.handleRateChange(e, data.tutor_sub_category_id) }}
//                                          maxLength='3'
//                                          placeholder="$"
//                                          defaultValue={data.one_hours}

//                                        ></input>
//                                         </>
//                                         :

//                                         <>
//                                           {this.state.experiaceData.map((data, indexe) => {
//                                             return this.state.rateData.map((rdata, rindex) => {
//                                               flag = 0
//                                               if (data.id == rdata.tutor_exp_id) {
//                                                 // if (rdata.sub_category_id == data.id && this.state.id == data.categories_id) {
//                                                   flag = 1
//                                                   return (
//                                                     <input defaultValue={this.state.subject1} onChange={(e) => { this.handleRateChange(e, data.id) }}
//                                                       type="text" maxLength='3' name="amount11" id="amount11" placeholder="$" />
//                                                   )
//                                                 // }
//                                               }
//                                             })
//                                           })}
//                                           {
//                                             flag == 0 ?
//                                               <input defaultValue={this.state.subject1} maxLength='3' onChange={(e) => { this.handleRateChange(e, data.id) }} type="text" name="amount11" id="amount11" placeholder="$ " />


//                                               : null}

//                                         </>
//                                 }
                                       
//                                         <div class="rate_inputt teaching_edit">
//                                           <label>Your Earning (15% Platform Fees1222222)</label>
//                                           {this.state.totalPrice1.map((datat1, indext1) => {
//                                     if (datat1.tid == data.tutor_sub_category_id) {
//                                       return (

//                                         <p>$ {datat1.tprice}</p>
//                                       )
//                                     }
//                                   })}
//                                         </div>

//                                       </div>
//                                     </>
//                                   )
//                                 }
//                               })

//                             })}
//                           </>
//                           : */}
//                           {this.state.subcatData.map((showdata) => {
//                             return this.state.subject2.map((items) => {
//                               if (showdata.id == items.id) {

//                                 return (
//                                   <div class="teaching-text-box">
//                                     <h3>{showdata.sub_category_name}</h3>
//                                     <div class="rate_inputt teaching_edit">
//                                       <label>Rate (in $)</label>

//                                       {this.state.rateData.length == 0 ?
//                                         <>
//                                           <input type='text'
//                                             onChange={(e) => { this.handleRateChange(e, showdata.id) }}
//                                             maxLength='3'
//                                             placeholder="$"

//                                           ></input>

//                                         </>
//                                         :
//                                         <>
//                                           {this.state.experiaceData.map((data, indexe) => {
//                                             return this.state.rateData.map((rdata, rindex) => {
//                                               flag = 0
//                                               if (data.id == rdata.tutor_exp_id) {
//                                                 if (rdata.sub_category_id == showdata.id && this.state.id == data.categories_id) {
//                                                   flag = 1
//                                                   return (
//                                                     <input defaultValue={this.state.subject1} onChange={(e) => { this.handleRateChange(e, showdata.id) }}
//                                                       type="text" maxLength='3' name="amount11" id="amount11" placeholder="$" />
//                                                   )
//                                                 }
//                                               }
//                                             })
//                                           })}
//                                           {
//                                             flag == 0 ?
//                                               <input defaultValue={this.state.subject1} maxLength='3' onChange={(e) => { this.handleRateChange(e, showdata.id) }} type="text" name="amount11" id="amount11" placeholder="$ " />


//                                               : null}

//                                         </>


//                                       }
//                                     </div>

//                                     <div class="rate_inputt teaching_edit">
//                                       <label>Your Earning (15% Platform Fees2222222222)</label>
//                                       {this.state.totalPrice1.map((datat1, indext1) => {
//                                         if (datat1.tid == showdata.id) {
//                                           return (

//                                             <p>$ {datat1.tprice}</p>
//                                           )
//                                         }
//                                       })}
//                                     </div>

//                                   </div>
//                                 )
//                               }
//                             })
//                           })

//                         }


//                       </div>
//                     </>
//                      :
//                    <p>You can add your skills that you want to provide as services and add per hour pricing rate for each service.</p>}

//                   <div class="text-center all_popups_buttonss my-3">
//                     <button class="cancel_bb btn btn-primary border_radius_20 mx-2" onClick={this.handleBackButton}>Cancel</button>
//                     {this.state.HomeTutionData.length > 0 ?
//                     // handleModal90
//                       <button class="submit_bb btn btn-primary border_radius_20 mx-2" onClick={()=>this.handleModal90()}> Save</button>
//                       :
//                       <button class="submit_bb btn btn-primary border_radius_20 mx-2" onClick={() => { alert('Please Fill rating') }}> Save</button>
//                     }
//                   </div>

//                 </div>
//                 <Modal show={this.state.show} onHide={() => this.handleModal()}>
//                   <Modal.Header className="allmodal_header modal-header" ><h2>Learning Skills</h2></Modal.Header>
//                   <Modal.Body className="all_popupModal">
//                     <div class="row">
//                       <div class="col-md-12">

//                         <div class="learning_skills_modal">
//                           <form>


//                             {this.state.subcatData.map((subcategories, indexe) => {
//                               if(this.state.subject2.length>0){

//                               return (
//                                 <>
//                                   {this.state.subject2.map((data, rindex) => {

//                                     if (subcategories.id == data.id) {
//                                       cid = subcategories.id
//                                       return (
//                                         <>
//                                           <div class="form-group">
//                                             <input type="checkbox" id={subcategories.id}
//                                               onChange={(e) => this.handleChangeId1(data.id, e)}
//                                               defaultChecked={this.state.isChecked}
//                                                />
//                                             <label for={data.id}>{subcategories.sub_category_name}</label>
//                                           </div>
//                                         </>)


//                                     }
//                                   })}
//                                   {cid == subcategories.id ?
//                                     null
//                                     :

//                                     <div class="form-group">
//                                       <input type="checkbox" id={subcategories.id}
//                                         onChange={(e) => this.handleChangeId(subcategories.id, e)}
//                                         value={this.state.isselectB}
//                                       />
//                                       <label for={subcategories.id}>{subcategories.sub_category_name}</label>
//                                     </div>

//                                   }
//                                 </>
//                               )
//                                 }
//                                 else{
//                                   return(
//                                   <div class="form-group">
//                                   <input type="checkbox" id={subcategories.id}
//                                     onChange={(e) => this.handleChangeId(subcategories.id, e)}
//                                     value={this.state.isselectB}
//                                   />
//                                   <label for={subcategories.id}>{subcategories.sub_category_name}</label>
//                                 </div>
//                                   )


//                                 }
//                             })
//                             }



//                           </form>
//                         </div>
//                       </div>
//                     </div>
//                   </Modal.Body>
//                   <Modal.Footer className="text-center all_popups_buttonss custom_modal_footer mb-4">
//                     {this.state.subject1.length > 0 ?
//                       <Button className="submit_bb" onClick={() => this.handleModal(this.state.subject1)}>Submit</Button>
//                       :
//                       <Button className="submit_bb" onClick={() => alert('Please Select Your Leaning Skills')}>Submit</Button>
//                     }
//                     <Button onClick={() => this.cancelModal()} className="submit_bb">Cancel</Button>

//                   </Modal.Footer>
//                 </Modal>


//                 <Modal show={this.state.show90} onHide={() => this.handleModal90()}>

// <Modal.Header className="allmodal_header modal-header" >
//   <h3>Confirmation Message</h3>
//       <h6>SuccessFully Save ..</h6>
//                   </Modal.Header>
//                   <Modal.Body>

            
              
              
            
//             </Modal.Body>
//             <Modal.Footer className="text-center all_popups_buttonss custom_modal_footer">
//             <Button   onClick={()=>this.handleLoadMore()}  class="submit_bb" type="submit">Load More</Button>
//                 <Button onClick={()=>{this.handleNextButton()}} class="cancel_bb" type="submit">Next</Button>
//               </Modal.Footer>
//             </Modal>
         
//               </div>
//             </div>
//           </div>
//         </section>
//         <Footer></Footer>
//       </main>);
//   }
// }

// export default TeachingSkills;



import React from 'react';
import Header from "../common/header";
import Footer from "../common/footer";
import axios from 'axios';
import { API_BASE_URL, BASE_URL } from '../../config/api.js';
import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal } from "react-bootstrap";

var cid = ''
var subject = ''
class TeachingSkills extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      store_values: false,
      show: "",
      year: '',
      catid: '',
      subcatData: [],
      subject1: [],
      rateData: [],
      experiaceData: [],
      HomeTutionData: [],
      monthData: '',
      totalPrice1: [],
      totalPrice2: [],
      totalPrice3: [],
      subject2: [],
      id: this.props.match.params.id,
      sessinoRateList: [],
      isselectB:false,
      show90:''
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeId = this.handleChangeId.bind(this);
    this.handleRateChange = this.handleRateChange.bind(this);
    this.handleData = this.handleData.bind(this);
    this.handleBackButton = this.handleBackButton.bind(this);
  }

  handleModal() {
    this.setState({ subject2: this.state.subject1 })
    this.setState({ show: !this.state.show })
  }

  cancelModal = () => {
    this.setState({ subject1: [] })
    this.setState({ show: !this.state.show })
    this.setState({subject2:[]})
  }



  handleChange(evt) {

    this.setState({ [evt.target.name]: evt.target.value });
    this.setState({ year: evt.target.value })


  }


  handleChangeId1(id){
    this.state.subject2.map((data, index) => {
      if (data.id == id) {
    var values = this.state.subject2.findIndex(obj => obj.id === id)//1

    console.log('index value of : ', this.state.subject2.findIndex(obj => obj.id === id))
    this.state.subject2.splice(values, 1);
      }})
  }

  handleChangeId(id, name) {


    let isChecked = name.target.checked;
    this.setState({ isChecked: name.target.checked })



    const subject = { 'id': id, 'name': name.target.checked }

    this.setState({ store_values: subject })

    let s1 = 0;

    if (this.state.subject1.length > 0) {

      this.state.subject1.map((data, index) => {

        if (data.id == id ) {
          s1 = 1;

          var checked = this.state.subject1;

          var values = checked.indexOf(index)
          checked.splice(values, 1);
          // this.setState({ subject1: checked });

        }
      })
    }
    if (s1 == 0) {

      this.setState(
        {
          subject1: [...this.state.subject1, subject],
        })
    }


  }



  handleBackButton() {
    this.props.history.push('/edu_list');

  }

  handleData(price, id) {

    // On Demand Section ////
    if (isNaN(price)) {



    } else {
      const persent = price * 0.15
      const totalAmount = price - persent

      const item1 =
        { tid: id, tprice: totalAmount }

      let s1 = 0;

      this.state.totalPrice1.map((data, index) => {

        if (id == data.tid) {
          s1 = 1;

          data.tprice = totalAmount
        }
      })
      if (s1 == 0) {
        this.setState({ totalPrice1: [...this.state.totalPrice1, item1] })
      }
    }



  }



  handleRateChange(event, id) {
    this.handleData(event.target.value, id)

    if (event.target.value == '0' || event.target.value == '00' || event.target.value == '000') {
      alert("Invalid Rate", 'Please Enter Non-Zero Price.');

    }
    if (event.target.value.indexOf('.') !== -1) {
      alert("Error", 'Invalid Rate');

    }

    const price = event.target.value
    const item =
      { price: price, sub_category_id: id }
    this.setState({ newlistData: [item] })


    let s1 = 0;
    if (this.state.HomeTutionData.length > 0) {

      this.state.HomeTutionData.map((data, index) => {
        if (data.sub_category_id == id) {

          s1 = 1;
          data.price = price
        }
      })
    }

    if (s1 == 0) {

      this.setState(

        {
          HomeTutionData: [...this.state.HomeTutionData, item],
        })
    }




  }


  componentDidMount() {



    axios({
      method: 'get',
      url: API_BASE_URL + `sub_category_list/?category_id=` + this.state.id,

    }).then(response => {



      this.setState({ subcatData: response.data.result })
    }).catch((error) => {
      console.log(error)
    });



    const UserToken = localStorage.getItem('UserToken');
    if (UserToken != '') {
      axios({
        method: 'GET',

        url: API_BASE_URL + "tutor_session_rate_tutorside/",
        headers: {
          'Authorization': 'Bearer ' + UserToken
        },
      }).then(response => {
        response.data.subject_list.map((data) => {
          console.log('da?????',data)
          var sub1 = []
          var sub2 = []
          this.setState({ sessinoRateList: "" })
          let year = ""

            this.setState({ sessinoRateList: response.data.subject_list })
            console.log('session',this.state.sessinoRateList)
            this.state.sessinoRateList.map((session)=>{
              let data=session.one_hours
              console.log('data,,,,,',data)
              if (this.state.id == session.tutor_category_id) {
              // this.setState({one_hours:this.state.o})
              }

            })
            
            // var item = {}
            // item =
            //   { price: data.one_hours, sub_category_id: data.tutor_sub_category_id }
            // console.log("items", item)
            // this.state.HomeTutionData.push(item)
            // let value = { 'id': data.tutor_sub_category_id, 'name': data.sub_category_name }
            // sub1.push(value)

            // sub2.push(value)
            // year = data.experience
            // console.log('dddd..>>>',sub2)
          // }

          this.setState({ subject1: sub1 })
          this.setState({ subject2: sub2 })
          this.setState({ year: year })

        })
      })
  
    }}


  handleSubmit = (id, event) => {
   


    if(!this.state.year){
      alert("Please Select Your Experience")
    }
if(!this.state.HomeTutionData){
  alert("Please Select Leaning skill")
}
    const sendData =

    {
      "items": [

        {
          'form_status': 'Form-3',
          "category": this.state.id,
          "experiace": this.state.year,

          "rate": this.state.HomeTutionData

        }
      ]


    }

    const UserToken = localStorage.getItem('UserToken');
    if (UserToken != '') {

      const jsonData = JSON.stringify(sendData)


      axios({
        method: 'post',
        url: API_BASE_URL + "add_tutor_session_rate/",
        headers: {
          'Authorization': "Bearer " + UserToken,
          'Content-Type': 'application/json'
        },
        data: jsonData,
      })


        .then(res => {



          if (res.data.success === 201) {
            if (res.data.type === "user_exist_error") {

              this.setState({ resMessageUsername: true })

            }
          }

          if (res.data.success === 200) {
            // this.props.history.push("/about_you");
            localStorage.setItem('ButtonShow',1)
          }

        }).catch((error) => {
          console.log(error)
        })
    }
  }


  handleModal90=()=>{
    if(this.state.year==0){
      alert("Please Enter Experience.")
    }
    else if(this.state.HomeTutionData.length==0){
      alert("Please Enter  Your Rate.")

    }
    else {
    this.setState({show90:!this.state.show90})
    }
  }

  handleLoadMore=()=>{
    this.handleSubmit()
    this.props.history.push("/edu_list");
  }

handleNextButton=()=>{
 
  this.handleSubmit()
  this.props.history.push("/about_you");
}
  

  render() {
    let flag = 0

    // const { paramCatId } = this.props.location

    // this.state.catid = paramCatId

    return (
      <main>
        <Header></Header>
        <section className="login_form mx-auto margin_top">
          <div className="container-fluid border-0 width_custom">
            <div className="row">
              <div className="col-md-7 col-sm-12 bg_banner_login"></div>
              <div className="col-md-5 col-sm-12 m-auto">

                <div className="card2 border-0 my-3 loginpage">
                  <div className="text-center">
                    <h2>Teaching Skills Details</h2>
                  </div>
                  <div class="teaching-text-box teaching_edit" onClick={() => this.handleModal(this.state.subject1)}>
                    {this.state.subject2.length == 0 ?
                      <p>Choose Your Selected Area</p>
                      : <p>
                        {this.state.subject2.map((data12, index) => {
                          console.log('data12>>>',data12)

                          return this.state.subcatData.map((datanew) => {
                                  console.log("datamew",datanew)
                            if (datanew.id == data12.id) {
                              
                              if (index == this.state.subject1.length - 1) {
                                return (
                                  <>{datanew.sub_category_name}</>
                                )
                              } else {
                                return (
                                  <>{datanew.sub_category_name} </>
                                )
                              }
                            }
                          })
                        })}
                      </p>}
                    <FontAwesomeIcon icon={faPencilAlt}></FontAwesomeIcon>
                  </div>
                  {this.state.subject1.length > 0 ?
                    <>
                      <div class="teaching_show">
                        <h3>My Experience</h3>


                        <div class="teaching-text-box">

                          <select onChange={this.handleChange}   >
                            {this.state.year?
                            <optipn>{this.state.year}</optipn>
                            :
                            <option>Select Year</option>}
                            <option value="0">0 Year</option>
                            <option value="1">1 Year </option>
                            <option value="2">2  Year </option>
                            <option value="3">3 Year </option>
                            <option value="4">4 Year </option>
                            <option value="5">5 Year </option>
                            <option value="6">6 Year </option>
                            <option value="7">7 Year </option>
                            <option value='8'>8 Year</option>
                            <option valur="9">9 Year </option>
                            <option value="11">10 Year</option>
                            <option value="11">11 Year</option>
                            <option value="12" >12 Year </option>
                            <option value='12+' >12+ Year </option>
                          </select>
                        </div>


                      </div>


                      <div class="teaching_show">
                        <h3>Add Rate Per Hour</h3>
                    
                          
                          {this.state.subcatData.map((showdata) => {
                            console.log('show',showdata)
                            return this.state.subject2.map((items) => {
                              if (showdata.id == items.id) {

                                return (
                                  <div class="teaching-text-box">
                                    <h3>{showdata.sub_category_name}</h3>
                                    <div class="rate_inputt teaching_edit">
                                      <label>Rate (in $)</label>

                                      {this.state.rateData.length == 0 ?
                                        <>
                                          <input type='text'
                                            onChange={(e) => { this.handleRateChange(e, showdata.id) }}
                                            maxLength='3'
                                            placeholder="$"
                                            // defaultValue={}

                                          ></input>

                                        </>
                                        :
                                        <>
                                          {this.state.experiaceData.map((data, indexe) => {
                                            return this.state.rateData.map((rdata, rindex) => {
                                              flag = 0
                                              if (data.id == rdata.tutor_exp_id) {
                                                if (rdata.sub_category_id == showdata.id && this.state.id == data.categories_id) {
                                                  flag = 1
                                                  return (
                                                    <input defaultValue={this.state.subject1} onChange={(e) => { this.handleRateChange(e, showdata.id) }}
                                                      type="text" maxLength='3' name="amount11" id="amount11" placeholder="$" />
                                                  )
                                                }
                                              }
                                            })
                                          })}
                                          {
                                            flag == 0 ?
                                              <input defaultValue={this.state.subject1} maxLength='3' onChange={(e) => { this.handleRateChange(e, showdata.id) }} type="text" name="amount11" id="amount11" placeholder="$ " />


                                              : null}

                                        </>


                                      }
                                    </div>

                                    <div class="rate_inputt teaching_edit">
                                      <label>Your Earning (15% Platform Fees)</label>
                                      {this.state.totalPrice1.map((datat1, indext1) => {
                                        if (datat1.tid == showdata.id) {
                                          return (

                                            <p>$ {datat1.tprice}</p>
                                          )
                                        }
                                      })}
                                    </div>

                                  </div>
                                )
                              }
                            })
                          })

                        }


                      </div>
                    </>
                     :
                   <p>You can add your skills that you want to provide as services and add per hour pricing rate for each service.</p>}

                  <div class="text-center all_popups_buttonss my-3">
                    <button class="cancel_bb btn btn-primary border_radius_20 mx-2" onClick={this.handleBackButton}>Cancel</button>
                    {this.state.subject1.length > 0 ?
                    // handleModal90
                      <button class="submit_bb btn btn-primary border_radius_20 mx-2" onClick={()=>this.handleModal90()}> Save</button>
                      :
                      <button class="submit_bb btn btn-primary border_radius_20 mx-2" onClick={() => { alert('Please Select Your Leaning Skills') }}> Save</button>
                    }
                  </div>

                </div>
                <Modal show={this.state.show} onHide={() => this.handleModal()}>
                  <Modal.Header className="allmodal_header modal-header" ><h2>Learning Skills</h2></Modal.Header>
                  <Modal.Body className="all_popupModal">
                    <div class="row">
                      <div class="col-md-12">

                        <div class="learning_skills_modal">
                          <form>


                            {this.state.subcatData.map((subcategories, indexe) => {
                              if(this.state.subject2.length>0){

                              return (
                                <>
                                  {this.state.subject2.map((data, rindex) => {

                                    if (subcategories.id == data.id) {
                                      cid = subcategories.id
                                      return (
                                        <>
                                          <div class="form-group">
                                            <input type="checkbox" id={subcategories.id}
                                              onChange={(e) => this.handleChangeId1(data.id, e)}
                                              defaultChecked={this.state.isChecked}
                                               />
                                            <label for={data.id}>{subcategories.sub_category_name}</label>
                                          </div>
                                        </>)


                                    }
                                  })}
                                  {cid == subcategories.id ?
                                    null
                                    :

                                    <div class="form-group">
                                      <input type="checkbox" id={subcategories.id}
                                        onChange={(e) => this.handleChangeId(subcategories.id, e)}
                                        value={this.state.isselectB}
                                      />
                                      <label for={subcategories.id}>{subcategories.sub_category_name}</label>
                                    </div>

                                  }
                                </>
                              )
                                }
                                else{
                                  return(
                                  <div class="form-group">
                                  <input type="checkbox" id={subcategories.id}
                                    onChange={(e) => this.handleChangeId(subcategories.id, e)}
                                    value={this.state.isselectB}
                                  />
                                  <label for={subcategories.id}>{subcategories.sub_category_name}</label>
                                </div>
                                  )


                                }
                            })
                            }



                          </form>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="text-center all_popups_buttonss custom_modal_footer mb-4">
                    {this.state.subject1.length > 0 ?
                      <Button className="submit_bb" onClick={() => this.handleModal(this.state.subject1)}>Submit</Button>
                      :
                      <Button className="submit_bb" onClick={() => alert('Please Select Your Leaning Skills')}>Submit</Button>
                    }
                    <Button onClick={() => this.cancelModal()} className="submit_bb">Cancel</Button>

                  </Modal.Footer>
                </Modal>


                <Modal show={this.state.show90} onHide={() => this.handleModal90()}>

<Modal.Header className="allmodal_header modal-header" >
  {/* <h3>Confirmation Message</h3> */}
      <h6 className="sucesss_message">SuccessFully Save ..</h6>
                  </Modal.Header>
                  <Modal.Body>

            
              
              
            
            </Modal.Body>
            <Modal.Footer className="text-center all_popups_buttonss custom_modal_footer">
            <Button   onClick={()=>this.handleLoadMore()}  class="submit_bb" type="submit">Load More</Button>
                <Button onClick={()=>{this.handleNextButton()}} class="cancel_bb" type="submit">Next</Button>
              </Modal.Footer>
            </Modal>
         
              </div>
            </div>
          </div>
        </section>
        <Footer></Footer>
      </main>);
  }
}

export default TeachingSkills;