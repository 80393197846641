// import React from 'react';
// import axios from 'axios'
// import Header from '../common/header';
// import Footer from '../common/footer';
// import defaultMaleImg from '../../assets/img/default_male.png';
// import defaultFemaleImg from '../../assets/img/default_female.png';
// import shortlist1 from '../../assets/img/dashboard_icons/shortlist.png';
// import { API_BASE_URL, BASE_URL } from '../../config/api.js'
// import SideBar from '../user/sideBar';
// import chat_iconn from "../../assets/img/dashboard_icons/chat_icon.png";
// import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
// import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
// import StarRatings from '../../../node_modules/react-star-ratings';
// import { Link } from "react-router-dom";

// export default class Favlist extends React.Component {
//     constructor(props) {

//     super(props)
//     this.state = {
//         favoriteList: [],
//         tutor_cat_subcat_data: []
//     }

//     this.handleChange5 = this.handleChange5.bind(this);
//     this.handleClickFavCard = this.handleClickFavCard.bind(this);
//     this.onChatHandle = this.onChatHandle.bind(this);  
//     }

//     componentDidMount() {
//         const ID = JSON.stringify(this.state.val)
//         var UserToken = localStorage.getItem('UserToken');
//         this.handleClickFavCard();
//     }

//     handleClickFavCard(){
//         // alert("working");

//         var  UserToken = localStorage.getItem('UserToken');

//         axios({
//             method: 'get',
//             url: API_BASE_URL + 'student_favaurite_list/',
//             headers: { 'Authorization': "Bearer " + UserToken },
          
//         }).then(response => {
//             console.log("shdfuhsg",response.data)
//             this.setState({ favoriteList: response.data.favourite_data })
//             this.setState({ tutor_cat_subcat_data: response.data.tutor_cat_subcat_data })
//         })
//             .catch((error) => {
//                // console.log(error)
//             });
//     }

// handleChange5(id) {

//     // alert(id)

//      const UserToken = localStorage.getItem('UserToken');
//      if (UserToken != '') {

//          axios({
//              method: 'post',
//              url: API_BASE_URL + 'favourite/',
//              //  headers:{'Authorization' : "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjE0MDU4OTgyLCJqdGkiOiI4NWQ4OGNlNzI4OWM0YWQ0YjQ4NDI5NDJmZjRjNmJmOSIsInVzZXJfaWQiOjcyOX0.qCV92t6zM-cDgTlnz5MmF31MuHm9kvNOjLCW4CZlTWI"},
//              headers: { 'Authorization': "Bearer " + UserToken },
//              data: {
//                  tutor_id: id
//              }

//          }).then(response => {
//             this.handleClickFavCard();
//          })
//         .catch((error) => {
//             // console.log(error)
//         });
//      }
//  }

//  onChatHandle(tutorId){
//     this.props.history.push({ 
//         pathname: '/conversations',
//         state: { id: tutorId }
//     });
// }

// onViewProfile(id){
       
//     this.props.history.push({ 
//         pathname: '/tutor_profile',
//         state: { id: id }
//      });
// }

   
// render() {
// const favoriteListData =
// this.state.favoriteList.map((Data) => {
// return (

// <div class="col-md-6 my-3">
// <div class="listing_boxx">
//     <div class="listing_user_img">
        
//     {Data.image == null ?
//         Data.gender == "female" ?
//         <img src={defaultFemaleImg} alt="" />
//         :<img src={defaultMaleImg} alt="" />
//         : <img src={BASE_URL + Data.image} alt="" />

//     }
//     </div>
//     <div class="tutor_detail_sec">

//     <div class="chat_favrt">

//         <h5>{Data.front_id}
//             {Data.rating!=''?
//             <StarRatings
//                 rating={Number(Data.rating)}
//                 starRatedColor="#ffc107"
//                 starDimension="18px"
//                 starSpacing="0px"
//             />
//             :<span>(New User)</span>}
//         </h5>
//         <div class="user_list_icons">
//             <div className="chat_favrt2 mr-2" onClick={() => this.onChatHandle(Data.user)}>
//                 <img src={chat_iconn} alt="" />
//             </div>
//             <div value={Data.user} onClick={() => this.handleChange5(Data.user)} className="chat_favrt2">
//                 <img src={shortlist1} alt="" />
//             </div>
//         </div>
//     </div>
        
//         {Data.online_status == 1 ?
//             <span><div className="online_status"></div>ONLINE</span>
//             :
//             <span><div className="offline_status"></div>OFFLINE</span>}

//         <p>
//             {this.state.tutor_cat_subcat_data.map((subcat) => {
            
//             if (Data.user == subcat.user_id){
//                 //console.log("heellllooooo",subcat.sub_category_name)
//                 return (
//                     <>
//                         {subcat.sub_category_name}
//                     </>
//                 )
//             }
            
//             })}
//         </p>

//         <div class="tutor_btnss">
//           <button class="chat_tutor" onClick={() => this.onViewProfile(Data.user)}>View Profile</button> 
//         </div>
//     </div>
// </div>
// </div>)})

// return (

// <main>
// <Header></Header>
// <section className="">
// <div className="container-fluid margin_top width_custom">
// <div className="row">

//     <div className="col-md-3 mt30">
//         <SideBar></SideBar>
//     </div>

//     <div className="col-md-9 mt30">
//         <div className="row">
//             <div className="col-md-12">
//                 <h2 className="dashboard_headings">Favorite List</h2>
//             </div>
//         </div>

//         <div className="row">
//         { this.state.favoriteList && this.state.favoriteList.length > 0 ?
//             favoriteListData
//             :
//             <div class="col-md-12 my-3">
//                 <div className="listing_boxx">
//                     <p class="no_record_pera">You have not added any Tutor in Favorite list.</p>
//                 </div>
//             </div>}
            
//         </div>
//     </div>
// </div>
// </div>
// </section>
// <Footer></Footer>
// </main>
// );
// }
// }

 


import React from 'react';
import axios from 'axios'
import Header from '../common/header';
import Footer from '../common/footer';
import defaultMaleImg from '../../assets/img/default_male.png';
import defaultFemaleImg from '../../assets/img/default_female.png';
import shortlist1 from '../../assets/img/dashboard_icons/shortlist.png';
import { API_BASE_URL, BASE_URL } from '../../config/api.js'
import SideBar from '../user/sideBar';
import chat_iconn from "../../assets/img/dashboard_icons/chat_icon.png";
import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
import StarRatings from '../../../node_modules/react-star-ratings';
import { Link } from "react-router-dom";
export default class Favlist extends React.Component {
constructor(props) {
super(props)
this.state = {
favoriteList: [],
tutor_cat_subcat_data: []
}
this.handleChange5 = this.handleChange5.bind(this);
this.handleClickFavCard = this.handleClickFavCard.bind(this);
this.onChatHandle = this.onChatHandle.bind(this);  
}
componentDidMount() {
const ID = JSON.stringify(this.state.val)
var UserToken = localStorage.getItem('UserToken');
this.handleClickFavCard();
}
handleClickFavCard(){
// alert("working");
var  UserToken = localStorage.getItem('UserToken');
axios({
method: 'get',
url: API_BASE_URL + 'student_favaurite_list/',
headers: { 'Authorization': "Bearer " + UserToken },
}).then(response => {
console.log("shdfuhsg",response.data)
this.setState({ favoriteList: response.data.favourite_data })
this.setState({ tutor_cat_subcat_data: response.data.tutor_cat_subcat_data })
})
.catch((error) => {
// console.log(error)
});
}
handleChange5(id) {
// alert(id)
const UserToken = localStorage.getItem('UserToken');
if (UserToken != '') {
axios({
method: 'post',
url: API_BASE_URL + 'favourite/',
//  headers:{'Authorization' : "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjE0MDU4OTgyLCJqdGkiOiI4NWQ4OGNlNzI4OWM0YWQ0YjQ4NDI5NDJmZjRjNmJmOSIsInVzZXJfaWQiOjcyOX0.qCV92t6zM-cDgTlnz5MmF31MuHm9kvNOjLCW4CZlTWI"},
headers: { 'Authorization': "Bearer " + UserToken },
data: {
tutor_id: id
}
}).then(response => {
this.handleClickFavCard();
})
.catch((error) => {
// console.log(error)
});
}
}
onChatHandle(tutorId){
this.props.history.push({ 
pathname: '/conversations',
state: { id: tutorId }
});
}
onViewProfile(id){
this.props.history.push({ 
pathname: '/tutor_profile',
state: { id: id }
});
}


onBookNow=()=>{
   this.props.history.push('/dashboard')
}
render() {
const favoriteListData =
this.state.favoriteList.map((Data) => {
return (
<div class="col-md-6 my-3">
   <div class="listing_boxx">
      <div class="listing_user_img">
         {Data.image == null ?
         Data.gender == "female" ?
         <img src={defaultFemaleImg} alt="" />
         :<img src={defaultMaleImg} alt="" />
         : <img src={BASE_URL + Data.image} alt="" />
         }
      </div>
      <div class="tutor_detail_sec">
         <div class="chat_favrt">
            <h5>
               {Data.front_id}
               {Data.rating!=''?
               <StarRatings
                  rating={Number(Data.rating)}
                  starRatedColor="#ffc107"
                  starDimension="18px"
                  starSpacing="0px"
                  />
               :<span>(New User)</span>}
            </h5>
            <div class="user_list_icons">
               <div className="chat_favrt2 mr-2" onClick={() => this.onChatHandle(Data.user)}>
                  <img src={chat_iconn} alt="" />
               </div>
               <div value={Data.user} onClick={() => this.handleChange5(Data.user)} className="chat_favrt2">
                  <img src={shortlist1} alt="" />
               </div>
            </div>
         </div>
         <div className="postal_status fav_status">
            {Data.online_status == 1 ?
            <span>
                <div className="online_status"></div>
                ONLINE
            </span>
            :
            <span>
                <div className="offline_status"></div>
                OFFLINE
            </span>
            }
            <p>12457</p>
         </div>
         <p className="fav_subject">
            {this.state.tutor_cat_subcat_data.map((subcat) => {
            if (Data.user == subcat.user_id){
            //console.log("heellllooooo",subcat.sub_category_name)
            return (
            <>
            {subcat.sub_category_name}
            </>
            )
            }
            })}
         </p>
         <div class="tutor_btnss">
            <button  onClick={() => this.onBookNow(Data.user)}  class="fav_booknow">Book Now</button> 
            <button class="chat_tutor" onClick={() => this.onViewProfile(Data.user)}>View Profile</button> 
         </div>
      </div>
   </div>
</div>
)})
return (
<main>
   <Header></Header>
   <section className="">
      <div className="container-fluid margin_top width_custom">
         <div className="row">
            <div className="col-md-3 mt30">
               <SideBar></SideBar>
            </div>
            <div className="col-md-9 mt30">
               <div className="row">
                  <div className="col-md-12">
                     <h2 className="dashboard_headings">Favorite List</h2>
                  </div>
               </div>
               <div className="row">
                  { this.state.favoriteList && this.state.favoriteList.length > 0 ?
                  favoriteListData
                  :
                  <div class="col-md-12 my-3">
                     <div className="listing_boxx">
                        <p class="no_record_pera">You have not added any Tutor in Favorite list.</p>
                     </div>
                  </div>
                  }
               </div>
            </div>
         </div>
      </div>
   </section>
   <Footer></Footer>
</main>
);
}
}
