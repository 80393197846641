import React from 'react';
import Header from "../common/header";
import Footer from "../common/footer";
import axios from 'axios';
import {API_BASE_URL} from '../../config/api.js';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";


class About_you extends React.Component {

  constructor (props) {
    super (props)
    this.state= {
      visible: false,
      about:'',
      year:'',
      ChangeTextAreaCount:0
  }

  this.handleChange=this.handleChange.bind(this);
  this.handleSubmit=this.handleSubmit.bind(this);
  // this.handleChange1=this.handleChange1.bind(this);
  }
  handleChange(e){
    // this.setState({ [event.target.id]: event.target.value });
    this.setState({about:e.target.value})
    var withoutSpace = e.target.value.replace(/ /g,"");
  console.log("space remove",withoutSpace)
    // this.setState({ChangeTextAreaCount:(e.target.value.length)});
  this.setState({ChangeTextAreaCount:withoutSpace.length})
  }
  // handleChange1(e){
  //   this.setState({year:e.target.value})

  // }

  handleSubmit=(event)=>{
 
    event.preventDefault();
    const  UserToken = localStorage.getItem('UserToken');

    if(UserToken!=''){

    this.setState({visible: true})

    axios({

      method: 'post',
      url:API_BASE_URL+"add_tutor_about/",
      headers:{'Authorization' :  "Bearer "+UserToken, 
      'Content-Type': 'application/json'}, 
      data: {
        tutor_about:this.state.about,
        // tutor_experience:this.state.year,
        form_status:'Form-4'
      },
      }).then(res => {

        this.setState({visible: false})

        if(res.data.success===200){
  
          this.props.history.push('availability');
          }
 
     }).catch((error)=>{
        console.log(error)
     })
   }
  }
  
    render() {
    // console.log("123");
    return (

    <main>
      <Header></Header>
        <section  className="login_form mx-auto margin_top">
          <div className="container-fluid border-0 width_custom">
            <div className="row">
              <div className="col-md-8 col-sm-12 bg_banner_login login_main_banner"></div>
                <div className="col-md-4 col-sm-12 m-auto">
                  <div className="card2 border-0 my-3 login_card">
                    <form className=" text-left loginpage" onSubmit={this.handleSubmit} method="post">
                        <div className="text-center" >
                          <h2>About Tutor</h2>
                        </div>

                        <div className="rate_box_content">
                       
                          <div className="form-group">

                            

                            {/* <select onChange={this.handleChange1} className="form-control select_collapse_rate_input" value={this.state.year} id="exampleFormControlSelect1">
                                  <option>1 </option>
                                  <option>2 </option>
                                  <option>3 </option>
                                  <option>4 </option>
                                  <option>5 </option>
                                </select> */}



                            {/* <textarea onChange={this.handleChange} 
                            className="form-control " 
                            rows="6" 
                            cols="50" 
                            placeholder="About Tutor"
                            maxLength="500"
                            value={this.state.about}
                            required>
                              
                            </textarea >
                            <p>{this.state.ChangeTextAreaCount}/500</p> */}

                            {/* <div className="col-md-12"> */}
                                                <div className="text-areaa ">
                                                   <textarea className="form-control" id="review" rows="6" maxLength="500" onChange={this.handleChange} cols="50" name="description" placeholder="Add Tutor" required ></textarea>
                                                   <div className="rating_count">
                                                      <p>{this.state.ChangeTextAreaCount}/500</p>
                                                   </div>
                                                </div>
                                             {/* </div> */}

                            {/* <select onChange={this.handleChange} className="form-control select_collapse_rate_input" value={this.state.year} id="exampleFormControlSelect1">
                                  <option>1 </option>
                                  <option>2 </option>
                                  <option>3 </option>
                                  <option>4 </option>
                                  <option>5 </option>
                                </select> */}
             
                          </div>

                         <Loader className="text-center"
                            visible = {this.state.visible}
                            type="Oval"
                            color="#00bacc"
                            height={40}
                            width={40}
                            timeout={3000} //3 secs
                          />
                  
                          <div className="form-group">
                              <a className="decorationNone" href="#">
                              <button className="btn btn-block send-button border_radius_20 tx-tfm" type="submit">Next</button>
                              </a>
                          </div>

                        </div>
                    </form>
                  </div>
                </div>
            </div>
        </div>
    </section>
<Footer></Footer>

    </main>
    );
  }
}

export default  About_you;


 
