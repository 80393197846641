import firebase from 'firebase'
import 'firebase/messaging';
// import {Button, Row, Col, Toast} from 'react-bootstrap';
import React from 'react'
// import { Component } from 'react';
// import { useAlert } from 'react-alert'
// import Login from '../src/components/auth/login';
import swal from 'sweetalert';

// Your web app's Firebase configuration
// var firebaseConfig = {
//   apiKey: "AIzaSyDLT4ToI9k87qsZuEWXxoctp7Wt7Ch1fUs",
//   authDomain: "want-a-teacher-311005.firebaseapp.com",
//   projectId: "want-a-teacher-311005",
//   storageBucket: "want-a-teacher-311005.appspot.com",
//   messagingSenderId: "1063192044923",
//   appId: "1:1063192044923:web:eb684da73b7fb27f4389de",
// };
// var firebaseConfig = {
//   apiKey: "AIzaSyDVEhbboW5K9miblTp5UKwxP7iI7XNIo_s",
//   authDomain: "want-a-teacher.firebaseapp.com",
//   projectId: "want-a-teacher",
//   storageBucket: "want-a-teacher.appspot.com",
//   messagingSenderId: "352223030556",
//   appId: "1:352223030556:web:dc904433bb754e41d99ed9",
//   measurementId: "G-4MQ2E4PDHB"
// };
var firebaseConfig = {
  apiKey: "AIzaSyDVEhbboW5K9miblTp5UKwxP7iI7XNIo_s",
  authDomain: "want-a-teacher.firebaseapp.com",
  projectId: "want-a-teacher",
  storageBucket: "want-a-teacher.appspot.com",
  messagingSenderId: "352223030556",
  appId: "1:352223030556:web:dc904433bb754e41d99ed9",
  measurementId: "G-4MQ2E4PDHB"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);


const messaging = firebase.messaging();

// class App extends React.Component {
// constructor(props){
//   super(props);
//   this.state={
//     alert:''

//   }
// }
// render(){


//   return (
//     <button
//       onClick={() => {
//         this.state.alert.show('Oh look, an alert!')
//       }}
//     >
//       Show Alert
//     </button>
//   )
// }
// }
// export default App;


// componentDidMount(){

// }














export const sweetAlertUpdateBooking =(title,body,payload,props)=>{

  
  console.log('.????????????????????????/',props)
  swal({
    title:title,
      text: body,
      icon: "success",
  })
  .then(() => {
    localStorage.setItem('country_code',payload.data.booking_id)
    
    if(payload.data.user_type==="0"){
      // window.location.replace("/tutorbookingdetail");
      window.open('/userbookingdetail', '_blank');
    }

    else if(payload.data.user_type==="1"){
      // window.location.replace("/userbookingdetail");
      window.open('/tutorbookingdetail', '_blank');
    }
    // 
    // localStorage.setItem('bookingstatus',payload.data.status)
    // if(payload.data.status==='waiting for payment'){
    // 
    // }

    // else{
      // if(payload.data.status==='waiting for payment'){
      //   
      // }

    // }
    // this.props.history.push("/dashboard")
    
  })
 
}





export const requestFirebaseNotificationPermission = () =>
  new Promise((resolve, reject) => {
    console.log("647444",resolve)
    messaging
      .requestPermission()
      .then(() => messaging.getToken())
      .then((firebaseToken) => {
        resolve(firebaseToken);
      })
      .catch((err) => {
        reject(err);
      });
  });

  export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      // alert('ddidididididdddd............',payload)
      resolve(payload);
    });
  });


// export default class notificationsHanlde


  
//  <Toast onClose={() => this.setState({show:false})} show={this.state.show} delay={3000} autohide animation>
//           <Toast.Header>
//             <img
//               src="holder.js/20x20?text=%20"
//               className="rounded mr-2"
//               alt=""
//             />
//             <strong className="mr-auto">{payload.data.title}</strong>
//             <small>{payload.data.body}</small>
//           </Toast.Header>
//           <Toast.Body>{payload.data.body}dshssjsdsjdjjdjsffffffjjj</Toast.Body>
//         </Toast> 
        // <Button onClick={() => this.setState({show:true})}>Show Toast</Button>
  

messaging
  .requestPermission()
  .then(() => {
    console.log("Permission granted!");
  })
  .catch(() => {
    console.log("Permission denied!");
  });




messaging.onMessage(payload => {
console.log('new notifications',payload)
// window.location.replace("/login");

// var userType = ''

// if (payload.data.user_type == "1"){

//   userType = 'tutor'
// }else{
//   userType = 'user'
// }
sweetAlertUpdateBooking(payload.notification.title,payload.notification.body,payload)

  
});


  export const getToken = (setTokenFound) => {
    console.log("oppppppp",setTokenFound)
    return messaging.getToken({vapidKey: 'BFHdJVqtAg6uSQvDTrpjZHCmK7VcoAHjdWa8eF1NFmZzWxMhxNlG8L8iiHvp296ii0Aj3J0jV5yqig5OwclgIl8'}).then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        // setTokenFound(true);
        // this.state.isTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.');
        // this.state.isTokenFound(false);
        // shows on the UI that permission is required 
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
  }
export default firebase