// import React from 'react';
// import Header from '../common/header';
// import Footer from '../common/footer';
// import axios from 'axios';
// import moment from 'moment';
// import 'moment-timezone'
// import StarRatings from 'react-star-ratings';
// import ReactStars from "react-rating-stars-component";
// import defaultMaleImg from '../../assets/img/default_male.png';
// import defaultFemaleImg from '../../assets/img/default_female.png';
// import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
// import visacardImg from '../../assets/img/categories/visa.png';
// import { API_BASE_URL, BASE_URL, API_IMAGE_URL_MEDIA } from '../../config/api.js'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faAngleRight, faCalendar, faCreditCard, faEdit, faPen, faPlus, faStar, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
// import { Button, Modal } from "react-bootstrap";
// import SideBar from "./sideBar.js";
// import { Link } from "react-router-dom";
// import swal from 'sweetalert';
// var value = ''
// var FilterMornigResult = ''
// var FilterAfternoonResult = ''
// var FilterEveningResult = ''
// var pickdata = '';
// var pickdatamorning='';
// var pickdataevening=''
// const days = [
// { label: "S", value: "sunday" },
// { label: "M", value: "monday" },
// { label: "T", value: "tuesday" },
// { label: "W", value: "wednesday" },
// { label: "T", value: "thursday" },
// { label: "F", value: "friday" },
// { label: "S", value: "saturday" },
// ]
// var DaysM = []
// var DaysA = []
// var DaysE = []
// var result = []
// var result1 = []
// var result2 = []
// var UserToken = ''
// export default class UserBookingdetail extends React.Component {
// constructor(props) {
// super(props)
// this.state = {
// bookingdetailList: [],
// MessageOfferPriceAlreadyExist: '',
// MessageOfferPriceAdded: '',
// offerlist: [],
// student_rating: '',
// tutor_rating: '',
// myidvalue: '',
// preDateTime: [],
// ChangeTextAreaCount:0,
// accptedid: '',
// description:'',
// value1: '',
// MessagePaymentAdded: '',
// MessagePaymentalreadyAdded: '',
// MessageBookingComplete: '',
// Messageofferprice: '',
// bookingdetailList: [],
// MessageOfferPriceAlreadyExist: '',
// MessageOfferPriceAdded: '',
// tutor_rating: '',
// myidvalue: '',
// rating: '',
// datalist: [],
// MessageEditDetails: '',
// stripeListDetails: [],
// customerid: '',
// payementid: '',
// tutrocardid: '',
// DefaultMessage: "",
// rate_list: [],
// student_review_rating: [],
// }
// this.handleChange = this.handleChange.bind(this);
// this.handleAccepted = this.handleAccepted.bind(this);
// this.handleRejected = this.handleRejected.bind(this);
// this.ratingshow = this.ratingshow.bind(this);
// this.handleComplete = this.handleComplete.bind(this);
// this.BookingDetail = this.BookingDetail.bind(this);
// this.RatingReviewChange = this.RatingReviewChange.bind(this);
// this.proposed_schedule = this.proposed_schedule.bind(this);
// this.handleModalAddCard = this.handleModalAddCard.bind(this);
// this.handleDescription=this.handleDescription.bind(this);
// this.ReportDetails=this.ReportDetails.bind(this);
// this.sweetAlertReportDetails=this.sweetAlertReportDetails.bind(this);
// this.handlePayment=this.handlePayment.bind(this);
// this.handleId=this.handleId.bind(this);
// }
// handlePayment(e){
// e.preventDefault()
// this.handleModalAddCard()
// }
// sweetAlertBookingSuccess = () => {
// swal({
// text: "Payment is Successfull",
// icon: "success",
// })
// }

// handleId(bookingId,categoryId,subcategoryId){
//    this.props.history.push({ 
//        pathname: '/editbooking',
//        state: { 
//        bookingId: bookingId,
//       subcategoryId:subcategoryId,
//       categoryId:categoryId}
//     });
// }
// sweetAlertBookingAlreadyCompleted = () => {
// swal({
// text: "Booking is already completed",
// icon: "warning",
// dangerMode: true,
// })
// }
// sweetAlertBookingCompleted = () => {
// swal({
// text: "Booking completed Successfully",
// icon: "success",
// })
// }
// sweetAlertCancelBooking = () =>{
// swal({
// text: "Booking cancelled",
// icon: "success",
// })
// }
// sweetAlertCardDeleted = () => {
// swal({
// text: "Card Deleted Successfully",
// icon: "success",
// })
// }
// sweetAlertRating = () => {
// swal({
// text: "Your Rating & Review submitted successfully",
// icon: "success",
// })
// }
// sweetAlertReportDetails=()=>{
// swal({
// text: "Report submitted",
// icon: "success",
// })
// }
// handleModalAddCard() {
// this.setState({ showcard: !this.state.showcard })
// }
// handleChange(e) {
// this.setState({ [e.target.name]: e.target.value });
// }
// componentDidUpdate(prevProps, nextProps) {
// const { params } = this.props.location
// value = params
// const { tutor_params_id } = this.props.location
// }
// componentDidMount() {
// this.BookingDetail()
// this.booking_rating()
// const tutor_id = localStorage.getItem("tutor_id")
// this.TutorSessionRateList(tutor_id)
// }
// BookingDetail=()=> {
// const country_code = localStorage.getItem("country_code")
// const UserToken = localStorage.getItem('UserToken');
// if (UserToken != '') {
// axios({
// method: 'post',
// url: API_BASE_URL + "booking_detail_student/",
// headers: { 'Authorization': "Bearer " + UserToken },
// data: {
// booking_id: country_code
// }
// }).then(response => {
// this.setState({
// bookingdetailList: response.data.booking_detail,
// offerlist: response.data.offerlist
// })
// var data2 = (response.data.booking_detail[0].time_schedule)
// const c = data2.replace(/'/g, '"');
// const parsedata = JSON.parse(c)
// const AfternoontimeSlot = parsedata.afternoon
// {
// AfternoontimeSlot.map((data) => {
// const pick = data.time
// return (
// pick.map((pickle) => {
  
//    // if(pickdata!=''){
//    //    pickdata = pickdata+","+pickle
//    // }else{
//    //    pickdata = pickle
//    // }
//    // console.log("11>>>>",pickdata)
// return (
// this.setState({ timervalueAfternoon: pick })
// )
// }
// )
// )
// })
// }
// console.log("after",this.state.timervalueAfternoon)
// const MorningtimeSlot = parsedata.morning
// {
// MorningtimeSlot.map((data) => {
// const pick = (data.time)
// return (
// pick.map((pickle) => {
//    // if(pickdatamorning!=''){
//    //    pickdatamorning=pickdatamorning+","+pickle
//    // }
//    // else{
//    //    pickdatamorning=pickle
//    // }
// return (
// this.setState({ timervalueMorning: pick })
// )

// })
// )
// })
// }
// console.log("mor",this.state.timervalueMorning)
// const EveningTimeSlot = parsedata.evening
// {
// EveningTimeSlot.map((data) => {
// const pick = data.time
// return (
// pick.map((pickle) => {
//    // if(pickdataevening!=''){
//    //    pickdataevening=pickdataevening+","+pickle
//    // }
//    // else{
//    //    pickdataevening=pickdataevening+","+pickle
//    // }
// console.log("pickvalue", pickle)
// return (
// this.setState({ timervalueEvening: pick })
// )
// })
// )
// })
// }
// console.log("even",this.state.timervalueEvening)
// var preDateTime = [];
// var data1 = (response.data.booking_detail[0].schedule);
// const b = data1.replace(/'/g, '"');
// const datalist = JSON.parse(b);
// const datalistvalue = datalist.date_schedule;
// const finalscheduledata=datalistvalue[0].weekday_time;
// console.log("finl",finalscheduledata)
// this.setState({ preDateTime: finalscheduledata });
// this.proposed_schedule()
// this.stripeListDetails()
// }).catch(error => {
// console.log(error)
// })
// }
// }
// proposed_schedule = () => {
// const result = this.state.preDateTime.filter(x => x.time === 'morning');
// const result1 = this.state.preDateTime.filter(x => x.time === 'afternoon');
// const result2 = this.state.preDateTime.filter(x => x.time === 'evening');
// FilterMornigResult = result
// FilterAfternoonResult = result1
// FilterEveningResult = result2
// DaysM = result.map((data) => {
// console.log("kapil",data.day)
// return (
// data.day
// )
// })
// DaysE = result2.map((data) => {
// console.log("kapil2",data.day)
// return (
// data.day
// )
// })
// DaysA = result1.map((data) => {
// console.log("kapil1",data.day)
// return (
// data.day
// )
// })
// }
// TutorSessionRateList(tutor_id) {
// UserToken = localStorage.getItem('UserToken');
// if (UserToken != '') {
// const sendData = {
// tutor_id: tutor_id
// };
// axios({
// method: 'post',
// url: API_BASE_URL + "tutor_session_rate_list/",
// headers: { 'Authorization': "Bearer " + UserToken },
// data: sendData
// }).then(response => {
// this.setState({ rate_list: response.data.rate_list })
// }).catch((error) => {
// console.log(error)
// });
// }
// }
// stripeListDetails() {
// UserToken = localStorage.getItem('UserToken');
// if (UserToken != '') {
// const sendData = {
// };
// axios({
// method: 'post',
// url: API_BASE_URL + "student_stripe_card_list/",
// headers: { 'Authorization': "Bearer " + UserToken },
// data: sendData
// }).then(response => {
// this.setState({ stripeListDetails: response.data.customer_slist.data })
// }).catch((error) => {
// console.log(error)
// });
// }
// }

// offerpriceAdded = () => {
//    swal({
//        text: "Offer accepted successfully",
//        icon: "success",
//    })
// }
// AcceptOffer = (id) => {
// this.setState({ accptedid: id })
// const val = localStorage.setItem("country_code", value);
// const country_code = localStorage.getItem("country_code")
// const UserToken = localStorage.getItem('UserToken');
// }
// handleAccepted(id,booking_id) {
// const UserToken = localStorage.getItem('UserToken');
// if (UserToken != '') {
// const sendData = {
// offer_id: id,
// booking_id: booking_id,
// status: 'waiting for payment'
// }
// axios({
// method: 'post',
// url: API_BASE_URL + "offer_action/",
// headers: { 'Authorization': "Bearer " + UserToken },
// data: sendData
// }).then(response => {
// if (response.data.success == 200) {
// if (response.data.message == "Offer accepted successfully") {
//    this.offerpriceAdded()

// }
// }
// }).catch(error => {
// console.log(error)
// })
// }
// }
// handleRejected(offerId,bookingId) {
// const UserToken = localStorage.getItem('UserToken');
// if (UserToken != '') {
// const sendData = {
// offer_id: offerId,
// booking_id: bookingId,
// status: "rejected"
// }
// axios({
// method: 'post',
// url: API_BASE_URL + "offer_action/",
// headers: { 'Authorization': "Bearer " + UserToken },
// data: sendData
// }).then(response => {
// console.log("reject", response.data)
// }).catch()
// }
// }
// handleEditOption = () => {
// this.props.history.push('/editbooking');
// }
// handleModal1() {
// this.setState({ show1: !this.state.show1 })
// }
// handleModal2() {
// this.setState({ show2: !this.state.show2 })
// }
// RatingReviewChange=(e)=> {
// this.setState({ [e.target.name]: e.target.value });
// }
// handleDescription=(e)=>{
// this.setState({ [e.target.name]: e.target.value });
// this.setState({ChangeTextAreaCount:(e.target.value.length)});
// }
// handleComplete() {
// const bookingid = localStorage.getItem("country_code")
// const UserToken = localStorage.getItem('UserToken');
// if (UserToken != '') {
// const sendData6 = {
// booking_id: bookingid,
// booking_status: 'completed'
// }
// axios({
// method: 'post',
// url: API_BASE_URL + "session_completed/",
// headers: { 'Authorization': "Bearer " + UserToken },
// data: sendData6
// }).then(response => {
// if (response.data.success == 205) {
// if (response.data.message == "Your Booking  completed successfully") {
// this.setState({ MessageBookingComplete: true })
// this.sweetAlertBookingCompleted()
// }
// }
// })
// }
// }
// booking_rating() {
// const bookingid = localStorage.getItem("country_code")
// const UserToken = localStorage.getItem('UserToken');
// if (UserToken != '') {
// axios({
// method: 'post',
// url: API_BASE_URL + 'booking_rating/',
// data: {
// booking_id: bookingid
// },
// headers: { 'Authorization': "Bearer " + UserToken },
// }).then(response => {
// if (response.data.success == 200) {
// this.setState({
// student_review_rating: response.data.review_rating_data[0]
// })
// this.BookingDetail()
// }
// })
// }
// }
// ratingshow = e => {
// e.preventDefault();
// const UserToken = localStorage.getItem('UserToken');
// if (UserToken != '') {
// const booking_id = localStorage.getItem("country_code")
// const sendData7 = new FormData();
// sendData7.append("student_review", this.state.review);
// sendData7.append("student_rating", this.state.rating);
// sendData7.append("booking_id", booking_id);
// axios({
// method: 'post',
// url: API_BASE_URL + "student_rating/",
// headers: { 'Authorization': "Bearer " + UserToken },
// data: sendData7
// }).then(response => {
// console.log("fhuuefh",response.data)
// if (response.data.success == 200) {
// this.booking_rating()
// this.handleModal1()
// this.sweetAlertRating()
// this.setState({ student_rating: response.data })
// }
// })
// }
// }
// deletepaymentid = (id, customer) => {
// this.setState({ payementid: id })
// this.setState({ customerid: customer })
// localStorage.setItem('payementid', id)
// localStorage.setItem('customerid', customer)
// const UserToken = localStorage.getItem('UserToken');
// if (UserToken != '') {
// const formData = new FormData();
// formData.append('student_stripe_id', customer);
// formData.append('student_stripe_card_id', id);
// axios({
// method: 'POST',
// url: API_BASE_URL + "delete_stripe_card/",
// headers: { 'Authorization': 'Bearer ' + UserToken },
// data: formData
// })
// .then(res => {
// console.log('delete', res.data)
// if (res.data.success == 200) {
// if (res.data.message == "Card susscessfully deleted") {
// this.sweetAlertCardDeleted();
// }
// }
// this.stripeListDetails()
// }).catch((err) => {
// console.log(err)
// })
// }
// }
// makePayment = (id, customer,bookingamount) => {
// const bookingid = localStorage.getItem("country_code")
// const UserToken = localStorage.getItem('UserToken');
// if (UserToken != '') {
// const formData = new FormData();
// formData.append('student_stripe_id', customer);
// formData.append('student_stripe_card_id', id);
// formData.append('booking_id', bookingid);
// formData.append('amount', bookingamount)
// axios({
// method: 'POST',
// url: API_BASE_URL + "make_payment/",
// headers: { 'Authorization': 'Bearer ' + UserToken },
// data: formData
// })
// .then(res => {
// if (res.data.success == 200) {
// if (res.data.payment_status == "succeeded") {
// //this.setState({ paymentCompleteMessage: true })
// this.sweetAlertBookingSuccess()
// }
// }
// else if (res.data.success == 201) {
// if (res.data.message == "booking already completed or not waiting for payment valid.") {
// //this.setState({ paymentAlreadyCompleteMessage: true })
// this.sweetAlertBookingAlreadyCompleted()
// }
// }
// }).catch((err) => {
// console.log(err)
// })
// }
// }
// BookingCancel(id) {
// const UserToken = localStorage.getItem('UserToken');
// if (UserToken != '') {
// const sendData10 = new FormData();
// sendData10.append("status", "cancelled");
// sendData10.append("booking_id", id);
// axios({
// method: 'post',
// url: API_BASE_URL + "booking_action_student/",
// headers: { 'Authorization': "Bearer " + UserToken },
// data: sendData10
// }).then(response => {
// console.log("cancel", response.data)
// if(response.data.success==201){
// this.sweetAlertCancelBooking();
// }
// })
// }
// }
// defaultCard = (id, customer) => {
// this.setState({ tutrocardid: id })
// const UserToken = localStorage.getItem('UserToken');
// if (UserToken != '') {
// const formData = new FormData();
// formData.append('student_stripe_id', customer);
// formData.append('student_stripe_card_id', id);
// axios({
// method: 'POST',
// url: API_BASE_URL + "set_default_stripe_card/",
// headers: { 'Authorization': 'Bearer ' + UserToken },
// data: formData
// })
// .then(res => {
// this.stripeListDetails()
// if (res.data.message == "Card successfully added in default") {
// this.setState({ DefaultMessage: true })
// }
// }).catch((err) => {
// console.log(err)
// })
// }
// }
// ReportDetails(event) {
// event.preventDefault()
// const UserToken = localStorage.getItem('UserToken');
// if (UserToken != '') {
// const formData = new FormData();
// formData.append('description',this.state.description);
// formData.append('report_title', "test data new");
// formData.append("report_type", "booking")
// axios({
// method: 'POST',
// url: API_BASE_URL + "add_report/",
// headers: { 'Authorization': 'Bearer ' + UserToken },
// data: formData
// }).then(res => {
// this.sweetAlertReportDetails()
// this.handleModal2()
// }).catch((err) => {
// console.log(err)
// })
// }
// }
// render() {
// const lastschedule=FilterAfternoonResult
// const lastMorningSchedule=FilterMornigResult
// const lastEveningSchedule=FilterEveningResult
// const { params } = this.props.location
// value = params
// const { tutor_params_id } = this.props.location
// this.state.value1 = tutor_params_id
// return (
// <main>
//    <Header></Header>
//    <section className="">
//       <div className="container-fluid margin_top width_custom">
//          <div className="row">
//             <div className="col-md-3 mt30">
//                <SideBar></SideBar>
//             </div>
//             <div className="col-md-9 mt30">
//                <div className="row">
//                   <div className="col-md-12">
//                      <h2 className="dashboard_headings">Booking Detail</h2>
//                   </div>
//                </div>
//                {this.state.bookingdetailList.map((booking) => {
//                return (
//                <div className="row m-0">
//                   <div className="col-md-12 box_custom upcoming_session booking_detail_seccc">
//                      <div className="row">
//                         <div className="col-lg-6 col-sm-6 col-md-6 col-6">
//                         <span>{moment(booking.dob).tz('America/Chicago').format("DD MMM YYYY")}</span>
//                            {booking.category_name != '' ?
//                            <h4>{booking.category_name}</h4>
//                            : null}
//                            {booking.sub_category_name != '' ?
//                            <p className="sub_b">Subject :  <b>{booking.sub_category_name}</b></p>
//                            : null}
//                            {booking.tuition_type != ''  && booking.tuition_type!=undefined && booking.tuition_type!="undefined"?
//                            <p className="sub_b"><b>{booking.tuition_type}</b></p>
//                            : null}
//                         </div>
//                         <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-right">
//                            {booking.booking_status == 'pending' ?
//                            <button className="pending_btn">{booking.booking_status}</button>
//                            :<button className="accepted_btn">Payment pending</button>
//                            } <br/>
//                            {booking.id != '' ?
//                            <h6 class="m-2">{booking.id}</h6>
//                            : null}
//                         </div>
//                         <div class="col-md-12">
//                            {booking.booking_status == 'pending' && booking.booking_type=='post' ? 
//                            null
//                            :
//                            <div class="row box_custom_booking margin0">
//                               <div class="col-md-6">
//                                  <div class="booking_info">
//                                     {booking.image == null ?
//                                     booking.gender == "female" ?
//                                     <img class="booking_user_img" src={defaultFemaleImg} alt="" />
//                                     :<img class="booking_user_img" src={defaultMaleImg} alt="" />
//                                     : <img class="booking_user_img" src={BASE_URL + booking.image} alt="" />
//                                     }
//                                     <div class="info_pera">
//                                        <h2>{booking.fullname}<span>(New User)</span></h2>
//                                        {booking.online_status=='1' ? 
//                                        <div class="status_div left_justify">
//                                           <div className="online_status"></div>
//                                           ONLINE
//                                        </div>
//                                        :
//                                        <div class="status_div left_justify">
//                                           <div className="offline_status"></div>
//                                           Offline
//                                        </div>
//                                        }
//                                         {booking.rating == 0 || booking.rating == null ? 
//                                           <span>(New User)</span>
//                                           :
//                                           <span>
//                                              <StarRatings
//                                                 rating={Number(booking.rating)}
//                                                 starRatedColor="#ffc107"
//                                                 starDimension="18px"
//                                                 starSpacing="0px"
//                                                 />
//                                           </span>
//                                           }
//                                     </div>
//                                  </div>
//                               </div>
//                               <div class="col-md-6 chat_set text-right">
//                                  <button className="chat_btn">
//                                     <i className="fa fa-star" aria-hidden="true">
//                                        <FontAwesomeIcon icon={faFacebookMessenger}></FontAwesomeIcon>
//                                     </i>
//                                     Chat
//                                  </button>
//                               </div>
//                            </div>
//                            }
//                            <div class="row booking_box">
                         
                           
                          
//                               <div class="col-md-12 booking_detail_info">
//                               <div  class="address_info" onClick={() => this.handleId(booking.id,booking.category_id,booking.sub_category_id)} >
//                               <span></span> <span><i className="fa fa-star" aria-hidden="true">
//                            <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon></i></span> 
//                            </div>

//                                  {booking.booking_start != '' && booking.booking_start != "undefined" && booking.booking_start != undefined ?
                
//                                  <div class="address_info">
//                                                        {/* <i class="payment_icon_color">
//                                    <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
//                                 </i> */}
//                                     <span>Start Date/Time: </span>
//                                     <span>{moment(booking.booking_start).format("DD MMM YYYY")}</span>
//                                  </div>
//                                  : null}
//                                  {booking.frequency_of_session != '' && booking.frequency_of_session != "undefined" && booking.frequency_of_session != undefined ?
//                                  <div class="address_info"><span>Frequency : </span><span>{booking.frequency_of_session}</span></div>
//                                  : null}
//                                  <div class="address_info">
//                                     <h1>Proposed Schedule : </h1>
//                                  </div>
//                                  {this.state.timervalueMorning  ?
//                                  <>
//                                  <div className="address_info">
//                                     <span>Morning :</span> <span>{this.state.timervalueMorning.join(',')}</span>
//                                  </div>
//                                  {lastMorningSchedule.length>0?
//                                  <div className="schedule_day">
//                                     {days.map((data, index1) => {
//                                     return(
//                                     <>
//                                     {DaysM.includes(data.value)  ?
//                                     <button class="active_day">{data.label}</button>
//                                     :
//                                     <button >{data.label}</button>}
//                                     </>
//                                     )})}
//                                  </div>
//                                  :null}
//                                  </>
//                                  :null}
//                                  {this.state.timervalueAfternoon ?
//                                  <>
//                                  <div className="address_info">
//                                     <span>Afternoon :</span> <span>{this.state.timervalueAfternoon.join(',')}</span>
//                                  </div>
//                                  {lastschedule.length>0?
//                                  <div className="schedule_day">
//                                     {days.map((data, index1) => {
//                                     console.log("afternoon",data)
//                                     return (
//                                     <>
//                                     {DaysA.includes(data.value) ?
//                                     <button class="active_day">{data.label}</button>
//                                     : <button >{data.label}</button>}
//                                     </>
//                                     )})}
//                                  </div>
//                                  :null}
//                                  </>
//                                  :null}
//                                  {this.state.timervalueEvening ?
//                                  <>
//                                  <div className="address_info">
//                                     <span>Evening :</span> <span>{this.state.timervalueEvening.join(',')}</span>
//                                  </div>
//                                  {lastEveningSchedule.length>0?
//                                  <div className="schedule_day">
//                                     {days.map((data, index1) => {
//                                     return (
//                                     <>
//                                     {DaysE.includes(data.value) ?
//                                     <button class="active_day">{data.label}</button>
//                                     :
//                                     <button >{data.label}</button>
//                                     }
//                                     </>
//                                     )})}
//                                  </div>
//                                  :null} 
//                                  </>
//                                  :null}
//                                  <div class="address_info">
//                                     <h1>Payment : </h1>
//                                  </div>
//                                  {booking.booking_offer_price !='' && booking.booking_offer_price!='undefined' && booking.booking_offer_price!=undefined  ?   
//                                  <div class="address_info">
//                                     {/* <span>Offer Rate : </span>
//                                     <span>${booking.booking_offer_price} Rate per Hour </span> */}
//                                     <span>Booking Rate : </span>
//                                     <span>${booking.booking_offer_price} Rate per Hour </span>
                                    
//                                     <span>Total Hours</span>
//                                     <span>{booking.total_hours} Hour</span>
//                                     <span>Total Payable Ammount</span>
//                                     <span>${booking.total_payble}</span>
//                                  </div>
//                                  : null}
//                                  <Modal show={this.state.show1} onHide={() =>
//                                     this.handleModal1()}>
//                                     <Modal.Body>
//                                        <form class="new_modal_form" onSubmit={this.ratingshow} >
//                                           <div className="row">
//                                              <div className="col-md-12 text-center rating_divv">
//                                                 <div class="listing_user_img">
//                                                    {booking.image == null ?
//                                                    booking.gender == "female" ?
//                                                    <img class="card_profile_img" src={defaultFemaleImg} />
//                                                    : <img class="card_profile_img" src={defaultMaleImg} />
//                                                    : <img class="card_profile_img" src={booking.image} />
//                                                    }
//                                                 </div>
//                                                 <h6 class="feedback-text">{booking.front_id}</h6>
//                                                 <h2>Feedback</h2>
//                                              </div>
//                                              <div className="col-md-12">
//                                                 <div class="form-group">
//                                                    <ReactStars
//                                                       className="react_star_ratee"
//                                                       count={5}
//                                                       onChange={newRating =>
//                                                    {
//                                                    this.setState({ rating: newRating })
//                                                    }}
//                                                    size={24}
//                                                    name="rating"
//                                                    isHalf={true}
//                                                    emptyIcon={<i className="far fa-star"></i>}
//                                                    halfIcon={<i className="fa fa-star-half-alt"></i>}
//                                                    fullIcon={<i className="fa fa-star"></i>}
//                                                    activeColor="#ffd700"
//                                                    />
//                                                 </div>
//                                              </div>
//                                              <div className="col-md-12">
//                                                 <div className="text-areaa px-5">
//                                                    <textarea className="form-control" id="review" rows="4" maxLength="100" onChange={this.RatingReviewChange} cols="50" name="review" placeholder="Add Review" required ></textarea>
//                                                 </div>
//                                              </div>
//                                              <div class="text-center all_popups_buttonss custom_modal_footer m-auto">
//                                                 <button class="submit_bb mt-4" type="submit"> Submit</button>
//                                              </div>
//                                           </div>
//                                        </form>
//                                     </Modal.Body>
//                                  </Modal>
//                                  <Modal show={this.state.show2} onHide={() =>
//                                     this.handleModal2()}>
//                                     <Modal.Body>
//                                        <form class="new_modal_form" onSubmit={this.ReportDetails} >
//                                           <div className="row">
//                                              <div className="col-md-12 text-center rating_divv">
//                                                 <div class="listing_user_img">
//                                                    {booking.image == null ?
//                                                    booking.gender == "female" ?
//                                                    <img class="card_profile_img" src={defaultFemaleImg} />
//                                                    : <img class="card_profile_img" src={defaultMaleImg} />
//                                                    : <img class="card_profile_img" src={booking.image} />
//                                                    }
//                                                 </div>
//                                                 <h6 class="feedback-text">{booking.front_id}</h6>
//                                                 <h2 className="rating_mob">Report Tutor</h2>
//                                              </div>
//                                              <div className="col-md-12">
//                                                 <div className="text-areaa px-5">
//                                                    <textarea className="form-control" id="review" rows="4" maxLength="500" onChange={this.handleDescription} cols="50" name="description" placeholder="Add Review" required ></textarea>
//                                                    <p>{this.state.ChangeTextAreaCount}/500</p>
//                                                 </div>
//                                              </div>
//                                              <div class="text-center all_popups_buttonss custom_modal_footer m-auto">
//                                                 <button class="submit_bb mt-4" type="submit"> Submit</button>
//                                              </div>
//                                           </div>
//                                        </form>
//                                     </Modal.Body>
//                                  </Modal>
//                               </div>
//                               <div class="col-md-12 right_btns text-right my-2">
//                                  {booking.booking_status == "waiting for payment" ?
//                                  <>
//                                  <div class="card_det">
//                                     <div class="address_info card_payment_header">
//                                        <h1>Add Card </h1>
//                                        <Link to="/payment">
//                                        <i class="payment_icon_color">
//                                           <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
//                                        </i>
//                                        </Link>
//                                     </div>
//                                     {this.state.stripeListDetails.map((carddata, index) => {
//                                     if (index == 0 && !this.state.tutrocardid) {
//                                     return (
//                                     <div class="card_payment_body">
//                                        <div class="card_data_info">
//                                           <img class="visa_image" src={visacardImg} />
//                                           <div class="visa_name_det">
//                                              <h1>{carddata.name}</h1>
//                                              <h1>xxxx-xxxx-xxxx-{carddata.last4}</h1>
//                                           </div>
//                                        </div>
//                                        <i class="payment_icon_color font-size22" onClick={() =>
//                                           this.handleModalAddCard()}>
//                                           <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
//                                        </i>
//                                     </div>
//                                     )
//                                     }
//                                     else {
//                                     if (carddata.id == this.state.tutrocardid) {
//                                     return (
//                                     <div class="card_payment_body">
//                                        <div class="card_data_info">
//                                           <img class="visa_image" src={visacardImg} />
//                                           <div class="visa_name_det">
//                                              <h1>{carddata.name}</h1>
//                                              <h1>xxxx-xxxx-xxxx-{carddata.last4}</h1>
//                                           </div>
//                                        </div>
//                                        <i class="payment_icon_color font-size22" onClick={() =>
//                                           this.handleModalAddCard()}>
//                                           <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
//                                        </i>
//                                     </div>
//                                     )
//                                     }
//                                     }
//                                     })}
//                                  </div>
//                                  <Modal show={this.state.showcard} onHide={() =>
//                                     this.handleModalAddCard()}>
//                                     <Modal.Header className="allmodal_header modal-header" closeButton>
//                                        <h2>Choose your card</h2>
//                                     </Modal.Header>
//                                     <Modal.Body>
//                                        <form class="loginpage" onSubmit={this.handlePayment}>
//                                           {this.state.stripeListDetails.map((carddata, index) => {
//                                           if (this.state.tutrocardid) {
//                                           return (
//                                           <div className="verify_input_box card_payment_body border-bottom_card">
//                                              <div class="card_payment_body2">
//                                                 <div class="card_data_info">
//                                                    <img class="visa_image" src={visacardImg} />
//                                                    <div class="visa_name_det">
//                                                       <h1>{carddata.name}</h1>
//                                                       <h1>xxxx-xxxx-xxxx-{carddata.last4}</h1>
//                                                    </div>
//                                                 </div>
//                                                 <div class="card_data_info2">
//                                                    <input onChange={() => this.defaultCard(carddata.id, carddata.customer)}
//                                                    type="radio"
//                                                    id="card1"
//                                                    name="card_type"
//                                                    required>
//                                                    </input>
//                                                    <i onClick={(e) =>
//                                                       this.deletepaymentid(carddata.id, carddata.customer)} class="payment_icon_color">
//                                                       <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
//                                                    </i>
//                                                 </div>
//                                              </div>
//                                           </div>
//                                           )
//                                           }
//                                           else {
//                                           if (index == 0) {
//                                           return (
//                                           <div className="verify_input_box card_payment_body border-bottom_card">
//                                              <div class="card_payment_body2">
//                                                 <div class="card_data_info">
//                                                    <img class="visa_image" src={visacardImg} />
//                                                    <div class="visa_name_det">
//                                                       <h1>{carddata.name}</h1>
//                                                       <h1>xxxx-xxxx-xxxx-{carddata.last4}</h1>
//                                                    </div>
//                                                 </div>
//                                                 <div class="card_data_info2">
//                                                    <input onChange={() => this.defaultCard(carddata.id, carddata.customer)}
//                                                    type="radio"
//                                                    id="card1"
//                                                    name="card_type"
//                                                    required>
//                                                    </input>
//                                                    <i onClick={(e) =>
//                                                       this.deletepaymentid(carddata.id, carddata.customer)} class="payment_icon_color">
//                                                       <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
//                                                    </i>
//                                                 </div>
//                                              </div>
//                                           </div>
//                                           )
//                                           } else {
//                                           return (
//                                           <div className="verify_input_box card_payment_body border-bottom_card">
//                                              <div class="card_payment_body2">
//                                                 <div class="card_data_info">
//                                                    <img class="visa_image" src={visacardImg} />
//                                                    <div class="visa_name_det">
//                                                       <h1>{carddata.name}</h1>
//                                                       <h1>xxxx-xxxx-xxxx-{carddata.last4}</h1>
//                                                    </div>
//                                                 </div>
//                                                 <div class="card_data_info2">
//                                                    <input onChange={() => this.defaultCard(carddata.id, carddata.customer)}
//                                                    type="radio"
//                                                    id="card1"
//                                                    name="card_type"
//                                                    required>
//                                                    </input>
//                                                    <i onClick={(e) =>
//                                                       this.deletepaymentid(carddata.id, carddata.customer)} class="payment_icon_color">
//                                                       <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
//                                                    </i>
//                                                 </div>
//                                              </div>
//                                           </div>
//                                           )
//                                           }
//                                           }
//                                           })
//                                           }
//                                           <div class="col-md-12 right_btns text-center all_popups_buttonss">
//                                              <button class="submit_bb btn btn-primary border_radius_20 mx-2" type='submit'>save</button>
//                                              <Link to="/payment">
//                                              <button class="submit_bb btn btn-primary border_radius_20 mx-2">Add New Card</button></Link>
//                                           </div>
//                                        </form>
//                                     </Modal.Body>
//                                  </Modal>
//                                  </>
//                                  :
//                                  ""
//                                  }
//                                  <>
//                                  {booking.booking_status == "waiting for payment" && (booking.booking_type == 'post' || booking.booking_type == 'direct')?
//                                  <>
//                                  {this.state.stripeListDetails.length > 0 ?
//                                  <>
//                                  {this.state.stripeListDetails.map((carddata, cardindex) => {
//                                  if (carddata.id != this.state.tutrocardid) {
//                                  if (cardindex == 0 && !this.state.tutrocardid) {
//                                  return (
//                                  <div class="col-md-12 right_btns text-center all_popups_buttonss my-4 detail_mob">
//                                     <button class="cancel_bb btn btn-primary mx-2" onClick={() => { this.BookingCancel(booking.id) }}> Cancel</button>
//                                     <button class="submit_bb btn btn-primary mx-2" onClick={() => this.makePayment(carddata.id, carddata.customer,booking.booking_offer_price)}> Make Payment</button>
//                                  </div>
//                                  )
//                                  }
//                                  } else {
//                                  return (
//                                  <div class="col-md-12 right_btns text-center all_popups_buttonss my-4 detail_mob">
//                                     <button class="cancel_bb btn btn-primary mx-2" onClick={() => { this.BookingCancel(booking.id) }}> Cancel</button>
//                                     <button class="submit_bb btn btn-primary mx-2" onClick={() => this.makePayment(carddata.id, carddata.customer,booking.booking_offer_price)}> Make Payment</button>
//                                  </div>
//                                  )
//                                  }
//                                  })
//                                  }
//                                  </>
//                                  :
//                                  <div class="col-md-12 right_btns text-center all_popups_buttonss my-3 detail_mob">
//                                     <button class="cancel_bb btn btn-primary mx-2"> CANCEL</button>
//                                     <Link to="/payment">
//                                     <button class="submit_bb btn btn-primary mx-2"> ADD  CARD</button></Link>
//                                  </div>
//                                  }
//                                  </> :
//                                  ""}
//                                  </>
//                               </div>
//                               {booking.booking_status == "upcoming" ?
//                               <div class="col-md-12 right_btns text-center all_popups_buttonss my-4 detail_mob">
//                                  <button class="cancel_bb btn btn-primary mx-2" onClick={() => { this.BookingCancel(booking.id) }}> CANCEL</button>
//                                  <button class="submit_bb btn btn-primary mx-2" onClick={this.handleComplete}>COMPLETE</button>
//                               </div>
//                               : ""
//                               }
//                            </div>
//                            {booking.booking_status == 'pending' ?     
//                            this.state.offerlist.map((offer)=>{
//                            if(booking.id==offer.booking){
//                            return(
//                            <div class="row box_custom_booking margin0">
//                               <div class="col-md-6">
//                                  <div class="booking_info">
//                                     {offer.image == null ?
//                                     offer.gender == "female" ?
//                                     <img class="booking_user_img" src={defaultFemaleImg} alt="" />
//                                     :<img class="booking_user_img" src={defaultMaleImg} alt="" />
//                                     : <img class="booking_user_img" src={BASE_URL + offer.image} alt="" />
//                                     }
//                                     <div class="info_pera">
//                                        <h2>
//                                           {offer.fullname}
//                                           {offer.rating == 0 || offer.rating == null ? 
//                                           <span>(New User)</span>
//                                           :
//                                           <span>
//                                              <StarRatings
//                                                 rating={Number(offer.rating)}
//                                                 starRatedColor="#ffc107"
//                                                 starDimension="18px"
//                                                 starSpacing="0px"
//                                                 />
//                                           </span>
//                                           }
//                                        </h2>
//                                        {offer.online_status=='1' ? 
//                                        <div class="status_div left_justify">
//                                           <div className="online_status"></div>
//                                           ONLINE
//                                        </div>
//                                        :
//                                        <div class="status_div left_justify">
//                                           <div className="offline_status"></div>
//                                           Offline
//                                        </div>
//                                        }
//                                     </div>
//                                  </div>
//                               </div>
//                               <div class="col-md-6 text-right set_status">
//                                  {offer.booking_status == 'pending' ?
//                                  <button className="pending_btn">{offer.booking_status}</button>
//                                  :<button className="accepted_btn">{offer.booking_status}</button>
//                                  } <br/>
//                               </div>
//                               <div className="col-md-12">
//                                  <div className="border_l"></div>
//                               </div>
//                               <div class="col-md-12">
//                                  <div class="address_info">
//                                     <span>Offer Rate : </span>
//                                     <span>${offer.tutor_offer_price} Rate per Hour </span>
//                                  </div>
//                               </div>
//                               <div class="col-md-6 text-left">
//                                  <button className="chat_btn">
//                                     <i className="fa fa-star" aria-hidden="true">
//                                        <FontAwesomeIcon icon={faFacebookMessenger}></FontAwesomeIcon>
//                                     </i>
//                                     Chat
//                                  </button>
//                               </div>
//                               <div class="col-md-6 text-right set_offers">
//                                  <button className="accept_btn_detail mr-2" onClick={()=>{this.handleAccepted(offer.id,booking.id)}}>Accept Offer</button>
//                                  <button className="reject_btn_detail" onClick={()=>{this.handleRejected(offer.id,booking.id)}}>Reject Offer</button>
//                               </div>
//                            </div>
//                            )}}):null}
//                         </div>
//                      </div>
//                      {booking.booking_status == "pending" ?
//                      <div class="col-md-12 right_btns text-center all_popups_buttonss my-3 detail_mob">
//                         <button onClick={() => { this.BookingCancel(booking.id) }} class="cancel_bb btn btn-primary mx-2">REJECT</button>
//                         <button class="submit_bb btn btn-primary mx-2"> Chat</button>
//                      </div>
//                      : ""}
//                      {booking.booking_status == 'completed' ?
//                      <>
//                      {
//                      booking.student_rating != null ?
//                      <div class="row">
//                         <div class="col-md-12 right_btns text-center all_popups_buttonss my-4 detail_mob rebook">
//                            <button onClick={() => this.handleModal2()} class="cancel_bb btn btn-primary mx-2"> REPORT</button>
//                            <button class="submit_bb btn btn-primary mx-2"> REBOOK</button>
//                         </div>
//                      </div>
//                      :
//                      <div class="row">
//                         <div class="col-md-3 m-auto loginpage">
//                         <i className="fa fa-star"  aria-hidden="true">
//                                        <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
//                                     </i>
//                            <button class="btn btn-block send-button border_radius_20 tx-tfm mb-4" type="submit" onClick={() => this.handleModal1()}>RATE THIS TUTOR</button>
//                         </div>
//                      </div>
//                      }
//                      </>
//                      : null}
//                   </div>
//                </div>
//                )
//                })}
//             </div>
//          </div>
//       </div>
//    </section>
//    <Footer></Footer>
// </main >
// );
// }
// }



import React from 'react';
import Header from '../common/header';
import Footer from '../common/footer';
import axios from 'axios';
import moment from 'moment';
import 'moment-timezone'
import StarRatings from 'react-star-ratings';
import ReactStars from "react-rating-stars-component";
import defaultMaleImg from '../../assets/img/default_male.png';
import defaultFemaleImg from '../../assets/img/default_female.png';
import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
import visacardImg from '../../assets/img/categories/visa.png';
import { API_BASE_URL, BASE_URL, API_IMAGE_URL_MEDIA } from '../../config/api.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCalendar, faCreditCard, faEdit, faPen, faPlus, faStar, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal } from "react-bootstrap";
import SideBar from "./sideBar.js";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import swal from 'sweetalert';
var value = ''
var FilterMornigResult = ''
var FilterAfternoonResult = ''
var FilterEveningResult = ''
var pickdata = '';
var pickdatamorning='';
var pickdataevening=''
const days = [
{ label: "S", value: "sunday" },
{ label: "M", value: "monday" },
{ label: "T", value: "tuesday" },
{ label: "W", value: "wednesday" },
{ label: "T", value: "thursday" },
{ label: "F", value: "friday" },
{ label: "S", value: "saturday" },
]
var DaysM = []
var DaysA = []
var DaysE = []
var result = []
var result1 = []
var result2 = []
var UserToken = ''
export default class UserBookingdetail extends React.Component {
constructor(props) {
super(props)
this.state = {
bookingdetailList: [],
MessageOfferPriceAlreadyExist: '',
MessageOfferPriceAdded: '',
offerlist: [],
student_rating: '',
tutor_rating: '',
myidvalue: '',
preDateTime: [],
ChangeTextAreaCount:0,
accptedid: '',
description:'',
value1: '',
MessagePaymentAdded: '',
MessagePaymentalreadyAdded: '',
MessageBookingComplete: '',
Messageofferprice: '',
bookingdetailList: [],
MessageOfferPriceAlreadyExist: '',
MessageOfferPriceAdded: '',
tutor_rating: '',
myidvalue: '',
rating: '',
datalist: [],
MessageEditDetails: '',
stripeListDetails: [],
customerid: '',
payementid: '',
tutrocardid: '',
DefaultMessage: "",
rate_list: [],
student_review_rating: [],
buttonHide:false,
visible: false,
}
this.handleChange = this.handleChange.bind(this);
this.handleAccepted = this.handleAccepted.bind(this);
this.handleRejected = this.handleRejected.bind(this);
this.ratingshow = this.ratingshow.bind(this);
this.handleComplete = this.handleComplete.bind(this);
this.BookingDetail = this.BookingDetail.bind(this);
this.RatingReviewChange = this.RatingReviewChange.bind(this);
this.proposed_schedule = this.proposed_schedule.bind(this);
this.handleModalAddCard = this.handleModalAddCard.bind(this);
this.handleDescription=this.handleDescription.bind(this);
this.ReportDetails=this.ReportDetails.bind(this);
this.sweetAlertReportDetails=this.sweetAlertReportDetails.bind(this);
this.handlePayment=this.handlePayment.bind(this);
this.handleId=this.handleId.bind(this);
this.onChatHandle=this.onChatHandle.bind(this);
}
handlePayment(e){
e.preventDefault()
this.handleModalAddCard()
}
sweetAlertBookingSuccess = () => {
swal({
text: "Payment is Successfull",
icon: "success",
})
}
handleId(bookingId,categoryId,subcategoryId){
this.props.history.push({ 
pathname: '/editbooking',
state: { 
bookingId: bookingId,
subcategoryId:subcategoryId,
categoryId:categoryId}
});
}
sweetAlertBookingAlreadyCompleted = () => {
swal({
text: "Booking is already completed",
icon: "warning",
dangerMode: true,
})
}

offerpriceAlready=()=>{
   swal({
      text: "Booking Offer already accepted",
      icon: "warning",
      dangerMode: true,
      })
}
sweetAlertBookingCompleted = () => {
swal({
text: "Booking completed Successfully",
icon: "success",
})
}
sweetAlertCancelBooking = () =>{
swal({
text: "Booking cancelled",
icon: "success",
})
}
sweetAlertCardDeleted = () => {
swal({
text: "Card Deleted Successfully",
icon: "success",
})
}
sweetAlertRating = () => {
swal({
text: "Your Rating & Review submitted successfully",
icon: "success",
})
}
sweetAlertReportDetails=()=>{
swal({
text: "Report submitted",
icon: "success",
})
}
handleModalAddCard() {
this.setState({ showcard: !this.state.showcard })
}
handleChange(e) {
this.setState({ [e.target.name]: e.target.value });
}
componentDidUpdate(prevProps, nextProps) {
const { params } = this.props.location
value = params
const { tutor_params_id } = this.props.location
}
componentDidMount() {
this.BookingDetail()
this.booking_rating()
const tutor_id = localStorage.getItem("tutor_id")
this.TutorSessionRateList(tutor_id)
}
BookingDetail=()=> {
const country_code = localStorage.getItem("country_code")
const UserToken = localStorage.getItem('UserToken');
if (UserToken != '') {
axios({
method: 'post',
url: API_BASE_URL + "booking_detail_student/",
headers: { 'Authorization': "Bearer " + UserToken },
data: {
booking_id: country_code
}
}).then(response => {
this.setState({
bookingdetailList: response.data.booking_detail,
offerlist: response.data.offerlist
})
var data2 = (response.data.booking_detail[0].time_schedule)
const c = data2.replace(/'/g, '"');
const parsedata = JSON.parse(c)
const AfternoontimeSlot = parsedata.afternoon
{
AfternoontimeSlot.map((data) => {
const pick = data.time
return (
pick.map((pickle) => {
// if(pickdata!=''){
//    pickdata = pickdata+","+pickle
// }else{
//    pickdata = pickle
// }
// console.log("11>>>>",pickdata)
return (
this.setState({ timervalueAfternoon: pick })
)
}
)
)
})
}
console.log("after",this.state.timervalueAfternoon)
const MorningtimeSlot = parsedata.morning
{
MorningtimeSlot.map((data) => {
const pick = (data.time)
return (
pick.map((pickle) => {
// if(pickdatamorning!=''){
//    pickdatamorning=pickdatamorning+","+pickle
// }
// else{
//    pickdatamorning=pickle
// }
return (
this.setState({ timervalueMorning: pick })
)
})
)
})
}
console.log("mor",this.state.timervalueMorning)
const EveningTimeSlot = parsedata.evening
{
EveningTimeSlot.map((data) => {
const pick = data.time
return (
pick.map((pickle) => {
// if(pickdataevening!=''){
//    pickdataevening=pickdataevening+","+pickle
// }
// else{
//    pickdataevening=pickdataevening+","+pickle
// }
console.log("pickvalue", pickle)
return (
this.setState({ timervalueEvening: pick })
)
})
)
})
}
console.log("even",this.state.timervalueEvening)
var preDateTime = [];
var data1 = (response.data.booking_detail[0].schedule);
const b = data1.replace(/'/g, '"');
const datalist = JSON.parse(b);
const datalistvalue = datalist.date_schedule;
const finalscheduledata=datalistvalue[0].weekday_time;
console.log("finl",finalscheduledata)
this.setState({ preDateTime: finalscheduledata });
this.proposed_schedule()
this.stripeListDetails()
}).catch(error => {
console.log(error)
})
}
}
proposed_schedule = () => {
const result = this.state.preDateTime.filter(x => x.time === 'morning');
const result1 = this.state.preDateTime.filter(x => x.time === 'afternoon');
const result2 = this.state.preDateTime.filter(x => x.time === 'evening');
FilterMornigResult = result
FilterAfternoonResult = result1
FilterEveningResult = result2
DaysM = result.map((data) => {
console.log("kapil",data.day)
return (
data.day
)
})
DaysE = result2.map((data) => {
console.log("kapil2",data.day)
return (
data.day
)
})
DaysA = result1.map((data) => {
console.log("kapil1",data.day)
return (
data.day
)
})
}
TutorSessionRateList(tutor_id) {
UserToken = localStorage.getItem('UserToken');
if (UserToken != '') {
const sendData = {
tutor_id: tutor_id
};
axios({
method: 'post',
url: API_BASE_URL + "tutor_session_rate_list/",
headers: { 'Authorization': "Bearer " + UserToken },
data: sendData
}).then(response => {
this.setState({ rate_list: response.data.rate_list })
}).catch((error) => {
console.log(error)
});
}
}
stripeListDetails() {
UserToken = localStorage.getItem('UserToken');
if (UserToken != '') {
const sendData = {
};
axios({
method: 'post',
url: API_BASE_URL + "student_stripe_card_list/",
headers: { 'Authorization': "Bearer " + UserToken },
data: sendData
}).then(response => {
this.setState({ stripeListDetails: response.data.customer_slist.data })
}).catch((error) => {
console.log(error)
});
}
}
offerpriceAdded = () => {
swal({
text: "Offer accepted successfully",
icon: "success",
})
}
AcceptOffer = (id) => {
this.setState({ accptedid: id })
const val = localStorage.setItem("country_code", value);
const country_code = localStorage.getItem("country_code")
const UserToken = localStorage.getItem('UserToken');
}
handleAccepted(id,booking_id) {
const UserToken = localStorage.getItem('UserToken');
if (UserToken != '') {
const sendData = {
offer_id: id,
booking_id: booking_id,
status: 'waiting for payment'
}
axios({
method: 'post',
url: API_BASE_URL + "offer_action/",
headers: { 'Authorization': "Bearer " + UserToken },
data: sendData
}).then(response => {
if (response.data.success == 200) {
if (response.data.message == "Offer succefully accepted") {
this.offerpriceAdded()
}
if(response.data.message=='Offer allready accepted or rejected'){
   this.offerpriceAlready()

}
}
}).catch(error => {
console.log(error)
})
}
}
handleRejected(offerId,bookingId) {
const UserToken = localStorage.getItem('UserToken');
if (UserToken != '') {
const sendData = {
offer_id: offerId,
booking_id: bookingId,
status: "rejected"
}
axios({
method: 'post',
url: API_BASE_URL + "offer_action/",
headers: { 'Authorization': "Bearer " + UserToken },
data: sendData
}).then(response => {
if(response.data.success==200){
   this.setState({buttonHide:true})
   if(response.data.message=='Offer allready accepted or rejected'){
      this.offerpriceAlready()

   }
}
}).catch()
}
}
handleEditOption = () => {
this.props.history.push('/editbooking');
}
handleModal1() {
this.setState({ show1: !this.state.show1 })
}
handleModal2() {
this.setState({ show2: !this.state.show2 })
}
RatingReviewChange=(e)=> {
this.setState({ [e.target.name]: e.target.value });
}
handleDescription=(e)=>{
this.setState({ [e.target.name]: e.target.value });
var withoutSpace = e.target.value.replace(/ /g,"");
console.log("space remove",withoutSpace)
  // this.setState({ChangeTextAreaCount:(e.target.value.length)});
this.setState({ChangeTextAreaCount:withoutSpace.length})
// this.setState({ChangeTextAreaCount:(e.target.value.length)});
}
handleComplete() {
const bookingid = localStorage.getItem("country_code")
const UserToken = localStorage.getItem('UserToken');
if (UserToken != '') {
const sendData6 = {
booking_id: bookingid,
booking_status: 'completed'
}
axios({
method: 'post',
url: API_BASE_URL + "session_completed/",
headers: { 'Authorization': "Bearer " + UserToken },
data: sendData6
}).then(response => {
if (response.data.success == 205) {
if (response.data.message == "Your Booking  completed successfully") {
this.setState({ MessageBookingComplete: true })
this.sweetAlertBookingCompleted()
}
}
})
}
}
booking_rating() {
const bookingid = localStorage.getItem("country_code")
const UserToken = localStorage.getItem('UserToken');
if (UserToken != '') {
axios({
method: 'post',
url: API_BASE_URL + 'booking_rating/',
data: {
booking_id: bookingid
},
headers: { 'Authorization': "Bearer " + UserToken },
}).then(response => {
if (response.data.success == 200) {
this.setState({
student_review_rating: response.data.review_rating_data[0]
})
this.BookingDetail()
}
})
}
}
ratingshow = e => {
e.preventDefault();
const UserToken = localStorage.getItem('UserToken');
if (UserToken != '') {
const booking_id = localStorage.getItem("country_code")
const sendData7 = new FormData();
sendData7.append("student_review", this.state.review);
sendData7.append("student_rating", this.state.rating);
sendData7.append("booking_id", booking_id);
axios({
method: 'post',
url: API_BASE_URL + "student_rating/",
headers: { 'Authorization': "Bearer " + UserToken },
data: sendData7
}).then(response => {
console.log("fhuuefh",response.data)
if (response.data.success == 200) {
this.booking_rating()
this.handleModal1()
this.sweetAlertRating()
this.setState({ student_rating: response.data })
}
})
}
}
deletepaymentid = (id, customer) => {
this.setState({ payementid: id })
this.setState({ customerid: customer })
localStorage.setItem('payementid', id)
localStorage.setItem('customerid', customer)
const UserToken = localStorage.getItem('UserToken');
if (UserToken != '') {
const formData = new FormData();
formData.append('student_stripe_id', customer);
formData.append('student_stripe_card_id', id);
axios({
method: 'POST',
url: API_BASE_URL + "delete_stripe_card/",
headers: { 'Authorization': 'Bearer ' + UserToken },
data: formData
})
.then(res => {
console.log('delete', res.data)
if (res.data.success == 200) {
if (res.data.message == "Your stripe card successfully deleted") {
this.sweetAlertCardDeleted();
}
}
this.stripeListDetails()
}).catch((err) => {
console.log(err)
})
}
}
makePayment = (id, customer,bookingamount) => {
const bookingid = localStorage.getItem("country_code")
const UserToken = localStorage.getItem('UserToken');
this.setState({ visible: true })
if (UserToken != '') {
const formData = new FormData();
formData.append('student_stripe_id', customer);
formData.append('student_stripe_card_id', id);
formData.append('booking_id', bookingid);
formData.append('amount', bookingamount)
axios({
method: 'POST',
url: API_BASE_URL + "make_payment/",
headers: { 'Authorization': 'Bearer ' + UserToken },
data: formData
})
.then(res => {
   this.setState({ visible: false })
if (res.data.success == 200) {
if (res.data.message == "Your payment succefull complete") {
//this.setState({ paymentCompleteMessage: true })
this.sweetAlertBookingSuccess()
}
}
else if (res.data.success == 201) {
if (res.data.message == "Booking id is not valid .") {
//this.setState({ paymentAlreadyCompleteMessage: true })
this.sweetAlertBookingAlreadyCompleted()
}
}
}).catch((err) => {
console.log(err)
})
}
}
BookingCancel(id) {
const UserToken = localStorage.getItem('UserToken');
if (UserToken != '') {
const sendData10 = new FormData();
sendData10.append("status", "cancelled");
sendData10.append("booking_id", id);
axios({
method: 'post',
url: API_BASE_URL + "booking_action_student/",
headers: { 'Authorization': "Bearer " + UserToken },
data: sendData10
}).then(response => {
console.log("cancel", response.data)
if(response.data.success==201){
this.sweetAlertCancelBooking();
}
})
}
}
defaultCard = (id, customer) => {
this.setState({ tutrocardid: id })
const UserToken = localStorage.getItem('UserToken');
if (UserToken != '') {
const formData = new FormData();
formData.append('student_stripe_id', customer);
formData.append('student_stripe_card_id', id);
axios({
method: 'POST',
url: API_BASE_URL + "set_default_stripe_card/",
headers: { 'Authorization': 'Bearer ' + UserToken },
data: formData
})
.then(res => {
this.stripeListDetails()
if (res.data.message == "Card successfully added in default") {
this.setState({ DefaultMessage: true })
}
}).catch((err) => {
console.log(err)
})
}
}
ReportDetails(event) {
event.preventDefault()
const UserToken = localStorage.getItem('UserToken');
if (UserToken != '') {
const formData = new FormData();
formData.append('description',this.state.description);
formData.append('report_title', "test data new");
formData.append("report_type", "booking")
axios({
method: 'POST',
url: API_BASE_URL + "add_report/",
headers: { 'Authorization': 'Bearer ' + UserToken },
data: formData
}).then(res => {
this.sweetAlertReportDetails()
this.handleModal2()
}).catch((err) => {
console.log(err)
})
}
}


onChatHandle(tutorId){
   this.props.history.push({ 
       pathname: '/conversations',
       state: { id: tutorId }
    });
}



handleReBooking(catid,subcatid){
   this.props.history.push({ 
      pathname: '/dashboard',
         });


}
render() {
const lastschedule=FilterAfternoonResult
const lastMorningSchedule=FilterMornigResult
const lastEveningSchedule=FilterEveningResult
const { params } = this.props.location
value = params
const { tutor_params_id } = this.props.location
this.state.value1 = tutor_params_id
return (
<main>
   <Header></Header>
   <section className="">
      <div className="container-fluid margin_top width_custom">
         <div className="row">
            <div className="col-md-3 mt30">
               <SideBar></SideBar>
            </div>
            <div className="col-md-9 mt30">
               <div className="row">
                  <div className="col-md-12">
                     <h2 className="dashboard_headings">Booking Detail</h2>
                  </div>
               </div>
               {this.state.bookingdetailList.map((booking) => {
               return (
               <div className="row m-0">
                  <div className="col-md-12 box_custom upcoming_session booking_detail_seccc">
                     <div className="row">
                        <div className="col-lg-6 col-sm-6 col-md-6 col-6">
                           <span>{moment(booking.dob).tz('America/Chicago').format("DD MMM YYYY")}</span>
                           {booking.category_name != '' ?
                           <h4>{booking.category_name}</h4>
                           : null}
                           {booking.sub_category_name != '' ?
                           <p className="sub_b">Subject :  <b>{booking.sub_category_name}</b></p>
                           : null}
                           {booking.tuition_type != ''  && booking.tuition_type!=undefined && booking.tuition_type!="undefined"?
                           <p className="sub_b"><b>{booking.tuition_type}</b></p>
                           : null}
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-right">
                           {booking.booking_status == 'pending' ?
                           <button className="pending_btn">{booking.booking_status}</button>
                           :
                        
                           booking.booking_status=="waiting for payment"?
                           <button className="accepted_btn">Payment Pending</button>
                           :
                           booking.booking_status=='upcoming'?
                           
                           <button className="accepted_btn upcoming_btn">{booking.booking_status}</button>
                           :
                           booking.booking_status=='completed'?
                           <button className="accepted_btn">{booking.booking_status}</button>
                           :
                           <button className="accepted_btn cancel_btn">{booking.booking_status}</button>
                           } <br/>
                           {booking.id != '' ?
                           <h6 class="m-2">{booking.id}</h6>
                           : null}
                        </div>
                        <div class="col-md-12">
                           {booking.booking_status == 'pending' && booking.booking_type=='post' ? 
                           null
                           :
                           <div class="row box_custom_booking margin0">
                              <div class="col-md-6">
                                 <div class="booking_info">
                                    {booking.image == null ?
                                    booking.gender == "female" ?
                                    <img class="booking_user_img" src={defaultFemaleImg} alt="" />
                                    :<img class="booking_user_img" src={defaultMaleImg} alt="" />
                                    : <img class="booking_user_img" src={BASE_URL + booking.image} alt="" />
                                    }
                                    <div class="info_pera">
                                       <h2>{booking.fullname}<span>(New User)</span></h2>
                                       {booking.online_status=='1' ? 
                                       <div class="status_div left_justify">
                                          <div className="online_status"></div>
                                          ONLINE
                                       </div>
                                       :
                                       <div class="status_div left_justify">
                                          <div className="offline_status"></div>
                                          Offline
                                       </div>
                                       }
                                       {booking.rating == 0 || booking.rating == null ? 
                                       <span>(New User)</span>
                                       :
                                       <span>
                                          <StarRatings
                                             rating={Number(booking.rating)}
                                             starRatedColor="#ffc107"
                                             starDimension="18px"
                                             starSpacing="0px"
                                             />
                                       </span>
                                       }
                                    </div>
                                 </div>
                              </div>
                              <div class="col-md-6 chat_set text-right">
                                 <button onClick={() => this.onChatHandle(booking.user)} className="chat_btn">
                                    <i className="fa fa-star" aria-hidden="true">
                                       <FontAwesomeIcon icon={faFacebookMessenger}></FontAwesomeIcon>
                                    </i>
                                    Chat
                                 </button>
                              </div>
                           </div>
                           }
                           <div class="row booking_box">
                              <div class="col-md-12 booking_detail_info">
                                 <div  class="address_info" onClick={() =>
                                    this.handleId(booking.id,booking.category_id,booking.sub_category_id)} >
                                    <span><h1>Edit Details</h1></span> 
                                    <span>
                                       <i className="fa fa-star" aria-hidden="true">
                                          <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
                                       </i>
                                    </span>
                                 </div>
                                 {booking.booking_start != '' && booking.booking_start != "undefined" && booking.booking_start != undefined ?
                                 <div class="address_info">
                                    {/* 
                                    <i class="payment_icon_color">
                                       <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
                                    </i>
                                    */}
                                    <span>Start Date/Time: </span>
                                    <span>{moment(booking.booking_start).format("DD MMM YYYY")}</span>
                                 </div>
                                 : null}
                                 {booking.frequency_of_session != '' && booking.frequency_of_session != "undefined" && booking.frequency_of_session != undefined ?
                                 <div class="address_info"><span>Frequency : </span><span>{booking.frequency_of_session}</span></div>
                                 : null}
                                 <div class="address_info">
                                    <h1>Proposed Schedule : </h1>
                                 </div>
                                 {this.state.timervalueMorning  ?
                                 <>
                                 <div className="address_info">
                                    <span>Morning :</span> <span className="schedule_time">{this.state.timervalueMorning.join(',')}</span>
                                 </div>
                                 {lastMorningSchedule.length>0?
                                 <div className="schedule_day">
                                    {days.map((data, index1) => {
                                    return(
                                    <>
                                    {DaysM.includes(data.value)  ?
                                    <button class="active_day">{data.label}</button>
                                    :
                                    <button >{data.label}</button>}
                                    </>
                                    )})}
                                 </div>
                                 :null}
                                 </>
                                 :null}
                                 {this.state.timervalueAfternoon ?
                                 <>
                                 <div className="address_info">
                                    <span>Afternoon :</span> <span className="schedule_time">{this.state.timervalueAfternoon.join(',')}</span>
                                 </div>
                                 {lastschedule.length>0?
                                 <div className="schedule_day">
                                    {days.map((data, index1) => {
                                    console.log("afternoon",data)
                                    return (
                                    <>
                                    {DaysA.includes(data.value) ?
                                    <button class="active_day">{data.label}</button>
                                    : <button >{data.label}</button>}
                                    </>
                                    )})}
                                 </div>
                                 :null}
                                 </>
                                 :null}
                                 {this.state.timervalueEvening ?
                                 <>
                                 <div className="address_info">
                                    <span>Evening :</span> <span className="schedule_time">{this.state.timervalueEvening.join(',')}</span>
                                 </div>
                                 {lastEveningSchedule.length>0?
                                 <div className="schedule_day">
                                    {days.map((data, index1) => {
                                    return (
                                    <>
                                    {DaysE.includes(data.value) ?
                                    <button class="active_day">{data.label}</button>
                                    :
                                    <button >{data.label}</button>
                                    }
                                    </>
                                    )})}
                                 </div>
                                 :null} 
                                 </>
                                 :null}
                                 <div class="address_info">
                                    <h1>Payment : </h1>
                                 </div>
                                 {booking.booking_offer_price !='' && booking.booking_offer_price!='undefined' && booking.booking_offer_price!=undefined  ?   
                                 <div class="address_info payment_detail">
                                    {/* <span>Offer Rate : </span>
                                    <span>${booking.booking_offer_price} Rate per Hour </span> */}
                                    <div className="payment_detail">
                                       <span>Booking Rate: </span>
                                       <span>${booking.booking_offer_price} Rate per Hour </span>
                                    </div>
                                    <div className="payment_detail">
                                       <span>Total Hours:</span>
                                       <span>{booking.total_hours} Hour</span>
                                    </div>
                                    <div className="payment_detail">
                                       <span>Total Payable Ammount:</span>
                                       <span>${booking.total_payble}</span>
                                    </div>
                                 </div>
                                 : null}
                                 <Modal show={this.state.show1} onHide={() =>
                                    this.handleModal1()}>
                                    <Modal.Body>
                                       <form class="new_modal_form" onSubmit={this.ratingshow} >
                                          <div className="row">
                                             <div className="col-md-12 text-center rating_divv">
                                                <div class="listing_user_img">
                                                   {booking.image == null ?
                                                   booking.gender == "female" ?
                                                   <img class="card_profile_img" src={defaultFemaleImg} />
                                                   : <img class="card_profile_img" src={defaultMaleImg} />
                                                   : <img class="card_profile_img" src={booking.image} />
                                                   }
                                                </div>
                                                <h6 class="feedback-text">{booking.front_id}</h6>
                                                <h2>Feedback</h2>
                                             </div>
                                             <div className="col-md-12">
                                                <div class="form-group">
                                                   <ReactStars
                                                      className="react_star_ratee"
                                                      count={5}
                                                      onChange={newRating =>
                                                   {
                                                   this.setState({ rating: newRating })
                                                   }}
                                                   size={24}
                                                   name="rating"
                                                   isHalf={true}
                                                   emptyIcon={<i className="far fa-star"></i>}
                                                   halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                   fullIcon={<i className="fa fa-star"></i>}
                                                   activeColor="#ffd700"
                                                   />
                                                </div>
                                             </div>
                                             <div className="col-md-12">
                                                <div className="text-areaa px-5">
                                                   <textarea className="form-control" id="review" rows="4" maxLength="100" onChange={this.RatingReviewChange} cols="50" name="review" placeholder="Add Review" required ></textarea>
                                                </div>
                                             </div>
                                             <div class="text-center all_popups_buttonss custom_modal_footer m-auto">
                                                <button class="submit_bb mt-4" type="submit"> Submit</button>
                                             </div>
                                          </div>
                                       </form>
                                    </Modal.Body>
                                 </Modal>
                                 <Modal show={this.state.show2} onHide={() =>
                                    this.handleModal2()}>
                                    <Modal.Body>
                                       <form class="new_modal_form" onSubmit={this.ReportDetails} >
                                          <div className="row">
                                             <div className="col-md-12 text-center rating_divv">
                                                <div class="listing_user_img">
                                                   {booking.image == null ?
                                                   booking.gender == "female" ?
                                                   <img class="card_profile_img" src={defaultFemaleImg} />
                                                   : <img class="card_profile_img" src={defaultMaleImg} />
                                                   : <img class="card_profile_img" src={booking.image} />
                                                   }
                                                </div>
                                                <h6 class="feedback-text">{booking.front_id}</h6>
                                                <h2 className="rating_mob">Report Tutor</h2>
                                             </div>
                                             <div className="col-md-12">
                                                <div className="text-areaa px-5">
                                                   <textarea className="form-control" id="review" rows="4" maxLength="500" onChange={this.handleDescription} cols="50" name="description" placeholder="Add Review" required ></textarea>
                                                   <div className="rating_count">
                                                      <p>{this.state.ChangeTextAreaCount}/500</p>
                                                   </div>
                                                </div>
                                             </div>
                                             <div class="text-center all_popups_buttonss custom_modal_footer m-auto">
                                                <button class="submit_bb mt-4" type="submit"> Submit</button>
                                             </div>
                                          </div>
                                       </form>
                                    </Modal.Body>
                                 </Modal>
                              </div>
                              <div class="col-md-12 right_btns text-right my-2">
                                 {booking.booking_status == "waiting for payment" ?
                                 <>
                                 <div class="card_det">
                                    <div class="address_info card_payment_header">
                                       <h1>Add Card </h1>
                                       <Link to="/payment">
                                       <i class="payment_icon_color">
                                          <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                                       </i>
                                       </Link>
                                    </div>
                                    {this.state.stripeListDetails.map((carddata, index) => {
                                    if (index == 0 && !this.state.tutrocardid) {
                                    return (
                                    <div class="card_payment_body">
                                       <div class="card_data_info">
                                          <img class="visa_image" src={visacardImg} />
                                          <div class="visa_name_det">
                                             <h1>{carddata.name}</h1>
                                             <h1>xxxx-xxxx-xxxx-{carddata.last4}</h1>
                                          </div>
                                       </div>
                                       <i class="payment_icon_color font-size22" onClick={() =>
                                          this.handleModalAddCard()}>
                                          <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
                                       </i>
                                    </div>
                                    )
                                    }
                                    else {
                                    if (carddata.id == this.state.tutrocardid) {
                                    return (
                                    <div class="card_payment_body">
                                       <div class="card_data_info">
                                          <img class="visa_image" src={visacardImg} />
                                          <div class="visa_name_det">
                                             <h1>{carddata.name}</h1>
                                             <h1>xxxx-xxxx-xxxx-{carddata.last4}</h1>
                                          </div>
                                       </div>
                                       <i class="payment_icon_color font-size22" onClick={() =>
                                          this.handleModalAddCard()}>
                                          <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
                                       </i>
                                    </div>
                                    )
                                    }
                                    }
                                    })}
                                 </div>
                                 <Modal show={this.state.showcard} onHide={() =>
                                    this.handleModalAddCard()}>
                                    <Modal.Header className="allmodal_header modal-header" closeButton>
                                       <h2>Choose your card</h2>
                                    </Modal.Header>
                                    <Modal.Body>
                                       <form class="loginpage" onSubmit={this.handlePayment}>
                                          {this.state.stripeListDetails.map((carddata, index) => {
                                          if (this.state.tutrocardid) {
                                          return (
                                          <div className="verify_input_box card_payment_body border-bottom_card">
                                             <div class="card_payment_body2">
                                                <div class="card_data_info">
                                                   <img class="visa_image" src={visacardImg} />
                                                   <div class="visa_name_det">
                                                      <h1>{carddata.name}</h1>
                                                      <h1>xxxx-xxxx-xxxx-{carddata.last4}</h1>
                                                   </div>
                                                </div>
                                                <div class="card_data_info2">
                                                   <input onChange={() => this.defaultCard(carddata.id, carddata.customer)}
                                                   type="radio"
                                                   id="card1"
                                                   name="card_type"
                                                   required>
                                                   </input>
                                                   <i onClick={(e) =>
                                                      this.deletepaymentid(carddata.id, carddata.customer)} class="payment_icon_color">
                                                      <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                                                   </i>
                                                </div>
                                             </div>
                                          </div>
                                          )
                                          }
                                          else {
                                          if (index == 0) {
                                          return (
                                          <div className="verify_input_box card_payment_body border-bottom_card">
                                             <div class="card_payment_body2">
                                                <div class="card_data_info">
                                                   <img class="visa_image" src={visacardImg} />
                                                   <div class="visa_name_det">
                                                      <h1>{carddata.name}</h1>
                                                      <h1>xxxx-xxxx-xxxx-{carddata.last4}</h1>
                                                   </div>
                                                </div>
                                                <div class="card_data_info2">
                                                   <input onChange={() => this.defaultCard(carddata.id, carddata.customer)}
                                                   type="radio"
                                                   id="card1"
                                                   name="card_type"
                                                   required>
                                                   </input>
                                                   <i onClick={(e) =>
                                                      this.deletepaymentid(carddata.id, carddata.customer)} class="payment_icon_color">
                                                      <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                                                   </i>
                                                </div>
                                             </div>
                                          </div>
                                          )
                                          } else {
                                          return (
                                          <div className="verify_input_box card_payment_body border-bottom_card">
                                             <div class="card_payment_body2">
                                                <div class="card_data_info">
                                                   <img class="visa_image" src={visacardImg} />
                                                   <div class="visa_name_det">
                                                      <h1>{carddata.name}</h1>
                                                      <h1>xxxx-xxxx-xxxx-{carddata.last4}</h1>
                                                   </div>
                                                </div>
                                                <div class="card_data_info2">
                                                   <input onChange={() => this.defaultCard(carddata.id, carddata.customer)}
                                                   type="radio"
                                                   id="card1"
                                                   name="card_type"
                                                   required>
                                                   </input>
                                                   <i onClick={(e) =>
                                                      this.deletepaymentid(carddata.id, carddata.customer)} class="payment_icon_color">
                                                      <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                                                   </i>
                                                </div>
                                             </div>
                                          </div>
                                          )
                                          }
                                          }
                                          })
                                          }
                                          <div class="col-md-12 right_btns text-center all_popups_buttonss">
                                             <button class="submit_bb btn btn-primary border_radius_20 mx-2" type='submit'>save</button>
                                             <Link to="/payment">
                                             <button class="submit_bb btn btn-primary border_radius_20 mx-2">Add New Card</button></Link>
                                          </div>
                                       </form>
                                    </Modal.Body>
                                 </Modal>
                                 </>
                                 :
                                 ""
                                 }
                                 <>
                                 {booking.booking_status == "waiting for payment" && (booking.booking_type == 'post' || booking.booking_type == 'direct')?
                                 <>
                                 {this.state.stripeListDetails.length > 0 ?
                                 <>
                                 {this.state.stripeListDetails.map((carddata, cardindex) => {
                                 if (carddata.id != this.state.tutrocardid) {
                                 if (cardindex == 0 && !this.state.tutrocardid) {
                                 return (
                                  
               
                                 <div class="col-md-12 right_btns text-center all_popups_buttonss my-4 detail_mob">
                                      <Loader
                                    // ="please Select Country"
                                    visible={this.state.visible}
                                    type="Oval"
                                    color="#00bacc"
                                    height={40}
                                    width={40}
                                    timeout={3000} //3 secs
                                    />
                                    <button class="cancel_bb btn btn-primary mx-2" onClick={() => { this.BookingCancel(booking.id) }}> Cancel</button>
                                    <button class="submit_bb btn btn-primary mx-2" onClick={() => this.makePayment(carddata.id, carddata.customer,booking.booking_offer_price)}> Make Payment</button>
                                 </div>
                                 )
                                 }
                                 } else {
                                 return (
                                 <div class="col-md-12 right_btns text-center all_popups_buttonss my-4 detail_mob">
                                      <Loader
                                    // ="please Select Country"
                                    visible={this.state.visible}
                                    type="Oval"
                                    color="#00bacc"
                                    height={40}
                                    width={40}
                                    timeout={3000} //3 secs
                                    />
                                    <button class="cancel_bb btn btn-primary mx-2" onClick={() => { this.BookingCancel(booking.id) }}> Cancel</button>
                                    <button class="submit_bb btn btn-primary mx-2" onClick={() => this.makePayment(carddata.id, carddata.customer,booking.total_payble)}> Make Payment</button>
                                 </div>
                                 )
                                 }
                                 })
                                 }
                                 </>
                                 :
                                 <div class="col-md-12 right_btns text-center all_popups_buttonss my-3 detail_mob">
                                    <button class="cancel_bb btn btn-primary mx-2"> CANCEL</button>
                                    <Link to="/payment">
                                    <button class="submit_bb btn btn-primary mx-2"> ADD  CARD</button></Link>
                                 </div>
                                 }
                                 </> :
                                 ""}
                                 </>
                              </div>
                              {booking.booking_status == "upcoming" ?
                              <div class="col-md-12 right_btns text-center all_popups_buttonss my-4 detail_mob">
                                 <button class="cancel_bb btn btn-primary mx-2" onClick={() => { this.BookingCancel(booking.id) }}> CANCEL</button>
                                 <button class="submit_bb btn btn-primary mx-2" onClick={this.handleComplete}>COMPLETE</button>
                              </div>
                              : ""
                              }
                           </div>
                           {booking.booking_status == 'pending' ?     
                           this.state.offerlist.map((offer)=>{
                           if(booking.id==offer.booking){
                           return(
                           <div class="row box_custom_booking margin0">
                              <div class="col-md-6">
                                 <div class="booking_info">
                                    {offer.image == null ?
                                    offer.gender == "female" ?
                                    <img class="booking_user_img" src={defaultFemaleImg} alt="" />
                                    :<img class="booking_user_img" src={defaultMaleImg} alt="" />
                                    : <img class="booking_user_img" src={BASE_URL + offer.image} alt="" />
                                    }
                                    <div class="info_pera">
                                       <h2>
                                          {offer.fullname}
                                          {offer.rating == 0 || offer.rating == null ? 
                                          <span>(New User)</span>
                                          :
                                          <span>
                                             <StarRatings
                                                rating={Number(offer.rating)}
                                                starRatedColor="#ffc107"
                                                starDimension="18px"
                                                starSpacing="0px"
                                                />
                                          </span>
                                          }
                                       </h2>
                                       {offer.online_status=='1' ? 
                                       <div class="status_div left_justify">
                                          <div className="online_status"></div>
                                          ONLINE
                                       </div>
                                       :
                                       <div class="status_div left_justify">
                                          <div className="offline_status"></div>
                                          Offline
                                       </div>
                                       }
                                    </div>
                                 </div>
                              </div>
                              <div class="col-md-6 text-right set_status">
                                 {offer.booking_status == 'pending' ?
                                 <button className="pending_btn">{offer.booking_status}</button>
                                 :<button className="accepted_btn">{offer.booking_status}</button>
                                 } <br/>
                              </div>
                              <div className="col-md-12">
                                 <div className="border_l"></div>
                              </div>
                              <div class="col-md-12">
                                 <div class="address_info">
                                    <span>Offer Rate : </span>
                                    <span>${offer.tutor_offer_price} Rate per Hour </span>
                                 </div>
                              </div>
                              <div class="col-md-6 text-left">
                                 <button onClick={() => this.onChatHandle(booking.user)} className="chat_btn">
                                    <i className="fa fa-star" aria-hidden="true">
                                       <FontAwesomeIcon icon={faFacebookMessenger}></FontAwesomeIcon>
                                    </i>
                                    Chat
                                 </button>
                              </div>
                              {this.state.buttonHide==false?
                              <div class="col-md-6 text-right set_offers">
                                 <button className="accept_btn_detail mr-2" onClick={()=>{this.handleAccepted(offer.id,booking.id)}}>Accept Offer</button>
                                 <button className="reject_btn_detail" onClick={()=>{this.handleRejected(offer.id,booking.id)}}>Reject Offer</button>
                              </div>
                              :null}
                           </div>
                           )}}):null}
                        </div>
                     </div>
                     {booking.booking_status == "pending" ?
                     <div class="col-md-12 right_btns text-center all_popups_buttonss my-3 detail_mob">
                        <button onClick={() => { this.BookingCancel(booking.id) }} class="cancel_bb btn btn-primary mx-2">REJECT</button>
                        <button onClick={() => this.onChatHandle(booking.user)} class="submit_bb btn btn-primary mx-2"> Chat</button>
                     </div>
                     : ""}
                     {booking.booking_status == 'completed' ?
                     <>
                     {
                     booking.student_rating != null ?
                     <div class="row">
                        <div class="col-md-12 right_btns text-center all_popups_buttonss my-4 detail_mob rebook">
                           <button onClick={() => this.handleModal2()} class="cancel_bb btn btn-primary mx-2"> REPORT</button>
                           <button onClick={() => this.handleReBooking(booking.category_id,booking.sub_category_id)} class="submit_bb btn btn-primary mx-2"> REBOOK</button>
                        </div>
                     </div>
                     :
                     <div class="row">
                        <div class="col-md-3 m-auto loginpage">
                           
                           <button class="btn btn-block send-button border_radius_20 tx-tfm mb-4" type="submit" onClick={() => this.handleModal1()}><span className="star_rating"><i className="fa fa-star"  aria-hidden="true">
                              <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                           </i></span>RATE THIS TUTOR</button>
                        </div>
                     </div>
                     }
                     </>
                     : null}
                  </div>
               </div>
               )
               })}
            </div>
         </div>
      </div>
   </section>
   <Footer></Footer>
</main >
);
}
}