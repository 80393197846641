import React from 'react';
import ReactDOM from 'react-dom';
// import { withRouter } from "react-router-dom";
import { Redirect } from 'react-router';
import Header from '../common/header';
import Footer from '../common/footer.js';
import SideBar from "../user/sideBar";
import axios from 'axios';
import { API_BASE_URL, BASE_URL } from '../../config/api.js';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import swal from 'sweetalert';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

class CheckoutForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible : false,
            name: '',
            cvv: '',
            exp_month: '',
            exp_year: '',
            redirect : false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChange1 = this.handleChange1.bind(this);
        this.handleChange2 = this.handleChange2.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    sweetAlertCardAdded = () => {
        swal({
            text: "Your card added successfully",
            icon: "success"  
        }).then(()=> {
            // window.location.href = "userbooking";
        })
    }

    componentDidMount() {
       
        const UserToken = localStorage.getItem('UserToken');

        if (UserToken != '') {
            const sendData = {
            };

            
            axios({
                method: 'post',
                url: API_BASE_URL + "create_student_stripe_account/",
                headers: { 'Authorization': "Bearer " + UserToken },
                data: sendData

            }).then(response => {
                
                console.log("lavesh", response.data)
                // this.StripeList()
            }).catch((error) => {
                console.log(error)

            });
        }
    }


    handleChange(e) {
        console.log(e.target.value)
        this.setState({ name: e.target.value })
    }

    handleChange1(e) {
        console.log(e.target.value)
        this.setState({ cvv: e.target.value })
    }

    handleChange2(e) {
        const CardExp = e.target.value
        const dateData = CardExp.split('/');
        const month = dateData[0]
        // const year = '20' + dateData[1]
        const year = dateData[1]
        console.log("Month: ", month)
        console.log("Year: ", year)
        console.log(e.target.value)
        this.setState({ exp_month: month })
        this.setState({ exp_year: year })
    }

    handleSubmit = async (event) => {

        this.setState({visible: true})

       //console.log("jjj",this.state.visible)
        
        event.preventDefault();

        const { stripe } = this.props;
       
        const UserToken = localStorage.getItem('UserToken');

        if (UserToken != '') {

            const formData = new FormData();

            formData.append('card_holder_name', this.state.name);
            formData.append('exp_month', this.state.exp_month);
            formData.append('exp_year', this.state.exp_year);
            formData.append('card_cvc', this.state.cvv);
            formData.append('card_number', 4242424242424242);

            axios({
                method: 'post',
                url: API_BASE_URL + "add_student_stripe_card/",
                headers: { 'Authorization': "Bearer " + UserToken },
                data: formData

            }).then(response => {

                this.state.visible = false

                if (response.data.success == 201) {
                   
                    if (response.data.message == "Your card detail successfully added in stripe.") {
                        this.sweetAlertCardAdded()
                        // this.props.history.replace("/userbooking")
                        this.setState({ redirect: true });
                    }
                }
            
            })
            .catch((error) => {
                console.log(error)
            });
        }
    };

render() {

    if (this.state.redirect) {
        return (
            <Redirect to={'/userbooking'} />
        )
    }
const { stripe } = this.props;
return (
<main>
<Header></Header>
<section className="">
<div className="container-fluid margin_top width_custom">
<div className="row">
<div className="col-md-3 mt30">
<SideBar></SideBar>
</div>

<div className="col-md-9 mt30">
<div className="row">
<div className="col-md-12">
<h2 className="dashboard_headings">Card Detail</h2>
</div>
</div>
<div class="row box_custom absv_cont">
<div class="col-md-12">
<form class="in-modal booktutorr_form loginpage cont_btn" onSubmit={this.handleSubmit} >
<div class="row text-left">
    <div class="col-md-6">
        <label>Card Holder Name</label><br></br>
        <input name="name" onChange={this.handleChange} type="text" placeholder="Card holder name" required />
    </div>
    <div class="col-md-6">
        <label>Card Number</label><br></br>
        <input maxLength='16' type="text" placeholder="Card number" required />
    </div>
    <div class="col-md-6">
        <label>Expiry Date</label><br></br>
        <input name='exp_date' onChange={this.handleChange2} type="text" placeholder="MM/YYYY" required />
    </div>
    <div class="col-md-6">
        <label>CVV</label><br></br>
        <input maxLength='3' name="cvv" onChange={this.handleChange1} type="text" placeholder="Cvv" required />
    </div>
    <div class="col-md-12 text-center mt-3">
    <Loader
        visible = {this.state.visible}
        type="Oval"
        color="#00bacc"
        height={40}
        width={40}
        />
    </div>
    <div class="col-md-3 m-auto">
        <button class="btn btn-block send-button border_radius_20 tx-tfm mb-4" disabled={!stripe} type="submit">Save</button>
    </div>
</div>
</form>
</div>
</div>
</div>
</div>
</div>
</section>
<Footer></Footer>
</main>
)
}
}

const InjectedCheckoutForm = () => (
    <ElementsConsumer>
        {({ stripe, elements }) => (
            <CheckoutForm stripe={stripe} elements={elements} />
        )}
    </ElementsConsumer>
);
const stripePromise = loadStripe('pk_test_6pRNASCoBOKtIshFeQd4XMUh');

const App = () => (
    <Elements stripe={stripePromise}>
        <InjectedCheckoutForm />
    </Elements>
);

export default App;