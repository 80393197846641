import React from 'react';
import ReactDOM from 'react-dom';
import Header from '../common/header';
import Footer from '../common/footer';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import axios from 'axios';
import { API_BASE_URL, BASE_URL } from '../../config/api.js'
import defaultMaleImg from '../../assets/img/default_male.png';
import defaultFemaleImg from '../../assets/img/default_female.png';
import shortlist from "../../assets/img/dashboard_icons/shortlist.png";
import removelist from "../../assets/img/dashboard_icons/removelist.png";
import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
import { faHeart, faStar, faComment, faUser, faUsers, faGlobe, faStopwatch, faListAlt } from '@fortawesome/free-solid-svg-icons';
import StarRatings from '../../../node_modules/react-star-ratings';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { BrowserRouter as Router, Link } from "react-router-dom";

export class MapContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cat_id: this.props.location.state.cat_id,
            cat_name: this.props.location.state.cat_name,
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            tutorexdata: [],
            tutor_cat_subcat_data: [],
            address: '',
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            mapCenter: {
                lat: 49.2827291,
                lng: -123.1207375
            }
        }
        this.onMarkerClick = this.onMarkerClick.bind(this);
        this.onInfoFav = this.onInfoFav.bind(this);
        this.onViewProfile=this.onViewProfile.bind(this);
        this.onChatHandle = this.onChatHandle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);

    }
    handleChange = address => {
        console.log("bhagwati", address)
        this.setState({ address })
    }
    handleSelect = address => {
        this.setState({ address })
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latlng => {
                console.log('Sucess', latlng)
                this.setState({ mapCenter: latlng })
            })
            .catch(error => console.log('Error', error
            ))
    }
    onMarkerClick = (props, marker, e) =>

        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });

    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {

            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            })
        }
    };

    windowHasOpened = () => {
        this.setState({
            showingInfoWindow: true
        })
    }
    windowHasClosed = () => {
        this.setState({
            showingInfoWindow: false
        })
    }

    onInfoWindowOpen(props, id) {
 
     
        const div = (<div onClick={() => this.onInfoFav(props, id)}>
            {this.state.selectedPlace.favlist_status != null ?
                <img src={shortlist} alt="" />
                : <img src={removelist} alt="" />
            }
        </div>);

        const button = (         
            <button class="chat_tutor" onClick={() => this.onViewProfile(props, id)}>
                View Profile</button>
        );

        const button2 = (
            <button class="chat_tutor ml-2" onClick={() => this.onChatHandle(props, id)}>
            <i className=""><FontAwesomeIcon icon={faFacebookMessenger}></FontAwesomeIcon></i>
            Chat</button>
        );

        ReactDOM.render(React.Children.only(div), document.getElementById("iwc"));
        ReactDOM.render(React.Children.only(button), document.getElementById("iwc2"));
        ReactDOM.render(React.Children.only(button2), document.getElementById("iwc3"));
    }

    onChatHandle(tutorId) {
        this.props.history.push({
            pathname: '/conversations',
            state: { id: tutorId }
        });
    }
    onViewProfile(props){
       
        this.props.history.push({ 
            pathname: '/tutor_profile',
            state: { id: props }
         });
    }

    componentDidMount() {

        if (navigator.geolocation) {
            navigator.geolocation.watchPosition(function(position) {
              console.log("Latitude is :", position.coords.latitude);
              console.log("Longitude is :", position.coords.longitude);
            });
          }
        
        axios({
            method: 'post',
            url: API_BASE_URL + 'tutor_filter_login/',
            data: {
            },
        }).then(response => {
            this.setState({ tutorexdata: response.data.tutorexdata })
            this.setState({ tutor_cat_subcat_data: response.data.tutor_cat_subcat_data })
            console.log("map response", response.data)
        })
            .catch((error) => {
                console.log(error)
            });
    }
    onInfoFav(id) {
      

        const UserToken = localStorage.getItem('UserToken');
        if (UserToken != '') {

            axios({
                method: 'post',
                url: API_BASE_URL + 'favourite/',
                headers: { 'Authorization': "Bearer " + UserToken },
                data: {
                    tutor_id: id
                }
            }).then(response => {
                console.log("favlistdata", response.data);
            })
            .catch((error) => {
                console.log(error)
            });
        }
    }



render() {

const tutorList = this.state.tutorexdata.map((tutor) => {
    console.log('tutorfavstatus',tutor.lattitude)

    return (
        <Marker onClick={this.onMarkerClick}
            id={tutor.front_id}
            fullname={tutor.fullname}
            category_id={tutor.tutor_category_list}
            image={tutor.image}
            favlist_status={tutor.fav_status}
            userId={tutor.user}
            rating={tutor.rating}
            online_status={tutor.online_status}
            gender={tutor.gender}
            position={{ lat: tutor.lattitude, lng: tutor.longitude }}
        />
    )

})


const tutor_cat_subcat_data =
this.state.tutor_cat_subcat_data.map((subjects) => {

if (this.state.selectedPlace.userId == subjects.user_id)
    return (
        <>
            {subjects.sub_category_name}
        </>
    )
})

return (
<main>
<Header></Header>
<section>
<div className="container-fluid margin_top width_custom mb-5">
<div id='googleMaps_wrap'>
<PlacesAutocomplete
value={this.state.address}
onChange={this.handleChange}
onSelect={this.handleSelect}
>
{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
<div>
<input
{...getInputProps({
    placeholder: 'Search Tutor Location',
    className: 'location-search-input',
})}
/>
<div className="autocomplete-dropdown-container">
{loading && <div>Loading...</div>}
{suggestions.map(suggestion => {
    const className = suggestion.active
        ? 'suggestion-item--active'
        : 'suggestion-item';
    // inline style for demonstration purpose
    const style = suggestion.active
        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
        : { backgroundColor: '#ffffff', cursor: 'pointer' };
    return (
        <div
            {...getSuggestionItemProps(suggestion, {
                className,
                style,
            })}
        >
            <span>{suggestion.description}</span>
        </div>
    );
})}
</div>
</div>
)}
</PlacesAutocomplete>


<div class="row">
<div class="col-md-12">
<Link class="no_underline" to={"/tutorsearch/"+this.state.cat_name.replaceAll(" ","_")+'/'+this.state.cat_id}>
    <div class="list_buttons">
    <i className="">
        <FontAwesomeIcon icon={faListAlt}></FontAwesomeIcon>
    </i> list view
    </div>
</Link>
</div>
<div class="col-md-12">
<div id='googleMaps'>
<Map
google={this.props.google}
onClick={this.onMapClicked} zoom={14}
initialCenter={{
    lat: this.state.mapCenter.lat,
    lng: this.state.mapCenter.lng
}}
center={{
    lat: this.state.mapCenter.lat,
    lng: this.state.mapCenter.lng
}}>

{/* <Marker onMouseover={this.onMarkerClick}
    id={"2345"}
    name={'User Current location'}
/> */}

{tutorList}

<InfoWindow
    onOpen={this.windowHasOpened}
    onClose={this.windowHasClosed}
    marker={this.state.activeMarker}
    visible={this.state.showingInfoWindow}
    onOpen={e => {
        this.onInfoWindowOpen(this.state.selectedPlace.userId);
    }}>
    <div className="infowindow">
        {this.state.selectedPlace.image == null ?
            this.state.selectedPlace.gender == "female" ?
                <img className="map_profile" src={defaultFemaleImg} alt="" />
                : <img className="map_profile" src={defaultMaleImg} alt="" />
            : <img className="map_profile" src={BASE_URL + this.state.selectedPlace.image} ></img>
        }

        <div class="tutor_detail_sec">
            <div class="chat_favrt">
                <h5> {this.state.selectedPlace.id}

                    {this.state.selectedPlace.rating != '' ?
                        <StarRatings
                            rating={this.state.selectedPlace.rating}
                            starRatedColor="#ffc107"
                            starDimension="18px"
                            starSpacing="0px"
                        />
                        : <span>(New User)</span>}
                </h5>

                <div id="iwc" value={this.state.selectedPlace.userId} className="chat_favrt2">
                </div>
            </div>
            {this.state.selectedPlace.online_status == 1 ?
                <span><div className="online_status"></div>ONLINE</span>
                : <span><div className="offline_status"></div>OFFLINE</span>}

            <p>{tutor_cat_subcat_data}</p>

            <div class="tutor_btnss tutor_btnss_map">
                <div id='iwc2'>  </div>
                <div id='iwc3'>
                </div>
            </div>
        </div>
    </div>
</InfoWindow>
</Map>
</div>
</div>
</div>
</div>
</div>
</section>
<Footer></Footer>
</main>
)}}

export default GoogleApiWrapper({
    apiKey: ('AIzaSyBlVFdLcA2L_aB29DFJEdyJB6YoC2ErgQQ')
})(MapContainer)







