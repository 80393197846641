import $ from "jquery";

$(function() {
    $(window).on("scroll", function() {
        if($(window).scrollTop() > 50) {
            $(".Header").addClass("activeHeader");
        } else {
            //remove the background property so it comes transparent again (defined in your css)
           $(".Header").removeClass("activeHeader");
        }
    });
});


if ($('.scroll-to-target').length) {
    $(".scroll-to-target").on('click', function () {
      var target = $(this).attr('data-target');
      // animate
      $('html, body').animate({
        scrollTop: $(target).offset().top
      }, 1000);
  
    });
  }