// import React, { useState } from 'react';
// // import ReactFlagsSelect from 'react-flags-select';
// import PhoneInput from "react-phone-input-2";
// import 'react-phone-input-2/lib/style.css'
// import axios from "axios";
// import { API_BASE_URL } from '../../config/api.js';
// import { Button, Modal } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import Header from "../common/header";
// import Footer from "../common/footer";
// import firebase from '../../firebase.js';
// import "styled-components";
// import fb from "../../assets/img/categories/Icon/facebook.png";
// import google from "../../assets/img/categories/Icon/google.png";
// import twitter from "../../assets/img/categories/Icon/twitter.png";
// import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
// import { faEye, faEyeSlash } from '../../../node_modules/@fortawesome/free-solid-svg-icons'
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// import Loader from "react-loader-spinner";

// class signUp extends React.Component {

//   constructor() {
//     super();
//     this.state = {
//       visible: false,
//       phone: '',
//       username: '',
//       responseMessageCountryCode:false,
//       id: '',
//       email: '',
//       password: '',
//       fullname: '',
//       notificationId: '',
//       responseMessageUserType:false,
//       // password2: '',
//       type: 'password',
//       // type1: 'password2',
//       country_code: 'AD',
//       countryCode: [],
//       input: {},
//       errors: {},
//       value: '',
//       selected: 'US',
//       phoneCode: [],
//       // phonecode1:["IN"],
//       phonecode1: ['AF', 'AL', 'DZ', 'AS', 'AD', 'AO', 'AI', 'AQ', 'AG', 'AR', 'AM', 'AW', 'AU', 'AT', 'AZ', 'BS', 'BH', 'BD', 'BB', 'BY', 'BE', 'BZ', 'BJ', 'BM', 'BT', 'BO', 'BA', 'BW', 'BV', 'BR', 'IO', 'BN', 'BG', 'BF', 'BI', 'KH', 'CM', 'CA', 'CV', 'KY', 'CF', 'TD', 'CL', 'CN', 'CX', 'CC', 'CO', 'KM', 'CG', 'CD', 'CK', 'CR', 'CI', 'HR', 'CU', 'CY', 'CZ', 'DK', 'DJ', 'DM', 'DO', 'TL', 'EC', 'EG', 'SV', 'GQ', 'ER', 'EE', 'ET', 'XA', 'FK', 'FO', 'FJ', 'FI', 'FR', 'GF', 'PF', 'TF', 'GA', 'GM', 'GE', 'DE', 'GH', 'GI', 'GR', 'GL', 'GD', 'GP', 'GU', 'GT', 'GG', 'GN', 'GW', 'GY', 'HT', 'HM', 'HN', 'HK', 'HU', 'IS', 'IN', 'ID', 'IR', 'IQ', 'IE', 'IL', 'IT', 'JM', 'JP', 'JE', 'JO', 'KZ', 'KE', 'KI', 'KP', 'KR', 'KW', 'KG', 'LA', 'LV', 'LB', 'LS', 'LR', 'LY', 'LI', 'LT', 'LU', 'MO', 'MK', 'MG', 'MW', 'MY', 'MV', 'ML', 'MT', 'IM', 'MH', 'MQ', 'MR', 'MU', 'YT', 'MX', 'FM', 'MD', 'MC', 'MN', 'MS', 'MA', 'MZ', 'MM', 'NA', 'NR', 'NP', 'AN', 'NL', 'NC', 'NZ', 'NI', 'NE', 'NG', 'NU', 'NF', 'MP', 'NO', 'OM', 'PK', 'PW', 'PS', 'PA', 'PG', 'PY', 'PE', 'PH', 'PN', 'PL', 'PT', 'PR', 'QA', 'RE', 'RO', 'RU', 'RW', 'SH', 'KN', 'LC', 'PM', 'VC', 'WS', 'SM', 'ST', 'SA', 'SN', 'RS', 'SC', 'SL', 'SG', 'SK', 'SI', 'XG', 'SB', 'SO', 'ZA', 'GS', 'SS', 'ES', 'LK', 'SD', 'SR', 'SJ', 'SZ', 'SE', 'CH', 'SY', 'TW', 'TJ', 'TZ', 'TH', 'TG', 'TK', 'TO', 'TT', 'TN', 'TR', 'TM', 'TC', 'TV', 'UG', 'UA', 'AE', 'GB', 'US', 'UM', 'UY', 'UZ', 'VU', 'VA', 'VE', 'VN', 'VG', 'VI', 'WF', 'EH', 'YE', 'YU', 'ZM', 'ZW'],
//       //responseMessageEmail: true,
//       responseMessageEmail: false,
//       responseMessageUsername: false,
//       responseMessagePassword: false,
//       MessageUsername: false, 
//       MessageEmail: false,
//       checked: false,
//       val: '',
//       usertype: '',
//       Tokken: '',
//       RadioError: '',
//       country:"",
//       val1: ''

//     }

//     this.handleChange = this.handleChange.bind(this);
//     this.handleSubmit = this.handleSubmit.bind(this);
//     this.showHide = this.showHide.bind(this);
//     // this.handleChangeCountry=this.handleChangeCountry.bind(this);
//     // this.showHide1 = this.showHide1.bind(this);
//   }

//   handleChange(evt) {
//     const value = evt.target.value;
//     console.log(evt.target.value)
//     this.setState({ [evt.target.name]: evt.target.value });
//     let input = this.state.input;
//     input[evt.target.name] = evt.target.value;
//     this.setState({ input });
//   }


//   handleSubmit = event => {

//     event.preventDefault();
//     console.log("djgsfh", this.state.notificationId)

//     var usertype = '';

//     const { userType } = this.props.location

//     console.log(">>>>>", userType)

//     if (userType != undefined && userType != 'undefined') {
//       usertype = userType
//       localStorage.setItem('usertype', userType);

//     } else {
//       usertype = localStorage.getItem('usertype');
//     }

//     if (this.validate()) {

//       let input = {};
//       input["email"] = "";
//       input["username"] = "";
//       input["password"] = "";
//       // input["password2"] = "";
//       input["phone"] = "";
//       input["checkboxfield"] = "";
//       const val = localStorage.getItem('usertype');
//       console.log("kawww", this.state.fullname)
//       const data = this.state.country
//       // console.log("countrydata", data.countryCode)
//       const countryCodeData = data.countryCode

//       const sendData = {
//         fullname: this.state.fullname,
//         username: this.state.username,
//         email: this.state.email,
//         password: this.state.password,
//         user_type: usertype,
//         notification_id: this.state.notificationId,
//         device_type: "web",
//         // password2: this.state.password2,
//         // country_code:"+"+(this.state.phone),
//         country_code: countryCodeData
//       };
//       console.log("fff", sendData)
//       axios({
//         method: 'post',
//         url: API_BASE_URL + 'register_user/',
//         data: sendData
//       }).then(response => {
//         // localStorage.getItem('usertype');
//         console.log('123: ', localStorage.setItem('username', this.state.username))

//         this.setState({ Tokken: response.data.access })

//         // localStorage.setItem('UserToken',this.state.Tokken)

//         console.log("dgdhdjdjdjdkdkkd", this.state.Tokken)

//         if (response.data.success == 205) {
//           if (response.data.type == "user_exist_error") {
//             this.setState({ responseMessageEmail: true })
//             this.setState({ MessageEmail: true })
//             this.setState({MessageUsername: true })
//             // this.setState({responseMessageUsername:true})
//           }
//           else if (response.data.message == "Country code is required.") {
//             // this.setState({ responseMessageUsername: true })
//             this.setState({ responseMessageCountryCode: true })
//         }
//         }
//         else if (response.data.success == 200) {
//           // alert(localStorage.setItem('username', this.state.username,this.state.country))
//           // this.handleAdduser()        
//           localStorage.setItem('email', this.state.email)
//           localStorage.setItem('username', this.state.username)
//           localStorage.setItem('password', this.state.password)
//           localStorage.setItem('country_code', this.state.phone)
//           localStorage.setItem('phone_code', countryCodeData)
         
//           this.props.history.push("/otp");
          
//           // localStorage.setItem('fullname',this.state.fullname)
//         }
//       }).catch((error) => {
//         console.log(error)
//       });
//     }
//   }




//   componentDidMount = () => {
//     const user = localStorage.getItem('UserToken')
//     if (user && user !== 'undefined') {

//       this.props.history.push('/')
//     }

//     // console.log("fsyutsssss",ref)
//     const messaging = firebase.messaging();

//     if ("serviceWorker" in navigator) {

//       navigator.serviceWorker
//         .register("../firebase-messaging-sw.js")
//         .then((registration) => {
//           console.log("Registration successful, scope is:", registration.scope);
//           messaging.getToken({ vapidKey: 'BMH_Mj7u1uLtKOHj6YTGjjEQ0iSwHic_gSRgFwShoDkByoCWJRraoQjpr19g8ODTX3GXtNF-zHKHGF7Me0DLCIk', serviceWorkerRegistration: registration })
//             .then((currentToken) => {
//               if (currentToken) {
//                 console.log('current token for client: ', currentToken);
//                 this.setState({ notificationId: currentToken })
//                 this.setState({ id: currentToken })
//                 console.log("notitfication_id", this.state.id)
//               } else {
//                 console.log('No registration token available. Request permission to generate one.');
//               }
//             }).catch((err) => {
//               console.log('An error occurred while retrieving token. ', err);
              
//             });
//         })
//         .catch(function (err) {
//           console.log("Service worker registration failed, error:", err);
//         });
//     }

//     const val = localStorage.getItem('usertype');
//     console.log("user", val)
//     axios.get(API_BASE_URL + `data_list_country/`)
//       .then(res => {
//         this.setState({ phoneCode: res.data.country });
//       })
//   }

//   validatePhone(phone) {
//     var re = /^[0-9\b]+$/;
//     return re.test(phone);
//   }

//   // validate() {
//   //   let input = this.state.input;
//   //   let errors = {};
//   //   let isValid = true;

//   //   if (!this.state.isSelectedA) {
//   //     if (!input["selectvalue"]) {
//   //       isValid = false;
//   //       errors["selectvalue"] = "Please select Term & Condition.";
//   //     }
//   //   }

//   //   if (!input["username"]) {
//   //     isValid = false;
//   //     errors["username"] = "Please enter your phone no.";
//   //   }
//   //   if (typeof input["username"] !== "undefined") {
//   //     if (!input["username"].match(/^[0-9\b]+$/)) {
//   //       isValid = false;
//   //       errors["username"] = "Please enter only numbers";
//   //     }
//   //   }
//   //   if (typeof input["username"] !== "undefined") {
//   //     const re = /^\S*$/;
//   //     if (input["username"].length <= 9 || !re.test(input["username"])) {
//   //       isValid = false;
//   //       errors["username"] = "Please enter valid phone no.";
//   //     }
//   //   }

//   //   if (!input["email"]) {
//   //     isValid = false;
//   //     errors["email"] = "Please enter your email address.";
//   //   }
//   //   if (typeof input["email"] !== "undefined") {
//   //     var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
//   //     if (!pattern.test(input["email"])) {
//   //       isValid = false;
//   //       errors["email"] = "Please enter valid email address.";
//   //     }
//   //   }

//   //   if (!this.state.isSelectedA) {
//   //     if (!input["checkboxfield"]) {
//   //       isValid = false;
//   //       errors["checkboxfield"] = "Please Select Terms & Conditions.";
//   //     }
//   //   }

//   //   this.setState({ errors: errors });
//   //   return isValid;
//   // }


//   validate() {

//     let input = this.state.input;
//     let errors = {};
//     let isValid = true;

//     if (!input["fullname"]) {
//         isValid = false;
//         errors["fullname"] = "Fullname is required.";
//       }
      
//       if(typeof input["fullname"] !== "undefined"){
//         if(!input["fullname"].match(/^[aA-zZ\s]+$/)){
            
//            isValid = false;
//            errors["fullname"] = "Please enter only characters.";
//         }        
//      }


//     if (!input["email"]) {
//         isValid = false;
//         errors["email"] = "*Please enter your email-ID.";
//     }

//     if (typeof input["email"] !== "undefined") {
//         var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
//         if (!pattern.test(input["email"])) {
//             isValid = false;
//             errors["email"] = "Please enter valid email address.";

//         }
//     }

//     if (!input["username"]) {
//         isValid = false;
//         errors["username"] = "Phone Number is required.";

//     }

//     if(typeof input["username"] !== "undefined"){
//         if(!input["username"].match(/^[0-9\b]+$/)){
            
//            isValid = false;
//            errors["username"] = "Please enter only numbers.";
//         }        
//      }


//      if (typeof input["username"] !== "undefined") {

//         const re = /^\S*$/;

//         if (input["username"].length <= 9 || !re.test(input["username"])) {
//             isValid = false;
//             errors["username"] = "Please enter valid phone number.";

//         }

//     }

  
//      if (!input["password"]) {
//         isValid = false;
//         errors["password"] = "Password is required.";

//     }
//     // if (typeof fields["password"] !== "undefined") {
//     //     if (!fields["password"].match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {
//     //       formIsValid = false;
//     //       errors["password"] = "*Please enter secure and strong password.";
//     //     }
//     //   }

//     if (typeof input["password"] !== "undefined") {
//         // var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

//             if (!input["password"].match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {

//             isValid = false;

//             errors["password"] = "Password must contain at least One Number One Upper Case Letter One Lower Case Letter One Special Character.";

//     }
//     }


    

      
  

//     if (!this.state.isSelectedA) {
//         if (!input["checkboxfield"]) {
//             isValid = false;
//             errors["checkboxfield"] = "Please agree to the Terms of Services & Privacy Policy to proceed.";
//         }
//     }

//     this.setState({ errors: errors });

//     return isValid;
// }
//   showHide(e) {

//     this.setState({

//       type: this.state.type === 'input' ? 'password' : 'input'
//     })
//   }

//   handleModal() {
//     this.setState({ show: !this.state.show })
//   }


//   handleClick1 = () => {
  
//     this.setState({ show: !this.state.show })
//     this.setState({ isSelectedA: false })
//   }

//   handleClick2 = () => {

//     this.setState({ show: !this.state.show })
//     this.setState({ isSelectedA: true })
//   }

//   setCountryValue(v1) {
//     this.setState({ selected: v1 });
//   }

//   render() {
//     var list = [];
//     this.state.phoneCode.map((data) => {
//       list.push(data.country_code)
//     })
//     this.state.phonecode1 = list;

//     return (
//       <main>
//         <Header></Header>
//         <section className="login_form mx-auto margin_top">
//           <div className="container-fluid border-0 width_custom">
//             <div className="row">
//               <div className="col-md-8 col-sm-12 bg_banner_login login_main_banner"></div>
//               <div className="col-md-4 col-sm-12 m-auto">
//                 <div className="card2 border-0 my-3 login_card">
//                   <form autocomplete="off" className=" text-center loginpage" onSubmit={this.handleSubmit} method="post">
//                     <h2 >Student Registration</h2>
//                     <div className="row">
//                       <div className="col-sm-4">
//                       <div className="logo" >
//                       <Link to="#">
//                         {/* <img src="img/logo/logo.png" className="logo-sticky-none" alt="Logo"></img> */}
//                       </Link>
//                     </div>

//                     <div className="form-group input_container combine">
//                       <PhoneInput
//                         country={'us'}
//                         // value={this.state.phone}
//                         enableSearch={true}
//                         onChange={(phone, country) => this.setState({ phone, country })}
//                       />
//                       {/* <ReactPhoneInput
//                       inputExtraProps={{
//                         name: "phone",
//                       required: true,
//                        autoFocus: true
//                                }}
//                    defaultCountry={"sg"}
//                      value={this.state.phone}
//                     onChange={this.handleChangeCountry}
//                         /> */}

//                       {/* <ReactFlagsSelect
//                          autocomplete="false"
//                         //   countries={this.state.phonecode1}
//                           selected={this.state.selected}
//                           searchable={true}
//                           showOptionLabel={true}
//                           onSelect={code => this.setState({selected :code})}>
//                         </ReactFlagsSelect> */}
//                     </div>
//                       </div>
//                       <div className="col-sm-8">
//                       <div className="form-group">
//                       <input type="text"
//                         onChange={this.handleChange}
//                         maxLength="10"
//                         id="defaultLoginFormUsername"
//                         className="form-control my-input"
//                         name="username"
//                         value={this.state.input.username}
//                         placeholder="Mobile Number"
//                         //pattern="^\d{10}$"
//                          />
//                     </div>
//                       </div>
//                     </div>
                    

//                     <div className="form-group">
//                       <input type="text"
//                         onChange={this.handleChange}
//                         id="fullname"
//                         name="fullname"
//                         //   min="0"
//                         //   title="Use 6 or more characters with a minimum of number, symbol lowercase and uppercase letter." 
//                         value={this.state.input.fullname}
//                         className="form-control my-input"
//                         //  pattern='(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}'
//                         placeholder="Full Name"
//                          />

//            <div className="text-danger">{this.state.errors.fullname}</div>
//            <div className="text-danger">  {this.state.responseMessageFullname ? "Fullname Already Registered." : null} </div>  
//                       {/* <span className="password__show" onClick={this.showHide1}><i class="fa fa-eye" aria-hidden="true"><FontAwesomeIcon icon={faEye} /></i></span> */}

//                       {/* <div className="text-danger" >{this.state.errors.password2}</div> */}
//                     </div>
                    
//                     <div className="text-danger">  {this.state.responseMessageUsername ? "Phone No. already registered" : null} </div>
//                     <div className="text-danger" >{this.state.errors.username}</div>

//                     <div className="form-group">
//                       <input type="email"
//                         onChange={this.handleChange}
//                         id="defaultLoginFormEmail"
//                         name="email"
//                         className="form-control my-input"
//                         placeholder="E-mail"
//                         value={this.state.input.email}
//                          />
//                        <div className="text-danger">{this.state.errors.email}</div>
//                           <div className="text-danger">  {this.state.MessageEmail ? "Email Account already exists," : null} </div>                                 
//                     </div>

//                     <div className="form-group eye_iconn_show">
//                       <input type={this.state.type}
//                         onChange={this.handleChange}
//                         id="PasdefaultLoginFormPasswordsword" name="password"
//                         value={this.state.input.password}
//                         min="0"
//                         title="Use 6 or more characters with a minimum of number, symbol lowercase and uppercase letter."
//                         pattern='(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}'
//                         className="form-control my-input"
//                         placeholder="Password"
//                          />
//                          <div className="text-danger" > {this.state.errors.password}</div>
//                       <span className="password__show" onClick={this.showHide}>
//                         {this.state.type === 'input' ?
//                           <i class="fa fa-eye" aria-hidden="true"><FontAwesomeIcon icon={faEye} /></i>
//                           :
//                           <i class="fa fa-eye" aria-hidden="true"><FontAwesomeIcon icon={faEyeSlash} /></i>
//                         }
//                       </span>
//                       <div className="text-danger"> {this.state.errors.password}</div>
//                     </div>

//                     <div className="form-check input_checkbox_modal my-3">
//                       <input
//                         type="checkbox"
//                         checked={this.state.isSelectedA ? true : false}
//                         className="form-check-input"
//                         id="materialContactFormCopy"
//                         onClick={() => this.handleModal()}
//                          />
                         
//                       <label className="form-check-label signing_note" for="materialContactFormCopy">I accept the terms of use and privacy policy. i am over 18 years of age or a legal gaurdian.</label>
//                       <Modal show={this.state.show} onHide={() => this.handleModal()}>
//                         <Modal.Header className="allmodal_header modal-header" closeButton><h2>Terms of services & privacy policy</h2>
//                           {/* <div class="allmodal_header modal-header"><h2>Edit Name</h2><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button></div> */}
//                         </Modal.Header>
//                         <Modal.Body className="all_popupModal">
//                           <div class="row">
//                             <div class="col-md-12">
//                               <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

//                               <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
//                             </div>
//                           </div>
//                         </Modal.Body>
//                         <Modal.Footer className="text-center all_popups_buttonss custom_modal_footer">
//                           <Button className="cancel_bb" onClick={() => this.handleClick1()}> Cancel</Button>
//                           <Button className="submit_bb" onClick={() => this.handleClick2()}> Agree</Button>
//                         </Modal.Footer>
//                       </Modal>
//                     </div>
//                     <div className="text-danger">{this.state.errors.checkboxfield}</div>
//                     <div className="d-flex flex-row location justify-content-center">
//                       <a type="button">
//                         <img className="social_icons" src={fb} alt="" />
//                       </a>
//                       <a type="button">
//                         <img className="social_icons" src={google} alt="" />
//                       </a>
//                       {/* <a type="button">
//                         <img className="social_icons" src={twitter} alt="" />
//                         </a> */}
//                     </div>

//                     <Loader
//                       visible={this.state.visible}
//                       type="Oval"
//                       color="#00bacc"
//                       height={40}
//                       width={40}
//                       timeout={3000} //3 secs
//                     />
//                        <div className="text-danger">{this.state.responseMessageUserType ? "UserType is required " : null} </div>



//                     <div className="form-group">
//                       <button className="btn  btn-block  send-button border_radius_20 tx-tfm" onSubmit={this.handleSubmit} type="submit">Submit</button>
//                     </div>

//                     <p className="signup_pera">
//                       <Link to="login">If you already have an account, SignIn.</Link>
//                     </p>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//         <Footer></Footer>
//       </main>
//     );
//   }
// }
// export default signUp;




import React, { useState } from 'react';
// import ReactFlagsSelect from 'react-flags-select';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import axios from "axios";
import { API_BASE_URL } from '../../config/api.js';
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../common/header";
import Footer from "../common/footer";
import firebase from '../../firebase.js';
import "styled-components";
import fb from "../../assets/img/categories/Icon/facebook.png";
import google from "../../assets/img/categories/Icon/google.png";
import twitter from "../../assets/img/categories/Icon/twitter.png";
import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '../../../node_modules/@fortawesome/free-solid-svg-icons'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import validator from 'validator'
class signUp extends React.Component {
constructor() {
super();
this.state = {
visible: false,
phone: '',
username: '',
responseMessageCountryCode:false,
fullnamevalidationerror:'',
emailvalidationerror:'',
usernameResponseerror:'',
emailvalidation:false,
fullnamevalidation:false,
passwordvalidationerror:'',
EmailResponseerror:'',
usernamevalidationerror:'',
passwordvalidation:false,
usernamevalidation:false,
id: '',
email: '',
password: '',
fullname: '',
notificationId: '',
responseMessageUserType:false,
// password2: '',
type: 'password',
// type1: 'password2',
country_code: 'AD',
countryCode: [],
input: {},
errors: {},
value: '',
selected: 'US',
phoneCode: [],
// phonecode1:["IN"],
phonecode1: ['AF', 'AL', 'DZ', 'AS', 'AD', 'AO', 'AI', 'AQ', 'AG', 'AR', 'AM', 'AW', 'AU', 'AT', 'AZ', 'BS', 'BH', 'BD', 'BB', 'BY', 'BE', 'BZ', 'BJ', 'BM', 'BT', 'BO', 'BA', 'BW', 'BV', 'BR', 'IO', 'BN', 'BG', 'BF', 'BI', 'KH', 'CM', 'CA', 'CV', 'KY', 'CF', 'TD', 'CL', 'CN', 'CX', 'CC', 'CO', 'KM', 'CG', 'CD', 'CK', 'CR', 'CI', 'HR', 'CU', 'CY', 'CZ', 'DK', 'DJ', 'DM', 'DO', 'TL', 'EC', 'EG', 'SV', 'GQ', 'ER', 'EE', 'ET', 'XA', 'FK', 'FO', 'FJ', 'FI', 'FR', 'GF', 'PF', 'TF', 'GA', 'GM', 'GE', 'DE', 'GH', 'GI', 'GR', 'GL', 'GD', 'GP', 'GU', 'GT', 'GG', 'GN', 'GW', 'GY', 'HT', 'HM', 'HN', 'HK', 'HU', 'IS', 'IN', 'ID', 'IR', 'IQ', 'IE', 'IL', 'IT', 'JM', 'JP', 'JE', 'JO', 'KZ', 'KE', 'KI', 'KP', 'KR', 'KW', 'KG', 'LA', 'LV', 'LB', 'LS', 'LR', 'LY', 'LI', 'LT', 'LU', 'MO', 'MK', 'MG', 'MW', 'MY', 'MV', 'ML', 'MT', 'IM', 'MH', 'MQ', 'MR', 'MU', 'YT', 'MX', 'FM', 'MD', 'MC', 'MN', 'MS', 'MA', 'MZ', 'MM', 'NA', 'NR', 'NP', 'AN', 'NL', 'NC', 'NZ', 'NI', 'NE', 'NG', 'NU', 'NF', 'MP', 'NO', 'OM', 'PK', 'PW', 'PS', 'PA', 'PG', 'PY', 'PE', 'PH', 'PN', 'PL', 'PT', 'PR', 'QA', 'RE', 'RO', 'RU', 'RW', 'SH', 'KN', 'LC', 'PM', 'VC', 'WS', 'SM', 'ST', 'SA', 'SN', 'RS', 'SC', 'SL', 'SG', 'SK', 'SI', 'XG', 'SB', 'SO', 'ZA', 'GS', 'SS', 'ES', 'LK', 'SD', 'SR', 'SJ', 'SZ', 'SE', 'CH', 'SY', 'TW', 'TJ', 'TZ', 'TH', 'TG', 'TK', 'TO', 'TT', 'TN', 'TR', 'TM', 'TC', 'TV', 'UG', 'UA', 'AE', 'GB', 'US', 'UM', 'UY', 'UZ', 'VU', 'VA', 'VE', 'VN', 'VG', 'VI', 'WF', 'EH', 'YE', 'YU', 'ZM', 'ZW'],
//responseMessageEmail: true,
responseMessageEmail: false,
responseMessageUsername: false,
responseMessagePassword: false,
MessageUsername: false, 
MessageEmail: false,
checked: false,
val: '',
usertype: '',
Tokken: '',
RadioError: '',
country:"",
val1: ''
}
this.handleChange = this.handleChange.bind(this);
this.handleSubmit = this.handleSubmit.bind(this);
this.showHide = this.showHide.bind(this);
// this.handleChangeCountry=this.handleChangeCountry.bind(this);
// this.showHide1 = this.showHide1.bind(this);
}
handleChange(evt) {
const value = evt.target.value;
console.log(evt.target.value)
this.setState({ [evt.target.name]: evt.target.value });
let input = this.state.input;
input[evt.target.name] = evt.target.value;
this.setState({ input });
this.setState({fullnamevalidation:false})
this.setState({usernamevalidation:false})
this.setState({emailvalidation:false})
this.setState({passwordvalidation:false})
if(evt.target.name=="fullname"){
   this.setState({fullnamevalidationerror:evt.target.value})
 }
 if(evt.target.name=="email"){
   this.setState({emailvalidationerror:evt.target.value})
 }
 if(evt.target.name==="password"){
   this.setState({passwordvalidationerror:evt.target.value})
 }
 if(evt.target.name==="username"){
   this.setState({usernamevalidationerror:evt.target.value})
 }
}
handleSubmit = event => {
event.preventDefault();
this.setState({responseMessageCountryCode:''})
console.log("djgsfh", this.state.notificationId)
var usertype = '';
const { userType } = this.props.location
console.log(">>>>>", userType)
if (userType != undefined && userType != 'undefined') {
usertype = userType
localStorage.setItem('usertype', userType);
} else {
usertype = localStorage.getItem('usertype');
}
if (this.validate()) {
let input = {};
input["email"] = "";
input["username"] = "";
input["password"] = "";
// input["password2"] = "";
input["phone"] = "";
input["checkboxfield"] = "";
const val = localStorage.getItem('usertype');
console.log("kawww", this.state.fullname)
const data = this.state.country
// console.log("countrydata", data.countryCode)
const countryCodeData = data.countryCode
const sendData = {
fullname: this.state.fullname,
username: this.state.username,
email: this.state.email,
password: this.state.password,
user_type: usertype,
notification_id: this.state.notificationId,
device_type: "web",
// password2: this.state.password2,
// country_code:"+"+(this.state.phone),
country_code: countryCodeData
};
console.log("fff", sendData)
axios({
method: 'post',
url: API_BASE_URL + 'register_user/',
data: sendData
}).then(response => {
// localStorage.getItem('usertype');
console.log('123: ', localStorage.setItem('username', this.state.username))
this.setState({ Tokken: response.data.access })
// localStorage.setItem('UserToken',this.state.Tokken)
console.log("dgdhdjdjdjdkdkkd", this.state.Tokken)
// if (response.data.success === 205) {
// if (response.data.type == "user_exist_error") {
//    this.setState({MessageUsername: true })
// this.setState({ responseMessageEmail: true })
// this.setState({ MessageEmail: true })


// }

// }
if(response.data.success==205){
   if(response.data.message==='Country code is required.'){
      this.setState({ responseMessageCountryCode: true })
   }
   else if(response.data.message=='This mobile number allredy in use. Please use another one.'){
      this.setState({ usernameResponseerror: true })
   }
   else if(response.data.message=='This email address allredy in use. Please use another one.'){
      this.setState({EmailResponseerror:true})
   }

}

else if (response.data.success == 200) {
       
localStorage.setItem('email', this.state.email)
localStorage.setItem('username', this.state.username)
localStorage.setItem('password', this.state.password)
localStorage.setItem('country_code', this.state.phone)
localStorage.setItem('phone_code', countryCodeData)
this.props.history.push("/otp");
// localStorage.setItem('fullname',this.state.fullname)
}
}).catch((error) => {
console.log(error)
});
}
}
componentDidMount = () => {
const user = localStorage.getItem('UserToken')
if (user && user !== 'undefined') {
this.props.history.push('/')
}
// console.log("fsyutsssss",ref)
const messaging = firebase.messaging();
if ("serviceWorker" in navigator) {
navigator.serviceWorker
.register("../firebase-messaging-sw.js")
.then((registration) => {
console.log("Registration successful, scope is:", registration.scope);
messaging.getToken({ vapidKey: 'BMH_Mj7u1uLtKOHj6YTGjjEQ0iSwHic_gSRgFwShoDkByoCWJRraoQjpr19g8ODTX3GXtNF-zHKHGF7Me0DLCIk', serviceWorkerRegistration: registration })
.then((currentToken) => {
if (currentToken) {
console.log('current token for client: ', currentToken);
this.setState({ notificationId: currentToken })
this.setState({ id: currentToken })
console.log("notitfication_id", this.state.id)
} else {
console.log('No registration token available. Request permission to generate one.');
}
}).catch((err) => {
console.log('An error occurred while retrieving token. ', err);
});
})
.catch(function (err) {
console.log("Service worker registration failed, error:", err);
});
}
const val = localStorage.getItem('usertype');
console.log("user", val)
axios.get(API_BASE_URL + `data_list_country/`)
.then(res => {
this.setState({ phoneCode: res.data.country });
})
}
validatePhone(phone) {
var re = /^[0-9\b]+$/;
return re.test(phone);
}
// validate() {
//   let input = this.state.input;
//   let errors = {};
//   let isValid = true;
//   if (!this.state.isSelectedA) {
//     if (!input["selectvalue"]) {
//       isValid = false;
//       errors["selectvalue"] = "Please select Term & Condition.";
//     }
//   }
//   if (!input["username"]) {
//     isValid = false;
//     errors["username"] = "Please enter your phone no.";
//   }
//   if (typeof input["username"] !== "undefined") {
//     if (!input["username"].match(/^[0-9\b]+$/)) {
//       isValid = false;
//       errors["username"] = "Please enter only numbers";
//     }
//   }
//   if (typeof input["username"] !== "undefined") {
//     const re = /^\S*$/;
//     if (input["username"].length <= 9 || !re.test(input["username"])) {
//       isValid = false;
//       errors["username"] = "Please enter valid phone no.";
//     }
//   }
//   if (!input["email"]) {
//     isValid = false;
//     errors["email"] = "Please enter your email address.";
//   }
//   if (typeof input["email"] !== "undefined") {
//     var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
//     if (!pattern.test(input["email"])) {
//       isValid = false;
//       errors["email"] = "Please enter valid email address.";
//     }
//   }
//   if (!this.state.isSelectedA) {
//     if (!input["checkboxfield"]) {
//       isValid = false;
//       errors["checkboxfield"] = "Please Select Terms & Conditions.";
//     }
//   }
//   this.setState({ errors: errors });
//   return isValid;
// }
validate() {
let input = this.state.input;
let errors = {};
let isValid = true;
// if (!input["fullname"]) {
// isValid = false;
// errors["fullname"] = "Fullname is required.";
// }
// if(typeof input["fullname"] !== "undefined"){
// if(!input["fullname"].match(/^[aA-zZ\s]+$/)){
// isValid = false;
// errors["fullname"] = "Please enter only characters.";
// }        
// }
if(this.state.fullnamevalidationerror===""){
   this.setState({fullnamevalidation:'*Please Enter Your FullName'})
}

if(this.state.emailvalidationerror===""){
   this.setState({emailvalidation:'*Please enter your email-ID.'})
}
if(!validator.isEmail(this.state.email)){
   this.setState({emailvalidation:'*Please Enter Valid Email'})

   
 }


// if (!input["email"]) {
// isValid = false;
// errors["email"] = "*Please enter your email-ID.";
// }
// if (typeof input["email"] !== "undefined") {
// var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
// if (!pattern.test(input["email"])) {
// isValid = false;
// errors["email"] = "Please enter valid email address.";
// }
// }
// if (!input["username"]) {
// isValid = false;
// errors["username"] = "Phone Number is required.";
// }
// if(typeof input["username"] !== "undefined"){
// if(!input["username"].match(/^[0-9\b]+$/)){
// isValid = false;
// errors["username"] = "Please enter only numbers.";
// }        
// }
// if (typeof input["username"] !== "undefined") {
// const re = /^\S*$/;
// if (input["username"].length <= 9 || !re.test(input["username"])) {
// isValid = false;
// errors["username"] = "Please enter valid phone number.";
// }
// }



if(this.state.usernamevalidationerror===''){
   this.setState({usernamevalidation:'*Please enter your username.'})
 }

 else if(this.state.username.length<=8){
   this.setState({usernamevalidation:"*Please Enter valid Number "})
 }


if(this.state.passwordvalidationerror===""){
   this.setState({passwordvalidation:'*Please enter your password.'})
}
// if (!input["password"]) {
//    isValid = false;
//    errors["password"] = "*Please enter your password.";
//  }
if(this.state.passwordvalidationerror!=='') {

   // if (typeof fields["password"] !== "undefined") {
     if (!input["password"].match(/^.*(?=.{5,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {
      isValid = false;
       this.setState({passwordvalidation:'Password must contain at least One Number One Upper Case Letter One Lower Case Letter One Special Character.'})

       // this.setState({})
     }
   
 // }
}

// if (!input["password"]) {
// isValid = false;
// errors["password"] = "Password is required.";
// }
// if (typeof fields["password"] !== "undefined") {
//     if (!fields["password"].match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {
//       formIsValid = false;
//       errors["password"] = "*Please enter secure and strong password.";
//     }
//   }
// if (typeof input["password"] !== "undefined") {
// // var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
// if (!input["password"].match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {
// isValid = false;
// errors["password"] = "Password must contain at least One Number One Upper Case Letter One Lower Case Letter One Special Character.";
// }
// }
if (!this.state.isSelectedA) {
if (!input["checkboxfield"]) {
isValid = false;
errors["checkboxfield"] = "Please agree to the Terms of Services & Privacy Policy to proceed.";
}
}
this.setState({ errors: errors });
return isValid;
}
showHide(e) {
this.setState({
type: this.state.type === 'input' ? 'password' : 'input'
})
}
handleModal() {
this.setState({ show: !this.state.show })
}
handleClick1 = () => {
this.setState({ show: !this.state.show })
this.setState({ isSelectedA: false })
}
handleClick2 = () => {
this.setState({ show: !this.state.show })
this.setState({ isSelectedA: true })
}
setCountryValue(v1) {
this.setState({ selected: v1 });
}
render() {
var list = [];
this.state.phoneCode.map((data,index) => {
list.push(data.country_code)
})
this.state.phonecode1 = list;
return (
<main>
   <Header></Header>
   <section className="login_form mx-auto margin_top">
      <div className="container-fluid border-0 width_custom">
         <div className="row">
            <div className="col-md-8 col-sm-12 bg_banner_login login_main_banner"></div>
            <div className="col-md-4 col-sm-12 m-auto">
               <div className="card2 border-0 my-3 login_card">
                  <form autoComplete="off" className=" text-center loginpage register_form_main" onSubmit={this.handleSubmit} method="post">
                     <h2 >Student Registration</h2>
                     <div className="row">
                        <div className="col-sm-3">
                           <div className="form-group input_container combine country_code">
                              <PhoneInput
                              country={'us'}
                              // value={this.state.phone}
                              enableSearch={true}
                              onChange={(phone, country) => this.setState({ phone, country })}
                              />
                             
                           </div>
                        </div>
                        <div className="col-sm-9">
                           <div className="form-group">
                              <input type="text"
                                 onChange={this.handleChange}
                                 maxLength="10"
                                 id="defaultLoginFormUsername"
                                 className="form-control my-input"
                                 name="username"
                                 value={this.state.input.username}
                                 placeholder="Mobile Number"
                                 //pattern="^\d{10}$"
                                 />
                           </div>
                        </div>
                     </div>
                     <div className="text-danger">{this.state.responseMessageCountryCode ?'Please Enter Valid CountryCode':null}</div>  
                     <div className="text-danger">{this.state.usernameResponseerror?'This mobile number allredy in use. Please use another one.':null}</div>
                     <div className="text-danger">  {this.state.usernamevalidation}</div>
                     <div className="text-danger">{this.state.MessageUsername}</div>
                     <div className="form-group">
                        <input type="text"
                        onChange={this.handleChange}
                        id="fullname"
                        name="fullname"
                      
                        value={this.state.input.fullname}
                        className="form-control my-input"
                        //  pattern='(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}'
                        placeholder="Full Name"
                        />
                        {/* <div className="text-danger">{this.state.errors.fullname}</div> */}
                        <div className="text-danger">  {this.state.responseMessageFullname ? "Fullname Already Registered." : null} </div>
                        {/* 
                        <span className="password__show" onClick={this.showHide1}>
                           <i class="fa fa-eye" aria-hidden="true">
                              <FontAwesomeIcon icon={faEye} />
                           </i>
                        </span>
                        */}
                        {/* 
                        <div className="text-danger" >{this.state.errors.password2}</div>
                        */}
                     </div>
                     <div className="text-danger"> {this.state.fullnamevalidation}</div>
                     {/* <div className="text-danger">  {this.state.responseMessageUsername ? "Phone No. already registered" : null} </div>
                     <div className="text-danger" >{this.state.errors.username}</div> */}
                     <div className="form-group">
                        <input type="email"
                           onChange={this.handleChange}
                           id="defaultLoginFormEmail"
                           name="email"
                           className="form-control my-input"
                           placeholder="E-mail"
                           value={this.state.input.email}
                           />
                        {/* <div className="text-danger">{this.state.errors.email}</div> */}
                        <div className="text-danger">  {this.state.MessageEmail ? "Email Account already exists," : null} </div>
                     </div>
                     <div className="text-danger">{this.state.emailvalidation}</div>
                     <div className="text-danger">{this.state.EmailResponseerror?'This email address allredy in use. Please use another one.':null}</div>
                     <div className="form-group eye_iconn_show">
                        <input type={this.state.type}
                           onChange={this.handleChange}
                           id="PasdefaultLoginFormPasswordsword" name="password"
                           value={this.state.input.password}
                           // min="0"
                           // title="Use 6 or more characters with a minimum of number, symbol lowercase and uppercase letter."
                           // pattern='(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}'
                           className="form-control my-input"
                           placeholder="Password"
                           />
                               <div className="text-danger" >  {this.state.passwordvalidation}</div>
                        {/* <div className="text-danger" > {this.state.errors.password}</div> */}
                        <span className="password__show" onClick={this.showHide}>
                           {this.state.type === 'input' ?
                           <i className="fa fa-eye" aria-hidden="true">
                              <FontAwesomeIcon icon={faEye} />
                           </i>
                           :
                           <i className="fa fa-eye" aria-hidden="true">
                              <FontAwesomeIcon icon={faEyeSlash} />
                           </i>
                           }
                        </span>
                        {/* <div className="text-danger"> {this.state.errors.password}</div> */}
                     </div>
                     <div className="form-check input_checkbox_modal my-3">
                        <input
                        type="checkbox"
                        checked={this.state.isSelectedA ? true : false}
                        className="form-check-input"
                        id="materialContactFormCopy"
                        onClick={() => this.handleModal()}
                        />
                        <label className="form-check-label signing_note" htmlFor="materialContactFormCopy">I accept the terms of use and privacy policy. i am over 18 years of age or a legal gaurdian.</label>
                        <Modal show={this.state.show} onHide={() =>
                           this.handleModal()}>
                           <Modal.Header className="allmodal_header modal-header" closeButton>
                              <h2>Terms of services & privacy policy</h2>
                              {/* 
                              <div class="allmodal_header modal-header">
                                 <h2>Edit Name</h2>
                                 <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                              </div>
                              */}
                           </Modal.Header>
                           <Modal.Body className="all_popupModal">
                              <div className="row">
                                 <div className="col-md-12">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                 </div>
                              </div>
                           </Modal.Body>
                           <Modal.Footer className="text-center all_popups_buttonss custom_modal_footer">
                              <Button className="cancel_bb" onClick={() => this.handleClick1()}> Cancel</Button>
                              <Button className="submit_bb" onClick={() => this.handleClick2()}> Agree</Button>
                           </Modal.Footer>
                        </Modal>
                     </div>
                     <div className="text-danger">{this.state.errors.checkboxfield}</div>
                     <div className="d-flex flex-row location justify-content-center">
                        <a type="button">
                        <img className="social_icons" src={fb} alt="" />
                        </a>
                        <a type="button">
                        <img className="social_icons" src={google} alt="" />
                        </a>
                        {/* <a type="button">
                        <img className="social_icons" src={twitter} alt="" />
                        </a> */}
                     </div>
                     

                     <Loader
                        visible={this.state.visible}
                        type="Oval"
                        color="#00bacc"
                        height={40}
                        width={40}
                        timeout={3000} //3 secs
                        />
 
                     <div className="text-danger">{this.state.responseMessageUserType ? "UserType is required " : null} </div>
                     <div className="form-group">
                        <button className="btn  btn-block  send-button border_radius_20 tx-tfm" onSubmit={this.handleSubmit} type="submit">Submit</button>
                     </div>
                     <p className="signup_pera">
                        <Link to="login">
                        If you already have an account, SignIn.</Link>
                     </p>
                  </form>
               </div>
            </div>
         </div>
      </div>
   </section>
   <Footer></Footer>
</main>
);
}
}
export default signUp;