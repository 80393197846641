// import React from 'react';
// import Header from "../common/header";
// import axios from 'axios';
// import { API_BASE_URL } from '../../config/api.js'
// import Footer from "../common/footer";
// import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
// import { faAngleDown, faEye } from '@fortawesome/free-solid-svg-icons';
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// import Loader from "react-loader-spinner";
// const SECTIONS = [
//   {
//     title: 'Sunday',
//     content: '',
//   },
//   {
//     title: 'Monday',
//     content: '',
//   },
//   {
//     title: 'Tuesday',
//     content: '',
//   },
//   {
//     title: 'Wednesday',
//     content: '',
//   },
//   {
//     title: 'Thursday',
//     content: '',
//   },
//   {
//     title: 'Friday',
//     content: '',
//   },
//   {
//     title: 'Saturday',
//     content: '',
//   },];
// class Availability extends React.Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       activeSections: [],
//       activeallcheck:false,
//       button: true,
//       visible: false,
//       CheckBox1: '',
//       CheckBox2: '',
//       isSelectedA: false,
//       isSelectedB: false,
//       isSelectedAll: false,
//       status: '',
//       selectedView: [],
//       selectedViewM: [],
//       selectedViewT: [],
//       selectedViewW: [],
//       selectedViewTh: [],
//       selectedViewF: [],
//       selectedViewS: [],
//       dataID: [],
//       id: "",
//       colorToggleID: '',
//       value: false,
//       wholeweek: false,
//       sundaystatus: false,
//       mondaystatus: false,
//       tuesdaystatus: false,
//       wednesdaystatus: false,
//       thursdaystatus: false,
//       fridaystatus: false,
//       saturdaystatus: false,
//       status: true,
//       wholeweek: false,
//       wholeweek1: 0,
//       sundaystatus: false,
//       sundaystatus1: 0,
//       mondaystatus: false,
//       mondaystatus1: 0,
//       tuesdaystatus: false,
//       tuesdaystatus1: 0,
//       wednesdaystatus: false,
//       wednesdaystatus1: 0,
//       thursdaystatus: false,
//       thursdaystatus1: 0,
//       fridaystatus: false,
//       fridaystatus1: 0,
//       saturdaystatus: false,
//       saturdaystatus1: 0,
//       modelVisibale: false,
//       mydynmicvalue: [],
//       her: [],
//       mystatusmanage: '',
//       timeArray: [
//         {
//           "id": 1, 'value': '12AM-1AM', to: "/cms",
//           className: "side_nav_item"
//         },
//         {
//           "id": 2, 'value': '1AM-2AM', to: "/cms",
//           className: "side_nav_item"
//         },
//         {
//           "id": 3, 'value': '2AM-3AM', to: "/cms",
//           className: "side_nav_item"
//         },
//         {
//           "id": 4, 'value': '3AM-4AM', to: "/cms",
//           className: "side_nav_item"
//         },
//         {
//           "id": 5, 'value': '4AM-5AM', to: "/cms",
//           className: "side_nav_item"
//         },
//         {
//           "id": 6, 'value': '5AM-6AM',
//           className: "side_nav_item", to: "/cms",
//         },
//         { "id": 7, 'value': '6AM-7AM', className: "side_nav_item", to: "/cms", },
//         { "id": 8, 'value': '7AM-8AM', className: "side_nav_item", to: "/cms", },
//         { "id": 9, 'value': '8AM-9AM', className: "side_nav_item", to: "/cms", },
//         { "id": 10, 'value': '9AM-10AM', className: "side_nav_item", to: "/cms", },
//         { "id": 11, 'value': '10AM-11AM', className: "side_nav_item", to: "/cms", },
//         { "id": 12, 'value': '11AM-12PM', className: "side_nav_item", to: "/cms", },
//         { "id": 13, 'value': '12PM-1PM', className: "side_nav_item", to: "/cms", },
//         { "id": 14, 'value': '1PM-2PM', className: "side_nav_item", to: "/cms", },
//         { "id": 15, 'value': '2PM-3PM', className: "side_nav_item", to: "/cms", },
//         { "id": 16, 'value': '3PM-4PM', className: "side_nav_item", to: "/cms", },
//         { "id": 17, 'value': '4PM-5PM', className: "side_nav_item", to: "/cms", },
//         { "id": 18, 'value': '5PM-6PM', className: "side_nav_item", to: "/cms", },
//         { "id": 19, 'value': '6PM-7PM', className: "side_nav_item", to: "/cms", },
//         { "id": 20, 'value': '7PM-8PM', className: "side_nav_item", to: "/cms", },
//         { "id": 21, 'value': '8PM-9PM', className: "side_nav_item", to: "/cms", },
//         { "id": 22, 'value': '9PM-10PM', className: "side_nav_item", to: "/cms", },
//         { "id": 23, 'value': '10PM-11PM', },
//         { "id": 24, 'value': '11PM-12PM' },
//       ],
//       SECTIONS: [
//         {
//           title: 'Sunday',
//           content: '',
//         },
//         {
//           title: 'Monday',
//           content: '',
//         },
//         {
//           title: 'Tuesday',
//           content: '',
//         },
//         {
//           title: 'Wednesday',
//           content: '',
//         },
//         {
//           title: 'Thursday',
//           content: '',
//         },
//         {
//           title: 'Friday',
//           content: '',
//         },
//         {
//           title: 'Saturday',
//           content: '',
//         },],
//       activeLink: null,
//       myvaluestatus:''

//     }
//     this.handleSubmit = this.handleSubmit.bind(this);
//     this.handleWeek = this.handleWeek.bind(this);
//     this.handleWeek1 = this.handleWeek1.bind(this);
//     this.handleCheck = this.handleCheck.bind(this);

//   }


//   handleSubmit = event => {
//     console.log("-=-=-=-=-=-=-=-=", this.state.selectedViewW.length)

//     event.preventDefault();
    
//     // if(this.state.selectedViewW.length > 0 
//     //   || this.state.selectedViewM.length > 0  
//     //   ||this.state.selectedViewT.length > 0 
//     //   ||this.state.selectedViewTh.length > 0 
//     //   ||this.state.selectedViewF.length > 0   
//     //   ||this.state.selectedViewS.length > 0  
//     //   ||this.state.selectedView.length > 0 || 
//     //   this.state.wholeweek1==0  ){


//     const sendData = {
//       "items": [

//         {
//           "whole_week": this.state.wholeweek1,
//           "days": {
//             "monday_status": this.state.mondaystatus1,
//             "monday_time": this.state.selectedViewM,
//             "tuesday_status": this.state.tuesdaystatus1,
//             "tuesday_time": this.state.selectedViewT,
//             "wednesday_status": this.state.wednesdaystatus1,
//             "wednesday_time": this.state.selectedViewW,
//             "thursday_status": this.state.thursdaystatus1,
//             "thursday_time": this.state.selectedViewTh,
//             "friday_status": this.state.fridaystatus1,
//             "friday_time": this.state.selectedViewF,
//             "saturday_status": this.state.saturdaystatus1,
//             "saturday_time": this.state.selectedViewS,
//             "sunday_status": this.state.sundaystatus1,
//             "sunday_time": this.state.selectedView
//           }
//         },
//       ],
//       "form_status": "Form-5"
//     }
 
//     const UserToken = localStorage.getItem('UserToken');
//     if (UserToken != '') {

//       const json = JSON.stringify(sendData);
  

//       this.setState({ visible: true })
//       axios({
//         method: 'POST',
//         url: API_BASE_URL + "availability/",
//         headers: { 'Authorization': "Bearer " + UserToken },
//         data: json,
//         headers: {
//           'Authorization': 'Bearer ' + UserToken,

//           'Content-Type': 'application/json'
//         },
//       }).then((response) => {

//         console.log("my full day value", response.data)

//         this.setState({ visible: false })

//         if (response.data.success == 200) {
 

//           //  this.props.history.push('tutor_home');
          
//         }

//       }).catch((error) => {
//         console.log(error)
//           ;
//       })
//     }
//   }

//   handleCheck = async(event, day) => {

   
//     let daataaaa=this.state.dayname

//     let status=event.target.checked
//     let value=day
//     console.log('value111: ', value, 'status: ', status)
//   //  console.log()         
//     if (value == 'Sunday' && status == false) {
//       this.setState({ value: 'Sunday' })
//       this.setState({ isSelectedA: true })
//       this.setState({ sundaystatus: true })
//       this.setState({ sundaystatus1: 0 })
//     } else
//       if (value == 'Sunday' && status == true) {
//         this.setState({ value: 'Sunday1' })
//         this.setState({ isSelectedA: false })
//         this.setState({ sundaystatus: false })
//         this.setState({ sundaystatus1: 1 })
//       }

//     if (value == 'Monday' && status == false) {
//       this.setState({ value: 'Monday' })
//       this.setState({ isSelectedA: true })
//       this.setState({ mondaystatus: true })
//       this.setState({ mondaystatus1: 0 })
//     } else
//       if (value == 'Monday' && status == true) {
//         this.setState({ value: 'Monday1' })
//         this.setState({ isSelectedA: false })
//         this.setState({ mondaystatus: false })
//         this.setState({ mondaystatus1: 1 })
//       }

//     if (value == 'Tuesday' && status == false) {
//       this.setState({ value: 'Tuesday' })
//       this.setState({ isSelectedA: true })
//       this.setState({ tuesdaystatus: true })
//       this.setState({ tuesdaystatus1: 0 })
//     } else
//       if (value == 'Tuesday' && status == true) {
//         this.setState({ value: 'Tuesday1' })
//         this.setState({ tuesdaystatus: false })
//         this.setState({ tuesdaystatus1: 1 })
//         this.setState({ isSelectedA: false })
//       }

//     if (value == 'Wednesday' && status == false) {
//       this.setState({ value: 'Wednesday' })
//       this.setState({ isSelectedA: true })
//       this.setState({ wednesdaystatus: true })
//       this.setState({ wednesdaystatus1: 0 })
//     } else
//       if (value == 'Wednesday' && status == true) {
//         this.setState({ value: 'Wednesday1' })
//         this.setState({ wednesdaystatus: false })
//         this.setState({ wednesdaystatus1: 1 })
//         this.setState({ isSelectedA: false })
//       }

//     if (value == 'Thursday' && status == false) {
//       this.setState({ value: 'Thursday' })
//       this.setState({ isSelectedA: true })
//       this.setState({ thursdaystatus: true })
//       this.setState({ thursdaystatus1: 0 })
//     } else
//       if (value == 'Thursday' && status == true) {
//         this.setState({ value: 'Thursday1' })
//         this.setState({ thursdaystatus: false })
//         this.setState({ thursdaystatus1: 1 })
//         this.setState({ isSelectedA: false })
//       }

//     if (value == 'Friday' && status == false) {
//       this.setState({ value: 'Friday' })
//       this.setState({ isSelectedA: true })
//       this.setState({ fridaystatus: true })
//       this.setState({ fridaystatus1: 0 })
//     } else
//       if (value == 'Friday' && status == true) {
//         this.setState({ value: 'Friday1' })
//         this.setState({ isSelectedA: false })
//         this.setState({ fridaystatus: false })
//         this.setState({ fridaystatus1: 1 })
//       }

//     if (value == 'Saturday' && status == false) {
//       this.setState({ value: 'Saturday' })
//       this.setState({ isSelectedA: true })
//       this.setState({ saturdaystatus: true })
//       this.setState({ saturdaystatus1: 0 })
//     } else
//       if (value == 'Saturday' && status == true) {
//         this.setState({ value: 'Saturday1' })
//         this.setState({ isSelectedA: false })
//         this.setState({ saturdaystatus: false })
//         this.setState({ saturdaystatus1: 1 })
//       }
//   }


//   handleWeek1 = (e) => {
//     console.log('trrr',e.target.checked);
//   }

//   handleWeek = (e,value) => {
//     console.log('hrrr',e.target.checked, 'value',value)

   
// let status=e.target.checked

// let val=value
// this.setState({activeallcheck:e.target.checked})
  


//     if (status==false && val=="Wholeweek") {
//       this.setState({ isSelectedAll: true })
//       this.setState({ wholeweek: true })
//       this.setState({ isSelectedB: true })
//       this.setState({ mondaystatus1: 0 })
//       this.setState({ tuesdaystatus1: 0 })
//       this.setState({ wednesdaystatus1: 0 })
//       this.setState({ thursdaystatus1: 0 })
//       this.setState({ fridaystatus1: 0 })
//       this.setState({ saturdaystatus1: 0 })
//       this.setState({ sundaystatus1: 0 })
      
//       this.setState({ wholeweek1: 0 })
//       this.setState({ activeSections: "" })

//     } else
//       if (status == true && val=="Wholeweek") {
//         this.setState({ isSelectedAll: false })
//         this.setState({ wholeweek: false })
//         this.setState({ isSelectedB: false })
//         this.setState({ mondaystatus1: 1 })
//           this.setState({ tuesdaystatus1: 1 })
//         this.setState({ wednesdaystatus1: 1 })
//         this.setState({ thursdaystatus1: 1 })
//         this.setState({ fridaystatus1: 1 })
//         this.setState({ saturdaystatus1: 1 })
//         this.setState({ sundaystatus1: 1 })
//         this.setState({ wholeweek1: 1 })
//       }

//     let titleName = e.target.name;
   
//     const checked = e.target.checked;
//     this.setState({ value: checked })
//     this.setState({ handleWholeweek: e.target.checked })
 


//   }

//   changeColor(a, ID) {
//     console.log("myname", ID)
//     this.setState({ colorToggleID: ID })

//     this.setState({ buttoncolor: !this.state.buttoncolor })
//     if (ID == 'Sunday') {

//       var isSame = false;
   



// if(this.state.selectedView.length>0)
// {

// for (var i=0; i < this.state.selectedView.length; i++){

//           if(this.state.selectedView[i]===a){

           
//             isSame = true;
          
//               this.state.selectedView.splice(i,1)
//          break 
//              }else{
//               isSame = false;
//              }
//             }

//             if(!isSame){
//               this.state.selectedView.push(a)
//             }
     
//        }else {
//         this.state.selectedView.push(a)
//        }
//     }
 

//     if (ID == 'Monday') {
//       var isSame = false;
 
// if(this.state.selectedViewM.length>0){

// for (var i=0; i < this.state.selectedViewM.length; i++){

//           if(this.state.selectedViewM[i]===a){
        
//             isSame = true;
          
//               this.state.selectedViewM.splice(i,1)
//               break 
//              }else{
//               isSame = false;
//              }
//             }

//             if(!isSame){
//               this.state.selectedViewM.push(a)
//             }
     
//        }else {
//         this.state.selectedViewM.push(a)
//        }
//       }
    
    
//     if (ID == 'Tuesday') {
//       var isSame = false;
//       if(this.state.selectedViewT.length>0)
// {

//   for (var i=0; i < this.state.selectedViewT.length; i++){

//     if(this.state.selectedViewT[i]===a){

//       console.log("1>>"+a)
//       isSame = true;
    
//         this.state.selectedViewT.splice(i,1)
//    break 
//        }else{
//         isSame = false;
//        }
//       }

//       if(!isSame){
//         this.state.selectedViewT.push(a)
//       }

//  }else {
//   this.state.selectedViewT.push(a)
//  }

   
//     }
//     if (ID == 'Wednesday') {

//       var isSame = false;
//       if(this.state.selectedViewW.length>0)
// {

//   for (var i=0; i < this.state.selectedViewW.length; i++){

//     if(this.state.selectedViewW[i]===a){

//       console.log("1>>"+a)
//       isSame = true;
    
//         this.state.selectedViewW.splice(i,1)
//    break 
//        }else{
//         isSame = false;
//        }
//       }

//       if(!isSame){
//         this.state.selectedViewW.push(a)
//       }

//  }else {
//   this.state.selectedViewW.push(a)
//  }    
//     }

//     if (ID == 'Thursday') {

//       var isSame = false;

// if(this.state.selectedViewTh.length>0)
// {

// for (var i=0; i < this.state.selectedViewTh.length; i++){

    
//           if(this.state.selectedViewTh[i]===a){

//             console.log("1>>"+a)
//             isSame = true;
          
//               this.state.selectedViewTh.splice(i,1)
//          break 
//              }else{
//               isSame = false;
//              }
//             }
            
          

//             if(!isSame){
//               this.state.selectedViewTh.push(a)
//             }
     
//        }else {
//         this.state.selectedViewTh.push(a)
//        }

//     }

//     if (ID == 'Friday') {

//     var isSame = false;

// if(this.state.selectedViewF.length>0)
// {

// for (var i=0; i < this.state.selectedViewF.length; i++){

//           if(this.state.selectedViewF[i]===a){

//             console.log("1>>"+a)
//             isSame = true;
          
//               this.state.selectedViewF.splice(i,1)
//          break 
//              }else{
//               isSame = false;
//              }
//             }        

//             if(!isSame){
//               this.state.selectedViewF.push(a)
//             }
     
//        }else {
//         this.state.selectedViewF.push(a)
//        }
      
//     }
//     if (ID == 'Saturday') {

//       var isSame = false;
      



// if(this.state.selectedViewS.length>0)
// {

// for (var i=0; i < this.state.selectedViewS.length; i++){

  
//           if(this.state.selectedViewS[i]===a){

          
//             isSame = true;
          
//               this.state.selectedViewS.splice(i,1)
//          break 
//              }else{
//               isSame = false;
//              }
//             }
            
   

//             if(!isSame){
//               this.state.selectedViewS.push(a)
//             }
     
//        }else {
//         this.state.selectedViewS.push(a)
//        }
   
//     }
//     const selectedView = this.state.selectedView.map((selectedID, index) => {
    
//       return (
//         this.setState({ dataID: selectedID })
//       )
//     }
//     )
//   }

//   handleview =(day)=> {
//   //  await this.setState({myvaluestatus:day})
//     if (!this.state.value) {
     
//       if (day == 'Sunday') {
//         this.setState({ dayname: day })
//         this.setState({ status: true })
//       }

//       if (day == 'Monday') {
//         this.setState({ dayname: day })
//         this.setState({ status: true })

//       }
//       if (day == 'Tuesday') {
//         this.setState({ dayname: day })
//         this.setState({ status: true })
//       }
//       if (day == 'Wednesday') {
//         this.setState({ dayname: day })
//         this.setState({ status: true })
//       }
//       if (day == 'Thursday') {
//         this.setState({ dayname: day })
//         this.setState({ status: true })
//       }
//       if (day == 'Friday') {
//         this.setState({ dayname: day })
//         this.setState({ status: true })
//       }
//       if (day == 'Saturday') {
//         this.setState({ dayname: day })
//         this.setState({ status: true })
//       }
//     }

//   }
//   render() {

//     console.log("day name >>>>",this.state.dayname)


//     return (
//       <main>
//         <Header></Header>
//         <section className="login_form mx-auto margin_top">
//           <div className="container-fluid border-0 width_custom">
//             <div className="row">
//               <div className="col-md-7 col-sm-12 bg_banner_login login_main_banner"></div>
//               <div className="col-md-5 col-sm-12 m-auto">
//                 <div className="card2 border-0 my-3 login_card">
//                   <form className=" text-left loginpage" onSubmit={this.handleSubmit} method="POST">
//                     <div className="text-center" >
//                       <h2>Availability </h2>
//                     </div>
//                     <div className="rate_box_content">

//                       <h5 class="schedule_head">Days/Hours
//                         <span>
//                           {this.state.isSelectedAll == true ?

//                             <>
//                               <input
//                                 type="checkbox"
//                                 name="checkAll"
//                                 // defaultChecked={true}
//                                 onChange={this.handleWeek}
//                                required />
//                             </> :

//                             <input type="checkbox"
//                               onChange={(event)=>this.handleWeek(event,'Wholeweek')}
//                               checked={this.state.activeallcheck}
                    
//                               name="checkAll"  />
//                           }
//                           <label for="vehicle1">Whole Week</label>
//                         </span>
//                       </h5>


//                       <div id="demo2" className="demo_collapse_sec collapse">
//                         <div class="multi_select_buttons">

//                         </div>

//                       </div>


//                       {this.state.SECTIONS.map((titleData, index) => {
//                         return (
//                           <div>
//                             <div className="demo_option" onClick={() => this.handleview(titleData.title)}>

//                               <h4 data-toggle="collapse" data-target="#demo5">{titleData.title} </h4>

//                               <span class="day_checkk">

//                                 {
//                                   this.state.handleWholeweek == true && this.state.value == true
//                                     ?
//                                     <>
//                                       <input type="checkbox"
//                                         onClick={(e)=>this.handleCheck(e,titleData.title)}
//                                         checked={this.state.value}

//                                       />
//                                     </>


//                                     : this.state.handleWholeweek == false && this.state.value == false ?
//                                       <>

//                                         <input type="checkbox"
//                                           onClick={(e)=>this.handleCheck(e,titleData.title)}
//                                           value={this.state.value}

//                                         />

//                                       </>
//                                       :
//                                       <>
//                                         <input type="checkbox"
//                                           onClick={(e)=>this.handleCheck(e,titleData.title)} name="checkAll"
//                                           // value={this.state.value}
//                                         />
//                                       </>

//                                 }

                            
//                                 <label for="vehicle1">Full Day</label>
//                               </span>

//                             </div>


//                             {
//                               this.state.dayname == titleData.title ?

//                                 <div id="demo5" className="demo_collapse_sec collapse">
//                                   <div class="multi_select_buttons">

//                                     <>
//                                       {this.state.timeArray.map((arraydata, index) => {

//                                       if(titleData.title == 'Sunday'){

//                                         return (
//                                           <>
//                                             <>
//                                               {this.state.selectedView.includes(arraydata.value) ?
//                                                 <>
//                                                   {this.state.status == true ?
//                                                     <>
//                                                       <button className='active_multicheck' onClick={() => this.changeColor(arraydata.value, this.state.dayname)} value={arraydata.value}>
//                                                         {arraydata.value}
//                                                       </button>
//                                                     </>
//                                                     :
//                                                     null
//                                                   }
//                                                 </>
//                                                 :
//                                                 <button className='deactive_multicheck' onClick={() => this.changeColor(arraydata.value, this.state.dayname)} value={arraydata.value}>
//                                                   {arraydata.value}
//                                                 </button>

//                                               }

//                                             </></>)

//                                       }else if (titleData.title == 'Monday') {

//                                         return (
//                                           <>
//                                             <>
//                                               {this.state.selectedViewM.includes(arraydata.value) ?
//                                                 <>
//                                                   {this.state.status == true ?
//                                                     <>
//                                                       <button className='active_multicheck' onClick={() => this.changeColor(arraydata.value, this.state.dayname)} value={arraydata.value}>
//                                                         {arraydata.value}
//                                                       </button>
//                                                     </>
//                                                     :
//                                                     null
//                                                   }
//                                                 </>
//                                                 :
//                                                 <button className='deactive_multicheck' onClick={() => this.changeColor(arraydata.value, this.state.dayname)} value={arraydata.value}>
//                                                   {arraydata.value}
//                                                 </button>

//                                               }

//                                             </></>)
                                        
//                                       }else if (titleData.title == 'Tuesday') {

//                                         return (
//                                           <>
//                                             <>
//                                               {this.state.selectedViewT.includes(arraydata.value) ?
//                                                 <>
//                                                   {this.state.status == true ?
//                                                     <>
//                                                       <button className='active_multicheck' onClick={() => this.changeColor(arraydata.value, this.state.dayname)} value={arraydata.value}>
//                                                         {arraydata.value}
//                                                       </button>
//                                                     </>
//                                                     :
//                                                     null
//                                                   }
//                                                 </>
//                                                 :
//                                                 <button className='deactive_multicheck' onClick={() => this.changeColor(arraydata.value, this.state.dayname)} value={arraydata.value}>
//                                                   {arraydata.value}
//                                                 </button>

//                                               }

//                                             </></>)
                                        
//                                       }else if (titleData.title == 'Wednesday') {

//                                         return (
//                                           <>
//                                             <>
//                                               {this.state.selectedViewW.includes(arraydata.value) ?
//                                                 <>
//                                                   {this.state.status == true ?
//                                                     <>
//                                                       <button className='active_multicheck' onClick={() => this.changeColor(arraydata.value, this.state.dayname)} value={arraydata.value}>
//                                                         {arraydata.value}
//                                                       </button>
//                                                     </>
//                                                     :
//                                                     null
//                                                   }
//                                                 </>
//                                                 :
//                                                 <button className='deactive_multicheck' onClick={() => this.changeColor(arraydata.value, this.state.dayname)} value={arraydata.value}>
//                                                   {arraydata.value}
//                                                 </button>

//                                               }

//                                             </></>)
                                        
//                                       }else if (titleData.title == 'Thursday') {

//                                         return (
//                                           <>
//                                             <>
//                                               {this.state.selectedViewTh.includes(arraydata.value) ?
//                                                 <>
//                                                   {this.state.status == true ?
//                                                     <>
//                                                       <button className='active_multicheck' onClick={() => this.changeColor(arraydata.value, this.state.dayname)} value={arraydata.value}>
//                                                         {arraydata.value}
//                                                       </button>
//                                                     </>
//                                                     :
//                                                     null
//                                                   }
//                                                 </>
//                                                 :
//                                                 <button className='deactive_multicheck' onClick={() => this.changeColor(arraydata.value, this.state.dayname)} value={arraydata.value}>
//                                                   {arraydata.value}
//                                                 </button>

//                                               }

//                                             </></>)
                                        
//                                       }else if (titleData.title == 'Friday') {

//                                         return (
//                                           <>
//                                             <>
//                                               {this.state.selectedViewF.includes(arraydata.value) ?
//                                                 <>
//                                                   {this.state.status == true ?
//                                                     <>
//                                                       <button className='active_multicheck' onClick={() => this.changeColor(arraydata.value, this.state.dayname)} value={arraydata.value}>
//                                                         {arraydata.value}
//                                                       </button>
//                                                     </>
//                                                     :
//                                                     null
//                                                   }
//                                                 </>
//                                                 :
//                                                 <button className='deactive_multicheck' onClick={() => this.changeColor(arraydata.value, this.state.dayname)} value={arraydata.value}>
//                                                   {arraydata.value}
//                                                 </button>

//                                               }

//                                             </></>)
                                        
//                                       }else if (titleData.title == 'Saturday') {

//                                         return (
//                                           <>
//                                             <>
//                                               {this.state.selectedViewS.includes(arraydata.value) ?
//                                                 <>
//                                                   {this.state.status == true ?
//                                                     <>
//                                                       <button className='active_multicheck' onClick={() => this.changeColor(arraydata.value, this.state.dayname)} value={arraydata.value}>
//                                                         {arraydata.value}
//                                                       </button>
//                                                     </>
//                                                     :
//                                                     null
//                                                   }
//                                                 </>
//                                                 :
//                                                 <button className='deactive_multicheck' onClick={() => this.changeColor(arraydata.value, this.state.dayname)} value={arraydata.value}>
//                                                   {arraydata.value}
//                                                 </button>

//                                               }

//                                             </></>)
                                        
//                                       }




                                        
//                                       })}
//                                     </>

//                                   </div>
//                                 </div>

//                                 :
             
//                             null}



//                           </div>
//                         )
//                       })
//                       }


//                     </div>

//                     <Loader
//                       className="text-center"
//                       visible={this.state.visible}
//                       type="Oval"
//                       color="#00bacc"
//                       height={40}
//                       width={40}
//                       timeout={3000} //3 secs
//                     />

//                     <div class="form-group">
//                       <button class="btn btn-block send-button border_radius_20 tx-tfm" type="submit">Next</button>
//                     </div>

//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//         <Footer></Footer>
//       </main>);
//   }
// }
// export default Availability


import React from 'react';
import Header from "../common/header";
import axios from 'axios';
import { API_BASE_URL } from '../../config/api.js'
import Footer from "../common/footer";
import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
import { faAngleDown, faEye } from '@fortawesome/free-solid-svg-icons';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
const SECTIONS = [
  {
    title: 'Sunday',
    content: '',
  },
  {
    title: 'Monday',
    content: '',
  },
  {
    title: 'Tuesday',
    content: '',
  },
  {
    title: 'Wednesday',
    content: '',
  },
  {
    title: 'Thursday',
    content: '',
  },
  {
    title: 'Friday',
    content: '',
  },
  {
    title: 'Saturday',
    content: '',
  },];
class Availability extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeSections: [],
      activeallcheck:false,
      button: true,
      visible: false,
      CheckBox1: '',
      CheckBox2: '',
      isSelectedA: false,
      isSelectedB: false,
      isSelectedAll: false,
      status: '',
      selectedView: [],
      selectedViewM: [],
      selectedViewT: [],
      selectedViewW: [],
      selectedViewTh: [],
      selectedViewF: [],
      selectedViewS: [],
      dataID: [],
      id: "",
      colorToggleID: '',
      value: false,
      wholeweek: false,
      sundaystatus: false,
      mondaystatus: false,
      tuesdaystatus: false,
      wednesdaystatus: false,
      thursdaystatus: false,
      fridaystatus: false,
      saturdaystatus: false,
      status: true,
      wholeweek: false,
      wholeweek1: 0,
      sundaystatus: false,
      sundaystatus1: 0,
      mondaystatus: false,
      mondaystatus1: 0,
      tuesdaystatus: false,
      tuesdaystatus1: 0,
      wednesdaystatus: false,
      wednesdaystatus1: 0,
      thursdaystatus: false,
      thursdaystatus1: 0,
      fridaystatus: false,
      fridaystatus1: 0,
      saturdaystatus: false,
      saturdaystatus1: 0,
      modelVisibale: false,
      mydynmicvalue: [],
      her: [],
      mystatusmanage: '',
      timeArray: [
        {
          "id": 1, 'value': '12AM-1AM', to: "/cms",
          className: "side_nav_item"
        },
        {
          "id": 2, 'value': '1AM-2AM', to: "/cms",
          className: "side_nav_item"
        },
        {
          "id": 3, 'value': '2AM-3AM', to: "/cms",
          className: "side_nav_item"
        },
        {
          "id": 4, 'value': '3AM-4AM', to: "/cms",
          className: "side_nav_item"
        },
        {
          "id": 5, 'value': '4AM-5AM', to: "/cms",
          className: "side_nav_item"
        },
        {
          "id": 6, 'value': '5AM-6AM',
          className: "side_nav_item", to: "/cms",
        },
        { "id": 7, 'value': '6AM-7AM', className: "side_nav_item", to: "/cms", },
        { "id": 8, 'value': '7AM-8AM', className: "side_nav_item", to: "/cms", },
        { "id": 9, 'value': '8AM-9AM', className: "side_nav_item", to: "/cms", },
        { "id": 10, 'value': '9AM-10AM', className: "side_nav_item", to: "/cms", },
        { "id": 11, 'value': '10AM-11AM', className: "side_nav_item", to: "/cms", },
        { "id": 12, 'value': '11AM-12PM', className: "side_nav_item", to: "/cms", },
        { "id": 13, 'value': '12PM-1PM', className: "side_nav_item", to: "/cms", },
        { "id": 14, 'value': '1PM-2PM', className: "side_nav_item", to: "/cms", },
        { "id": 15, 'value': '2PM-3PM', className: "side_nav_item", to: "/cms", },
        { "id": 16, 'value': '3PM-4PM', className: "side_nav_item", to: "/cms", },
        { "id": 17, 'value': '4PM-5PM', className: "side_nav_item", to: "/cms", },
        { "id": 18, 'value': '5PM-6PM', className: "side_nav_item", to: "/cms", },
        { "id": 19, 'value': '6PM-7PM', className: "side_nav_item", to: "/cms", },
        { "id": 20, 'value': '7PM-8PM', className: "side_nav_item", to: "/cms", },
        { "id": 21, 'value': '8PM-9PM', className: "side_nav_item", to: "/cms", },
        { "id": 22, 'value': '9PM-10PM', className: "side_nav_item", to: "/cms", },
        { "id": 23, 'value': '10PM-11PM', },
        { "id": 24, 'value': '11PM-12PM' },
      ],
      SECTIONS: [
        {
          title: 'Sunday',
          content: '',
        },
        {
          title: 'Monday',
          content: '',
        },
        {
          title: 'Tuesday',
          content: '',
        },
        {
          title: 'Wednesday',
          content: '',
        },
        {
          title: 'Thursday',
          content: '',
        },
        {
          title: 'Friday',
          content: '',
        },
        {
          title: 'Saturday',
          content: '',
        },],
      activeLink: null

    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleWeek = this.handleWeek.bind(this);
    this.handleWeek1 = this.handleWeek1.bind(this);
    this.handleCheck = this.handleCheck.bind(this);

  }


  handleSubmit = event => {
    console.log("-=-=-=-=-=-=-=-=", this.state.selectedViewW.length)

    event.preventDefault();
    
    if(this.state.selectedViewW.length > 0 
      || this.state.mondaystatus1==1 ||
      this.state.tuesdaystatus1==1||
      this.state.wednesdaystatus1==1 ||
      this.state.thursdaystatus1==1
      ||
      this.state.fridaystatus1==1 ||
      this.state.saturdaystatus1==1||
      this.state.sundaystatus1==1

      || this.state.selectedViewM.length > 0  
      ||this.state.selectedViewT.length > 0 
      ||this.state.selectedViewTh.length > 0 
      ||this.state.selectedViewF.length > 0   
      ||this.state.selectedViewS.length > 0  
      ||this.state.selectedView.length > 0 || 
      this.state.wholeweek1==1  ){


    const sendData = {
      "items": [

        {
          "whole_week": this.state.wholeweek1,
          "days": {
            "monday_status": this.state.mondaystatus1,
            "monday_time": this.state.selectedViewM,
            "tuesday_status": this.state.tuesdaystatus1,
            "tuesday_time": this.state.selectedViewT,
            "wednesday_status": this.state.wednesdaystatus1,
            "wednesday_time": this.state.selectedViewW,
            "thursday_status": this.state.thursdaystatus1,
            "thursday_time": this.state.selectedViewTh,
            "friday_status": this.state.fridaystatus1,
            "friday_time": this.state.selectedViewF,
            "saturday_status": this.state.saturdaystatus1,
            "saturday_time": this.state.selectedViewS,
            "sunday_status": this.state.sundaystatus1,
            "sunday_time": this.state.selectedView
          }
        },
      ],
      "form_status": "Form-5"
    }
 
    const UserToken = localStorage.getItem('UserToken');
    if (UserToken != '') {

      const json = JSON.stringify(sendData);
  

      this.setState({ visible: true })
      axios({
        method: 'POST',
        url: API_BASE_URL + "availability/",
        headers: { 'Authorization': "Bearer " + UserToken },
        data: json,
        headers: {
          'Authorization': 'Bearer ' + UserToken,

          'Content-Type': 'application/json'
        },
      }).then((response) => {

        console.log("my full day value", response.data)

        this.setState({ visible: false })

        if (response.data.success == 200) {
 

           this.props.history.push('tutor_home');
          
        }

      }).catch((error) => {
        console.log(error)
          ;
      })
    }
  }
  }

  handleCheck = (value, status) => {

console.log('value',value.target.checked ,status)
if(value.target.checked==true && status=="Sunday"){
  this.setState({ sundaystatus1: 1 })
}
else if(value.target.checked==false && status=="Sunday"){
  this.setState({ sundaystatus1: 0 })

}


if(value.target.checked==true && status=="Monday"){
  this.setState({ mondaystatus1: 1 })
}
else if(value.target.checked==false && status=="Monday"){
  this.setState({ mondaystatus1: 0 })

}


if(value.target.checked==true && status=="Tuesday"){
  this.setState({ tuesdaystatus1: 1 })
}
else if(value.target.checked==false && status=="Tuesday"){
  this.setState({ tuesdaystatus1: 0 })

}

if(value.target.checked==true && status=="Wednesday"){
  this.setState({ wednesdaystatus1: 1 })
}
else if(value.target.checked==false && status=="Wednesday"){
  this.setState({ wednesdaystatus1: 0 })

}


if(value.target.checked==true && status=="Thursday"){
  this.setState({ thursdaystatus1: 1 })
}
else if(value.target.checked==false && status=="Thursday"){
  this.setState({ thursdaystatus1: 0 })

}

if(value.target.checked==true && status=="Friday"){
  this.setState({ fridaystatus1: 1 })
}
else if(value.target.checked==false && status=="Friday"){
  this.setState({ fridaystatus1: 0 })

}

if(value.target.checked==true && status=="Saturday"){
  this.setState({ saturdaystatus1: 1 })
}
else if(value.target.checked==false && status=="Saturday"){
  this.setState({ saturdaystatus1: 0 })

}
    // if (value == 'Sunday' && status == false) {
    
    //   this.setState({ value: 'Sunday' })
    //   this.setState({ isSelectedA: true })
    //   this.setState({ sundaystatus: true })
    //   this.setState({ sundaystatus1: 0 })
    // } else
    //   if (value == 'Sunday' && status == true) {
    //     this.setState({ value: 'Sunday1' })
    //     this.setState({ isSelectedA: false })
    //     this.setState({ sundaystatus: false })
    //     this.setState({ sundaystatus1: 1 })
    //   }

    // if (value == 'Monday' && status == false) {
    //   this.setState({ value: 'Monday' })
    //   this.setState({ isSelectedA: true })
    //   this.setState({ mondaystatus: true })
    //   this.setState({ mondaystatus1: 0 })
    // } else
    //   if (value == 'Monday' && status == true) {
    //     this.setState({ value: 'Monday1' })
    //     this.setState({ isSelectedA: false })
    //     this.setState({ mondaystatus: false })
    //     this.setState({ mondaystatus1: 1 })
    //   }

    // if (value == 'Tuesday' && status == false) {
    //   this.setState({ value: 'Tuesday' })
    //   this.shandleWeeketState({ isSelectedA: true })
    //   this.setState({ tuesdaystatus: true })
    //   this.setState({ tuesdaystatus1: 0 })
    // } else
    //   if (value == 'Tuesday' && status == true) {
    //     this.setState({ value: 'Tuesday1' })
    //     this.setState({ tuesdaystatus: false })
    //     this.setState({ tuesdaystatus1: 1 })
    //     this.setState({ isSelectedA: false })
    //   }

    // if (value == 'Wednesday' && status == false) {
    //   this.setState({ value: 'Wednesday' })
    //   this.setState({ isSelectedA: true })
    //   this.setState({ wednesdaystatus: true })
    //   this.setState({ wednesdaystatus1: 0 })
    // } else
    //   if (value == 'Wednesday' && status == true) {
    //     this.setState({ value: 'Wednesday1' })
    //     this.setState({ wednesdaystatus: false })
    //     this.setState({ wednesdaystatus1: 1 })
    //     this.setState({ isSelectedA: false })
    //   }

    // if (value == 'Thursday' && status == false) {
    //   this.setState({ value: 'Thursday' })
    //   this.setState({ isSelectedA: true })
    //   this.setState({ thursdaystatus: true })
    //   this.setState({ thursdaystatus1: 0 })
    // } else
    //   if (value == 'Thursday' && status == true) {
    //     this.setState({ value: 'Thursday1' })
    //     this.setState({ thursdaystatus: false })
    //     this.setState({ thursdaystatus1: 1 })
    //     this.setState({ isSelectedA: false })
    //   }

    // if (value == 'Friday' && status == false) {
    //   this.setState({ value: 'Friday' })
    //   this.setState({ isSelectedA: true })
    //   this.setState({ fridaystatus: true })
    //   this.setState({ fridaystatus1: 0 })
    // } else
    //   if (value == 'Friday' && status == true) {
    //     this.setState({ value: 'Friday1' })
    //     this.setState({ isSelectedA: false })
    //     this.setState({ fridaystatus: false })
    //     this.setState({ fridaystatus1: 1 })
    //   }

    // if (value == 'Saturday' && status == false) {
    //   this.setState({ value: 'Saturday' })
    //   this.setState({ isSelectedA: true })
    //   this.setState({ saturdaystatus: true })
    //   this.setState({ saturdaystatus1: 0 })
    // } else
    //   if (value == 'Saturday' && status == true) {
    //     this.setState({ value: 'Saturday1' })
    //     this.setState({ isSelectedA: false })
    //     this.setState({ saturdaystatus: false })
    //     this.setState({ saturdaystatus1: 1 })
    //   }
  }


  handleWeek1 = (e) => {
    console.log('trrr',e.target.checked);
  }

  handleWeek = (e,value) => {
    console.log('hrrr',e.target.checked,value)

   if(e.target.checked==true && value=="Wholeweek"){
     this.setState({wholeweek1:1})
     this.setState({sundaystatus1:1})
     this.setState({mondaystatus1:1})
     this.setState({tuesdaystatus1:1})
     this.setState({wednesdaystatus1:1})
     this.setState({thursdaystatus1:1})
     this.setState({fridaystatus1:1})
     this.setState({saturdaystatus1:1})
   }
   else if(e.target.checked==false && value=="Wholeweek"){
    this.setState({wholeweek1:0})
    this.setState({sundaystatus1:0})
    this.setState({mondaystatus1:0})
    this.setState({tuesdaystatus1:0})
    this.setState({wednesdaystatus1:0})
    this.setState({thursdaystatus1:0})
    this.setState({fridaystatus1:0})
    this.setState({saturdaystatus1:0})
   }

this.setState({activeallcheck:e.target.checked})
  


    // if (e.target.value == false) {
    //   this.setState({ isSelectedAll: true })
    //   this.setState({ wholeweek: true })
    //   this.setState({ isSelectedB: true })
    //   this.setState({ wholeweek1: 0 })
    //   this.setState({ activeSections: "" })

    // } else
    //   if (e.target.value == true) {
    //     this.setState({ isSelectedAll: false })
    //     this.setState({ wholeweek: false })
    //     this.setState({ isSelectedB: false })
    //     this.setState({ wholeweek1: 1 })
    //   }

    let titleName = e.target.name;
   
    const checked = e.target.checked;
    console.log('check',checked)
    this.setState({ value: checked })
    this.setState({ handleWholeweek: e.target.checked })
 


  }

  changeColor(a, ID) {
    console.log("myname", ID)
    this.setState({ colorToggleID: ID })

    this.setState({ buttoncolor: !this.state.buttoncolor })
    if (ID == 'Sunday') {

      var isSame = false;
   



if(this.state.selectedView.length>0)
{

for (var i=0; i < this.state.selectedView.length; i++){

          if(this.state.selectedView[i]===a){

           
            isSame = true;
          
              this.state.selectedView.splice(i,1)
         break 
             }else{
              isSame = false;
             }
            }

            if(!isSame){
              this.state.selectedView.push(a)
            }
     
       }else {
        this.state.selectedView.push(a)
       }
    }
 

    if (ID == 'Monday') {
      var isSame = false;
 
if(this.state.selectedViewM.length>0){

for (var i=0; i < this.state.selectedViewM.length; i++){

          if(this.state.selectedViewM[i]===a){
        
            isSame = true;
          
              this.state.selectedViewM.splice(i,1)
              break 
             }else{
              isSame = false;
             }
            }

            if(!isSame){
              this.state.selectedViewM.push(a)
            }
     
       }else {
        this.state.selectedViewM.push(a)
       }
      }
    
    
    if (ID == 'Tuesday') {
      var isSame = false;
      if(this.state.selectedViewT.length>0)
{

  for (var i=0; i < this.state.selectedViewT.length; i++){

    if(this.state.selectedViewT[i]===a){

      console.log("1>>"+a)
      isSame = true;
    
        this.state.selectedViewT.splice(i,1)
   break 
       }else{
        isSame = false;
       }
      }

      if(!isSame){
        this.state.selectedViewT.push(a)
      }

 }else {
  this.state.selectedViewT.push(a)
 }

   
    }
    if (ID == 'Wednesday') {

      var isSame = false;
      if(this.state.selectedViewW.length>0)
{

  for (var i=0; i < this.state.selectedViewW.length; i++){

    if(this.state.selectedViewW[i]===a){

      console.log("1>>"+a)
      isSame = true;
    
        this.state.selectedViewW.splice(i,1)
   break 
       }else{
        isSame = false;
       }
      }

      if(!isSame){
        this.state.selectedViewW.push(a)
      }

 }else {
  this.state.selectedViewW.push(a)
 }    
    }

    if (ID == 'Thursday') {

      var isSame = false;

if(this.state.selectedViewTh.length>0)
{

for (var i=0; i < this.state.selectedViewTh.length; i++){

    
          if(this.state.selectedViewTh[i]===a){

            console.log("1>>"+a)
            isSame = true;
          
              this.state.selectedViewTh.splice(i,1)
         break 
             }else{
              isSame = false;
             }
            }
            
          

            if(!isSame){
              this.state.selectedViewTh.push(a)
            }
     
       }else {
        this.state.selectedViewTh.push(a)
       }

    }

    if (ID == 'Friday') {

    var isSame = false;

if(this.state.selectedViewF.length>0)
{

for (var i=0; i < this.state.selectedViewF.length; i++){

          if(this.state.selectedViewF[i]===a){

            console.log("1>>"+a)
            isSame = true;
          
              this.state.selectedViewF.splice(i,1)
         break 
             }else{
              isSame = false;
             }
            }        

            if(!isSame){
              this.state.selectedViewF.push(a)
            }
     
       }else {
        this.state.selectedViewF.push(a)
       }
      
    }
    if (ID == 'Saturday') {

      var isSame = false;
      



if(this.state.selectedViewS.length>0)
{

for (var i=0; i < this.state.selectedViewS.length; i++){

  
          if(this.state.selectedViewS[i]===a){

          
            isSame = true;
          
              this.state.selectedViewS.splice(i,1)
         break 
             }else{
              isSame = false;
             }
            }
            
   

            if(!isSame){
              this.state.selectedViewS.push(a)
            }
     
       }else {
        this.state.selectedViewS.push(a)
       }
   
    }
    const selectedView = this.state.selectedView.map((selectedID, index) => {
    
      return (
        this.setState({ dataID: selectedID })
      )
    }
    )
  }

  handleview(e,day) {
    console.log('chekc',e.target.checked)
   let statusvalue=e.target.checked
    if (!this.state.value) {
     
      if ( day == 'Sunday' ) {
        this.setState({ dayname: day })
        this.setState({ status: true })
        // this.setState({sundaystatus1:1})
      }

    

      if (day == 'Monday') {
        this.setState({ dayname: day })
        this.setState({ status: true })
        // this.setState({sundaystatus1:1})

      }
    
      if (day == 'Tuesday') {
        this.setState({ dayname: day })
        this.setState({ status: true })
      }
      if (day == 'Wednesday') {
        this.setState({ dayname: day })
        this.setState({ status: true })
      }
      if (day == 'Thursday') {
        this.setState({ dayname: day })
        this.setState({ status: true })
      }
      if (day == 'Friday') {
        this.setState({ dayname: day })
        this.setState({ status: true })
      }
      if (day == 'Saturday') {
        this.setState({ dayname: day })
        this.setState({ status: true })
      }
    }

  }
  render() {

    console.log("day name >>>>",this.state.dayname,this.state.status)


    return (
      <main>
        <Header></Header>
        <section className="login_form mx-auto margin_top">
          <div className="container-fluid border-0 width_custom">
            <div className="row">
              <div className="col-md-7 col-sm-12 bg_banner_login login_main_banner"></div>
              <div className="col-md-5 col-sm-12 m-auto">
                <div className="card2 border-0 my-3 login_card">
                  <form className=" text-left loginpage" onSubmit={this.handleSubmit} method="POST">
                    <div className="text-center" >
                      <h2>Availability </h2>
                    </div>
                    <div className="rate_box_content">

                      <h5 class="schedule_head">Days/Hours
                        <span>
                          {this.state.isSelectedAll == true ?

                            <>
                              <input
                                type="checkbox"
                                name="checkAll"
                                defaultChecked={true}
                                onChange={this.handleWeek1}
                               required />
                            </> :

                            <input type="checkbox"
                              onChange={(event)=>this.handleWeek(event,"Wholeweek")}
                              checked={this.state.allChecked}
                    
                              name="checkAll"  />
                          }
                          <label for="vehicle1">Whole Week</label>
                        </span>
                      </h5>


                      <div id="demo2" className="demo_collapse_sec collapse">
                        <div class="multi_select_buttons">

                        </div>

                      </div>


                      {this.state.SECTIONS.map((titleData, index) => {
                        return (
                          <div>
                            <div className="demo_option" onClick={(e) => this.handleview(e,titleData.title)}>

                              <h4 data-toggle="collapse" data-target="#demo5">{titleData.title} </h4>

                              <span class="day_checkk">

                                {
                                  this.state.handleWholeweek == true && this.state.value == true
                                    ?
                                    <>
                                      <input type="checkbox"
                                        onClick={(event)=>this.handleCheck(event,titleData.title)}
                                        checked={this.state.value}

                                      />
                                    </>


                                    : this.state.handleWholeweek == false && this.state.value == false ?
                                      <>

                                        <input type="checkbox"
                                          onClick={(event)=>this.handleCheck(event,titleData.title)}
                                          value={this.state.value}

                                        />

                                      </>
                                      :
                                      <>
                                        <input type="checkbox"
                                          onClick={(event)=>this.handleCheck(event,titleData.title)} name="checkAll"
                                          value={this.state.value}
                                        />
                                      </>

                                }

                            
                                <label for="vehicle1">Full Day</label>
                              </span>

                            </div>


                            {
                              this.state.dayname == titleData.title ?

                                <div id="demo5" className="demo_collapse_sec collapse">
                                  <div class="multi_select_buttons">

                                    <>
                                      {this.state.timeArray.map((arraydata, index) => {

                                      if(titleData.title == 'Sunday'){

                                        return (
                                          <>
                                            <>
                                              {this.state.selectedView.includes(arraydata.value) ?
                                                <>
                                                  {this.state.status == true ?
                                                    <>
                                                      <button className='active_multicheck' onClick={() => this.changeColor(arraydata.value, this.state.dayname)} value={arraydata.value}>
                                                        {arraydata.value}
                                                      </button>
                                                    </>
                                                    :
                                                    null
                                                  }
                                                </>
                                                :
                                                <button className='deactive_multicheck' onClick={() => this.changeColor(arraydata.value, this.state.dayname)} value={arraydata.value}>
                                                  {arraydata.value}
                                                </button>

                                              }

                                            </></>)

                                      }else if (titleData.title == 'Monday') {

                                        return (
                                          <>
                                            <>
                                              {this.state.selectedViewM.includes(arraydata.value) ?
                                                <>
                                                  {this.state.status == true ?
                                                    <>
                                                      <button className='active_multicheck' onClick={() => this.changeColor(arraydata.value, this.state.dayname)} value={arraydata.value}>
                                                        {arraydata.value}
                                                      </button>
                                                    </>
                                                    :
                                                    null
                                                  }
                                                </>
                                                :
                                                <button className='deactive_multicheck' onClick={() => this.changeColor(arraydata.value, this.state.dayname)} value={arraydata.value}>
                                                  {arraydata.value}
                                                </button>

                                              }

                                            </></>)
                                        
                                      }else if (titleData.title == 'Tuesday') {

                                        return (
                                          <>
                                            <>
                                              {this.state.selectedViewT.includes(arraydata.value) ?
                                                <>
                                                  {this.state.status == true ?
                                                    <>
                                                      <button className='active_multicheck' onClick={() => this.changeColor(arraydata.value, this.state.dayname)} value={arraydata.value}>
                                                        {arraydata.value}
                                                      </button>
                                                    </>
                                                    :
                                                    null
                                                  }
                                                </>
                                                :
                                                <button className='deactive_multicheck' onClick={() => this.changeColor(arraydata.value, this.state.dayname)} value={arraydata.value}>
                                                  {arraydata.value}
                                                </button>

                                              }

                                            </></>)
                                        
                                      }else if (titleData.title == 'Wednesday') {

                                        return (
                                          <>
                                            <>
                                              {this.state.selectedViewW.includes(arraydata.value) ?
                                                <>
                                                  {this.state.status == true ?
                                                    <>
                                                      <button className='active_multicheck' onClick={() => this.changeColor(arraydata.value, this.state.dayname)} value={arraydata.value}>
                                                        {arraydata.value}
                                                      </button>
                                                    </>
                                                    :
                                                    null
                                                  }
                                                </>
                                                :
                                                <button className='deactive_multicheck' onClick={() => this.changeColor(arraydata.value, this.state.dayname)} value={arraydata.value}>
                                                  {arraydata.value}
                                                </button>

                                              }

                                            </></>)
                                        
                                      }else if (titleData.title == 'Thursday') {

                                        return (
                                          <>
                                            <>
                                              {this.state.selectedViewTh.includes(arraydata.value) ?
                                                <>
                                                  {this.state.status == true ?
                                                    <>
                                                      <button className='active_multicheck' onClick={() => this.changeColor(arraydata.value, this.state.dayname)} value={arraydata.value}>
                                                        {arraydata.value}
                                                      </button>
                                                    </>
                                                    :
                                                    null
                                                  }
                                                </>
                                                :
                                                <button className='deactive_multicheck' onClick={() => this.changeColor(arraydata.value, this.state.dayname)} value={arraydata.value}>
                                                  {arraydata.value}
                                                </button>

                                              }

                                            </></>)
                                        
                                      }else if (titleData.title == 'Friday') {

                                        return (
                                          <>
                                            <>
                                              {this.state.selectedViewF.includes(arraydata.value) ?
                                                <>
                                                  {this.state.status == true ?
                                                    <>
                                                      <button className='active_multicheck' onClick={() => this.changeColor(arraydata.value, this.state.dayname)} value={arraydata.value}>
                                                        {arraydata.value}
                                                      </button>
                                                    </>
                                                    :
                                                    null
                                                  }
                                                </>
                                                :
                                                <button className='deactive_multicheck' onClick={() => this.changeColor(arraydata.value, this.state.dayname)} value={arraydata.value}>
                                                  {arraydata.value}
                                                </button>

                                              }

                                            </></>)
                                        
                                      }else if (titleData.title == 'Saturday') {

                                        return (
                                          <>
                                            <>
                                              {this.state.selectedViewS.includes(arraydata.value) ?
                                                <>
                                                  {this.state.status == true ?
                                                    <>
                                                      <button className='active_multicheck' onClick={() => this.changeColor(arraydata.value, this.state.dayname)} value={arraydata.value}>
                                                        {arraydata.value}
                                                      </button>
                                                    </>
                                                    :
                                                    null
                                                  }
                                                </>
                                                :
                                                <button className='deactive_multicheck' onClick={() => this.changeColor(arraydata.value, this.state.dayname)} value={arraydata.value}>
                                                  {arraydata.value}
                                                </button>

                                              }

                                            </></>)
                                        
                                      }




                                        
                                      })}
                                    </>

                                  </div>
                                </div>

                                :
             
                            null}



                          </div>
                        )
                      })
                      }


                    </div>

                    <Loader
                      className="text-center"
                      visible={this.state.visible}
                      type="Oval"
                      color="#00bacc"
                      height={40}
                      width={40}
                      timeout={3000} //3 secs
                    />

                    <div class="form-group">
                      <button class="btn btn-block send-button border_radius_20 tx-tfm" type="submit">Next</button>
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer></Footer>
      </main>);
  }
}
export default Availability