import React from 'react';
import ReactDOM from 'react-dom';
 //import FacebookLogin from 'react-facebook-login'
import onMessageListener from '../../firebase' 
// importScripts('https://www.gstatic.com/firebasejs/8.2.0/firebase-app.js');
// importScripts('https://www.gstatic.com/firebasejs/8.2.0/firebase-messaging.js');
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin  from 'react-google-login';
import axios from "axios";
import {Link} from "react-router-dom";
import Header from "../common/header";
import Footer from "../common/footer";
import fb from "../../assets/img/categories/Icon/facebook.png";
import google from "../../assets/img/categories/Icon/google.png";
import twitter from "../../assets/img/categories/Icon/twitter.png";
import { API_BASE_URL } from '../../config/api';
import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '../../../node_modules/@fortawesome/free-solid-svg-icons'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import firebase from 'firebase';
import swal from 'sweetalert';
import validator from 'validator'

export default class Login extends React.Component {
    constructor() {
    super();
    this.state= {
        isLoggedIn: false,
        socialType: '',
        useridSocial:'',
        passwordvalidation:'',
        usernamevalidation:'',
        nameSocial:'',
        emailSocial:'',
        visible : false,
        MessageNotVerifiedErrorUser:false,
        // id:this.props.match.params.id,
        username: '',
        password:'',
        input: {},
        errors: {},
        fields:{},
        type: 'password',
        MessageUsername: '',
        MessagePassword: '',
        MessageNotVerified : '',
        MessageInvalidpassword:false,
        MessageInvalidUsername:false,
        notificationId: '',
        usernamevalidation:false,
        passwordvalidation:false,
        MessageNotVerifiedError:false,
        passwordvalidationerror:'',
        usernamevalidationerror:''
        
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showHide = this.showHide.bind(this);
    this.responseFacebook = this.responseFacebook.bind(this);
    this.responseGoogle = this.responseGoogle.bind(this);
    this.checkSocialLogin =  this.checkSocialLogin.bind(this);
    }

handleChange(evt) {
  console.log('.................>>>>>',!isNaN(evt.target.value))
 
    const value = evt.target.value;
    this.setState({ [evt.target.name]: evt.target.value });
    // let input = this.state.input;
    // input[evt.target.name] = evt.target.value;
    // this.setState({input});
    let fields = this.state.fields;
    fields[evt.target.name] = evt.target.value;
    this.setState({
      fields
    });
    this.setState({usernamevalidation:false})
    this.setState({passwordvalidation:false})
    this.setState({usernamevalidation:false})
    if(evt.target.name=="password"){
      this.setState({passwordvalidationerror:evt.target.value})
    }
   else if(evt.target.name=='username'){
    this.setState({usernamevalidationerror:evt.target.value})

   }
  
}

sweetAlertInValidPhone = () => {
  swal({
  text: "Your Username or password not valid", 
  icon: "warning",
  dangerMode: true,
  })
}
    showHide(e) {
      this.setState({type: this.state.type === 'input' ? 'password' : 'input'})
      // this.setState({type: this.state.type })
    }




    validateForm() {

      let fields = this.state.fields;
      let errors = {};
      let formIsValid = true;

      // if (!fields["username"]) {
      //   formIsValid = false;
      //   errors["username"] = "*Please enter your username.";
      // }

      // if (typeof fields["username"] !== "undefined") {
      //   if (!fields["username"].match(/^[a-zA-Z ]*$/)) {
      //     formIsValid = false;
      //     errors["username"] = "*Please enter alphabet characters only.";
      //   }
      // }

  
     
      if(this.state.passwordvalidationerror===""){
            this.setState({passwordvalidation:'*Please enter your password.'})
      }

      if(this.state.usernamevalidationerror===''){
        this.setState({usernamevalidation:'*Please enter your username.'})
      }
      // if()
      if(isNaN(this.state.username)){
        if(!validator.isEmail(this.state.username)){
          this.setState({usernamevalidation:'*Please Enter Valid Email'})

          
        }

      }

      else if(!isNaN(this.state.username) && (this.state.username.length<=8)){
        this.setState({usernamevalidation:"*Please Enter valid Number "})
      }



        
        //  if(this.state.passwordvalidationerror.match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)){
        // this.setState({passwordvalidation:'greater then 8'})

     

                 if (!fields["password"]) {
        formIsValid = false;
        errors["password"] = "*Please enter your password.";
      }
      if(this.state.passwordvalidationerror!=='') {

      // if (typeof fields["password"] !== "undefined") {
        if (!fields["password"].match(/^.*(?=.{5,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {
          formIsValid = false;
          this.setState({passwordvalidation:'Password must contain at least One Number One Upper Case Letter One Lower Case Letter One Special Character.'})
 
          // this.setState({})
        }
      
    // }
  }
      this.setState({
        errors: errors
      });
      return formIsValid;


    }

   

handleSubmit = event => {

event.preventDefault();
if (this.validateForm()) {
  let fields = {};
  fields["username"] = "";
  
  fields["password"] = "";
  this.setState({fields:fields});

  console.log('././/',!isNaN(this.state.username))


  console.log('email validator',validator.isEmail(this.state.username))

  


  const sendData = {
    username: this.state.username,
    password:this.state.password,
    notification_id:this.state.notificationId,
    device_type:"web"
  };

  console.log("iiiiiiiiiiiiiiiiiiii>>>>>>>>>",sendData)
this.setState({visible: true})

axios({
  method: 'post',
  url:API_BASE_URL + `login_user/`,
  data: sendData,
})
 .then(response => {

  localStorage.setItem("UserToken",response.data.access)
 
  this.setState({visible: false})
   if(response.data.success==200){
    localStorage.setItem('usertype',response.data.user_type); 
    
    if(response.data.user_type==1){
      localStorage.setItem('username',this.state.username)
      localStorage.setItem('UserToken',response.data.access)
      // localStorage.setItem
      this.setState({MessageNotVerified: true })
      this.props.history.push("/tutor_home")
      this.handleformStatus();
    }
     if (response.data.user_type=="0"){     
        localStorage.setItem('UserToken',response.data.access)
        localStorage.setItem('username',this.state.username)
        localStorage.setItem('password',this.state.password)
        this.props.history.push("/dashboard")
        // window.location.replace("/dashboard");
      }
    }
    if(response.data.success==201){
      if(response.data.message=='Username or password not valid'){
        this.setState({MessageNotVerifiedError: true })

      }
      else if(response.data.message=='User not valid.'){
        this.setState({MessageNotVerifiedErrorUser: true })
      }
       this.sweetAlertInValidPhone()
    }
  })
  .catch((error) => {
    console.log('errr',error)
  });
}
}
// }

handleformStatus(){
  //alert("form-status login");
  const  UserToken = localStorage.getItem('UserToken');
  //console.log(UserToken)
  axios({
    method: 'get',
    url:API_BASE_URL + 'check_form_status/',
    headers:{'Authorization' : "Bearer "+UserToken}, 
  }).then(response => {
    if(response.data.form_status==='Form-1'){
      this.props.history.push("certificate")
     }
     else if(response.data.form_status==='Form-2'){
      this.props.history.push("edu_list")
     }
     else if(response.data.form_status==='Form-3'){
      this.props.history.push("about_you")
     }
     else if(response.data.form_status==='Form-4'){
      this.props.history.push("availability")
     }
     else if(response.data.form_status==='Form-5'){
      this.props.history.push("tutor_home") 
     }
     else if(response.data.form_status==null || response.data.form_status=="" || response.data.form_status=="undefined" || response.data.form_status==undefined){
       this.props.history.push("personal_Detail")
     }
  }).catch((error) => {
      console.log("login response",error)
    });
}





checkSocialLogin(socialID, loginType){
 // alert(loginType);

const fillData ={
  social_id: socialID,
  login_type: loginType,
  notification_id:this.state.notificationId,
    device_type:"web"
}

  axios({
    method: 'post',
    url:API_BASE_URL + `check_social_login/`,
    data: fillData,
  })
   .then(response => {
      console.log("check social login",response.data.message);
        if(response.data.message != "Social id not registerd"){
         // alert(response.data.message);
          this.SocialLogin();
        }
        else{
         // alert(response.data.message);
          localStorage.setItem('social_id',this.state.useridSocial)
          localStorage.setItem('login_type',this.state.socialType)
          localStorage.setItem('fullname',this.state.nameSocial) 
          this.props.history.push("verifyAccount");
        }
    })
    .catch((error) => {
     // console.log(error)
    });
}

// social login 

SocialLogin(){

 const  usertype = localStorage.getItem('usertype');
  //alert(usertype);

 const loginData = {
   social_id: this.state.useridSocial,
   login_type: this.state.socialType,
   notification_id:this.state.notificationId,
    device_type:"web"
   //user_type: usertype
 }
 
   axios({
     method: 'post',
     url:API_BASE_URL + `social_login/`,
     data: loginData
   })
    .then(response => {
       console.log("login done",response.data);

      // const usertype = localStorage.setItem('usertype',response.data.user_type);
       const UserToken = localStorage.setItem('UserToken',response.data.access);
        
           if (response.data.user_type == "0"){
               this.props.history.push("/dashboard");
           }
           else if(response.data.user_type == "1") {
              
            this.props.history.push("/tutor_home")
            this.handleformStatus();

           }
       
     })
     .catch((error) => {
      // console.log(error)
     });

}

// facebook authentication

responseFacebook(response) {
  console.log("myyyyyyyy facebook response",response);
  if(response.accessToken != null){
    this.setState({isLoggedIn: true});
    this.setState({socialType: "facebook"});
    this.setState({nameSocial: response.name});
    this.setState({emailSocial: response.email});
    this.setState({useridSocial: response.userID});
    this.checkSocialLogin(this.state.useridSocial,this.state.socialType);

   // localStorage.setItem('UserToken',response.accessToken)
   // this.props.history.push("dashboard")

  }
} 


// google authentiation 

 responseGoogle = (response) => {
  console.log("goggle login working",response);

  if(response.tokenObj.access_token != null){
  
    this.setState({isLoggedIn: true});
    this.setState({socialType: "google"});
    this.setState({nameSocial: response.profileObj.name});
    this.setState({emailSocial: response.profileObj.email});
    this.setState({useridSocial: response.profileObj.googleId});
    this.checkSocialLogin(this.state.useridSocial,this.state.socialType);

  //  localStorage.setItem('UserToken',response.tokenObj.access_token)
  //  this.props.history.push("dashboard")
  }

}



componentDidMount= () =>{
  // alert(this.state.id)
  const user = localStorage.getItem('UserToken') 
  if (user && user !== 'undefined') {            
      // this.props.history.push('/')             
  }
  const messaging = firebase.messaging();


  

  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("../firebase-messaging-sw.js")
      .then((registration)=> {
        console.log("Registration successful, scope is:", registration);
        messaging.getToken({vapidKey: 'BFHdJVqtAg6uSQvDTrpjZHCmK7VcoAHjdWa8eF1NFmZzWxMhxNlG8L8iiHvp296ii0Aj3J0jV5yqig5OwclgIl8'
        
         })
          .then((currentToken) => {
            if (currentToken) {
              // this.onBackgroundMessage(this.props)
              console.log('current token for client: ', currentToken);
              this.setState({notificationId:currentToken}) 
            } else {
              console.log('No registration token available. Request permission to generate one.');
            }
          }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            // catch error while creating client token
          });  
        })
        .catch(function(err) {
          console.log("Service worker registration failed, error:"  , err );
      }); 
    }
  }




render() {

// for facebook login

  const facebookLoggin = 
  <FacebookLogin
    appId="324622905928174"
    autoLoad={false}
    fields="name,email,picture"
    callback={this.responseFacebook}
    render={renderProps => (
     
    <a type="button" onClick={renderProps.onClick}>
      <img className="social_icons" src={fb} alt="" />
    </a>

    )}

  /> 

  // for google login

 const googleLoggin = 
 <GoogleLogin
    clientId="1063192044923-t9iscr7frokgh5f4sr2okl6ap58mlhjd.apps.googleusercontent.com"
    buttonText="Login"
    onSuccess={this.responseGoogle}
    onFailure={this.responseGoogle}
    cookiePolicy={'single_host_origin'}
    render={renderProps => (
      <a type="button" onClick={renderProps.onClick} disabled={renderProps.disabled}>
        <img className="social_icons" src={google} alt="" />
      </a>
      )}
  /> 

  return(
    <main>
    <Header></Header>
    <section  className="login_form mx-auto margin_top">
      <div className="container-fluid border-0 width_custom">
        <div className="row">
          <div className="col-md-8 col-sm-12 bg_banner_login login_main_banner">
          </div>
          <div className="col-md-4 col-sm-12 m-auto">
            <div className="card2 border-0 my-3 login_card">
              <form className=" text-center loginpage" onSubmit={this.handleSubmit} method="post">
              <h2 > Login </h2>
            <div className="logo">
              <a href="#">
                {/* <img src="/static/media/logo1.26950ff8.png"className="logo-sticky-none" alt="Logo"></img> */}
              </a>
            </div> 
                 
            <div className="form-group input_container">
              <input 
                type="text" 
                // maxLength="10" 
                onChange={this.handleChange}  
                id="Username" 
                name="username" 
                value={this.state.fields.username} 
                className="form-control my-input" 
                placeholder="Phone/Email" 
                />
                {this.state.usernamevalidation}
                 {/* <div className="text-danger">{this.state.errors.username}</div> */}
              {/* <div className="text-danger" >  {this.state.MessageInvalidUsername && !this.state.MessageInvalidpassword ? "phone No. is " : null} </div> */}
              {/* {this.state.usernamevalidation?<p className="text-danger">Please Enter Valid Username</p>:null} */}
            </div>

            <div className="form-group eye_iconn_show">
              <input 
                type={this.state.type} 
                onChange={this.handleChange} 
                id="Password" 
                name="password" 
                value={this.state.fields.password} 
                className="form-control my-input" 
                placeholder="Password" 
                />
           
              <span className="password__show" onClick={this.showHide}>
              {this.state.type === 'input'?
              
                <i className="fa fa-eye" aria-hidden="true"><FontAwesomeIcon icon={faEye} /></i>
                :
                <i className="fa fa-eye" aria-hidden="true"><FontAwesomeIcon icon={faEyeSlash} /></i>
              }
              </span>

              {/* <div className="form-group">
                <div className="text-danger">  {this.state.MessageInvalidUsername ? "Invalid username or password ." : null} </div>
              </div> */}
             
              {/* <div className= "text-danger">{this.state.MessageInvalidpassword? "password is required" : null} </div> */}
              {/* <div className="text-danger" > {!this.state.errors.username&&this.state.errors.password }</div> */}
              {/* {this.state.passwordvalidation?<p className="text-danger">Wrong Password</p>:null} */}
            </div>
           {this.state.passwordvalidation}

            <Loader
              visible = {this.state.visible}
              type="Oval"
              color="#00bacc"
              height={40}
              width={40}
              timeout={3000} //3 secs
            />

            <div className="form-group">
              <button className="btn  btn-block border_radius_20  send-button tx-tfm" type="submit">Login</button>
            </div>
                        
            <div className="col-md-12">
              <Link to="Send_otp">
                <p className="forgot_password">Forgotten password</p>
              </Link>
            <div className="login-or"> 
                <span className="span-or">OR</span>
            </div>
          </div>

          <div className="d-flex flex-row location justify-content-center">
              {facebookLoggin}
              {googleLoggin} 
              {/* <a type="button">
                <img className="social_icons" src={twitter} alt="" />
              </a> */}
          </div>
      
          <p className="signup_pera">Don't have an account?
            <Link to="/verifyaccount"> Signup</Link>
          </p>

          <div className="form-group">
              <Link className="decorationNone" to="/verifyaccount">
              <button className="btn  btn-block border_radius_20  send-button tx-tfm" type="submit">Create New Account</button></Link>
          </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<Footer></Footer>
</main>
    );
  }
}






