
import React from 'react';
import Slider from "react-slick"; 
import axios from 'axios';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import test1 from "../../assets/img/categories/test1.png";
import { API_BASE_URL } from '../../config/api';
import { BASE_URL } from '../../config/api';

class Testimonial extends React.Component {
  
  state = {
    TestimonialList: []
  }
  componentDidMount() {
    axios.get(API_BASE_URL + `testimonial_view/`)
      .then(res => {
        this.setState({TestimonialList: res.data.queryset});

      })
  }

  render() {
        var settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 1366,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }]
        };

    const TestimonialListData =
      this.state.TestimonialList.map((data) => {
        return (
            <div className="testimonial_slider">
              <div className="testi_content_slide">
                <div className="testi_content">
                  <p class="extra_content_limit">{data.review}</p>
                  <img src={BASE_URL + data.image} alt=""/>
                  <h6>{data.first_name + ' ' + data.last_name}</h6>
                  <span>{data.designation}</span>
                </div>
              </div>
            </div>
         )
      })

    return (
        
<section className="testimonial_section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="dark_head">
                <h2><span>Client</span><span> Testimonials</span></h2>
              </div>
              <p className="pera_dark text-center">Our Undergraduates, Graduates, Ex/Current School Teacher & Fulltime Tutors are ready to help students realise their full academic potential</p>
            </div>
          </div>
        </div>

       
        <div className="container-fluid width_custom">
        
          <div className="row">
         
            <div className="col-md-12 mt-4">
            <Slider {...settings}>               
                {TestimonialListData}
               {/* <div className="testimonial_slider">
                   <div className="testi_content_slide">
                        <div className="testi_content">
                            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the race.</p>
                            <img src={test1} alt="" />
                            <h6>Amanda Jackson</h6>
                            <span>CEO, NRD Group</span>
                        </div>
                    </div>
               </div>
               <div className="testimonial_slider">
                   <div className="testi_content_slide">
                        <div className="testi_content">
                            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the race.</p>
                            <img src={test1} alt="" />
                            <h6>Amanda Jackson</h6>
                            <span>CEO, NRD Group</span>
                        </div>
                    </div>
               </div>
               <div className="testimonial_slider">
                   <div className="testi_content_slide">
                        <div className="testi_content">
                            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the race.</p>
                            <img src={test1} alt="" />
                            <h6>Amanda Jackson</h6>
                            <span>CEO, NRD Group</span>
                        </div>
                    </div>
               </div>
               <div className="testimonial_slider">
                   <div className="testi_content_slide">
                        <div className="testi_content">
                            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the race.</p>
                            <img src={test1} alt="" />
                            <h6>Amanda Jackson</h6>
                            <span>CEO, NRD Group</span>
                        </div>
                    </div>
               </div> */}
               </Slider>
            </div>       
          </div>
        </div>
      </section>  
);
  }
}

export default Testimonial


