// import React from 'react';
// import Header from "../common/header";
// import Footer from "../common/footer";
// import axios from 'axios';
// import defaultMaleImg from '../../assets/img/default_male.png';
// import defaultFemaleImg from '../../assets/img/default_female.png';
// import { API_BASE_URL, BASE_URL } from '../../config/api.js';
// import moment from "moment";
// import { faAngleRight, faCheck } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
// import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
// import { faHeart, faStar, faComment, faUser, faUsers, faGlobe, faStopwatch } from '@fortawesome/free-solid-svg-icons';
// import StarRatings from '../../../node_modules/react-star-ratings';
// import { Link } from "react-router-dom";
// import swal from 'sweetalert';

// var UserToken = ''

// class Tutorprofile extends React.Component {
// constructor(props) {
// super(props)
// this.state = {
// usertype_var: "",
// id : this.props.location.state.id,
// front_id: '',
// tutor_user: '',
// tutorDetail: [],
// preDateTime: [],
// tutor_sub_categoryDetail: [],
// tutor_education_historyDetails: [],
// EducationDetail: [],
// datalist: [],
// categoryDataList:[]
// }
// this.onhandleClick = this.onhandleClick.bind(this);
// }
// sweetAlertChat = () => {
// swal({
// text: "Please login to chat with tutor.",
// icon: "warning",
// dangerMode: true,
// })
// }
// sweetAlertSchedule = () => {
// swal({
// text: "Please login to continue schedule.",
// icon: "warning",
// dangerMode: true,
// })
// }
// sweetAlertValidUser = () => {
// swal({
// title: "Sorry!!",
// text: "You are not a valid user.", 
// icon: "warning",
// dangerMode: true,
// })
// }
// static getDerivedStateFromProps(nextProps, prevState) {
// console.log('previd',nextProps.location.pathname.split('/')[2])
// return nextProps.location.pathname.split('/');
// }

// componentDidMount() {
// const value = localStorage.getItem('usertype');
// this.setState({usertype_var: value});
// this.onhandleClick(this.state.id)   
// }
// onhandleClick(tutor) {

// axios({
// method: 'post',
// url: API_BASE_URL + 'tutor_detail/',
// data: {
// tutor_id: tutor
// }
// }).then(response => {
// this.setState({ tutorDetail: response.data.tutor_profile })
// this.setState({ tutor_sub_categoryDetail: response.data.tutor_sub_category })
// this.setState({categoryDataList:response.data.tutor_category})
// this.setState({ tutor_education_historyDetails: response.data.tutor_education_history })
// this.setState({ EducationDetail: response.data.tutor_education_history })
// })
// .catch((error) => {
// console.log(error)
// });

// }

// onChatHandle(tutorId) {
//    this.props.history.push({ 
//       pathname: '/conversations',
//       state: { id: tutorId }
//    });
// }

// render() {
// UserToken = localStorage.getItem('UserToken');
// const { params_tutor_user } = this.props.location
// this.state.tutor_user = params_tutor_user
// const tutordetailConst = this.state.tutorDetail.map((value) => {
// return (
// <div class="row">
//    <div class="col-md-4">
//       <div class="tutor_sidebar tutor_side_mob">
//          <div class="img_tutor_profile">
//             {value.image == null ?
//             value.gender == "female" ?
//             <img class="booking_user_img" src={defaultFemaleImg} alt="" />
//             : <img class="booking_user_img" src={defaultMaleImg} alt="" />
//             : <img class="pro_tut" src={BASE_URL + value.image} alt="" />
//             }
//             <div class="profile_det_secc">
//                <h5>{value.front_id}</h5>
//                {value.rating != '' ?
//                <div class="rating_divv">
//                   <StarRatings
//                      rating={Number(value.rating)}
//                      starRatedColor="#ffc107"
//                      starDimension="18px"
//                      starSpacing="0px" />
//                </div>
//                : <span> (New User)</span>
//                }
//             </div>
//             <p>{value.fullname}</p>
//             {value.online_status == 1 ?
//             <div class="status_div">
//                <div className="online_status"></div>
//                ONLINE
//             </div>
//             :
//             <div class="status_div">
//                <div className="offline_status"></div>
//                OFFLINE
//             </div>
//             }
//          </div>
//          <div class="tutorr_about">
//             <h4>About me</h4>
//             <p>{value.teacher_about}</p>
//          </div>
//       </div>
//    </div>
//    <div class="col-md-8">
//       <div class="main_tutor_div">
//          <div class="tuition_secc">
//             <div class="tutorr_scc_icn">
//                <div class="tutor_iconns">
//                   <i className="tutor_fa_iconn" aria-hidden="true">
//                      <FontAwesomeIcon icon={faUsers} />
//                   </i>
//                </div>
//                <span>Home Tuition</span>
//             </div>
//             <div class="tutorr_scc_icn">
//                <div class="tutor_iconns">
//                   <i className="tutor_fa_iconn" aria-hidden="true">
//                      <FontAwesomeIcon icon={faGlobe} />
//                   </i>
//                </div>
//                <span>Group Tuition</span>
//             </div>
//             <div class="tutorr_scc_icn">
//                <div class="tutor_iconns">
//                   <i className="tutor_fa_iconn" aria-hidden="true">
//                      <FontAwesomeIcon icon={faStopwatch} />
//                   </i>
//                </div>
//                <span>Ondemnd Tuition</span>
//             </div>
//          </div>
//          <div class="tutor_padding">
//             <nav class="tutor_nav">
//                <div class="nav nav-tabs" id="nav-tab" role="tablist">
//                   <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Education Details</a>
//                   <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Availability</a>
//                   <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">Experience & Rate</a>
//                </div>
//             </nav>
//             <div class="tab-content tab_tutor_content" id="nav-tabContent">
//                <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
//                   {this.state.EducationDetail.map((data) => {
//                   return (
//                   <div class="tutor_edu_info">
//                      <div class="address_info"><span>Eduactions Details: </span> <span>{data.level_name}</span></div>
//                      <div class="address_info"><span>Study Fields : </span> <span>{data.field_name}</span></div>
//                      <div class="address_info"><span>Area Of Specialization Minor : </span> <span>{data.specialisation_minor_area}</span></div>
//                      <div class="address_info"><span>Area Of Specialization Major : </span> <span>{data.specialisation_major_area}</span></div>
//                      <div class="address_info"><span>University/College : </span> <span>{data.university_name}</span></div>
//                      <div class="address_info"><span>Date Attended : </span> <span> {moment(data.date_attended).tz('America/Chicago').format("MM/DD/YY hh:mm:ss A ")}CST</span></div>
//                   </div>
//                   )})}
//                </div>
//                <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
//                 <div class="col-md-12">
//                 {this.state.tutorDetail.map((value) => {
//                    const a = value.availability
//                    const b = a.replace(/'/g, '"');
//                    const datalist = JSON.parse(b)
//                    return datalist.map((data12) => {
//                    var dayslist = [data12.days]
//                    return (
//                      dayslist.map((daysdata) => {
//                         return (
//             <>
//               {daysdata.monday_status == 0 ?
//                   <div className="address_info">
//                      <span>Days : </span>
//                      <span>Full Day</span>
//                   </div>
//                   : null
//                }
//                {daysdata.monday_time.length != 0 ?
//                <div className="address_info">
//                      <span>Monday : </span>
//                      {daysdata.monday_time.map((mondata) => {
//                          return (
//                      <span>{mondata}</span>
//                      )
//                   })
//                   }
//                   </div>
//                   :null}
//                    {daysdata.tuesday_status == 0 ?
//                       <div className="address_info">
//                       <span>Tuesday : </span>
//                       <span>Full Day</span>
//                    </div>
//                     : null}
//                {daysdata.tuesday_time.length != 0 ?
//                       <div className="address_info">
//                              <span>Tuesday: </span>
//                              {
//                              daysdata.tuesday_time.map((tuesdata) => {
//                              return (
                             
//                                 <span>{tuesdata},  </span>
                             
//                              )
//                              })
//                              }
//                      </div>
//                           : null
//                         }
//                           {daysdata.wednesday_status == 0 ?
//                       <div className="address_info">
//                              <h1>Wednesday: </h1>
//                              <h1>Full Day</h1>
//                           </div>
//                           : null
//                           }
//                           {
//                           daysdata.wednesday_time.length != 0 ?
//                           <div className="address_info">
//                              <span>Wednesday: </span>
//                              {
//                              daysdata.wednesday_time.map((wednsdata) => {
//                              return (
                     
//                                 <span>{wednsdata},  </span>
                        
//                              )
//                              })
//                              }
//                           </div>
//                           : null
//                           }
                      
                  
//                           {daysdata.thursday_status == 0 ?
//                     <div className="address_info">
//                              <span>Thursday: </span>
//                              <h1>Full Day</h1>
//                           </div>
//                           : null
//                           }
//                           {
//                           daysdata.thursday_time.length != 0 ?
//                           <div className="address_info">
//                              <span>Thursday: </span>
//                              {
//                              daysdata.thursday_time.map((thirsdata) => {

//                              return (
                       
//                                 <span>{thirsdata},  </span>
                            
//                              )
//                              })
//                              }
//                           </div>
//                           : null
//                           }
             
//                           {daysdata.friday_status == 0 ?
//                            <div className="address_info">
//                              <span>Friday: </span>
//                              <span>Full Day</span>
//                            </div>
//                           : null
//                           }
//                           {
//                           daysdata.friday_time.length != 0 ?
//                           <div className="address_info">
//                              <span>Friday: </span>
//                              {
//                              daysdata.friday_time.map((fridata) => {
//                              return (
                       
//                                 <span>{fridata},  </span>
                           
//                              )
//                              })
//                              }
//                           </div>
//                           : null
//                           }
                       
//                           {
//                           daysdata.saturday_status == 0 ?
//                           <div className="address_info">
//                              <span>Saturday: </span>
//                              <span>Full Day</span>
//                           </div>
//                           : null
//                           }
//                           {
//                           daysdata.saturday_time.length != 0 ?
//                           <div className="address_info">
//                              <span>Saturday: </span>
//                              {
//                              daysdata.saturday_time.map((saturdaydata) => {
//                              return (
//                              <div >
//                                 <span>{saturdaydata},  </span>
//                              </div>
//                              )
//                              })
//                              }
//                           </div>
//                           : null
//                           }
                       
//                           {daysdata.sunday_status == 0 ?
//                               <div className="address_info">
//                                 <span>Sunday: </span>
//                                 <span>Full Day</span>
//                               </div>
//                           : null
//                           }
//                           {
//                           daysdata.sunday_time.length != 0 ?
//                           <div className="address_info">
                            
//                                 <span>Sunday: </span>
//                                 <span>
//                                 {
//                                 daysdata.sunday_time.map((sundata) => {
//                                 return (
//                                    <>{sundata},  </>
//                                 )
//                                 })
//                                 }
//                                 </span>
                           
//                           </div>
//                           : null
//                           }

//                 </>
//                 )
//                })
//                )
//                })
//                })}
//                </div>
//                </div>
//                <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
               
//                   <table class="table_experience_rate_show table table-striped table-bordered">
//                      <tr>
//                      <td>Category_Name</td>
//                         <td>Subjects</td>
//                         <td>Rate</td>
//                         <td>Experience</td>
                        
//                      </tr>
//                      {this.state.tutor_sub_categoryDetail.map((hours) => {
                        
//                      return this.state.categoryDataList.map((exp)=>{
//                       if(hours.tutor_exp_id==exp.id){
//                          return (
//                      <tr>
//                         <td>{exp.categories_name}</td>
//                         <td>{hours.sub_cat}</td>
//                         <td>${hours.one_hour}</td>
//                         <td>{exp.category_experience}</td>
                        
//                         </tr>
//                          )
//                       }
//                          })})}
//                   </table>
//                </div>
//             </div>
//          </div>
//       </div>

//       {/* <div class="tutor_btnss text-center mt-4">
//          {UserToken == null ?
//          <button class="chat_tutor mx-2" onClick={() =>
//             {this.sweetAlertChat() }}>
//             <i className="">
//                <FontAwesomeIcon icon={faFacebookMessenger}></FontAwesomeIcon>
//             </i>
//             Chat
//          </button>
//          :this.state.usertype_var == "1" ?
//          <button class="chat_tutor mx-2" onClick={() =>
//             {this.sweetAlertValidUser() }}>
//             <i className="">
//                <FontAwesomeIcon icon={faFacebookMessenger}></FontAwesomeIcon>
//             </i>
//             Chat
//          </button>
//          :
//          <button class="chat_tutor mx-2" onClick={() =>
//             this.onChatHandle(value.user)}>
//             <i className="">
//                <FontAwesomeIcon icon={faFacebookMessenger}></FontAwesomeIcon>
//             </i>
//             Chat
//          </button>
//          } 
//          {UserToken == null ?
//          <button class="view_profile mx-2" onClick={() => {this.sweetAlertSchedule() }}>Schedule</button>
//          :this.state.usertype_var == "1" ?
//          <button class="view_profile mx-2" onClick={() => {this.sweetAlertValidUser() }}>Schedule</button>
//          :<Link to={"/booking/"+value.user+'/'+this.state.id1}>
//          <button class="view_profile mx-2">Schedule</button>
//          </Link>}
//       </div> */}
      
//    </div>
// </div>
// )
// })
// return (
// <main>
//    <Header></Header>
//    <section className="inner_banner section_padding">
//       <div className="container">
//          <div className="row">
//             <div className="inner_contain">
//                <h2>Tutor Profile</h2>
//                <ul>
//                   <li>
//                      <Link to="/">
//                      Home</Link>
//                   </li>
//                   <i>
//                      <FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon>
//                      &nbsp;
//                   </i>
//                   <li><a href="#"> Tutor Profile</a></li>
//                </ul>
//             </div>
//          </div>
//       </div>
//    </section>
//    <section class="section_padding">
//       <div class="container">
//          {this.state.tutorDetail && this.state.tutorDetail.length >0?
//          tutordetailConst
//          :null}
//       </div>
//    </section>
//    <Footer></Footer>
// </main>
// )
// }
// }
// export default Tutorprofile;


import React from 'react';
import Header from "../common/header";
import Footer from "../common/footer";
import axios from 'axios';
import defaultMaleImg from '../../assets/img/default_male.png';
import defaultFemaleImg from '../../assets/img/default_female.png';
import { API_BASE_URL, BASE_URL } from '../../config/api.js';
import moment from "moment";
import { faAngleRight, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
import { faHeart, faStar, faComment, faUser, faUsers, faGlobe, faStopwatch } from '@fortawesome/free-solid-svg-icons';
import StarRatings from '../../../node_modules/react-star-ratings';
import { Link } from "react-router-dom";
import swal from 'sweetalert';

var UserToken = ''

class Tutorprofile extends React.Component {
constructor(props) {
super(props)
this.state = {
usertype_var: "",
id : this.props.location.state.id,
front_id: '',
tutor_user: '',
tutorDetail: [],
preDateTime: [],
tutor_sub_categoryDetail: [],
tutor_education_historyDetails: [],
EducationDetail: [],
datalist: [],
categoryDataList:[]
}
this.onhandleClick = this.onhandleClick.bind(this);
}
sweetAlertChat = () => {
swal({
text: "Please login to chat with tutor.",
icon: "warning",
dangerMode: true,
})
}
sweetAlertSchedule = () => {
swal({
text: "Please login to continue schedule.",
icon: "warning",
dangerMode: true,
})
}
sweetAlertValidUser = () => {
swal({
title: "Sorry!!",
text: "You are not a valid user.", 
icon: "warning",
dangerMode: true,
})
}
static getDerivedStateFromProps(nextProps, prevState) {
console.log('previd',nextProps.location.pathname.split('/')[2])
return nextProps.location.pathname.split('/');
}

componentDidMount() {
const value = localStorage.getItem('usertype');
this.setState({usertype_var: value});
this.onhandleClick(this.state.id)   
}
onhandleClick(tutor) {

axios({
method: 'post',
url: API_BASE_URL + 'tutor_detail/',
data: {
tutor_id: tutor
}
}).then(response => {
this.setState({ tutorDetail: response.data.tutor_profile })
this.setState({ tutor_sub_categoryDetail: response.data.tutor_sub_category })
this.setState({categoryDataList:response.data.tutor_category})
this.setState({ tutor_education_historyDetails: response.data.tutor_education_history })
this.setState({ EducationDetail: response.data.tutor_education_history })
})
.catch((error) => {
console.log(error)
});

}

onChatHandle(tutorId) {
   this.props.history.push({ 
      pathname: '/conversations',
      state: { id: tutorId }
   });
}

render() {
UserToken = localStorage.getItem('UserToken');
const { params_tutor_user } = this.props.location
this.state.tutor_user = params_tutor_user
const tutordetailConst = this.state.tutorDetail.map((value) => {
return (
<div class="row">
   <div class="col-md-4">
      <div class="tutor_sidebar tutor_side_mob">
         <div class="img_tutor_profile">
            {value.image == null ?
            value.gender == "female" ?
            <img class="booking_user_img" src={defaultFemaleImg} alt="" />
            : <img class="booking_user_img" src={defaultMaleImg} alt="" />
            : <img class="pro_tut" src={BASE_URL + value.image} alt="" />
            }
            <div class="profile_det_secc">
               <h5>{value.front_id}</h5>
               {value.rating != '' ?
               <div class="rating_divv">
                  <StarRatings
                     rating={Number(value.rating)}
                     starRatedColor="#ffc107"
                     starDimension="18px"
                     starSpacing="0px" />
               </div>
               : <span> (New User)</span>
               }
            </div>
            <p>{value.fullname}</p>
            {value.online_status == 1 ?
            <div class="status_div">
               <div className="online_status"></div>
               ONLINE
            </div>
            :
            <div class="status_div">
               <div className="offline_status"></div>
               OFFLINE
            </div>
            }
         </div>
         <div class="tutorr_about">
            <h4>About me</h4>
            <p>{value.teacher_about}</p>
         </div>
      </div>
   </div>
   <div class="col-md-8">
      <div class="main_tutor_div">
         <div class="tuition_secc">
            <div class="tutorr_scc_icn">
               <div class="tutor_iconns">
                  <i className="tutor_fa_iconn" aria-hidden="true">
                     <FontAwesomeIcon icon={faUsers} />
                  </i>
               </div>
               <span>Home Tuition</span>
            </div>
            <div class="tutorr_scc_icn">
               <div class="tutor_iconns">
                  <i className="tutor_fa_iconn" aria-hidden="true">
                     <FontAwesomeIcon icon={faGlobe} />
                  </i>
               </div>
               <span>Group Tuition</span>
            </div>
            <div class="tutorr_scc_icn">
               <div class="tutor_iconns">
                  <i className="tutor_fa_iconn" aria-hidden="true">
                     <FontAwesomeIcon icon={faStopwatch} />
                  </i>
               </div>
               <span>Ondemnd Tuition</span>
            </div>
         </div>
         <div class="tutor_padding">
            <nav class="tutor_nav">
               <div class="nav nav-tabs" id="nav-tab" role="tablist">
                  <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Education Details</a>
                  <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Availability</a>
                  <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">Experience & Rate</a>
               </div>
            </nav>
            <div class="tab-content tab_tutor_content" id="nav-tabContent">
               <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                  {this.state.EducationDetail.map((data) => {
                  return (
                  <div class="tutor_edu_info">
                     <div class="address_info"><span>Eduactions Details: </span> <span>{data.level_name}</span></div>
                     <div class="address_info"><span>Study Fields : </span> <span>{data.field_name}</span></div>
                     <div class="address_info"><span>Area Of Specialization Minor : </span> <span>{data.specialisation_minor_area}</span></div>
                     <div class="address_info"><span>Area Of Specialization Major : </span> <span>{data.specialisation_major_area}</span></div>
                     <div class="address_info"><span>University/College : </span> <span>{data.university_name}</span></div>
                     <div class="address_info"><span>Date Attended : </span> <span> {moment(data.date_attended).tz('America/Chicago').format("MM/DD/YY hh:mm:ss A ")}CST</span></div>
                  </div>
                  )})}
               </div>
               <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                <div class="col-md-12">
                {this.state.tutorDetail.map((value) => {
                   const a = value.availability
                   const b = a.replace(/'/g, '"');
                   const datalist = JSON.parse(b)
                   return datalist.map((data12) => {
                   var dayslist = [data12.days]
                   return (
                     dayslist.map((daysdata) => {
                        return (
            <>
            {/* <table class="table">
               <tr>
                  <th>Sunday:</th>
                  <td>12AM-1AM, 1AM-2AM,</td>
               </tr>
               <tr>
                  <th>Sunday:</th>
                  <td>12AM-1AM, 1AM-2AM,</td>
               </tr>
               <tr>
                  <th>Sunday:</th>
                  <td>12AM-1AM, 1AM-2AM,</td>
               </tr>
               <tr>
                  <th>Sunday:</th>
                  <td>12AM-1AM, 1AM-2AM,</td>
               </tr>
            </table> */}
              {daysdata.monday_status == 1 ?
               <table class="table">
               <tr>
                  <th>Monday : </th>
                  <td>Full Day</td>
               </tr>
               </table>
              
                  : null
               }
               {daysdata.monday_time.length != 0 ?
            
               <table class="table">
               <tr>
                  <th>Monday : </th>
                  <td>
                  {
                     daysdata.monday_time.map((mondata) => {
                      return (
                 <> {mondata},
                 </>                        )
                })
                }
                </td>
               </tr>
               </table>
                  :null}
                   {daysdata.tuesday_status == 1 ?
                
                  <table class="table">
                  <tr>
                     <th>Tuesday : </th>
                     <td>Full Day</td>
                  </tr>
                  </table>
                    : null}
               {daysdata.tuesday_time.length != 0 ?
                    
                     <table class="table">
                     <tr>
                        <th>Tuesday: </th>
                        <td>
                        {
                           daysdata.tuesday_time.map((tuesdata) => {
                            return (
                       <> {tuesdata},
                       </>                        )
                      })
                      }
                      </td>
                     </tr>
                     </table>
                          : null
                        }
                          {daysdata.wednesday_status == 1 ?
                     
                     <table class="table">
                  <tr>
                     <th>Wednesday: </th>
                     <td>Full Day</td>
                  </tr>
                  </table>
                          : null
                          }
                          {
                          daysdata.wednesday_time.length != 0 ?
                    
                        <table class="table">
                        <tr>
                           <th>Wednesday: </th>
                           <td>
                           {
                              daysdata.wednesday_time.map((wednsdata) => {
                               return (
                          <> {wednsdata},
                          </>                        )
                         })
                         }
                         </td>
                        </tr>
                        </table>
                          : null
                          }
                      
                  
                          {daysdata.thursday_status == 1?
                 
                  <table class="table">
                  <tr>
                     <th>Thursday: </th>
                     <td>Full Day</td>
                  </tr>
                  </table>
                          : null
                          }
                          {
                          daysdata.thursday_time.length != 0 ?
                       
                      
                        <table class="table">
                        <tr>
                           <th>Thursday: </th>
                           <td>
                           {
                              daysdata.thursday_time.map((thursdata) => {
                               return (
                          <> {thursdata},
                          </>                        )
                         })
                         }
                         </td>
                        </tr>
                        </table>
                          : null
                          }
             
                          {daysdata.friday_status == 1 ?
                           
                           <table class="table">
                           <tr>
                              <th>Friday: </th>
                              <td>Full Day</td>
                           </tr>
                           </table>
                          : null
                          }
                          {
                          daysdata.friday_time.length != 0 ?
                       
                        <table class="table">
                        <tr>
                           <th>Friday: </th>
                           <td>
                           {
                              daysdata.friday_time.map((fridata) => {
                               return (
                          <> {fridata},
                          </>                        )
                         })
                         }
                         </td>
                        </tr>
                        </table>
                          : null
                          }
                       
                          {
                          daysdata.saturday_status == 1?
                          <table class="table">
                          <tr>
                             <th>Saturday:</th>
                             <td>Full Day</td>
                          </tr>
                        
                       </table>
                          : null
                          }
                          {
                          daysdata.saturday_time.length != 0 ?
                        
                        <table class="table">
                        <tr>
                           <th>Saturday: </th>
                           <td>
                           {
                              daysdata.saturday_time.map((saturdaydata) => {
                               return (
                          <> {saturdaydata},
                          </>                        )
                         })
                         }
                         </td>
                        </tr>
                        </table>
                          : null
                          }
                          {daysdata.sunday_status==1?
                           <table class="table">
                           <tr>
                              <th>Sunday:</th>
                              <td>Full Day</td>
                           </tr>
                         
                        </table>
                        :null
                          
                        }
                        {
                          daysdata.sunday_time.length !=0?
                          <table class="table">
                          <tr>
                             <th>Sunday:</th>
                             <td>
                             {
                                daysdata.sunday_time.map((sundata) => {
                                 return (
                            <> {sundata},
                            </>                        )
                           })
                           }
                           </td>
                          </tr>
                          </table>
                          :null}
                       
                          {/* {daysdata.sunday_status == 0 ?
                              <div className="address_info">
                                <span>Sunday: </span>
                                <span>Full Day</span>
                              </div>
                          : null
                          }
                          {
                          daysdata.sunday_time.length != 0 ?
                          <div className="address_info">
                            
                                <span>Sunday: </span>
                                <span>
                                {
                                daysdata.sunday_time.map((sundata) => {
                                return (
                                   <>{sundata},  </>
                                )
                                })
                                }
                                </span>
                           
                          </div>
                          : null
                          } */}

                </>
                )
               })
               )
               })
               })}
               </div>
               </div>
               <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
               
                  <table class="table_experience_rate_show table table-striped table-bordered">
                     <tr>
                     <td>Category_Name</td>
                        <td>Subjects</td>
                        <td>Rate</td>
                        <td>Experience</td>
                        
                     </tr>
                     {this.state.tutor_sub_categoryDetail.map((hours) => {
                        
                     return this.state.categoryDataList.map((exp)=>{
                      if(hours.tutor_exp_id==exp.id){
                         return (
                     <tr>
                        <td>{exp.categories_name}</td>
                        <td>{hours.sub_cat}</td>
                        <td>${hours.one_hour}</td>
                        <td>{exp.category_experience}</td>
                        
                        </tr>
                         )
                      }
                         })})}
                  </table>
               </div>
            </div>
         </div>
      </div>

      {/* <div class="tutor_btnss text-center mt-4">
         {UserToken == null ?
         <button class="chat_tutor mx-2" onClick={() =>
            {this.sweetAlertChat() }}>
            <i className="">
               <FontAwesomeIcon icon={faFacebookMessenger}></FontAwesomeIcon>
            </i>
            Chat
         </button>
         :this.state.usertype_var == "1" ?
         <button class="chat_tutor mx-2" onClick={() =>
            {this.sweetAlertValidUser() }}>
            <i className="">
               <FontAwesomeIcon icon={faFacebookMessenger}></FontAwesomeIcon>
            </i>
            Chat
         </button>
         :
         <button class="chat_tutor mx-2" onClick={() =>
            this.onChatHandle(value.user)}>
            <i className="">
               <FontAwesomeIcon icon={faFacebookMessenger}></FontAwesomeIcon>
            </i>
            Chat
         </button>
         } 
         {UserToken == null ?
         <button class="view_profile mx-2" onClick={() => {this.sweetAlertSchedule() }}>Schedule</button>
         :this.state.usertype_var == "1" ?
         <button class="view_profile mx-2" onClick={() => {this.sweetAlertValidUser() }}>Schedule</button>
         :<Link to={"/booking/"+value.user+'/'+this.state.id1}>
         <button class="view_profile mx-2">Schedule</button>
         </Link>}
      </div> */}
      
   </div>
</div>
)
})
return (
<main>
   <Header></Header>
   <section className="inner_banner section_padding">
      <div className="container">
         <div className="row">
            <div className="inner_contain">
               <h2>Tutor Profile</h2>
               <ul>
                  <li>
                     <Link to="/">
                     Home</Link>
                  </li>
                  <i>
                     <FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon>
                     &nbsp;
                  </i>
                  <li><a href="#"> Tutor Profile</a></li>
               </ul>
            </div>
         </div>
      </div>
   </section>
   <section class="section_padding">
      <div class="container">
         {this.state.tutorDetail && this.state.tutorDetail.length >0?
         tutordetailConst
         :null}
      </div>
   </section>
   <Footer></Footer>
</main>
)
}
}
export default Tutorprofile;

