
export const BASE_URL = "https://admin.wantateacher.com/";
export const API_BASE_URL = "https://api.wantateacher.com/";
export const CHAT_IMAGEURL = "https://api.wantateacher.com/media/chat/";
export const CONVERSATION_BASE_URL = "https://api.wantateacher.com/";





export const Google_StreetNameURL = "https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:"

// export const BASE_URL = "http://173.212.250.62:3005";
// export const API_BASE_URL = "http://173.212.250.62:3005/api/";

// export const CHAT_URL='https://chat.wantateacher.com/';
// export const API_ChatBase_URL = "https://chat.edusys.sg/";

// export const CHAT_IMAGEURL = "http://173.212.250.62:3005/media/chat/";
// export const CONVERSATION_BASE_URL = "http://173.212.250.62:3005/";
// export const API_IMAGE_URL_MEDIA = "http://173.212.250.62:3005";
// // export const  API_BASE_URL = "http://192.168.29.254:5000/api/";
// export const BASE_URL = "http://192.168.29.254:5000";

