import React, { Component } from "react";
import axios from "axios";
import OtpInput from "react-otp-input";
import { API_BASE_URL } from '../../config/api.js';
import Header from "../common/header";
import Footer from "../common/footer";
import otp_img from "../../assets/img/categories/Icon/otp.png";
import { Link } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import swal from 'sweetalert';


class Emailverification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: "",
      MessageInvalidOTP: '',
      MessageSendOTP: '',
      MessageUpdateEmailSuccessfully: '',
      input: {},
      errors: {},

      visible: false,
      username: '',
      email: '',
      otp: "",
      val: "",
      val1: '',
      usertype: '',
      value: '',
      OTPError: '',
      MessageOTP: false,
      MessageSendOTP: false,
      time: [],
      i: 0,
      tempo: 0,
      newI: '',
      time: {}, seconds: 26,
      times: {}, seconds2: 26,

    }
    this.handleChange = otp => this.setState({ otp });
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmit1 = this.handleSubmit1.bind(this);
    // this.timer = 0;
    // this.countDown = this.countDown.bind(this);
    // this.timer2 = 0;
    // this.countDown2 = this.countDown2.bind(this);

  }

  handleChange(evt) {
    const value = evt.target.value;
    this.setState({ [evt.target.name]: evt.target.value });
  }

  handleSubmit = event => {
    event.preventDefault();

    const UserToken = localStorage.getItem('UserToken');
    console.log("aaaaaaa", UserToken)

    if (UserToken != '') {

      const formData = new FormData();
      formData.append('new_email', localStorage.getItem('email'));
      formData.append('otp', this.state.otp);
      formData.append('status', 'verified');

      console.log('new_oe', formData)
      this.setState({ visible: true })
      axios({
        method: 'post',

        url: API_BASE_URL + "update_email_otp/",

        headers: { 'Authorization': "Bearer " + UserToken },
        data: formData,

      }).then(response => {
        this.setState({ visible: false })
        if (response.data.success == 201) {
          if (response.data.message == "Otp not verified or something wrong") {
            this.sweetAlertVerificationInvalidUser()





          }
        }
        else if (response.data.success == 200) {
          this.props.history.push("/login")
          localStorage.setItem('email', this.state.new_email)
        }
      })
        .catch((error) => {
          console.log(error)
        });
    }

  }


  handleSubmit1 = event => {

    // this.state.time.length = 0
    // this.setState({time:[]})

    // this.countFun(datavalue)
    // this.timer(datavalue)
    // {this.clear([])}}

    // this.countFun()

    event.preventDefault();
    // this.setState({ otp: "", });



    const UserToken = localStorage.getItem('UserToken');

    if (UserToken != '') {
      const sendData = {
        new_email: localStorage.getItem('email'),
      };
      this.setState({ visible: true })
      axios({
        method: 'post',

        url: API_BASE_URL + "update_email/",
        headers: { 'Authorization': "Bearer " + UserToken },
        data: sendData
      }).then(response => {
        console.log("oyp", response.data)
        // this.countFun()
        // this.timer()
        this.setState({ visible: false })
        if (response.data.success == 200) {

          if (response.data.message == "Otp send successfully on your mail") {

            this.sweetAlertEmailsend()
      
            // console.log("1222222",this.timer())
            // this.timer(this.state.time[this.state.i])
            // this.setInterval()
            // this.state.time[this.state.i]
            // let timeLeftVar = this.secondsToTime2(this.state.seconds2);
            // this.setState({ times: timeLeftVar });
            // if (this.timer2 == 0 && this.state.seconds2 > 0) {
            //   this.timer2 = setInterval(this.countDown2, 1000);
            // }





          }
        }
        else if (response.data.success == 201) {
          this.sweetAlertEmailErrorResponse()
        }
      })
        .catch((error) => {
          console.log(error)
        });
    }

  }

  // secondsToTime(secs) {
  //   let hours = Math.floor(secs / (60 * 60));

  //   let divisor_for_minutes = secs % (60 * 60);
  //   let minutes = Math.floor(divisor_for_minutes / 60);

  //   let divisor_for_seconds = divisor_for_minutes % 60;
  //   let seconds = Math.ceil(divisor_for_seconds);

  //   let obj = {
  //     "h": hours,
  //     "m": minutes,
  //     "s": seconds
  //   };
  //   return obj;
  // }
  componentDidMount() {
    this.interval()
    // let timeLeftVar = this.secondsToTime(this.state.seconds);
    //     this.setState({ time: timeLeftVar });
    
    //     if (this.timer == 0 && this.state.seconds > 0) {
    //       this.timer = setInterval(this.countDown, 1000);
    //     }
  

    // this.countFun()   
  }



  interval() {

    this.setState({ seconds: 26 });

    let myInterval = setInterval(() => {
        if (this.state.seconds == 0) {
            clearInterval(myInterval);
        } else {
            this.setState({ seconds: this.state.seconds - 1 });
        }
    }, 1000)
}



  // componentWillUnmount() {
  //   clearInterval(this.myInterval);
  // }

// countFun(){



  
//   // for (let i = 15; i >= 0; i--) {
//   // this.state.time.push(i)
//   // }
//   // clearInterval(this.myInterval);
  
//   // // console.log(this.state.time)
//   // this.myInterval = setInterval(() => {

//   //     if (this.state.i < 15) {
//   //         this.setState({ newI: this.state.i })
//   //         this.timer()
//   //         this.state.i = this.state.i + 1
//   //     } else {
//   //         this.setState({ tempo: this.state.i + 1 });
//   //         // console.log('trmo',this.state.tempo)
//   //     }
//   // }, 1000);
//   // return ()=> {
//   //   clearInterval(this.myInterval);
//   // };
//   // this.timer()
//     let timeLeftVar = this.secondsToTime(this.state.seconds);
//     this.setState({ time: timeLeftVar });

//     if (this.timer == 0 && this.state.seconds > 0) {
//       this.timer = setInterval(this.countDown, 1000);
//     }




//   }

  // secondsToTime(secs) {
  //   let hours = Math.floor(secs / (60 * 60));

  //   let divisor_for_minutes = secs % (60 * 60);
  //   let minutes = Math.floor(divisor_for_minutes / 60);

  //   let divisor_for_seconds = divisor_for_minutes % 60;
  //   let seconds = Math.ceil(divisor_for_seconds);

  //   let obj = {
  //     "h": hours,
  //     "m": minutes,
  //     "s": seconds
  //   };
  //   return obj;
  // }

  // secondsToTime2(secs) {
  //   let hours = Math.floor(secs / (60 * 60));

  //   let divisor_for_minutes = secs % (60 * 60);
  //   let minutes = Math.floor(divisor_for_minutes / 60);

  //   let divisor_for_seconds = divisor_for_minutes % 60;
  //   let seconds2 = Math.ceil(divisor_for_seconds);

  //   let obj = {
  //     "h": hours,
  //     "m": minutes,
  //     "s": seconds2
  //   };
  //   return obj;
  // }

  // countDown() {
  //   // Remove one second, set state so a re-render happens.
  //   let seconds = this.state.seconds - 1;
  //   this.setState({
  //     time: this.secondsToTime(seconds),
  //     seconds: seconds,
  //   });

  //   // Check if we're at zero.
  //   if (seconds == 0) {
  //     clearInterval(this.timer);
  //   }
  // }

  // countDown2() {
  //   // Remove one second, set state so a re-render happens.
  //   let seconds2 = this.state.seconds2 - 1;
  //   this.setState({
  //     times: this.secondsToTime(seconds2),
  //     seconds2: seconds2,
  //   });

  //   // Check if we're at zero.
  //   if (seconds2 == 0) {
  //     clearInterval(this.timer2);
  //   }
  // }


  sweetAlertEmailsend = () => {
    swal({
      text: "Otp send successfully on your mail",

      icon: "success",

    })


  }


  sweetAlertVerificationInvalidUser = () => {
    swal({
      text: "Invalid Otp",
      icon: "warning",
      dangerMode: true,
    })
  }



  sweetAlertEmailErrorResponse = () => {
    swal({
      text: "Invalid User",
      icon: "warning",
      dangerMode: true,
    })

  }


//   timer() {
//     // console.log('++ ',this.state.time[this.state.i] )
//     return (
//         <p>{this.state.time[this.state.i]}</p>
//     )
// }




resenddd = () => {


  this.setState({otp:''})

this.interval()
  // this.sendOtp()

  // let timeLeftVar = this.secondsToTime2(this.state.seconds2);
  //         this.setState({ times: timeLeftVar });
  //         if (this.timer2 == 0 && this.state.seconds2 > 0) {
  //           this.timer2 = setInterval(this.countDown2, 1000);
  //         }

}




  render() {

    return (
      <main>
        <Header></Header>
        <section className="login_form mx-auto margin_top otp_page">
          <div className="container-fluid width_custom border-0">
            <div className="row">
              <div className="col-md-7 col-sm-12 bg_banner_login">

              </div>
              <div className="col-md-5 col-sm-12 m-auto">
                <div className="card2 border-0 my-3">
                  <form className=" text-center loginpage" onSubmit={this.handleSubmit} method="post">
                    <h2 >Verify Email </h2>
                    <div className="logo" >
                      <Link to="#">
                        <img src={otp_img} alt="Logo"></img>
                      </Link>
                    </div>
                    <p class="otp_peraa">OTP has been send to your registered email - {localStorage.getItem('email')}</p>
                    <p class="otp_peraa margin0">Please enter it below</p>
                    <div className="form-group otp_input_section">
                      <OtpInput
                        value={this.state.otp}
                        onChange={this.handleChange}
                        numInputs={6}
                        autoFocus
                        name="otp"

                        isInputNum
                        // secure
                        separator={<span></span>} />
                      {this.state.OTPError ? this.state.OTPError : null}
                    </div>

                    <div className="form-group">
                      <div className="text-danger">{this.state.MessageOTP ? "OTP Is Invalid" : null}
                      </div>
                    </div>

                    <Loader
                      visible={this.state.visible}
                      type="Oval"
                      color="#00bacc"
                      height={40}
                      width={40}
                      timeout={3000} //3 secs
                    />

                    <div className="form-group mt-5">
                      {this.state.otp.length >= 6 ?
                        <button className="btn btn-block send-button border_radius_20 tx-tfm" type="submit">Verify</button>
                        :
                        <button className="btn btn-block send-button border_radius_20 tx-tfm" disabled="true" type="submit">Verify</button>
                      }
                    </div>


{this.state.seconds== 0 ?

<p className="resendotpbtn" >
  <button  onClick={() => this.resenddd()}>Resend Security Code</button>
  {this.state.seconds} sec left


</p>
:
<p className="resendotpbtn" >
  <button type="submit" disabled='true' >Resend Security Code</button>
  {this.state.seconds} sec left
</p>}
          


                   {/* <p class="resendotpbtn" >


                      {this.state.time.s != 0 ?
                        <>
                          {this.state.time.s} sec left
                   </>
                        : null}
                     

                   </p>
                    {this.state.time.s == 0 && this.state.times.s != 0 ?

                      <p class="resendotpbtn" >
                        <span type="submit" onClick={this.handleSubmit1}>Resend Security Code</span>
                        {this.state.times.s} sec left


                      </p>
                      :
                      <p class="resendotpbtn" >
                        <span type="submit" disabled='true' >Resend Security Code</span>
                      </p>} */}

{/* <div>
  

{this.state.time[this.state.i] == 0

    ?
    <>


        <button  onClick={this.handleSubmit1}>Resend OTP</button>
    
</>
    :
    <button disabled="true">Resend OTP</button>
}
<p >{this.timer()} sec left</p>


</div> */}

                    <div className="form-group">
                      <div className="text-danger text-center">{this.state.MessageSendOTP ? "OTP is sent succesfully." : null} </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer></Footer>
      </main>
    );
  }
}
export default Emailverification;