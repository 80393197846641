import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Header from '../common/header';
import Footer from'../common/footer';
import about1 from "../../assets/img/about_1.png";
import about2 from "../../assets/img/about_2.png";
// import Testimonial from'../home/testimonial';
import Category from'../home/category';
import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
import { faAngleRight, faEnvelope, faMapMarker, faPhone, } from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import axios from "axios";
import {API_BASE_URL} from'../../config/api.js';

export default class contact extends React.Component {
 constructor(props){
super(props);
this.state = {
  first_name: "",
  last_name: "",
  email: "",
  contact_no: "",
  message: "",
  MessageContact:''
}

this.handlechange = this.handlechange.bind(this);
this.handleSubmit = this.handleSubmit.bind(this);

}
  
    handlechange(evt){

      console.log('aess',evt.target.value)

   
      const common_name = evt.target.value;
   

       this.setState({[evt.target.name]:common_name});
      

    }

    handleSubmit = event => {
      event.preventDefault();
    
    

   



    const sendData = {

     first_name:this.state.first_name,
     last_name: this.state.last_name,
     email: this.state.email,
     contact_no: this.state.contact_no,
     message: this.state.message,
  
     };

        axios({
            method: 'post',
            url: API_BASE_URL + 'contact_us/',
            data: sendData
        }).then(response => {


          if (response.data.success == 200) {
            if (response.data.message == "contact us form successfully submitted.....") {
                this.setState({ MessageContact: true })
            }
        }


          // contact us form successfully submitted.....
          // console.log('aess',response.data)
      
          
        }).catch((error) => {
         
        });
        
    }
 

  render() {
    return (
      <main>
      <Header></Header>
      <section className="inner_banner section_padding">
        <div className="container">
          <div className="row">
            <div className="inner_contain">
            <h2>Contact Us</h2>
            <ul>
              <li><Link to="/">Home</Link></li> <i><FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon> &nbsp;</i>
              <li><a href="#"> Contact Us</a></li>
            </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="contact_main section_padding">
        <div className="container">
          <div className="row">
            <div className="col-sm-8">
              <div className="contact_aside">
                <div className="card4 border-0 contact_form contact_card">
                <h5>Get In Tuch</h5>
                <p>Fill The Form Below</p>

                <form autocomplete="off" class=" text-center loginpage cont_btn"  onSubmit={this.handleSubmit} method="post">

                  <div className="row">
                    <div className="col-sm-6">
                      <div class="form-group">
                        <input type="text" name="first_name" onChange={this.handlechange}  class="form-control my-input" placeholder="First Name" required/>
                        <div class="text-danger"></div><div class="text-danger"> 
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div class="form-group">
                        <input type="text" name="last_name" onChange={this.handlechange} class="form-control my-input" placeholder="Last Name" required/>
                        <div class="text-danger"></div><div class="text-danger"> 
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div class="form-group">
                        <input type="email" name="email" onChange={this.handlechange} class="form-control my-input" placeholder="E-mail" required/>
                        <div class="text-danger"></div><div class="text-danger"> 
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div class="form-group">
                        <input type="text" maxLength='10' name="contact_no" onChange={this.handlechange} class="form-control my-input" required placeholder="Contact Number " />
                        <div class="text-danger"></div><div class="text-danger"> 
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div class="form-group">
                        <textarea name="message" onChange={this.handlechange} className="form-control my-input custom_text" placeholder="Your Message Here" required></textarea>
                      </div>
                    </div>
                    <div className="col-sm-4 m-auto">
                      <div class="form-group">
                          <button class="btn btn-block send-button border_radius_20 tx-tfm " type="submit">Save</button>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <div className="text-success">  {this.state.MessageContact  ? "Your form has been submitted successfully" : null} </div>
                      </div>
                    </div>
                  </div>
                  
                </form>

                </div>
              </div>
                
            </div>

            <div className="col-sm-4">
              <div className="card4 contact_card">
                <div className="contact_deta">
                  <span><i><FontAwesomeIcon icon={faMapMarker}></FontAwesomeIcon> &nbsp;</i> <h5>Address</h5></span>
                  <p> Most of its text is made up from sections 1.10.32–3 of Cicero's De finibus</p>
                </div>
                <div className="contact_deta">
                  <span><i><FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon> &nbsp;</i> <h5>Email</h5></span>
                  <p>example@gmail.com</p>
                  <p>example@gmail.com</p>
                </div>
                <div className="contact_deta">
                  <span><i><FontAwesomeIcon icon={faPhone}></FontAwesomeIcon> &nbsp;</i> <h5>Contact</h5></span>
                  <p>+91 9856471235</p>
                  <p>+91 9564781234</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
      <section className="contact_detail map_section section_padding">
        <div className="address_mep">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d117763.5515414666!2d75.79380938754157!3d22.724115838761882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fcad1b410ddb%3A0x96ec4da356240f4!2sIndore%2C%20Madhya%20Pradesh!5e0!3m2!1sen!2sin!4v1615367719751!5m2!1sen!2sin"></iframe>
        </div>
      </section>
      <Footer></Footer>
      </main>
    );
  }
}