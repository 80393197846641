import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Header from '../common/header';
import Footer from'../common/footer';
import about1 from "../../assets/img/about_1.png";
import bs_1 from "../../assets/img/bs_1.png";
import become_2 from "../../assets/img/become_2.png";
import become_4 from "../../assets/img/become_4.png";
import become_5 from "../../assets/img/become_5.png";
import about2 from "../../assets/img/about_2.png";
// import Testimonial from'../home/testimonial';
import Category from'../home/category';
import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
import { faAngleRight, faCheck } from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import { API_BASE_URL } from '../../config/api';
import axios from 'axios';

export default class Become_teacher extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      become_studentContent : []
    }
  }

  componentDidMount(){
    axios({
      method: 'get',
      url: API_BASE_URL + `content_list/?content_type=become_student`,
      headers:{'Content-Type': 'become_student'}, 
      
      }).then(response => {
           // console.log("about api",response.data)
          this.setState({become_studentContent:response.data.content_data})
         // console.log("about", this.state.become_studentContent );
      }).catch((error) => {
          console.log(error);
      })
  
  }

  render() {
    return (
      <main>
      <Header></Header>
      <section className="inner_banner section_padding">
        <div className="container">
          <div className="row">
            <div className="inner_contain">
            <h2>Become Student</h2>
            <ul>
              <li><Link to="/">Home</Link></li> <i><FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon> &nbsp;</i>
              <li><a href="#"> Become Student</a></li>
            </ul>
            </div>
          </div>
        </div>
      </section>
      
      {this.state.become_studentContent.map((data)=>{
        console.log("become_student", data.title);  
        return(
          <>
            <div dangerouslySetInnerHTML={{__html: data.description }}>
             </div>

      
      {/* <section className="about_contain section_padding">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
            </div>
            <div className="col-sm-6">
                <img className="abt_cont_img img-fluid" src={bs_1} alt=""/>
            </div>
          </div>
        </div>
      </section>
     
      <section className="become_section1 section_padding">
        <div className="container-fluid custom_contant">
          <div class="dark_head"><h2><span>Lorem </span><span> Ipsum</span></h2></div>
          <p class="pera_dark text-center">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
          <div className="row mt-4">
            <div className="col-sm-3">
              <div className="about_card">
                <h1 className="about_no_1">01.</h1>
                <h4>Simply Dummy</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. dummy text of the printing and typesetting industry.</p>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="about_card">
                <h1 className="about_no_2">02.</h1>
                <h4>Simply Dummy</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. dummy text of the printing and typesetting industry.</p>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="about_card">
                <h1 className="about_no_3">03.</h1>
                <h4>Simply Dummy</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. dummy text of the printing and typesetting industry.</p>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="about_card">
                <h1 className="about_no_4">04.</h1>
                <h4>Simply Dummy</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. dummy text of the printing and typesetting industry.</p>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-sm-3">
              <div className="about_card">
                <h1 className="about_no_5">05.</h1>
                <h4>Simply Dummy</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. dummy text of the printing and typesetting industry.</p>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="about_card">
                <h1 className="about_no_6">06.</h1>
                <h4>Simply Dummy</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. dummy text of the printing and typesetting industry.</p>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="about_card">
                <h1 className="about_no_7">07.</h1>
                <h4>Simply Dummy</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. dummy text of the printing and typesetting industry.</p>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="about_card">
                <h1 className="about_no_8">08.</h1>
                <h4>Simply Dummy</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. dummy text of the printing and typesetting industry.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="become_section_2 section_padding requirement_section">
      <div className="container">
          <div class="dark_head"><h2><span>We Complete</span><span> Every Step Carefully</span></h2></div>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
        <div className="row">
          <div className="listed">
            <ul>
              <li><i className="check" aria-hidden="true"><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></i> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
              <li><i className="check" aria-hidden="true"><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></i> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
              <li><i className="check" aria-hidden="true"><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></i> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
              <li><i className="check" aria-hidden="true"><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></i> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
              <li><i className="check" aria-hidden="true"><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></i> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
              <li><i className="check" aria-hidden="true"><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></i> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
              <li><i className="check" aria-hidden="true"><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></i> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
            </ul>
          </div>
          </div>
        </div>
      </section> */}

    </>

    )})}
   
      <Footer></Footer>
      </main>
    );
  }
}