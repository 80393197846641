import React from 'react';
import Header from '../common/header';
import Footer from '../common/footer';
import defaultMaleImg from '../../assets/img/default_male.png';
import defaultFemaleImg from '../../assets/img/default_female.png';
import axios from 'axios';
import { Link } from "react-router-dom";
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { API_BASE_URL, BASE_URL } from '../../config/api.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
import TutorSideBar from "./tutorSideBar.js";




class TutorHome extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            booking_pending_list: [],
            last_session_list: [],
            education_level: [],
        }
        this.handleId = this.handleId.bind(this);
    }
    componentDidMount() {


        



        const UserToken = localStorage.getItem('UserToken');
        if (UserToken != '') {
            axios({
                method: 'post',
                url: API_BASE_URL + "tutor_home_detail/",
                headers: { 'Authorization': "Bearer " + UserToken },

            }).then(response => {
                this.setState({
                    profile_detail: response.data.profile_detail,
                    booking_pending_list: response.data.booking_pending_list,
                    last_session_list: response.data.booking_complete_list
                })
            })
        }

    }
    handleId(bookingId) {
        const val = localStorage.setItem("country_code", bookingId);
        console.log("bookingvalue", val)
        // this.props.history.push({ 
        //     pathname: '/tutorbookingdetail',
        //     state: { 
        //     bookingId: bookingId}
        //  });
    }

    // handleId(bookingId){
    //     this.props.history.push({ 
    //         pathname: '/tutorbookingdetail',
    //         state: { 
    //         bookingId: bookingId}
    //      });
    // }

    render() {
        const last_sessionDetailsConst = this.state.last_session_list.map((lastsession) => {
            //console.log("hiwjdksjhasiduhsifj",lastsession)
            return (
                <div class="row box_custom_booking margin0">
                    <div className="col-md-6 col-6 upper_booking_heads">
                        <h4>{lastsession.category_name}</h4>
                        <p className="sub_b">Subject :  <b>{lastsession.sub_category_name}</b></p>
                    </div>
                    <div className="col-md-6 col-6 text-right">
                        <Link onClick={() =>
                            this.handleId(lastsession.id)} className="no_underline"
                            to={{
                                pathname: "tutorbookingdetail",
                                params: lastsession.id,

                            }}>
                            <div className="arrow_left mt-3">&#8594;</div>
                        </Link>
                        {/* <div className="arrow_left" onClick={() => this.handleId(lastsession.id)}>&#8594;</div> */}
                    </div>
                    <div className="col-md-12"><div className="border_l"></div></div>
                    <div className="col-md-6">
                        <div className="booking_info">

                            {lastsession.image == null ?
                                lastsession.gender == "female" ?
                                    <img class="booking_user_img" src={defaultFemaleImg} alt="" />
                                    : <img class="booking_user_img" src={defaultMaleImg} alt="" />
                                : <img class="booking_user_img" src={BASE_URL + lastsession.image} alt="" />
                            }
                            <div className="info_pera">
                            {/* <div className="pro_detail"> */}
                                <h2>{lastsession.fullname} </h2>
                               
                                {lastsession.tuition_type != null && lastsession.tuition_type!='undefined' ?
                                    <span>{lastsession.tuition_type} </span>
                                    : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 text-right my-auto">
                        {/* <span>{lastsession.duration_of_session}</span> <br></br> */}
                    </div>
                </div>
            )
        })

        const booking_pending_listConst = this.state.booking_pending_list.map((pending) => {
            return (
                <div class="row box_custom_booking margin0">
                    <div className="col-md-6 col-6 upper_booking_heads">
                        <h4>{pending.category_name}</h4>
                        <p className="sub_b">Subject :  <b>{pending.sub_category_name}</b></p>
                    </div>
                    <div className="col-md-6 col-6 text-right">
                        {/* <div className="arrow_left" onClick={() => this.handleId(pending.id)}>&#8594;</div> */}
                        <Link onClick={() =>
                            this.handleId(pending.id)} className="no_underline"
                            to={{
                                pathname: "tutorbookingdetail",
                                params: pending.id,

                            }}>
                            <div className="arrow_left mt-3">&#8594;</div>
                        </Link>
                    </div>
                    <div className="col-md-12"><div className="border_l"></div></div>
                    <div className="col-md-6">
                        <div className="booking_info">
                            {pending.image == null ?
                                pending.gender == "female" ?
                                    <img class="booking_user_img" src={defaultFemaleImg} alt="" />
                                    : <img class="booking_user_img" src={defaultMaleImg} alt="" />
                                : <img class="booking_user_img" src={BASE_URL + pending.image} alt="" />
                            }

                            <div className="info_pera">
                                <h2>{pending.fullname} </h2>
                                {pending.tuition_type != 'undefined' && pending.tuition_type != undefined ?
                                    <span>{pending.tuition_type}</span>
                                    : null}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 text-right my-auto">
                        {/* <span>{pending.duration_of_session}</span> <br></br> */}
                    </div>
                </div>

            )
        })

        return (
            <main>
                <Header></Header>
                <section className="">
                    <div className="container-fluid margin_top width_custom">
                        <div className="row">
                            <div className="col-md-3 mt30">
                                <TutorSideBar></TutorSideBar>
                            </div>

                            <div className="col-md-9 mt30">
                                {this.state.last_session_list && this.state.last_session_list.length > 0 ?
                                    <>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h2 className="dashboard_headings">Last Session</h2>
                                            </div>
                                        </div>

                                        {last_sessionDetailsConst}
                                    </>
                                    : <div class="col-md-12 welcome_dashboard">
                                        <center><h4>No Last Session</h4> </center>
                                    </div>
                                }
                                <div className="row">
                                    <div className="col-md-12">
                                        <h2 className="dashboard_headings">New Booking</h2>
                                    </div>
                                </div>
                                <div className="row booking_sec">
                                    <div class="col-md-12">

                                        {booking_pending_listConst}

                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </section>
                <Footer></Footer>
            </main>
        );
    }
}

export default TutorHome
