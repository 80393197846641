import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Header from '../common/header';
import Footer from'../common/footer';
import about1 from "../../assets/img/about_1.png";
import about5 from "../../assets/img/about_5.png";
import about6 from "../../assets/img/about_6.png";
import about7 from "../../assets/img/about_7.png";
import about8 from "../../assets/img/about_8.png";
// import Testimonial from'../home/testimonial';
import Category from'../home/category';
import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
import { faAngleRight, LocationArrow } from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import { API_BASE_URL } from '../../config/api';
import axios from 'axios';

export default class about extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      aboutContent : []
    }

  }

    componentDidMount(){
      axios({
        method: 'get',
        url: API_BASE_URL + `content_list/?content_type=about_us`,
        headers:{'Content-Type': 'about_us'}, 
        
        }).then(response => {
            
            this.setState({aboutContent:response.data.content_data})
            // console.log("about", this.state.aboutContent );
            // console.log("about api",obj);
        }).catch((error) => {
            console.log(error);
        })
    
  }

  render() {

   // var obj;

    return (              
      <main>
      <Header></Header>

      <section className="inner_banner section_padding">
        <div className="container">
          <div className="row">
            <div className="inner_contain">
            <h2>About Us</h2>
              <ul>
                <li><Link to="/">Home</Link></li> <i><FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon> &nbsp;</i>
                <li><a href="#">About Us</a></li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      
      {this.state.aboutContent.map((data)=>{
      //const obj = JSON.parse(data.description);
        console.log("about", data.description);  

      return(
      <>
      <div dangerouslySetInnerHTML={{__html: data.description }}>
      </div>
      
       

      {/*
      <section className="about_contain section_padding">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
                <img className="abt_cont_img img-fluid" src={about1} alt=""/>
            </div>
            <div className="col-sm-6">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
            </div>
          </div>
        </div>
      </section>
      <section className="about_services section_padding">
        <div className="container">
          <div className="col-sm-12">
            <div class="dark_head"><h2><span>Lorem</span><span> Ipsum</span></h2></div>
          </div>
          <div className="row">
            <div className="col-sm-4">
              <div className="absv_cont">
              <img className="cont_img" src={about5} alt=""/>
                <h5>Lorem Ipsum</h5>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="absv_cont">
              <img className="cont_img" src={about5} alt=""/>
                <h5>Lorem Ipsum</h5>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="absv_cont">
              <img className="cont_img" src={about5} alt=""/>
                <h5>Lorem Ipsum</h5>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about_cont section_padding">
        <div className="container">
            <div class="dark_head"><h2><span>Lorem </span><span> Ipsum</span></h2>
            </div>
            <p class="pera_dark text-center">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
          <div className="row mt-4">
            <div className="col-sm-6">
              <div className="plans_inner">                  
                <div className="detail_img">
                  <img className="cont_img" src={about7} alt=""/>
                </div>
                <div>
                  <h5>Lorem Ipsum is simply dummy text of the</h5>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has</p>
                </div>
              </div>
              <div className="plans_inner">                  
                <div className="detail_img">
                  <img className="cont_img" src={about7} alt=""/>
                </div>
                <div>
                  <h5>Lorem Ipsum is simply dummy text of the</h5>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsums</p>
                </div>
              </div>
              <div className="plans_inner">                  
                <div className="detail_img">
                  <img className="cont_img" src={about7} alt=""/>
                </div>
                <div>
                  <h5>Lorem Ipsum is simply dummy text of the</h5>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="studets_img">
                <img src={about8} alt=""/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about_section4 section_padding">
        <div className="container">
        <div class="dark_head"><h2><span>Lorem </span><span> Ipsum</span></h2></div>
          <div className="row">
            <div className="col-sm-3">
              <img className="cont_img" src={about5} alt=""/>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            </div>
            <div className="col-sm-3">
              <img className="cont_img" src={about6} alt=""/>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            </div>
            <div className="col-sm-3">
              <img className="cont_img" src={about5} alt=""/>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            </div>
            <div className="col-sm-3">
              <img className="cont_img" src={about6} alt=""/>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3">
              <img className="cont_img" src={about6} alt=""/>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            </div>
            <div className="col-sm-3">
              <img className="cont_img" src={about5} alt=""/>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            </div>
            <div className="col-sm-3">
              <img className="cont_img" src={about6} alt=""/>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            </div>
            <div className="col-sm-3">
              <img className="cont_img" src={about5} alt=""/>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            </div>
          </div>
        </div>
      </section> */}
      </>
      )})}

      <Footer></Footer>
      </main>   
      
    );
  }
}