// import React from 'react';
// import Header from '../common/header';
// import 'firebase/messaging';
// import Footer from'../common/footer';
// import { API_BASE_URL, BASE_URL } from '../../config/api.js'
// import axios from 'axios';
// import moment from 'moment';
// import notify_img from "../../assets/img/rahul.png";
// import { getToken, onMessageListener } from '../../firebase';
// import {Button, Row, Col, Toast} from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import SideBar from '../user/sideBar';
// import TutorSideBar from '../tutor/tutorSideBar'
// import { requestFirebaseNotificationPermission } from '../../firebase';
// // import { onMessageListener } from '../../firebase';

// class Notifications extends React.Component {
// constructor(props){
// super(props);
//     this.state={
//      usertype_var: '',
//      user_notification:[],
//      setTokenFound:false,
//     //  const [show, setShow] = useState(false);
//     //  const [notification, setNotification] = useState({title: '', body: ''});
//     //  const [isTokenFound, setTokenFound] = useState(false);
//     show:false,
//     notification:{title: '', body: ''},
//     isTokenFound:false
//     }
// }





// componentDidMount(){
    
//     this.setState({isTokenFound:getToken(this.state.isTokenFound)})
//     // getToken(this.state.isTokenFound);
//     onMessageListener().then(payload => {
        
//         this.setState({show:true});
//         // setShow(true);
//         this.setState({notification:({title:payload.notification.title,body: payload.notification.body})})
//         // setNotification({title: payload.notification.title, body: payload.notification.body})
//         console.log("pay",payload);
//       }).catch(err => console.log('failed: ', err));

//     // onMessageListener().then(message => {
//     //     console.log("hello",message)
//     //     this.setState({setTokenFound:true});
//     //   }).catch(err => console.log('failed: ', err));
//     // // requestFirebaseNotificationPermission()
//     // .then((firebaseToken) => {
//     //   // eslint-disable-next-line no-console
//     //   console.log("Shubhamid",firebaseToken);
//     // })
//     // .catch((err) => {
//     //   return err;
//     // });

//     // setRequesting(true);
//     // axios.get('/messages').then((resp) => {
//     //     console.log("daataaa",resp.data.messages)
//     // //   setMessages(resp.data.messages);
//     // //   setRequesting(false);
//     // });

//     // onMessageListener()
//     // .then((payload) => {
//     //   const { title, body } = payload.data;
//     //   console.log("title data",title,body)
//     // //   toast.info(`${title}; ${body}`);
//     // })
//     // .catch((err) => {
//     // //   toast.error(JSON.stringify(err));
//     // });
//     // getToken(this.state.getToken)
//     // getToken(this);


//      this.setState({usertype_var: localStorage.getItem('usertype')});

// const UserToken = localStorage.getItem('UserToken');

// if (UserToken != '') {
// axios({
// method: 'GET',
// url: API_BASE_URL + "notification_list/",
// headers: { 'Authorization': "Bearer " + UserToken, 'Content-Type': 'application/json' },
// // data: formData
// }).then(response => {
// console.log("notification_list/", response.data)
// this.setState({user_notification:response.data.user_notification})
// }).catch((error) => {
// console.log(error)
// })

// }

// }

// // getToken(true)
// render() {

// return (
// <main>
// <Header></Header>
// <section className="">
// <div className="container-fluid margin_top width_custom">
// <div className="row">  
// <div className="col-md-3 mt30">
// {this.state.usertype_var == 0 ?
//     <SideBar></SideBar> :
//     <TutorSideBar></TutorSideBar>}
// </div>

// <div className="col-md-9 mt30">
//     <div className="row">
//         <div className="col-md-12">
//             <h2 className="dashboard_headings">Notifications</h2>
//         </div>
//     </div>   
//     <div className="row m-0">
//         <div className="col-md-12 box_custom upcoming_session notifications_secc">
//             <div class="notify_headerr">
//                 <h2>All Notifications</h2>
//             </div>
//             <div class="middle_notifi">
//                 <ul>
//                     {this.state.user_notification.length != 0 ? 
//                 this.state.user_notification.map((notification)=>{
//                 return(
//                     <li>
//                         <img src={notify_img} alt="" />
//                         <p>{notification.description}</p>
//                         <span>{moment(notification.added_on).format("DD MMM, Y")}</span>
//                     </li>  
//                 )})
//                     : <li> 
//                         <p>You have no Notifications</p>
//                       </li>}                             
//                 </ul>         
//             </div>


//             <Toast onClose={() => this.setState({show:false})} show={this.state.show} delay={3000} autohide animation style={{
//           position: 'absolute',
//           top: 20,
//           right: 20,
//         }}>
//           <Toast.Header>
//             <img
//               src="holder.js/20x20?text=%20"
//               className="rounded mr-2"
//               alt=""
//             />
//             <strong className="mr-auto">{this.state.notification.title}</strong>
//             <small>12 mins ago</small>
//           </Toast.Header>
//           <Toast.Body>{this.state.notification.body}gggggg</Toast.Body>
//         </Toast>
//         <Button onClick={() => this.setState({show:true})}>Show Toast</Button>
//             {/* <div class="notifyy_footer">
//                 <h4>load more activities</h4>
//             </div> */}
//         </div>
//     </div>
// </div>
// </div>
// </div>
// </section>
// <Footer></Footer>
// </main>
// );
// }
// }

// export default Notifications



import React from 'react';
import Header from '../common/header';
import 'firebase/messaging';
import Footer from'../common/footer';
import { API_BASE_URL, BASE_URL } from '../../config/api.js'
import axios from 'axios';
import moment from 'moment';
import notify_img from "../../assets/img/rahul.png";
import { getToken, onMessageListener } from '../../firebase';
import {Button, Row, Col, Toast} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import SideBar from '../user/sideBar';
import TutorSideBar from '../tutor/tutorSideBar'
import { requestFirebaseNotificationPermission } from '../../firebase';
// import { onMessageListener } from '../../firebase';

class Notifications extends React.Component {
constructor(props){
super(props);
    this.state={
     usertype_var: '',
     user_notification:[],
     setTokenFound:false,
    //  const [show, setShow] = useState(false);
    //  const [notification, setNotification] = useState({title: '', body: ''});
    //  const [isTokenFound, setTokenFound] = useState(false);
    show:false,
    notification:{title: '', body: ''},
    isTokenFound:false
    }
}





componentDidMount(){
    
    this.setState({isTokenFound:getToken(this.state.isTokenFound)})
    // getToken(this.state.isTokenFound);
    onMessageListener().then(payload => {
        
        this.setState({show:true});
        // setShow(true);
        this.setState({notification:({title:payload.notification.title,body: payload.notification.body})})
        // setNotification({title: payload.notification.title, body: payload.notification.body})
        console.log("pay",payload);
      }).catch(err => console.log('failed: ', err));

    // onMessageListener().then(message => {
    //     console.log("hello",message)
    //     this.setState({setTokenFound:true});
    //   }).catch(err => console.log('failed: ', err));
    // // requestFirebaseNotificationPermission()
    // .then((firebaseToken) => {
    //   // eslint-disable-next-line no-console
    //   console.log("Shubhamid",firebaseToken);
    // })
    // .catch((err) => {
    //   return err;
    // });

    // setRequesting(true);
    // axios.get('/messages').then((resp) => {
    //     console.log("daataaa",resp.data.messages)
    // //   setMessages(resp.data.messages);
    // //   setRequesting(false);
    // });

    // onMessageListener()
    // .then((payload) => {
    //   const { title, body } = payload.data;
    //   console.log("title data",title,body)
    // //   toast.info(`${title}; ${body}`);
    // })
    // .catch((err) => {
    // //   toast.error(JSON.stringify(err));
    // });
    // getToken(this.state.getToken)
    // getToken(this);


     this.setState({usertype_var: localStorage.getItem('usertype')});

const UserToken = localStorage.getItem('UserToken');

if (UserToken != '') {
axios({
method: 'GET',
url: API_BASE_URL + "notification_list/",
headers: { 'Authorization': "Bearer " + UserToken, 'Content-Type': 'application/json' },
// data: formData
}).then(response => {
console.log("notification_list/", response.data)
this.setState({user_notification:response.data.user_notification})
}).catch((error) => {
console.log(error)
})

}

}

// getToken(true)
render() {

return (
<main>
<Header></Header>
<section className="">
<div className="container-fluid margin_top width_custom">
<div className="row">  
<div className="col-md-3 mt30">
{this.state.usertype_var == 0 ?
    <SideBar></SideBar> :
    <TutorSideBar></TutorSideBar>}
</div>

<div className="col-md-9 mt30">
    <div className="row">
        <div className="col-md-12">
            <h2 className="dashboard_headings">Notifications</h2>
        </div>
    </div>   
    <div className="row m-0">
        <div className="col-md-12 box_custom upcoming_session notifications_secc">
            <div class="notify_headerr">
                <h2>All Notifications</h2>
            </div>
            <div class="middle_notifi notification_deta">
                <ul>
                    {this.state.user_notification.length != 0 ? 
                this.state.user_notification.map((notification)=>{
                return(
                    <li>
                        <img src={notify_img} alt="" />
                        {/* <p>{(notification ? "" : ", ") + notification.description}</p> */}
                        <p>{notification.description}</p>
                        <span>{moment(notification.added_on).format("DD MMM, Y")}</span>
                    </li>  
                )})
                    : <li> 
                        <p>You have no Notifications</p>
                      </li>}      
                {/* <Button onClick={() => this.setState({show:true})} className="notification_status">Show Toast</Button>     */}
                </ul>  
            </div>


            <Toast onClose={() => this.setState({show:false})} show={this.state.show} delay={3000} autohide animation style={{
          position: 'absolute',
          top: 20,
          right: 20,
        }}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />
            <strong className="mr-auto">{this.state.notification.title}</strong>
            <small>12 mins ago</small>
          </Toast.Header>
          <Toast.Body>{this.state.notification.body}</Toast.Body>
        </Toast>
        
            {/* <div class="notifyy_footer">
                <h4>load more activities</h4>
            </div> */}
        </div>
    </div>
</div>
</div>
</div>
</section>
<Footer></Footer>
</main>
);
}
}

export default Notifications

