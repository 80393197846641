import React, { Component } from "react";
import Header from '../common/header';
import Footer from '../common/footer';

import axios from 'axios';
import {API_BASE_URL} from '../../config/api.js'
// import Sidebar from '../user/sidebar';
import swal from 'sweetalert';
import moment from 'moment';

var SessionToken = ''
var BBBID = ''
var PDOFUID = ''
var SessionID = ''
export default class feesStructure extends Component {
   constructor() {
   super();
   this.state = {
     

   }
   }

   SessionNotStart = () => {
      swal({
          text: "Please Start Your Session On Time",
         //  icon: "warning",
         dangerMode : true
      })
   }
  
   SessionStart = () => {
      swal({
      text: "Your Session Is Starting",
      icon: "success",
      })
   }
   Somethingwrong = () => {
      swal({
          text: "Something Went Wrong",
          icon: "warning",
          dangerMode: true,
      })
  }
componentDidMount(){
   
		SessionToken = new URLSearchParams(this.props.location.search).get("token")
		BBBID = new URLSearchParams(this.props.location.search).get("bbb_id")
		PDOFUID = new URLSearchParams(this.props.location.search).get("pdofu")
      SessionID = new URLSearchParams(this.props.location.search).get("session_id")
      console.log("SessionToken",SessionToken)
      console.log("BBBID",BBBID)
      console.log("PDOFUID",PDOFUID)
      console.log("SessionID",SessionID)
   
}

   GETStartSession(){

    const UserToken = localStorage.getItem('UserToken');
    if (UserToken != '') {
   const sendData = new FormData();
   let Datetime = moment(new Date()).format('YYYY-MM-DD hh:mm:ss')
   sendData.append('current_time',"2021-08-02 04:25:00")
//    sendData.append('current_time',Datetime)
   sendData.append('bbb_id',BBBID)
   sendData.append('session_id',SessionID)
   sendData.append('user_id',PDOFUID)
   sendData.append('token',SessionToken)
   axios({
     method: 'POST',
   //   url: 'http://173.212.250.62:3002/api/join_meeting/',
     url: API_BASE_URL + 'join_meeting/',
     headers: { 'Authorization': "Bearer " + UserToken },
     data: sendData
   }).then(response => {
      console.log("Guru",response.data.success)
      if(response.data.success==201){
         if(response.data.message=="Please start your session on time"){
            // this.SessionNotStart()
            swal({
               text: "Please Start Your Session On Time",
              //  icon: "warning",
              dangerMode : true
           })
         }
      }
      else if(response.data.success==200){
         if(response.data.message=="This is your meeting request"){
            swal({
               text: "Your Session Is Starting",
               icon: "success",
            })
            const ConferencingLink = response.data.meeting_join_url
            window.location.href = ConferencingLink
             this.SessionStart()
            
         }
      }
   }).catch(error=>{
     // alert("Something Went Wrong")
   //   this.Somethingwrong()
     })
    }
 }

render() {
  
return(
<main class="box-header">
   <Header/>
   <section className="margin_top">
   <div className="container-fluid m_top100">
    <div className="row mb-5">
        <div className="col-md-4">
            {/* <Sidebar></Sidebar>      */}
        </div>
        <div className="col-md-8">
        {/* <div class="row"> */}
        <div class="col-md-12 all_heads margintop25">
            <h2>Start Conferencing Session <span></span></h2>
        </div>
        {/* </div> */}
        <div className="box_inovoice_detail">
        <center><button type="button" className="modal-btnn" onClick={this.GETStartSession}>Start Session</button></center> 
        </div>
        </div>
    </div>
</div>
   </section>
   <Footer/>
</main>
)
}
}