import React from 'react';
import axios from "axios";
import Header from "../common/header";
import Footer from "../common/footer";
import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '../../../node_modules/@fortawesome/free-solid-svg-icons'
import { API_BASE_URL } from '../../config/api';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
var payemtid=''
export default class Add_new_password extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
          visible : false,
          // id: this.props.match.params.id,
          password:'',
          type: 'password',
          type2: 'password',
          password2:'',
          input:{},
          fields:{},
          errors:{},
          responseNewMessage:'',
    }
    
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showHide = this.showHide.bind(this);
    this.showHide2 = this.showHide2.bind(this);
    }

    showHide(e) {
      
      this.setState({
        type: this.state.type === 'input' ? 'password' : 'input'
      })
     
    }
    showHide2(e) {
      
      this.setState({
        type2: this.state.type2 === 'input' ? 'password' : 'input'
      })
    }

    handleChange(evt) {
            const value=evt.target.value;
            this.setState({ [evt.target.name]: evt.target.value });
            let input = this.state.input;
            input[evt.target.name] = evt.target.value;
            this.setState({ input });
           
              if(evt.target.name=="password"){
              this.setState({passwordValidationErrorShow:false})
              }
              if(evt.target.name=="password2"){
              this.setState({password2ValidationErrorShow:false})
              }
          }



          componentDidMount(){
              payemtid = new URLSearchParams(this.props.location.search).get("token")
              console.log('pay',payemtid)
         
          }

    handleSubmit = event => {
            event.preventDefault();
            if (this.passwordValidation()) {
              let fields = {};
              fields['password']="";
              fields["password2"] = "";
              this.setState({responseNewMessage:false})

      const sendData = {
        email : localStorage.getItem("username"),
        type:"email",
            new_password : this.state.password,
            confirm_password : this.state.password2,
            verify_status:'forgate_password',
            otp:payemtid
      };


      const sendData1={
        username : localStorage.getItem("username"),
        type:"phone",
        new_password : this.state.password,
        confirm_password : this.state.password2,
        verify_status:'verified',
     

      }
              
         
         if(payemtid!=null){

      

      
   
     this.setState({visible: true})
      axios({
      method: 'post',
      url:API_BASE_URL+"forgate_password_by_email/",
      data: sendData
      
      }).then(response => {
        console.log("firebase",response)

      //   this.setState({visible: false})

      // if(response.data.success==200){
        this.props.history.push("/login")
      // }
    })
      .catch((error) => {
        console.log(error)
    });
  }
  else{

    this.setState({visible: true})
    axios({
    method: 'post',
    url:API_BASE_URL+"forgate_password_by_firebase/",
    data: sendData1
    
    }).then(response => {
      console.log("firebase",response)

    //   this.setState({visible: false})

    if(response.data.success==200){
      this.props.history.push("/login")
    }
  else if(response.data.success==201){
    if(response.data.message=="New password and confirm password must be same"){
      this.setState({ responseNewMessage: true })
    }
  }
  })
    .catch((error) => {
  });

  }
  }}

    
  // validate() {

    // let input = this.state.input;
    // let errors = {};
    // let isValid = true;
 
// if (!input["password"]) {
//     isValid = false;
//     errors["password"] = "Please enter your password.";
// }

// if (typeof input["password"] !== "undefined") {
//     var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

// if (!strongRegex.test(input["password"])) {
//     isValid = false;
//     errors["password"] = "Please add at least 1 lowercase letter,1 uppercase letter, 1 number and special character. Password length must be 8 character or longer.";
//   }
// }
// if (!input["password2"]) {
//   isValid = false;
//   errors["password2"] = "Please enter your confirm password.";
// }
// if (typeof input["password"] !== "undefined" && typeof input["password2"] !== "undefined") {
    
// if (input["password"] != input["password2"]) {
//     isValid = false;
//     errors["password"] = "Passwords don't match.";
//   }
// }

//     this.setState({ errors: errors });
//     return isValid;
// }
passwordValidation(){
  let fields = this.state.fields;
  let errors = {};
  let formIsValid = true;
 


  if(this.state.password!==""){
  
   var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
   if (!strongRegex.test(this.state.password)) {
     
    
    formIsValid = false;
    this.setState({passwordValidationErrorShow:'*Password must contain at least One Number One Upper Case Letter One Lower Case Letter One Special Character.'})
  }
}


if(this.state.password2!==""){
  
 var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
 if (!strongRegex.test(this.state.password2)) {
  
  
  formIsValid = false;
  this.setState({password2ValidationErrorShow:'*Password must contain at least One Number One Upper Case Letter One Lower Case Letter One Special Character.'})
}
}

//   if (typeof input["old_password"] !== "undefined") {
//     var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

//         if (!strongRegex.test(input["old_password"])) {

//         isValid = false;

//         errors["old_password"] = "Password must contain at least One Number One Upper Case Letter One Lower Case Letter One Special Character.";
// }
// }

// if (typeof fields["new_password"] !== "undefined") {
//   if(this.state.new_password!==''){
//     var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

//         if (!strongRegex.test(fields["new_password"])) {

//           formIsValid = false;
//         this.setState({newPasswordValidationErrorShow:'*Password must contain at least One Number One Upper Case Letter One Lower Case Letter One Special Character.'})
//         // errors["new_password"] = "Password must contain at least One Number One Upper Case Letter One Lower Case Letter One Special Character.";
// }
// }

// if (typeof fields["confirm_password"] !== "undefined") {
//   if(this.state.confirm_password!==''){
//   var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

//       if (!strongRegex.test(fields["confirm_password"])) {

//         formIsValid = false;
//         this.setState({ConfirmPasswordValidationErrorShow:'*Password must contain at least One Number One Upper Case Letter One Lower Case Letter One Special Character.'})
//       // errors["confirm_password"] = "Password must contain at least One Number One Upper Case Letter One Lower Case Letter One Special Character.";
// }
// }

   


  if(this.state.password===''){
    formIsValid = false;
 this.setState({passwordValidationErrorShow:'*Please Enter New Password'})
  }


  // if(this.state.old_password!=='') {

  //       // if (typeof fields["password"] !== "undefined") {
  //         if (!fields["old_password"].match(/^.*(?=.{5,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {
  //           formIsValid = false;
  //           this.setState({newPasswordValidationErrorShow:'Password must contain at least One Number One Upper Case Letter One Lower Case Letter One Special Character.'})
   
  //           // this.setState({})
  //         }
  //       }
       
  if(this.state.password2===''){
    formIsValid = false;
    this.setState({password2ValidationErrorShow:'*Please Enter Confirm Password'})
  }
  

  

  this.setState({
    errors: errors
  });
  return formIsValid;

}

	
render() {
  return(
    <main>
    <Header></Header>
      <section  className="login_form mx-auto margin_top">
        <div className="container-fluid border-0 width_custom">
          <div className="row">
            <div className="col-md-8 col-sm-12 bg_banner_login">

            </div>
            <div className="col-md-4 col-sm-12 m-auto">
              <div className="card2 border-0 my-3">
                <form className=" text-center loginpage" onSubmit={this.handleSubmit}>
                <h2>Set New Password</h2>
       
                <div className="form-group eye_iconn_show">
                  <input 
                    type={this.state.type}  
                    id="defaultLoginFormPassword1" 
                    onChange={this.handleChange} 
                    value={this.state.fields.password} 
                    name="password" 
                    className="form-control my-input" 
                    placeholder="New Password"/>
                <div className="text-danger">{this.state.passwordValidationErrorShow}</div>
                <span className="password__show" onClick={this.showHide}>
                {this.state.type === 'input'?
              
                    <i className="fa fa-eye" aria-hidden="true"><FontAwesomeIcon icon={faEye} /></i>
                    :
                    <i className="fa fa-eye" aria-hidden="true"><FontAwesomeIcon icon={faEyeSlash} /></i>
                  }
                </span>
            </div>

                <div className="form-group eye_iconn_show">
                  <input 
                    type={this.state.type2}  
                    id="defaultLoginFormPassword2" 
                    onChange={this.handleChange} 
                    value={this.state.fields.password2} 
                    name="password2" 
                    className="form-control my-input" 
                    placeholder="Confirm Password"/>
                 <div className="text-danger">{this.state.password2ValidationErrorShow}</div>

                  <span className="password__show" onClick={this.showHide2}>
                    {this.state.type2 === 'input'?
              
                      <i className="fa fa-eye" aria-hidden="true"><FontAwesomeIcon icon={faEye} /></i>
                      :
                      <i className="fa fa-eye" aria-hidden="true"><FontAwesomeIcon icon={faEyeSlash} /></i>
                    }
                  </span>
            </div>

            <Loader
              visible = {this.state.visible}
              type="Oval"
              color="#00bacc"
              height={40}
              width={40}
              timeout={3000} //3 secs
            />
             <div className="text-danger">{this.state.responseNewMessage ?'New password and confirm password must be same':null}</div>

            <div className="form-group">
              <button className="btn  btn-block border_radius_20  send-button tx-tfm" type="submit">Submit</button>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<Footer></Footer>
</main>
    );
  }
}