import React from 'react';
import axios from 'axios';
import { API_BASE_URL, Google_StreetNameURL } from '../../config/api.js'
import Header from "../common/header";
import Footer from "../common/footer";
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
var maxdate = ''
class PersonalDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      gender: '',
      postel_code: '',
      dob: '',
      // miniDate:'',
      ethnicityList: [],
      countryList: [],
      stateList: [],
      cityList: [],
      usertype: '',
      input: {},
      errors: {},
      country_id: '',
      value: '',
      tutor_state: '',
      country: '',
      City: '',
      ethnicity: '',
      checked: false,
      input: {},
      errors: {},
      CountryName: "USA",
      stateid: '',
      cityid: 0,
      nationalityid: '',
      MessageUserAlreadyExist: '',
      visible: false,
      validationToAddress: false
    };


    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.dateLimitation = this.dateLimitation.bind(this);
    this.CountryData = this.CountryData.bind(this);
    this.StateData = this.StateData.bind(this);
  }

  handleChange(evt) {
    const value = evt.target.value;
    this.setState({ [evt.target.name]: evt.target.value });
    let input = this.state.input;
    input[evt.target.name] = evt.target.value;
    this.setState({ input });

  }



  PostelCode = (e) => {
    this.setState({ postalCode: e.target.value });

    this.postalcodefromgoogle(e.target.value)
  }
  PostelCodeValue(Pcode) {
    this.setState({ postelcode: Pcode })
  }



  postalcodefromgoogle = (postalCode) => {
    console.log("postalcodefromgoogle", postalCode)
    this.setState({ countryid: '', CountryName: 'Country' })
    this.setState({ statelist: '', StateName: 'State' })
    this.setState({ cityid: '', CityName: 'City' })

    axios({
      method: 'GET',
      url: Google_StreetNameURL + postalCode + "&key=AIzaSyBlVFdLcA2L_aB29DFJEdyJB6YoC2ErgQQ",

    }).then(response => {
      console.log(response.data)
      this.setState({ validationToAddress: true })

      if (response.data.results.length != 0) {

        console.log("city", response.data.results[0].address_components[1].long_name)
        console.log("state", response.data.results[0].address_components[3].long_name)
        console.log("countrydsgdfg", response.data.results[0].address_components[4].long_name)
        this.setState({ addressComponent: response.data.results[0].address_components })




        this.getAdressData()

      }
      if (response.data.results.length == 0) {
        this.setState({ postalcodevalidation: response.data.results.length })

      }

    }).catch(error => {


    })
  }

  getAdressData() {
    this.state.addressComponent.map((data) => {
      console.log("adressdata====>", data)
      if (data.types[0] == "locality") {
        this.setState({ postal_locality: data.long_name })
        console.log("adressdata====>", data.long_name)
      }
      if (data.types[0] == "neighborhood") {
        this.setState({ postal_neighborhood: data.long_name })
        console.log("adressdata====>", data.long_name)
      }
      if (data.types[0] == "administrative_area_level_2") {
        this.setState({ cityidGoogle: data.long_name })
        console.log("adressdata====>", data.long_name)
      }
      if (data.types[0] == "administrative_area_level_1") {
        this.setState({ stateidGoogle: data.long_name })
        console.log("adressdata====>", data.long_name)
      }
      if (data.types[0] == "country") {
        this.setState({ countryidGoogle: data.long_name })
        console.log("countryidGoogle", data.long_name)

      }
    })

    this.CountryByPostalCode()
  }

  CountryByPostalCode() {
    console.log("countryListsgdfehdfg", this.state.countryList)
    this.state.countryList.map((data, index) => {

      if (data.name == this.state.countryidGoogle) {
        console.log("stateias454dj", data.name, data.id)
        return (
          this.setState({ countryid: data.id, CountryName: data.name })
        )
      }
      // this.CountryData()
    });

    console.log("countrydsf", this.state.countryid)
    this.stateAPI(this.state.countryid)
    // this.CountryData()
  }



  StateByPostalCode() {
    console.log("stateList", this.state.stateList)
    this.state.stateList.map((data, index) => {

      if (data.name == this.state.stateidGoogle) {
        console.log("stateList id name", data.name, data.id)
        return (
          this.setState({ statelist: data.id, StateName: data.name })
        )
      }


    });

    console.log("stateList id ", this.state.stateid)
    // this.CityDataList()
    this.cityAPI(this.state.statelist)
    // this.StateData()
  }
  CityByPostalCode(cityList) {
    console.log("dsfdsdsgfdsf", cityList)
    cityList.map((data, index) => {
      console.log("dsfdsdsgfdsf", data)

      if (data.name == this.state.cityidGoogle) {
        console.log("cityListt id name", data.name, data.id)
        return (
          this.setState({ cityid: data.id, CityName: data.name })
        )
      }
    });

    console.log("cityList id ", this.state.stateid)

  }

  handleDateChange = (date) => {


    this.setState({ dob: date })
  }

  dateLimitation() {
    
  }

  hello(c) {

    maxdate = c
    // alert('dddd')
  }

  handleSubmit = event => {
    event.preventDefault();
    event.target.reset();
    if (this.validate()) {
      let input = {};
      // input["fullname"] = "";
      input["gender"] = "";
      input["postel_code"] = "";
      input["dob"] = "";
      input["City"] = "";
      input["tutor_state"] = "";
      input["country"] = "";
      input["ethnicity"] = "";

      console.log('postelcode>>>>>',this.state.postel_code)
      const UserToken = localStorage.getItem('UserToken');
      if (UserToken != '') {


        const sendData = new FormData();
        
        sendData.append("gender", this.state.gender);
        sendData.append("dob", moment(this.state.dob).format("YYYY-MM-DD"));
        sendData.append("country", this.state.countryid);
        if(this.state.statelist==""){
          sendData.append("state", 0);      
        }
        else{
          sendData.append("state", this.state.statelist);  
        }
        
        sendData.append("ethnicity", this.state.ethnicity);
        sendData.append("postel_code",this.state.postelcode);
        if(this.state.cityid==""){
          sendData.append("city", 0);      
        }
        else{
        sendData.append("city", this.state.cityid);
        }
        sendData.append("user_type", this.state.user_type);
        sendData.append("form_status", 'Form-1');

        // const sendData = {
        //   // fullname:this.state.fullname,
        //   gender: this.state.gender,
        //   dob: this.state.dob,
        //   country: this.state.countryid,
        //   state: this.state.statelist,
        //   ethnicity: this.state.ethnicity,
        //   postel_code: this.state.postel_code,
        //   city: this.state.cityid,
        //   user_type: this.state.user_type,
        //   form_status: 'Form-1'
        // };

        console.log("23456", sendData)
        axios({
          method: 'post',
          url: API_BASE_URL + "create_teacher_profile/",
          headers: { 'Authorization': "Bearer " + UserToken },
          data: sendData,


        }).then(res => {

          if (res.status == 200) {

            this.props.history.push("certificate");
          }
        }
        )
      }

    }
  }

  // }}

  CountryData = (evt) => {
    console.log("countrydata", evt.target.value)
    // this.setState({cityidlist:[]})
    const countryID = evt.target.value
    this.setState({ countryid: evt.target.value })
    this.setState({ defaultCountryUSA: evt.target.value })
    // this.setState({countryid:''})
    // this.setState({statelist:''})
    this.stateAPI(evt.target.value)
  }

  stateAPI = (countryID) => {
    console.log("countrydata stateAPI", countryID)
    axios({
      method: 'get',

      url: API_BASE_URL + "data_list_state/?country_id=" + countryID,

    }).then(response => {
      console.log("dddd", response.data)
      this.setState({ stateList: response.data.state })
      this.StateByPostalCode()

    })
  }




  StateData = (evt) => {

    console.log("state_id", evt)

    const stateid = evt
    this.setState({ statelist: evt })

    this.cityAPI(evt)

  }
  cityAPI = (evt) => {
    console.log("stateiddd", evt)
    axios({
      method: 'get',
      url: API_BASE_URL + "data_list_city/?state_id=" + evt,

    }).then(response => {
      console.log("response", response.data.city)

      this.setState({ cityList: response.data.city })
      this.CityByPostalCode(response.data.city)
    })
  }

  CityData = e => {
    this.setState({ cityid: e.target.value })



  }


  componentDidMount() {

    let newDate = new Date()
    const year = newDate.getFullYear();
    const month = newDate.getMonth();
    const day = newDate.getDate();

    const c = new Date(year - 16, month, day) // minus 16 years
  

    this.hello(c)

    axios.get(API_BASE_URL + `data_list_country/`)

      .then(res => {


        this.setState({ countryList: res.data.country });
        this.setState({ ethnicityList: res.data.ethnicity })

        this.CountryByPostalCode()



      })
  }





  EthnicityData = evt => {
    this.setState({ ethnicityid: evt.target.value })
    this.setState({ [evt.target.name]: evt.target.value });

  }





  validate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;




    if (!input["gender"]) {
      isValid = false;
      errors["gender"] = "please select gerder";
      errors["gender"] = "Please select gender";
    }


    this.setState({ errors: errors });
    return isValid;

  }

  render() {
    const countryListData =
      this.state.countryList.map((country) => {

        return (

          <option value={country.id}>{country.name}</option>
        )
        // }
      })


    const cityListData =
      this.state.cityList.map((city) => {

        return (
          <option value={city.id}>{city.name}</option>
        )
        // }
      })


    const ethnicityListData =
      this.state.ethnicityList.map((ethnicity) => {

        return (
          <option value={ethnicity.id}>{ethnicity.name}</option>
        )

      })

    return (

      <main>
        <Header></Header>
        <section className="login_form mx-auto margin_top">
          <div className="container-fluid border-0 width_custom">
            <div className="row">
              <div className="col-md-8 col-sm-12 bg_banner_login"></div>
              <div className="col-md-4 col-sm-12 m-auto">
                <div className="card2 border-0 my-3">
                  <form className=" text-center loginpage" onSubmit={this.handleSubmit} method="post">
                    <h2 > Personal Details </h2>
                    <div className="logo" >
                      <a href="#">
                        {/* <img src="/static/media/logo1.26950ff8.png"className="logo-sticky-none" alt="Logo"></img> */}
                      </a>
                    </div>



                    <div className="form-group input_container">
                      <select
                        name="gender"
                        id="gender"
                        className="form-control my-input"
                        value={this.state.input.gender}
                        onChange={this.handleChange}
                        required>
                        <option value="">Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>

                      </select>
                      <div className="text-danger" > {this.state.errors.gender}</div>
                    </div>

                    <div className="form-group input_container">

                      <DatePicker selected={this.state.dob}
                        onChange={date => this.handleDateChange(date)}
                        dateFormat='MM-dd-y'

                        placeholderText="MM/DD/YY"
                        showMonthDropdown
                        showYearDropdown

                        dropdownMode="select"
                        onCalendarOpen={() => this.dateLimitation()}
                        // maxDate={new Date()}
                        maxDate={maxdate}

                      />

                      <div className="text-danger"> {this.state.errors.dob}</div>
                    </div>
                    <div className="form-group input_container">
                      <input
                        type="text"
                        id="pin-code"

                        maxLength="6"

                        className="form-control my-input"
                        onChange={this.PostelCode}
                        defaultValue={this.state.postelcode}
                        onBlur={() => { this.PostelCodeValue(this.state.postalCode) }}

                        placeholder="Zip/Postal Code"
                        pattern="^(?(^00000(|-0000))|(\d{5}(|-\d{4})))$"
                        required />
                      <div className="text-danger"> {this.state.errors.postel_code}</div>
                    </div>


                    <div className="form-group input_container">
                      <select
                        id="country"

                        name="country"

                        className="form-control my-input"
                        onChange={this.CountryData}
                        // onChange={this.StateDataList}
                        required>
                        <option hidden value={this.state.Countryid}>{this.state.CountryName}</option>
                        {/* <option >USA</option> */}
                        {countryListData}
                      </select>
                      <div className="text-danger"> {this.state.errors.country}</div>
                    </div>
                    {this.state.validationToAddress ?
                      <>

                        <div className="form-group input_container">
                          <select
                            id="state"
                            name="tutor_state"
                            // value={this.state.statelist} 
                            className="form-control my-input"
                            onChange={(e) => this.StateData(e.target.value)}

                            required>
                            <option hidden value={this.state.statelist}>{this.state.StateName}</option>
                            {/* <option value="">State/Province</option> */}
                            {
                              this.state.stateList.map((state, indexs) => {
                                return (
                                  <option value={state.id}>{state.name}</option>
                                )
                              })
                            }

                          </select>

                          <div className="text-danger"> {this.state.errors.tutor_state}</div>
                        </div>

                        <div className="form-group input_container">
                          {this.state.cityid != '' ?

                            <select
                              id="city"
                              name="City"
                              // value={this.state.cityidlist} 
                              className="form-control my-input"
                              // onChange= {this.CityData}
                              onChange={this.CityData}
                            // onChange={this.CityData}
                            // onClick={this.CItyListData1}
                            >
                              <option hidden value={this.state.cityid}>{this.state.CityName}</option>
                              {/* <option value="">City</option> */}
                              {
                                this.state.cityList.map((city, indexs) => {
                                  return (
                                    <option value={city.id}>{city.name}</option>
                                  )
                                })
                              }
                              {/* {cityListData? cityListData:null} */}
                            </select>
                            : null}
                          <div className="text-danger"> {this.state.errors.City}</div>
                        </div>
                      </>
                      : null}


                    <div className="form-group input_container">
                      <select
                        name="ethnicity"
                        value={this.state.input.ethnicity}
                        className="form-control my-input"
                        onChange={this.EthnicityData}
                        required>
                        <option>Ethnicity</option>
                        {ethnicityListData ? ethnicityListData : null}
                        {/* {ethnicityListData} */}
                      </select>


                      <div className="text-danger">{this.state.errors.ethnicity}</div>
                    </div>

                    <div className="form-group">
                      <button className="btn btn-block send-button border_radius_20 tx-tfm" type="submit">Next</button>
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer></Footer>

      </main>
    );
  }
}

export default PersonalDetail;



