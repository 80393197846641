
// import React from 'react';
// export default class Demo extends React.Component {
//     constructor() {
//       super();
//       this.handleData = this.handleData.bind(this);
//       this.state = {
//         fromChild: ''
//       };
//     }
    
//     handleData(data) {
//       this.setState({
//         fromChild: data
//       });
//     }
    
//     render() {
//       return (
//         <div>
//           {/* <InputFoo handlerFromParant={this.handleData} />  */}
//          <button></button>
//         </div>
//       );
//     }
//   }



// //   export default  class InputFoo extends React.Component {
// //     constructor() {
// //       super();
// //       this.handleChange = this.handleChange.bind(this);
// //       this.submitHandler = this.submitHandler.bind(this);
// //       this.state = {
// //         inputField: ''
// //       };
// //     }
    
// //     submitHandler(evt) {
// //       evt.preventDefault();
// //       // pass the input field value to the event handler passed
// //       // as a prop by the parent (App)
// //       this.props.handlerFromParant(this.state.inputField);
      
// //       this.setState({
// //         inputField: ''
// //       });
// //     }
    
// //     handleChange(event) {
// //       this.setState({
// //         inputField: event.target.value
// //       });
// //     }
  
// //     render() {
// //       return (
// //         <div>
// //           <form onSubmit={this.submitHandler}>
// //             <input type="text" 
// //                    id="theInput" 
// //                    value={this.state.inputField} 
// //                    onChange={this.handleChange} />
// //             <input type="submit" />
// //           </form>
// //           <h5>Visible in child:<br />{this.state.inputField}</h5>
// //         </div>
// //       );
// //     }
// //   }
  
  
import React from "react";

import ZenDeskChat from '@goranefbl/react-zendesk-chat';
import ReactDOM from "react-dom";
import Zendesk from "react-zendesk";
const ZENDESK_KEY = "b4c15039-4b50-44c2-94fc-7dcad67f723c";

// Take contact form as an example
// Let's customise our contact form appearance, launcher and add prefill content
const setting = {
  chat: {
    connectOnPageLoad: true
  }
};

export default class  Demo  extends React.Component {
  constructor(props){
    super(props);
    this.state={

    }
  }


  


  
  render(){
  return (
    <>
  <h1>helllo</h1>



<Zendesk 
  defer zendeskKey={ZENDESK_KEY} {...setting} 
  onLoaded={() => {
    alert("hi")
  //  console.log('errrrr')
    
  }}
 
 
  
  />
    
  
   


</>
  )}}



  