import React from 'react';
import Header from '../common/header';
import Footer from '../common/footer';
import defaultMaleImg from '../../assets/img/default_male.png';
import defaultFemaleImg from '../../assets/img/default_female.png';
import axios from 'axios';
import { Link } from "react-router-dom";
import { Tabs, Tab } from 'react-bootstrap-tabs';
import moment from 'moment';
import  'moment-timezone';
import { API_BASE_URL, BASE_URL } from '../../config/api.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
import TutorSideBar from "./tutorSideBar.js";

class TutorBooking extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            newList: [],
            newList1: [],
            newList2: [],
            pendingList: [],
            pendingList1: [],
            pendingList2: [],
            upcomingList: [],
            upcomingList1: [],
            upcomingList2: [],
            completedList: [],
            completedList1: [],
            completedList2: [],
            BookingPendingList: [],
            BookingUpcomingList: [],
            BookingAcceptList: [],
            BookingAcceptList1:[],
            BookingCancelledList: [],
        }

        this.handleId=this.handleId.bind(this);
        this.onChatHandle=this.onChatHandle.bind(this);

    }
    handleId(bookingId){
        const val = localStorage.setItem("country_code", bookingId);
        console.log("bookingvalue",val)
        // this.props.history.push({ 
        //     pathname: '/tutorbookingdetail',
        //     state: { 
        //     bookingId: bookingId}
        //  });
    }
    // handleId(bookingId){
    //     this.props.history.push({ 
    //         pathname: '/tutorbookingdetail',
    //         state: { 
    //         bookingId: bookingId}
    //      });
    // }
    componentDidMount() {
        const UserToken = localStorage.getItem('UserToken');

        if (UserToken != '') {
            const sendData = {
                booking_status: 'pending',
            }
            axios({
                method: 'post',
                url: API_BASE_URL + "booking_list/",
                headers: { 'Authorization': "Bearer " + UserToken },
                data: sendData

            }).then(res => {
                this.setState({ BookingPendingList: res.data.booking_teacher_details })
                // console.log("titanic",res.data.booking_teacher_details[0].id);

            }).catch((error) => {
                console.log(error)
            })
        }
        if (UserToken != '') {
            const sendData2 = {
                booking_status: 'upcoming',  
            }

            axios({
                method: 'post',
                url: API_BASE_URL + "booking_list/",
                headers: { 'Authorization': "Bearer " + UserToken },
                data: sendData2
            }).then(response => {
                this.setState({ BookingUpcomingList: response.data.booking_teacher_details })
                console.log("respomse mahage",response)
            })

        }

        if (UserToken != '') {
            const sendData3 = {
                // booking_status  : 'upcoming',
                booking_status: 'completed',
            }

            axios({
                method: 'post',
                url: API_BASE_URL + "booking_list/",
                headers: { 'Authorization': "Bearer " + UserToken },
                data: sendData3

            }).then(response => {
                this.setState({ BookingAcceptList: response.data.booking_teacher_details })
                console.log("helllooooooooooooooo",response)
            })
        }
        if (UserToken != '') {
            const sendData4 = {

                // booking_status  : 'upcoming',
                booking_status: 'waiting for payment',

            }

            axios({
                method: 'post',
                url: API_BASE_URL + "booking_list/",
                headers: { 'Authorization': "Bearer " + UserToken },
                data: sendData4

            }).then(response => {
                this.setState({ BookingAcceptList1: response.data.booking_teacher_details })
                console.log(response)

            })
        }

        if (UserToken != '') {
            const sendData4 = {

                booking_status: 'cancelled',

            }

            axios({
                method: 'post',
                url: API_BASE_URL + "booking_list/",
                headers: { 'Authorization': "Bearer " + UserToken },
                data: sendData4

            }).then(response => {
                console.log("cancelled record",response.data)
                this.setState({BookingCancelledList:response.data.booking_teacher_details})
            })
        }

    }

    onChatHandle(tutorId){
        this.props.history.push({ 
            pathname: '/conversations',
            state: { id: tutorId }
         });
    }
   
    render() {
        const BookingPendingListConst = this.state.BookingPendingList.map((booking) => {
            return (

                this.state.BookingPendingList.length != 0 ?

                    <div className="row box_custom_booking">
                        <div className="col-md-6 col-6 upper_booking_heads">
                            <h6>{moment(booking.booking_start).format("DD MMM YYYY")+", "}<span class="booking_id_color">{booking.id}</span></h6>
                            <h4>{booking.category_name}</h4>
                            <p className="sub_b">Subject :  <b> {booking.sub_category_name}</b></p>
                        </div>
                        <div className="col-md-6 col-6 text-right">
                            <button className="pending_btn">{booking.booking_status}</button><br/>
                              {/* <div className="arrow_left mt-3" onClick={() => this.handleId(booking.id)} >&#8594;</div> */}
                              <Link onClick={() =>
      this.handleId(booking.id, booking.tutor)} className="no_underline"
      to={{
      pathname: "tutorbookingdetail",
      params: booking.id,
      tutor_params_id: booking.tutor
      }}>
      <div className="arrow_left mt-3">&#8594;</div>
      </Link>
                        
                        </div>
                        {booking.booking_type == 'direct' ?
                        <>
                        <div className="col-md-12"><div className="border_l"></div></div>
                        <div className="col-md-6 col-6">
                            <div className="booking_info">

                                {booking.image == null ?
                                     booking.gender == "female" ?
                                     <img class="booking_user_img" src={defaultFemaleImg} alt="" />
                                     :<img class="booking_user_img" src={defaultMaleImg} alt="" />
                                    : <img class="booking_user_img" src={BASE_URL + booking.image} alt="" />
                                }
                               
                                <div className="info_pera">
                                    <h2>{booking.fullname} </h2>
                                    <div>

                                        {booking.qualification == ''  ||booking.qualification==null?
                                            // <button>Graduate</button>
                                            null

                                            : <button>{booking.qualification}</button>}
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-6 text-right my-auto">
                        {booking.online_status=='1' ? 
                                   <div class="status_div set_status">
                                          <div className="online_status"></div>
                                          ONLINE
                                       </div>
                                       :
                                       <div class="status_div set_status">
                                          <div className="offline_status"></div>
                                          Offline
                                       </div>
                                       }
                        <button onClick={() => this.onChatHandle(booking.user)} className="chat_btn"><i className="fa fa-star" aria-hidden="true">
                            <FontAwesomeIcon icon={faFacebookMessenger}></FontAwesomeIcon></i> Chat
                        </button>
                        </div>
                        </>:null}
                       </div>:null)
        })

        const BookingAcceptListConst = this.state.BookingAcceptList.map((booking) => {
            return (

                this.state.BookingAcceptList.length != 0 ?

                    <div className="row box_custom_booking">
                        <div className="col-md-6 col-6 upper_booking_heads">
                        <h6>{moment(booking.booking_start).format("DD MMM YYYY")+", "}<span class="booking_id_color">{booking.id}</span></h6>
                            <h4>{booking.category_name}</h4>
                            <p className="sub_b">Subject :  <b> {booking.sub_category_name}</b></p>
                        </div>
                        <div className="col-md-6 col-6 text-right">
                            <button className="accepted_btn">{booking.booking_status}</button><br />
                            {/* <div className="arrow_left mt-3" onClick={() => this.handleId(booking.id)} >&#8594;</div> */}
                            <Link onClick={() =>
      this.handleId(booking.id, booking.tutor)} className="no_underline"
      to={{
      pathname: "tutorbookingdetail",
      params: booking.id,
      tutor_params_id: booking.tutor
      }}>
      <div className="arrow_left mt-3">&#8594;</div>
      </Link>
                        
                        </div>
                        <div className="col-md-12"><div className="border_l"></div></div>
                        <div className="col-md-6 col-6">
                            <div className="booking_info">
                                {booking.image == null ?
                                     booking.gender == "female" ?
                                     <img class="booking_user_img" src={defaultFemaleImg} alt="" />
                                     :<img class="booking_user_img" src={defaultMaleImg} alt="" />
                                    : <img class="booking_user_img" src={BASE_URL + booking.image} alt="" />
                                }
                                {/* <img className="profile_b" src={user} alt="" /> */}
                                <div className="info_pera">
                                    <h2>{booking.fullname} </h2>
                                    <div>
                                        {booking.qualification==null || booking.qualification==''?
                                        null
                                        :
                                        <button>{booking.qualification}</button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-6 text-right my-auto">
                        {booking.online_status=='1' ? 
                                         <div class="status_div set_status">
                                          <div className="online_status"></div>
                                          ONLINE
                                       </div>
                                       :
                                       <div class="status_div set_status">
                                          <div className="offline_status"></div>
                                          Offline
                                       </div>
                                       }
                          <button onClick={() => this.onChatHandle(booking.user)} className="chat_btn"><i className="fa fa-star" aria-hidden="true">
                                <FontAwesomeIcon icon={faFacebookMessenger}></FontAwesomeIcon></i> Chat
                            </button>
                        </div>
                    </div>
                    :
                    <div></div>
            )
        })




        
        const BookingAcceptList1Const = this.state.BookingAcceptList1.map((booking) => {
            return (

                this.state.BookingAcceptList1.length != 0 ?

                    <div className="row box_custom_booking">
                        <div className="col-md-6 col-6 upper_booking_heads">
                        <h6>{moment(booking.booking_start).format("DD MMM YYYY")+", "}<span class="booking_id_color">{booking.id}</span></h6>
                          <h4>{booking.category_name}</h4>
                            <p className="sub_b">Subject :  <b> {booking.sub_category_name}</b></p>
                        </div>
                        <div className="col-md-6 col-6 text-right">
                            <button className="accepted_btn">Payment pending</button><br />
                            {/* <div className="arrow_left mt-3" onClick={() => this.handleId(booking.id)} >&#8594;</div> */}
                        
                            <Link onClick={() =>
      this.handleId(booking.id, booking.tutor)} className="no_underline"
      to={{
      pathname: "tutorbookingdetail",
      params: booking.id,
      tutor_params_id: booking.tutor
      }}>
      <div className="arrow_left mt-3">&#8594;</div>
      </Link>
                        </div>
                        <div className="col-md-12"><div className="border_l"></div></div>
                        <div className="col-md-6 col-6">
                            <div className="booking_info">
                                {booking.image == null ?
                                     booking.gender == "female" ?
                                     <img class="booking_user_img" src={defaultFemaleImg} alt="" />
                                     :<img class="booking_user_img" src={defaultMaleImg} alt="" />
                                    : <img class="booking_user_img" src={BASE_URL + booking.image} alt="" />
                                }
                                {/* <img className="profile_b" src={user} alt="" /> */}
                                <div className="info_pera">
                                    <h2>{booking.fullname} </h2>
                                    <div>
                                        {booking.qualification==null || booking.qualification==''?
                                        null:
                                        <button>{booking.qualification}</button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-6 text-right my-auto">
                        <button onClick={() => this.onChatHandle(booking.user)} className="chat_btn"><i className="fa fa-star" aria-hidden="true">
                            <FontAwesomeIcon icon={faFacebookMessenger}></FontAwesomeIcon></i> Chat
                        </button>
                        </div>
                    </div>
                    :
                    <div></div>
            )
        })

        const BookingUpcomingListConst = this.state.BookingUpcomingList.map((booking) => {
            return (
                this.state.BookingUpcomingList.length != 0 ?


                    <div className="row box_custom_booking">
                        <div className="col-md-6 col-6 upper_booking_heads">
                        <h6>{moment(booking.booking_start).format("DD MMM YYYY")+", "}<span class="booking_id_color">{booking.id}</span></h6>
                            <h4>{booking.category_name}</h4>
                            <p className="sub_b">Subject :  <b>{booking.sub_category_name}</b></p>
                        </div>
                        <div className="col-md-6 col-6 text-right">
                            <button className="accepted_btn upcoming_btn">{booking.booking_status}</button><br />
                            {/* <div className="arrow_left mt-3" onClick={() => this.handleId(booking.id)} >&#8594;</div> */}
                            <Link onClick={() =>
      this.handleId(booking.id, booking.tutor)} className="no_underline"
      to={{
      pathname: "tutorbookingdetail",
      params: booking.id,
      tutor_params_id: booking.tutor
      }}>
      <div className="arrow_left mt-3">&#8594;</div>
      </Link>
                        </div>
                        <div className="col-md-12"><div className="border_l"></div></div>
                        <div className="col-md-6 col-6">
                            <div className="booking_info">
                                {booking.image == null ?
                                    booking.gender == "female" ?
                                    <img class="booking_user_img" src={defaultFemaleImg} alt="" />
                                    :<img class="booking_user_img" src={defaultMaleImg} alt="" />
                                    :<img class="booking_user_img" src={BASE_URL + booking.image} alt="" />
                                }
                                {/* <img className="profile_b" src={user} alt="" /> */}
                                <div className="info_pera">
                                    <h2>{booking.fullname} </h2>
                                    <div>
                                        {booking.qualification==null || booking.qualification==''?
                                        null:
                                        <button>{booking.qualification}</button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-6 text-right my-auto">
                        <button onClick={() => this.onChatHandle(booking.user)} className="chat_btn"><i className="fa fa-star" aria-hidden="true">
                            <FontAwesomeIcon icon={faFacebookMessenger}></FontAwesomeIcon></i> Chat
                        </button>
                            {/* <button className="chat_btn"><i className="fa fa-star" aria-hidden="true"><FontAwesomeIcon icon={faFacebookMessenger}></FontAwesomeIcon></i> Chat</button> */}
                        </div>
                        {/* <div className="col-md-12"><div className="border_l"></div></div> */}
                    </div>

                    :
                    <div>
                    </div>
            )
        })


        const BookingCancelledListConst = this.state.BookingCancelledList.map((booking) => {
            return (

                this.state.BookingCancelledList.length != 0 ?

                    <div className="row box_custom_booking">
                        <div className="col-md-6 col-6 upper_booking_heads">
                        <h6>{moment(booking.booking_start).format("DD MMM YYYY")+", "}<span class="booking_id_color">{booking.id}</span></h6>
                          <h4>{booking.category_name}</h4>
                            <p className="sub_b">Subject :  <b> {booking.sub_category_name}</b></p>
                        </div>
                        <div className="col-md-6 col-6 text-right">
                            <button className="accepted_btn cancel_btn">{booking.booking_status}</button><br />
                            {/* <div className="arrow_left mt-3" onClick={() => this.handleId(booking.id)} >&#8594;</div> */}
                            <Link onClick={() =>
      this.handleId(booking.id, booking.tutor)} className="no_underline"
      to={{
      pathname: "tutorbookingdetail",
      params: booking.id,
      tutor_params_id: booking.tutor
      }}>
      <div className="arrow_left mt-3">&#8594;</div>
      </Link>
                        
                        </div>
                        <div className="col-md-12"><div className="border_l"></div></div>
                        <div className="col-md-6 col-6">
                            <div className="booking_info">
                                {booking.image == null ?
                                     booking.gender == "female" ?
                                     <img class="booking_user_img" src={defaultFemaleImg} alt="" />
                                     :<img class="booking_user_img" src={defaultMaleImg} alt="" />
                                    : <img class="booking_user_img" src={BASE_URL + booking.image} alt="" />
                                }
                                {/* <img className="profile_b" src={user} alt="" /> */}
                                <div className="info_pera">
                                    <h2>{booking.fullname} </h2>
                                    <div>
                                        {booking.qualification==null || booking.qualification==''?
                                        null:
                                        <button>{booking.qualification}</button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-6 text-right my-auto">
                        <button onClick={() => this.onChatHandle(booking.user)} className="chat_btn"><i className="fa fa-star" aria-hidden="true">
                            <FontAwesomeIcon icon={faFacebookMessenger}></FontAwesomeIcon></i> Chat
                        </button>
                        </div>
                    </div>
                    :
                    <div></div>
            )
        })


        return (
        <main>
        <Header></Header>
        <section className="">
            <div className="container-fluid margin_top width_custom">
                <div className="row">
                    <div className="col-md-3 mt30">
                        <TutorSideBar></TutorSideBar>
                    </div>

        <div className="col-md-9 mt30">
        <div className="row">
        <div className="col-md-12">
        <h2 className="dashboard_headings">Bookings</h2>
        </div>
        </div>
        <div className="row tab_header">
        <div className="col-md-12">
        <ul className="nav nav-pills custom_nav2 user_anv_mob border-0" role="tablist">
        <li className="nav-item width_li">

            <Tabs className="border-0 noborder_div">

                <Tab className="border-0" label="New">

                    <div className="col-md-12">
                        <div className="row booking_sec">
                            <div className="col-md-12" id="pending_section">
                                {BookingPendingListConst}
                            </div>
                        </div>
                    </div>

                </Tab>

                <Tab label="payment pending">

<div className="col-md-12">
    <div className="row  booking_sec">
        <div className="col-md-12" id="upcoming_section">
            {/* {BookingUpcomingListConst} */}

            {this.state.BookingAcceptList1 && this.state.BookingAcceptList1.length > 0 ?

                BookingAcceptList1Const
                :
                <div class="no_booking_msg"> You have not bookings yet.</div>
            }


        </div>
    </div>
</div>

</Tab>



                <Tab label="Upcoming">

                    <div className="col-md-12">
                        <div className="row  booking_sec">
                            <div className="col-md-12" id="upcoming_section">
                                {/* {BookingUpcomingListConst} */}

                                {this.state.BookingUpcomingList && this.state.BookingUpcomingList.length > 0 ?

                                    BookingUpcomingListConst
                                    :
                                    <div class="no_booking_msg"> You have not bookings yet.</div>
                                }


                            </div>
                        </div>
                    </div>

                </Tab>

                <Tab label="Completed">

                    <div className="col-md-12">
                        <div className="row  booking_sec">
                            <div className="col-md-12" id="completed_section">
                                {this.state.BookingAcceptList && this.state.BookingAcceptList.length > 0 ?

                                    BookingAcceptListConst
                                    :
                                    <div class="no_booking_msg"> You have not bookings yet.</div>

                                }
                            </div>
                        </div>
                    </div>

                </Tab>


                <Tab label="Rejected">

                    <div className="col-md-12">
                        <div className="row  booking_sec">
                            <div className="col-md-12" id="completed_section">
                            
                            {this.state.BookingCancelledList && this.state.BookingCancelledList.length > 0 ?

                                BookingCancelledListConst
                                :
                                <div class="no_booking_msg"> You have not bookings yet.</div>

                                }
                            </div>
                        </div>
                    </div>

                </Tab>

            </Tabs>

        </li>
        </ul>

        </div>
        </div>
        </div>
        </div>
        </div>
        </section>
        <Footer></Footer>
        </main>
        );
    }
}

export default TutorBooking
