
import React, { Component } from 'react';
// import { render } from 'react-dom';
import Slider from "react-slick"; 
import axios from 'axios';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { API_BASE_URL } from '../../config/api';
import { BASE_URL } from '../../config/api';
import { Link } from 'react-router-dom';

class Instructors extends React.Component {
  
  state = {
    PopularCategorySlider: [],
    PopularSubCategorySlider:[]
  }
  componentDidMount() {
    axios.get(API_BASE_URL + `category_list/`)
      .then(res => {
        this.setState({ PopularCategorySlider: res.data.result });
        this.setState({ PopularSubCategorySlider: res.data.result1 });
console.log("respppp",res.data.result);
      })
  }

  render() {
    var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
         
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
    };

    const PopularCategorySliderData =
      this.state.PopularCategorySlider.map((data) => {
        return (
                <div className="instructor_slider">
                  {/* <Link to={{
                    className:"no_decoration",
                    pathname: "/tutorsearch/"+data.category_name.replaceAll(" ","_"),
                    params33: data.id ,
                    params1:data.category_name,
                    params2:data.category_description
                  }}> */}
                  <Link to={"/tutorsearch/"+data.category_name.replaceAll(" ","_")+'/'+data.id}>
                    <img src={BASE_URL + data.category_image} alt=""/>  
                    <div className="instructor_content">
                      <div className="combo">
                        <h6>{data.category_name}</h6>
                        {this.state.PopularSubCategorySlider.map((subdata) => {
                        if (data.id === subdata.categories) {
                            const subcategoryName = [subdata.sub_category_name];
                            console.log(subcategoryName);
                          return (
                            <p>{subdata.sub_category_name}</p>
                          )
                        }})}  
                      </div> 
                    </div>    
                  </Link>    
                </div>)})

return (
        
<section className="instructor_section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="dark_head">
                <h2><span>Popular</span><span> Category</span></h2>
              </div>
              <p className="instructor_peraa text-center">Click on the image below & see our complete List of Levels & Subjects. Many Primary, Secondary, JC, IP, IB, International School, ITE, Poly & University students have learned & succeeded with WANT A TEACHER. You can too!</p>
            </div>
          </div>
        </div>

       
        <div className="container-fluid width_custom">
        
          <div className="row">
         
            <div className="col-md-12 my-5">
            <Slider {...settings}>
                {PopularCategorySliderData}
               {/* <div className="instructor_slider">
                    <img src={img1} alt=""/>
                    
                    <div className="instructor_content">
                        <h6>Middle School</h6>
                        <span>Maths , Science , Social Studies & Economics</span>
                    </div>
               </div>
               <div className="instructor_slider">
                    <img src={img1} alt=""/>
                    
                    <div className="instructor_content">
                        <h6>High School</h6>
                        <span>Social Studies & Economics ,Computers , Home Work Help</span>
                    </div>
               </div>
               <div className="instructor_slider">
                    <img src={img1} alt=""/>
                    
                    <div className="instructor_content">
                        <h6>Amanda Jackson</h6>
                        <span>CEO, NRD Group</span>
                    </div>
               </div>
               <div className="instructor_slider">
                    <img src={img1} alt=""/>
                    
                    <div className="instructor_content">
                        <h6>Amanda Jackson</h6>
                        <span>CEO, NRD Group</span>
                    </div>
               </div>
               <div className="instructor_slider">
                    <img src={img1} alt=""/>
                    
                    <div className="instructor_content">
                        <h6>Amanda Jackson</h6>
                        <span>CEO, NRD Group</span>
                    </div>
               </div>
               <div className="instructor_slider">
                    <img src={img1} alt=""/>
                    
                    <div className="instructor_content">
                        <h6>Amanda Jackson</h6>
                        <span>CEO, NRD Group</span>
                    </div>
               </div>
               <div className="instructor_slider">
                    <img src={img1} alt=""/>
                    
                    <div className="instructor_content">
                        <h6>Amanda Jackson</h6>
                        <span>CEO, NRD Group</span>
                    </div>
               </div>
               <div className="instructor_slider">
                    <img src={img1} alt=""/>
                    
                    <div className="instructor_content">
                        <h6>Amanda Jackson</h6>
                        <span>CEO, NRD Group</span>
                    </div>
               </div> */}            
               </Slider>
            </div>
          </div>
        </div>      
      </section>  
    );
  }
}

export default Instructors
