import React from 'react';
import {API_BASE_URL} from"../../config/api.js";
import axios from "axios";
import Header from "../common/header";
import Footer from "../common/footer";
import {Link} from"react-router-dom";
import input_img1 from "../../assets/img/categories/Icon/student.png";
import input_img2 from "../../assets/img/categories/Icon/teacher.png";
import logo_verify from "../../assets/img/categories/Icon/verify.png";
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons';


class verifyAccount extends React.Component {
  constructor (props) {
    super (props)
    this.state = {
       
      usertype: '',
      RadioError : ''

     }
     this.handleChange = this.handleChange.bind(this);
     this.SocialLogin = this.SocialLogin.bind(this);
     this.SocialRegistration = this.SocialRegistration.bind(this);
     this.handleformStatus = this.handleformStatus.bind(this);
    }

    handleChange=(evt)=> {
     // console.log("usertype"+evt)
                     
      this.setState({ usertype: evt });
      const usertype=localStorage.setItem('usertype',evt);
 
     }

   handleSubmit = event => {
     event.preventDefault();
     
     const  UserToken = localStorage.getItem('UserToken');
     const  login_type = localStorage.getItem('login_type');
     const  social_id = localStorage.getItem('social_id');

      if(UserToken!=''){
       // alert(login_type)

        if(login_type != null){

          this.SocialRegistration();

        }
        else{
            if (this.state.usertype=="0"){
              this.props.history.push("sign_up");
            }
            else if(this.state.usertype=="1") {
              this.props.history.push("t_sign_up")
            }
        }
       
     }

   }

   // social registration 

SocialRegistration(){

  const  social_id = localStorage.getItem('social_id');
  const  login_type = localStorage.getItem('login_type');
  const  fullname = localStorage.getItem('fullname');

 // alert("social registration");

  const registerData = {
    social_id: social_id,
    login_type: login_type,
    fullname: fullname,
    user_type: this.state.usertype
  }
  
    axios({
      method: 'post',
      url:API_BASE_URL + `social_registration/`,
      data: registerData,
    })
     .then(response => {
        console.log("registration done",response.data);
        if (response.data.success == 200) {
         
          this.SocialLogin();
         
        }else{
          //alert(response.data.message)
        }
      })
      .catch((error) => {
       // console.log(error)
      });

}

// social login

   SocialLogin(){

     const  social_id = localStorage.getItem('social_id');
     const  login_type = localStorage.getItem('login_type');

   // alert("social login");
  
    const loginData = {
      social_id: social_id,
      login_type: login_type,
      //user_type: this.state.usertype
    }
    
      axios({
        method: 'post',
        url:API_BASE_URL + `social_login/`,
        data: loginData
      })
       .then(response => {
            console.log("login done",response.data);

            const UserToken = localStorage.setItem('UserToken',response.data.access);
          
            if (response.data.user_type == "0"){
                this.props.history.push("dashboard");
            }
            else if(response.data.user_type == "1") {

              this.handleformStatus()
             // this.props.history.push("tutor_home")
            }
         
        })
        .catch((error) => {
         // console.log(error)
        });
  
  }

  handleformStatus(){
    //alert("form-status verify account");
    const  UserToken = localStorage.getItem('UserToken');
    //console.log(UserToken)
    axios({
      method: 'get',
      url:API_BASE_URL + 'check_form_status/',
      headers:{'Authorization' : "Bearer "+UserToken}, 
    }).then(response => {
      if(response.data.form_status==='Form-1'){
        this.props.history.push("certificate")
       }
       else if(response.data.form_status==='Form-2'){
        this.props.history.push("edu_list")
       }
       else if(response.data.form_status==='Form-3'){
        this.props.history.push("about_you")
       }
       else if(response.data.form_status==='Form-4'){
        this.props.history.push("availability")
       }
       else if(response.data.form_status==='Form-5'){
        this.props.history.push("tutor_home") 
       }
       else if(response.data.form_status==null || response.data.form_status=="" || response.data.form_status=="undefined" || response.data.form_status==undefined){
         this.props.history.push("personal_Detail")
       }
    }).catch((error) => {
        console.log("login response",error)
      });
  }
    

  render() {
      return(
       
        <main>
            <Header></Header>
            <section className="login_form mx-auto margin_top verification_page">
                <div className="container-fluid border-0 width_custom">
                    <div className="row">
                        <div className="col-md-8 col-sm-12 bg_banner_login login_main_banner">

                        </div>
                        <div className="col-md-4 col-sm-12 m-auto">
                            <div className="card2 border-0 my-3 login_card">
                            <form className=" text-center loginpage" onSubmit={this.handleSubmit}>
                                <h2 >Are You</h2>
                            <div className="logo">
                              <Link to="#">
                                    <img src={logo_verify}  alt="Logo"></img>
                                  </Link>
                            </div> 
                            <div className="form-group">
                           <div className="verify_input_box">
                                <img src={input_img1} alt="" />
                                <label for="usertype1"> A Student</label>
                                  <input 
                                    type="radio" 
                                    id="usertype1" 
                                    name="user_type"   
                                    onChange={()=>this.handleChange(0)}
                                    required>
                                  </input>
                           </div>
                        </div>
                        <div className="form-group">
                            <div className="verify_input_box">
                                <img src={input_img2} alt="" />
                                <label for="usertype2">A Teacher</label>
                                <input 
                                  type="radio" 
                                  id="usertype2" 
                                  name="user_type"   
                                  onChange={()=>this.handleChange(1)}
                                  required></input>
                            </div>
                       </div>
                    

                    <div className="form-group">
                      <button className="btn btn-block send-button border_radius_20 border_radius_20 tx-tfm" type="submit">Next</button>
                    </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<Footer></Footer>
</main>
    );
  }
}
             
export default verifyAccount;
   