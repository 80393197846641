import React from 'react';
import axios from "axios";
import { API_BASE_URL } from '../../config/api.js'
import Header from "../common/header";
import Footer from "../common/footer";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import moment from 'moment';
import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
import { faAngleDown, faEdit, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import certificate_img from "../../assets/img/categories/Icon/certificate.png";
import Loader from "react-loader-spinner";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from 'sweetalert'; 
var EDitData=''
class Certificate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nextDateDisabled: "",
      dateFormat: '',
      deleteEdu: [],
      condition: [],
      visible: false,
      education_levels_id: '',
      specialisation_major_area: '',
      specialisation_minor_area: '',
      training_accreditation_body: "",
      certificate_accreditation_body: '',
      certification_area: '',
      study_field_id: '',
      university_name: '',
      certification_type: '',
      date_attended: '',
      certificate_provided: '',
      training_type: '',
      upload_certificate: '',
      training_certificate: '',
      usertype: '',
      levelList: [],
      fieldList: [],
      buttondisable: 0,
      input: {},
      errors: {},
      value: '',
      checked: false,
      feilddata: '',
      leveldata: '',
      usernameError: '',
      emailError: '',
      passwordError: '',
      addressError: '',
      errors_data: ' ',
      MessageUsername: '',
      MessagePassword: '',
      MessageNotVerified: '',
      addMoreData: [],
      imageSizeResponse: '',
      show: '',
      deleteid: '',
      editHistory: false,
      educationHistoryId: '',
      myuid:''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.dateLimitation = this.dateLimitation.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleValue = this.handleValue.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleEducationDetails=this.handleEducationDetails.bind(this);
  }

  sweetAlertBookingAccepted = () => {
    swal({
      text: "Education detail successfully deleted",
      icon: "success",
    })
  }

  sweetAlertAddedSuccess = () => {
    swal({
      text: "Record Added successfully",
      icon: "success",
    })
  }
  
  sweetAlertEditRecordSuccess = () => {
    swal({
      text: "Record Edit successfully",
      icon: "success",
    })
  }

  handleDateChange(date) {

    this.setState({ date_attended: date })
    console.log("newdate", moment(this.state.dateFormat).format())

  }
  dateLimitation() {
    let newDate = new Date()
    const year = newDate.getFullYear();
    const month = newDate.getMonth();
    const day = newDate.getDate();
    const c = new Date(year, month, day)
    this.setState({ nextDateDisabled: c })
  }



componentDidUpdate=()=>{
  // if(localStorage.getItem('education_detail')==this.state.eduid){
  //   this.handleChange66()
  // }
  // else{
  //   null
  // }
}


  handleChange66(id) {
    console.log('12345.....',this.state.educationHistoryId)
    const UserToken = localStorage.getItem('UserToken');
    if (UserToken != '') {
      axios({
        method: 'GET',
        url: API_BASE_URL + "data_list3/",
        headers: { 'Authorization': 'Bearer ' + UserToken },
      })
      .then(res => {
        this.setState({ addMoreData: res.data.education });
        this.setState({ deleteEdu: res.data.education });
        {res.data.education.map((edudata)=>{
          
          //  if(this.state.eduid==edudata.id){
   return(
       
        this.setState({specialisation_major_area:edudata.specialisation_major_area,
          university_name:edudata.university_name,specialisation_minor_area:edudata.specialisation_minor_area,
          date_attended:moment(edudata.date_attended).toDate(),
          study_field_id:edudata.education_levels,
          education_levels_id:edudata.education_levels,
          education_levels_id1:edudata.level_name,
          study_field_id1:edudata.field_name,
          training_accreditation_body:edudata.certificate_accreditation_body})
        // this.setState({university_name:res.data.education[0].university_name})
        // this.setState({specialisation_minor_area:res.data.education[0].specialisation_minor_area})
       
        // this.setState({date_attended:moment(res.data.education[0].date_attended).toDate()})
        // this.setState({study_field_id:res.data.education[0].study_fields})
        // this.setState({education_levels_id:res.data.education[0].education_levels})
        // this.setState({education_levels_id1:res.data.education[0].level_name})
        // this.setState({study_field_id1:res.data.education[0].field_name})
        // this.setState({training_accreditation_body:res.data.education[0].certificate_accreditation_body})
      
        )
          //  }
      
    })}
        console.log("data_list3/",(res.data.education[0].date_attended))
      }).catch((err) => {
        console.log(err)
      })
    }

    
  }

  handleFileChange(evt) {

    this.setState({
      [evt.target.name]: evt.target.files[0]
    })

  }
  handleChange1(evt) {

    this.setState({ [evt.target.name]: evt.target.value });
  }

  handleChange(evt) {

    this.setState({ feilddata: evt.target.value })
    this.setState({ leveldata: evt.target.value })
    const value = evt.target.value;
    this.setState({ [evt.target.name]: evt.target.value, });
    let input = this.state.input;
    input[evt.target.name] = evt.target.value;
    this.setState({ input });
  }
  handleSubmit = (event) => {
    event.preventDefault();
    event.target.reset();

    const UserToken = localStorage.getItem('UserToken');
    if (UserToken != '') {
      //this.setState({ visible: true })
      if(this.state.editHistory == false){

      const sendData = new FormData();
      sendData.append("specialisation_major_area", this.state.specialisation_major_area);
      sendData.append("specialisation_minor_area", this.state.specialisation_minor_area);
      sendData.append("university_name", this.state.university_name);
      sendData.append("date_attended", moment(this.state.date_attended).format("YYYY-MM-DD"));
      sendData.append("education_levels_id", this.state.education_levels_id);
      sendData.append("study_field_id", this.state.study_field_id);
      sendData.append("certification_area", this.state.certification_area);
      sendData.append("certification_type", 1);
      sendData.append("certificate_accreditation_body", this.state.certificate_accreditation_body);
      sendData.append("training_type", this.state.training_type);
      sendData.append("training_accreditation_body", this.state.training_accreditation_body);
      sendData.append("certificate_provided", this.state.certificate_provided);
      sendData.append("training_accreditation_body", this.state.training_accreditation_body);
      sendData.append("upload_certificate", this.state.upload_certificate);
      sendData.append("training_certificate", this.state.training_certificate);
      sendData.append("form_status", 'Form-2');

        axios({
          method: 'post',
          url: API_BASE_URL + "educationhistory/",
          headers: { 'Authorization': "Bearer " + UserToken },
          data: sendData
        }).then(response => {
          // this.setState({ buttondisable: 1 })

         // this.setState({ visible: false })

          console.log("edu>>>", response)
          if (response.data.success === 200) {

            if (response.data.message == "Tutor education history detail successfully added") {

              this.sweetAlertAddedSuccess()
            }
            const local = localStorage.setItem("education_detail", response.data.education_detail)
            this.handleChange66(response.data.education_detail);

          }


          else if (response.data.success == 201) {
            if (response.data.message == "File too large. Size should not be more than 2 mb") {
              this.setState({ imageSizeResponse: true })
            }
          }

          if (response.data.success === 205) {
            if (response.data.type === "user_exist_error") {

              this.setState({ responseMessageUsername: true })
              console.log(response.data.type)
            }
          }
        })

      }else{
        const sendData = new FormData();
        
        sendData.append("education_history_id", this.state.educationHistoryId);
        sendData.append("specialisation_major_area", this.state.specialisation_major_area);
        sendData.append("specialisation_minor_area", this.state.specialisation_minor_area);
        sendData.append("university_name", this.state.university_name);
        sendData.append("date_attended", moment(this.state.date_attended).format("YYYY-MM-DD"));
        sendData.append("education_levels_id", this.state.education_levels_id);
        sendData.append("study_field_id", this.state.study_field_id);
        sendData.append("certification_area", this.state.certification_area);
        sendData.append("certification_type", 1);
        sendData.append("certificate_accreditation_body", this.state.certificate_accreditation_body);
        sendData.append("training_type", this.state.training_type);
        sendData.append("training_accreditation_body", this.state.training_accreditation_body);
        sendData.append("certificate_provided", this.state.certificate_provided);
        sendData.append("training_accreditation_body", this.state.training_accreditation_body);
        sendData.append("upload_certificate", this.state.upload_certificate);
        sendData.append("training_certificate", this.state.training_certificate);
        sendData.append("form_status", 'Form-2');

        axios({
          method: 'post',
          url: API_BASE_URL + "edit_education_history/",
          headers: { 'Authorization': "Bearer " + UserToken },
          data: sendData
        }).then(response => {
          this.setState({ buttondisable: 1 })
            console.log("edit_historryyy", response.data)
            if(response.data.message == 'Tutor education history detail successfully updated'){
              this.sweetAlertEditRecordSuccess()
              this.handleChange66();
              this.state.editHistory= false
            }
        })

      }
      
    }
  }
  componentDidMount() {

    this.handleChange66()

    axios.get(API_BASE_URL + `data_list1/`)

      .then(res => {

        console.log("mydata>>>>", res.data.education_level);
        this.setState({ levelList: res.data.education_level })
        this.setState({ fieldList: res.data.study_field })

      }).catch((err) => {
        console.log(err)
      })

  }

  sweetAlertBookingAccepted = () => {
    swal({
      text: "Tutor education detail successfully deleted",
      icon: "success",
    })
  }


  sweetAlertUpdatedata = () => {
    swal({
      text: "data updated successfully",
      icon: "success",
    })
  }


  handleModal(id, e) {
    this.setState({ deleteid: id })
    this.setState({ show: !this.state.show })
  }
  handleClick1 = () => {
    this.setState({ show: !this.state.show })
  }

  handleClickDelete = (id, event) => {

    // alert("-----click KArne Par Data-----", id)

    const UserToken = localStorage.getItem('UserToken');
    if (UserToken != '') {

      axios({
        method: 'POST',
        url: API_BASE_URL + "delete_education_history/",
        headers: { 'Authorization': 'Bearer ' + UserToken },
        data: {
          eduid: this.state.deleteid
        }
      })
        .then(res => {
          if (res.data.success == 200) {
            if (res.data.message == "tutor education detail successfully deleted") {

              this.sweetAlertBookingAccepted();
            }
            this.handleClick1();
          }
         this.handleChange66()
        }).catch((err) => {
          console.log(err)
        })
    }

  }


  handleValue() {
    this.props.history.push('edu_list');
  }

  handleEducationDetails=async(edu_id)=>{
    alert('1122222......'+edu_id)
    EDitData=edu_id

    // console.log('eDITDAA',EDitData)

    this.setState({myuid:edu_id})

      console.log('eDITDAA',this.state.myuid)
   
    this.setState({editHistory:true})
   await this.setState({educationHistoryId:edu_id})
    console.log("eeeeeee..>>>>",this.state.educationHistoryId)
  
 
  

  }
  
  render() {
    const levelListData =
      this.state.levelList.map((levels) => {
        return (
          <option value={levels.id} >{levels.level_name}</option>
        )
      })

    const fieldListData =
      this.state.fieldList.map((field) => {
        return (
          <option value={field.id} >{field.field_name}</option>
        )
      })
    const allid = this.state.addMoreData.map((updateData) => {
      return updateData

    })

    const addmoredata =
      this.state.addMoreData.map((updateData) => {

        console.log("huhfuhfudfh",updateData)

        return (
          <div class="col-md-12">
            <>
              <h2 class="m-0 p-0 postgrad_head">
                <span>{updateData.level_name}</span>
                <span>
                  <i onClick={(e) => this.handleModal(updateData.id, e)} class="trash_icon">
                  <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon></i>
                  <i onClick={() => this.handleEducationDetails(updateData.id)} class="edithistory_icon">
                  <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon></i>
                </span>
              </h2>
              <div class="address_info"><span>Field of Study : </span> <span>{updateData.field_name}</span></div>
              <div class="address_info"><span>Area Of Specialization Minor : </span> <span>{updateData.specialisation_minor_area}</span></div>
              <div class="address_info"><span>Area Of Specialization Major : </span> <span>{updateData.specialisation_major_area}</span></div>
              <div class="address_info"><span>University/College : </span> <span>{updateData.university_name}</span></div>
              <div class="address_info"><span>Date Attended : </span> <span> {moment(updateData.dob).tz('America/Chicago').format("MM/DD/YY hh:mm:ss A ")}CST</span></div>
              <div class="border_l"></div>
            </>
            <>
              <Modal show={this.state.show} onHide={() => this.handleModal()}>
                <Modal.Body className="all_popupModal">
                  <div class="row">
                    <div class="col-md-12">
                      <h2 class="offer_warning my-5">Are you sure you want to delete Education detail ?</h2>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer className="text-center all_popups_buttonss custom_modal_footer mb-4">
                  <Button className="cancel_bb" onClick={() => this.handleClick1()}> No</Button>
                  <Button className="submit_bb" onClick={() => this.handleClickDelete()}> Yes</Button>
                </Modal.Footer>
              </Modal>
            </>
          </div>
        )
      })

    return (
      <main>

        <Header></Header>

        <section className="login_form mx-auto margin_top otp_page">
          <div className="container-fluid border-0 width_custom">
            <div className="row">
              <div className="col-md-7 col-sm-12 loginpage cont_btn">

                <div className="card2 border-0 my-3">
                  <div class="row">
                    {
                      !this.state.deleteEdu.length
                        ?
                        <div class="col-md-12 text-center my-3">

                          <h6> No result found </h6>

                        </div>

                        :

                        <> {addmoredata} </>

                    }


                  </div>
                  <div class="row">
                    <div class="col-md-4 m-auto text-center">
                      <div class="">

                        {/* {this.state.buttondisable == 0 ?
                          <button class="btn btn-block send-button border_radius_20 tx-tfm" disabled="true" onClick={this.handleValue}>Next</button>
                          :  */}
                          <button class="btn btn-block send-button border_radius_20 tx-tfm" onClick={this.handleValue}>Next</button>
                          {/* } */}
                      </div>

                    </div>
                  </div>
                </div>

              </div>
              <div className="col-md-5 col-sm-12">
                <div className="card2 border-0 my-3 card_fixed login_card">

                  <form className=" text-left loginpage" onSubmit={this.handleSubmit} method="post">

                    <h2 class="text-center">My Education</h2>
                    <div className="logo" >
                      <Link to="#">
                        <img src={certificate_img} alt="Logo"></img>
                      </Link>
                    </div>

                    <div id="accordion">
                      <div class="certificate_cards" id="headingtwo">
                        <div className="cetifi_heads" data-toggle="collapse" data-target="#collapsetwo" aria-expanded="true" aria-controls="collapsetwo">
                          <h4> Education Detail  <i className="down_angle_icon" aria-hidden="true"><FontAwesomeIcon icon={faAngleDown}></FontAwesomeIcon></i></h4>
                        </div>

                         
                        <div id="collapsetwo" class="collapse show" aria-labelledby="headingtwo" data-parent="#accordion">
                          <div class="card-body2">
                            <div className="form-group input_container">
                              <select
                                onClick={this.handleChange1}
                                name="education_levels_id"
                                id="name"
                                className="form-control my-input"
                                // onChange= {this.LevelData}
                                required>
                                  {this.state.education_levels_id?
                                  <option value={this.state.education_levels_id}>{this.state.education_levels_id1}</option>
                                  :
                                <option >Level Of Education</option>}
                                {levelListData ? levelListData : null}
                              </select>
                              <div className="text-danger" >{this.state.errors.education_levels_id}</div>
                            </div>

                            <div className="form-group input_container">
                              <select 
                                onClick={this.handleChange1}
                                id="study_field_id"
                                name="study_field_id"
                                
                                className="form-control my-input"
                                required>
                                  {this.state.study_field_id?
                                  <option value={this.state.study_field_id}>{this.state.study_field_id1}</option>
                                  :
                                <option  >Field of Data</option>}
                                {fieldListData ? fieldListData : null}
                              </select>
                              <div className="text-danger" >{this.state.errors.study_field}</div>
                            </div>

                            <div className="form-group input_container">
                              <input
                                type="name"
                                onChange={this.handleChange}
                                id="specialisation_major_area"
                                name="specialisation_major_area"
                                defaultValue={this.state.specialisation_major_area}
                                className="form-control my-input"
                                placeholder="Area Of Specialization Major"
                                required />
                              <div className="text-danger" > {this.state.errors.specialisation_major_area}</div>
                            </div>

                            <div className="form-group input_container">
                              <input
                                type="name"
                                onChange={this.handleChange}
                                defaultValue={this.state.specialisation_minor_area}
                                id="specialisation_minor_area"
                                name="specialisation_minor_area"
                                className="form-control my-input"
                                placeholder="Area Of Specialization Minor"
                                required />
                              <div className="text-danger" > {this.state.errors.specialisation_minor_area}</div>
                            </div>

                            <div className="form-group input_container">
                              <input
                                type="name"
                                id="defaultLoginFormUsername"
                                name="university_name"
                                defaultValue={this.state.university_name}
                                className="form-control my-input"
                                onChange={this.handleChange}
                                placeholder="University/College"
                                required />
                              <div className="text-danger"> {this.state.errors.university_name}</div>
                            </div>

                            <div className="form-group input_container">

                                 <DatePicker selected={this.state.date_attended}
                                  onChange={date => this.handleDateChange(date)}
                                  dateFormat='MM-dd-y'
                                  // minDate={new Date()}
                                  isClearable
                                  // fixedHeight
                                  placeholderText="Date of Attended"
                                  // peekNextMonth
                                  // showMonthDropdown
                                  // showYearDropdown
                                  dropdownMode="select"   
                                  onCalendarOpen={this.dateLimitation}
                                  // maxDate={this.state.nextDateDisabled}
                                  // showTimeSelect    
                                  /> 
                                                       
                              <div className="text-danger" >{this.state.errors.date_attended}</div>
                            </div>
                          </div>
                        </div>


                      </div>
                      <div class="certificate_cards" id="headingOne">
                        <div className="cetifi_heads set_for_mb" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          <h4>Training & Certification (Optional)<i className="down_angle_icon" aria-hidden="true"><FontAwesomeIcon icon={faAngleDown}></FontAwesomeIcon></i></h4>
                        </div>

                        <div id="collapseOne" class="collapse " aria-labelledby="headingOne" data-parent="#accordion">
                          <div class="card-body2">
                            <div className="form-group input_container">
                              <input
                                type="text"
                                onChange={this.handleChange}
                                value={this.state.input.certification_type}
                                name="certification_type"
                                className="form-control my-input"
                                placeholder="Certification Type"
                              />
                              <div className="text-danger" > {this.state.errors.certification_type}</div>
                            </div>

                            <div className="form-group input_container">
                              <input
                                type="name"
                                onChange={this.handleChange}
                                id="defaultLoginFormUsername"
                                name="certificate_accreditation_body"
                                value={this.state.input.certificate_accreditation_body}
                                className="form-control my-input"
                                placeholder="Accreditation Body"
                                defaultValue={this.state.certificate_accreditation_body}
                              />
                              <div className="text-danger" > {this.state.errors.certificate_accreditation_body}</div>
                            </div>
                            <div className="row form-group input_container mx-0">
                              <div className="col-md-6 m-auto text-left">
                                <lable className="label_profile_tut">Upload Certificate</lable>
                              </div>
                              <div className="col-md-6 m-auto text-right">

                                <div id="xslUpload">
                                  <input
                                    type="file"
                                    id="file"
                                    name="upload_certificate"
                                    accept=".jpg,.jpeg,.png,.pdf,.doc"
                                    onChange={this.handleFileChange}
                                  />
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="text-center">
                      <Loader
                        visible={this.state.visible}
                        type="Oval"
                        color="#00bacc"
                        height={40}
                        width={40}
                        timeout={3000} //3 secs
                      />
                    </div>

                    <div className="form-group">
                      <button className="btn btn-block send-button border_radius_20 tx-tfm" type="submit">Save</button>
                    </div>

                    <div className="form-group text-center mt-4">
                      <div className="text-success">  {this.state.imageSizeResponse ? "File too large. Size should not be more than 2 mb" : null} </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer></Footer>
      </main>
    );
  }
}

export default Certificate;
