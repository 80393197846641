import { faBell, faCheckCircle,faCog, faComment, faFileInvoice, faSignOutAlt, faTachometerAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Redirect } from 'react-router';
import defaultMaleImg from '../../assets/img/default_male.png';
import defaultFemaleImg from '../../assets/img/default_female.png';
import axios from 'axios';
import {API_BASE_URL,BASE_URL} from '../../config/api.js'
import StarRatings from 'react-star-ratings';
import {Link} from"react-router-dom";
import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
import Switch from "react-switch";
import { withRouter } from 'react-router';
import firebase from '../../firebase.js';
import { Modal, Card,Button } from "react-bootstrap";
// import swal from 'sweetalert';
// import editprofile from '../common/editprofile';

class TutorSideBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        redirect : false,
        profile_detail:[],
        education_level:[],
        notificationList:[],
        RatingList :[], 
        ProfileData : [],   
        ImageData : [],
        favoriteList : [],
        show90:'',
        redirect : false,
        checked: true,
        notificationId: '',
        switchToggle: '',
        conversationId:''
    }

    this.handleLogoutClick = this.handleLogoutClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
}

handleChange=async(checked)=> {
    console.log("ddd",checked)
   await this.setState({ checked });
    let status='1'
    if(this.state.checked==true){
        status="1"
    }
    else{
        status ="0"
    }
    console.log("sss",this.state.checked)
    const  UserToken = localStorage.getItem('UserToken');
    if (UserToken != '') {
       
        axios({
            method: 'post',
            url: API_BASE_URL + "tutor_online_status/",
            headers: { 'Authorization': "Bearer " + UserToken },
            data :{
                 online_status : status
            }
        })
        .then(response => {
            console.log("ui8idf",response.data)
            
        }).catch((error) => {
            console.log(error)

        })
}
}


onlinedata=()=>{
    const  UserToken = localStorage.getItem('UserToken');
    axios({
        method:'post',
        url: API_BASE_URL +"tutor_home_detail/",
        headers:{'Authorization' : "Bearer "+ UserToken}, 
        
        }).then(response => {
            console.log("jdhiinsdgddt",response.data)
        this.setState({profile_detail:response.data.profile_detail})
        this.setState({education_level:response.data.education_level})
        const onlineStatus = response.data.profile_detail[0].online_status

    })
}

componentDidMount(){

    console.log('uuu',localStorage.getItem('datafinal'))
    const user = localStorage.getItem('UserToken') 
    if (user && user !== 'undefined') {            
        // this.props.history.push('/')             
    }
    const messaging = firebase.messaging();
  
  
    
  
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("../firebase-messaging-sw.js")
        .then((registration)=> {
          console.log("Registration successful, scope is:", registration);
          messaging.getToken({vapidKey: 'BFHdJVqtAg6uSQvDTrpjZHCmK7VcoAHjdWa8eF1NFmZzWxMhxNlG8L8iiHvp296ii0Aj3J0jV5yqig5OwclgIl8' })
            .then((currentToken) => {
              if (currentToken) {
                console.log('current token for client: ', currentToken);
                this.setState({notificationId:currentToken}) 
              } else {
                console.log('No registration token available. Request permission to generate one.');
              }
            }).catch((err) => {
              console.log('An error occurred while retrieving token. ', err);
              // catch error while creating client token
            });  
          })
          .catch(function(err) {
            console.log("Service worker registration failed, error:"  , err );
        }); 
      }
    console.log("propsdataa",this.props.data)
    this.chatActiveTutors();
    this.onlinedata()
    this.handleChange(true)
}


handleCancel=()=>{
    this.handleModal90()
  }

  handleModal90(){
    this.setState({show90:!this.state.show90})
  }
    
handleLogoutClick() {
    const UserToken = localStorage.getItem('UserToken');
    if (UserToken != '') {
        const sendData = {
            notification_id:this.state.notificationId,
            device_type:"web"
            }
      axios({
        method: 'POST',

        url: API_BASE_URL + 'logout_user/',
        headers: { 'Authorization': "Bearer " + UserToken },
        data: sendData,
        }).then(response => {
            console.log("res",response)
            if(response.data.success==200){
                if(response.data.message=="User successfully logout"){
                    // this.sweetAlertLogout()
                }
            }

           
        })
        .catch((error) => {
         
        });
    }
    localStorage.getItem('UserToken')
    localStorage.clear();
    this.setState({ redirect: true });
}

chatActiveTutors(){
    
    const UserToken = localStorage.getItem('UserToken');
    if (UserToken != '') {
        axios({
        method: 'Get',
        url: API_BASE_URL + 'tutor_chat_list_booking/',
        headers: { 'Authorization': "Bearer " + UserToken },
        
        }).then(response => {
           
            this.setState({conversationId:response.data.chat_user_list[0][0].user_id})
        })
        .catch((error) => { 
        });
    }
}

onChatHandle(id) {
 
    this.props.history.push({ 
        pathname: '/conversations',
        state: { id: id }
    });
}

render() {

    if (this.state.redirect) {
        return (
            <Redirect to={'/'} />
        )
    } 
    return (
       
     <div className="box_custom side_panel_dashboard sidebar_mobile">
          {this.state.profile_detail.map((data)=>{
              return(
      <div className="profile_dashboard tech_desbrd">
                                            
        {data.image==null? 
         data.gender == "female" ?
         <img src={defaultFemaleImg} alt="" />
         :<img src={defaultMaleImg} alt="" />
        :<img src={BASE_URL + data.image} alt=""/> 

        } 
          
          <div className="pro_detail pro_tutor_detail">

          {localStorage.getItem('datafinal')==null?
                               
                               
               <h6>{data.fullname}</h6>
               :<h6>{localStorage.getItem('datafinal')}</h6>}
               <div class="combine_graduate">
               <p>({data.front_id})</p>
               
                {this.state.education_level.map((data)=>{
                    return(
                        <>
                      <div class="graduate_btnnn">{data.level_name}</div>  
                        </>
                    )
                })}

                </div>

                {data.rating=''?

                <div class="start_ratingplus_review">
                    <StarRatings
                        rating={Number(data.rating)}
                        starRatedColor="#ffc107"
                        starDimension="18px"
                        starSpacing="0px"
                    /> 

                </div>
                
                :<p>New User</p>}
            
          </div>
      </div>
          )})}

      <div className="dashboard_sidebar text-center">
        <ul>
            <Link to="/tutor_home"> 
            <li>
            <i aria-hidden="true"><FontAwesomeIcon icon={faTachometerAlt}></FontAwesomeIcon></i>
            Dashboard
            </li></Link>
            <Link to="/tutorbooking"> 
            <li> 
            <i aria-hidden="true"><FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon></i>
            Sessions
            </li></Link>
            <Link to="/editprofile">
            <li>  
            <i aria-hidden="true"><FontAwesomeIcon icon={faUser}></FontAwesomeIcon></i>
            Edit Account
            </li></Link>
            <Link to="/bankaccountdetail">
            <li>  
            <i aria-hidden="true"><FontAwesomeIcon icon={faCog}></FontAwesomeIcon></i>
            Bank Account Details
            </li></Link>
            <li className="toggle_li">
            <span>
            <i aria-hidden="true"><FontAwesomeIcon icon={faFileInvoice}></FontAwesomeIcon></i>
            Active Status
            </span>
            <Switch onChange={this.handleChange} checked={this.state.checked} offColor='#f42'/>
            </li>
            {/* <Link to=''> */}
            <li onClick={() =>this.onChatHandle(this.state.conversationId)}>
            <i aria-hidden="true"><FontAwesomeIcon icon={faComment}></FontAwesomeIcon></i>
            Conversations
            </li>
            {/* </Link> */}
            <Link to="/notifications">
            <li>
            <i aria-hidden="true"><FontAwesomeIcon icon={faBell}></FontAwesomeIcon></i>
            Notifications
            </li></Link>
            
            <Link to="/helpSupport"><li>
            <i aria-hidden="true"><FontAwesomeIcon icon={faBell}></FontAwesomeIcon></i>
            Help & Support
            </li></Link>
            <Link onClick={() => this.handleModal90()}>  
            <li>
            <i aria-hidden="true"><FontAwesomeIcon icon={faSignOutAlt}></FontAwesomeIcon></i>
            Logout 
            </li></Link>
        </ul>
        <Modal show={this.state.show90} onHide={() => this.handleModal90()}>

<Modal.Header className="allmodal_header modal-header" >
      <h2>Are you Sure You Want To Logout?</h2>
                  </Modal.Header>
                  <Modal.Body>

            
              
              
            
            </Modal.Body>
            <Modal.Footer className="text-center all_popups_buttonss custom_modal_footer">
            <Button onClick={this.handleLogoutClick} class="submit_bb" type="submit">Yes</Button>
                <Button onClick={()=>this.handleCancel()} class="cancel_bb" type="submit">No</Button>
              </Modal.Footer>
            </Modal>
      </div>
    </div>
  );}
}


export default withRouter(TutorSideBar);