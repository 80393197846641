import React from 'react';
import Header from '../common/header';
import Footer from '../common/footer.js';
import accountIssue from '../../assets/img/dashboard_icons/account_issue.png';
import appIssue from '../../assets/img/dashboard_icons/app_issue.png';
import paymentIssue from '../../assets/img/dashboard_icons/payment_issue.png';
import tutorIssue from '../../assets/img/dashboard_icons/tutor_issue.png';
import axios from 'axios';
import { API_BASE_URL, BASE_URL } from '../../config/api.js';

class HelpSupport extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            helpSupportType:[],
            helpQuestionAnswers:[]
        }
    }

    componentDidMount(){
        this.issueList()
    }

    issueList(){

        // alert("working");

        axios({
            method: 'get',
            url: API_BASE_URL + 'issue_type_list/',
        }).then(response => {
            console.log("help support",response.data)
            this.setState({helpSupportType:response.data.issue_type})
        })
        .catch((error) => {
            // console.log(error)
        });
    }

    issueHelpSupport(id){

       axios({
           method: 'get',
           url: API_BASE_URL + `help_and_support/?issue_id=`+ id,
    
       }).then(response => {
           console.log("help support22",response.data)
           this.setState({helpQuestionAnswers:response.data.help_questions})
       })
       .catch((error) => {
           // console.log(error)
       });
   }

render() {
return (
<main>
<Header></Header>
<section className="inner_banner section_padding">
    <div className="container">
        <div className="row">
        <div className="inner_contain mb-4">
            <h2>Hi! How can i help you ?</h2>
        </div>
        </div>
    </div>
</section>
<section class="help_support_section">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="help_support_box help_support_mob">
                    {this.state.helpSupportType.map((data)=>{
                        return(
                        <div class="help_support_box_item" onClick={()=>{this.issueHelpSupport(data.id)}}>
                            <div class="help_support_box_icons">
                                <img src={accountIssue} alt=""/>
                            </div>
                            <h3 class="" value="1">{data.issue_name}</h3>
                        </div>
                        )
                    })}
                    
                    {/* <div class="help_support_box_item" onClick={()=>{this.issueHelpSupport(2)}}>
                        <div class="help_support_box_icons">
                            <img src={appIssue} alt=""/>
                        </div>
                        <h3 class="" value="2">App issue</h3>
                    </div>
                    <div class="help_support_box_item" onClick={()=>{this.issueHelpSupport(3)}}>
                        <div class="help_support_box_icons">
                            <img src={paymentIssue} alt=""/>
                        </div>
                        <h3 class="" value="2">Payment issue</h3>
                    </div>
                    <div class="help_support_box_item" onClick={()=>{this.issueHelpSupport(4)}}>
                        <div class="help_support_box_icons">
                            <img src={tutorIssue} alt=""/>
                        </div>
                        <h3 class="" value="2">Tutor issue</h3>
                    </div> */}
                </div>
            </div>
        </div>
    </div>
</section>
<section class="process_section">
    <div class="container">
        <div class="row">
            <div class="col-md-12 question_section">
               <div class="dark_head">
                   <h2><span>Top</span><span> Questions</span></h2>
                </div>

                <div class="accordion" id="accordionExample">
                    {this.state.helpQuestionAnswers.map((help)=>{
                        return(
                        <div class="card card-helpsupport">
                            <div class="card-header card_design" id="headingOne">
                                <h5 class="" data-toggle="collapse" data-target={"#collapse"+help.id} aria-expanded="true" aria-controls="collapseOne">{help.question}</h5>
                            </div>
                            <div id={"collapse"+help.id} class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">{help.answer}</div>
                            </div>
                        </div>
                        )
                    })}                    
                </div>
            </div>
        </div>
    </div>
</section>

<Footer></Footer>
</main>
)
}
}

export default HelpSupport;