import React, { Component } from "react";
import axios from "axios";
import OtpInput from "react-otp-input";
import firebase from  '../../firebase.js';
import {API_BASE_URL} from'../../config/api.js';
import Header from "../common/header";
import Footer from "../common/footer";
import otp_img from "../../assets/img/categories/Icon/otp.png";
import {Link} from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import swal from 'sweetalert';

class Mobileverification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: [],
      i: 0,
      tempo: 0,
      verifyStatus: '',
      ConfirmationResult:'',
        otp: "",
        MessageInvalidOTP : '',
        MessageSendOTP : '',
        MessageUpdateEmailSuccessfully : '',
        input: {},
        errors: {},
        visible : false,
        username:'',
        email:'',
        otp: "",
        MessageInvalidOTP : '',
        MessageSendOTP : '',
        MessageUpdateMobileSuccessfully : '',
        val:"",
        val1:'',
        usertype: '',
        value : '',
        OTPError : '',
        MessageOTP :false,
        MessageSendOTP:false,
   
    }
    this.handleChange = otp => this.setState({ otp });
		this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
 }


 sweetAlertInValidPhone = () => {
  swal({
  text: "Invalid Attempt", 
  icon: "warning",
  dangerMode: true,
  })
} 
sweetAlertVerificationSuccess = () =>{
  swal({
    text: "Verification Successful", 
    icon: "success",
    }).then(()=> {
      
        // this.props.history.push("/add_new_password");
      })
}


sweetAlertVerificationInvalidUser = () =>{
swal({
  text: "Invalid User", 
  icon: "warning",
  dangerMode: true,
  })
}

handleChange(evt) {
    const value=evt.target.value;
      this.setState({ [evt.target.name]: evt.target.value });
    }

handleSubmit = event => {
  event.preventDefault();
  this.otpIntegration()
  
}

otpIntegration=()=> {
  const code = this.state.otp
  var verifyOtp = this.state.ConfirmationResult   
  if(verifyOtp){
    this.setState({ verifyStatus: 'verified' })
    verifyOtp.confirm(code).then((result) => { 
      const user = result.user;
      this.handleverifyotp()
    }).catch((error) => {
  this.setState({ verifyStatus: 'unverified' })
      this.handleverifyotp()
    });
  }else{
    this.sweetAlertInValidPhone()
  }      
}


handleverifyotp=()=>{
  
  const sendData = {
  
    username: localStorage.getItem('username'),
    
    verify_status: this.state.verifyStatus,
  };


  axios({
    method: 'POST',
    url:API_BASE_URL+"forgate_password_verify_otp_by_firebase/",
    data: sendData
    }).then(response => {
    if (response.data.success==200){
        if (response.data.message == "Yours otp successfully verified.") {
          this.props.history.push("/login");
     
          this.sweetAlertVerificationSuccess()
        }else{
          this.sweetAlertVerificationInvalidUser()
        }
      }
    
    })
    .catch((error) => {
      console.log(error)
  });
}

// timer() {  
//   return (
//     <>{this.state.time[this.state.i]}</>
//   )
// }
// handletimer() {
//   for (let i = 26; i >= 0; i--) {
//       this.state.time.push(i)
//   }

//   setInterval(() => {

//     if (this.state.i < 26) {
//         this.setState({ newI: this.state.i })
//         this.timer()
//         this.state.i = this.state.i + 1
//     } else {
//         this.setState({ tempo: this.state.i + 1 });
//     }

// }, 1000);
// }

componentDidMount() {
  this.setUpRecaptcha()
  this.interval()

  this.sendOtp()
  }



  setUpRecaptcha=()=>{
    window.recaptchaVerifier=new firebase.auth.RecaptchaVerifier(
             "recaptcha-container",
             {
               size:'invisible',
                 callback:function(response){
                 console.log("captha resolved")
                //  this.sendOtp()
               }
             }
           )
         }



         sendOtp=()=>{
          // this.setUpRecaptcha()
          // var phoneNumber= '+91'+localStorage.getItem("username");
          var phoneNumber = '+' + localStorage.getItem('country_code') + localStorage.getItem('username');
          var appVerifier=window.recaptchaVerifier;
          firebase.auth().signInWithPhoneNumber(phoneNumber,appVerifier)
          .then((confirmationResult)=>{
            this.setState({ConfirmationResult :confirmationResult})
            console.log("confirmationResult",this.state.ConfirmationResult)
            let timeLeftVar = this.secondsToTime(this.state.seconds);
            this.setState({ time: timeLeftVar });
        
            if (this.timer == 0 && this.state.seconds > 0) {
              this.timer = setInterval(this.countDown, 1000);
            }
          }).catch((error)=>{
            if(error.message=="TOO_LONG"){
              this.InvalidPhoneNo()
            }
            else if(error.message=="We have blocked all requests from this device due to unusual activity. Try again later."){
              this.TooManyAttempts()
            }
            else if(error.message=="Invalid format."){
              this.InvalidPhoneNo()
            }
          

})       
          // this.handletimer()
        }     


        // secondsToTime2(secs) {
        //   let hours = Math.floor(secs / (60 * 60));
      
        //   let divisor_for_minutes = secs % (60 * 60);
        //   let minutes = Math.floor(divisor_for_minutes / 60);
      
        //   let divisor_for_seconds = divisor_for_minutes % 60;
        //   let seconds2 = Math.ceil(divisor_for_seconds);
      
        //   let obj = {
        //     "h": hours,
        //     "m": minutes,
        //     "s": seconds2
        //   };
        //   return obj;
        // }
      
        // countDown() {
        //   // Remove one second, set state so a re-render happens.
        //   let seconds = this.state.seconds - 1;
        //   this.setState({
        //     time: this.secondsToTime(seconds),
        //     seconds: seconds,
        //   });
      
        //   // Check if we're at zero.
        //   if (seconds == 0) {
        //     clearInterval(this.timer);
        //   }
        // }
      
        // countDown2() {
        //   // Remove one second, set state so a re-render happens.
        //   let seconds2 = this.state.seconds2 - 1;
        //   this.setState({
        //     times: this.secondsToTime(seconds2),
        //     seconds2: seconds2,
        //   });
      
        //   // Check if we're at zero.
        //   if (seconds2 == 0) {
        //     clearInterval(this.timer2);
        //   }
        // }
      
      
        // secondsToTime(secs) {
        //   let hours = Math.floor(secs / (60 * 60));
      
        //   let divisor_for_minutes = secs % (60 * 60);
        //   let minutes = Math.floor(divisor_for_minutes / 60);
      
        //   let divisor_for_seconds = divisor_for_minutes % 60;
        //   let seconds = Math.ceil(divisor_for_seconds);
      
        //   let obj = {
        //     "h": hours,
        //     "m": minutes,
        //     "s": seconds
        //   };
        //   return obj;
        // }
        resenddd = () => {

          this.setState({otp:''})
          this.interval()
              this.sendOtp()
          
              // let timeLeftVar = this.secondsToTime2(this.state.seconds2);
              //         this.setState({ times: timeLeftVar });
              //         if (this.timer2 == 0 && this.state.seconds2 > 0) {
              //           this.timer2 = setInterval(this.countDown2, 1000);
              //         }
          
            }
      
      
        interval() {

          this.setState({ seconds: 26 });
      
          let myInterval = setInterval(() => {
              if (this.state.seconds == 0) {
                  clearInterval(myInterval);
              } else {
                  this.setState({ seconds: this.state.seconds - 1 });
              }
          }, 1000)
      }
 
   InvalidOTP = () => {
    swal({
      text: "OTP Is Invalid.Please Enter Valid OTP.",
      icon: "warning",
      dangerMode: true,
    })
  }
  InvalidPhoneNo = () => {
    swal({
      text: "Mobile No Is Invalid",
      icon: "warning",
      dangerMode: true,
    })
  }
  TooManyAttempts = () => {
    swal({
      text: "Too Many Attempts Try Later",
      icon: "warning",
      dangerMode: true,
    })
  }
     
render() {
 return (
    <main>
    <Header></Header>
      <section className="login_form mx-auto margin_top otp_page">
        <div className="container-fluid width_custom border-0">
          <div className="row">
            <div className="col-md-7 col-sm-12 bg_banner_login">

            </div>
            <div className="col-md-5 col-sm-12 m-auto">
              <div className="card2 border-0 my-3">
                <form className=" text-center loginpage" onSubmit={this.handleSubmit} method="post">
                <div id="recaptcha-container"></div>
                  <h2 >Verify Mobile</h2>
              <div className="logo" >
                <Link to="#">
                  <img src={otp_img} alt="Logo"></img>
                </Link>
              </div> 
              <p class="otp_peraa">OTP has been send to your registered mobile number - {localStorage.getItem('username')}</p>
              <p class="otp_peraa margin0">Please enter it below</p>
              <div className="form-group otp_input_section">
                <OtpInput
                  value={this.state.otp}
                  onChange={this.handleChange}
                  numInputs={6}
                  name="otp"
                  separator={<span></span>}/>
                  {this.state.OTPError?this.state.OTPError:null}
              </div>
            
              <div className="form-group">
                <div className="text-danger">{this.state.MessageOTP ? "OTP Is Invalid" : null} 
                </div>
              </div>

              <Loader
                visible = {this.state.visible}
                type="Oval"
                color="#00bacc"
                height={40}
                width={40}
                timeout={3000} />

              <div className="form-group mt-5">
                {this.state.otp.length>=6?
                <button className="btn btn-block send-button border_radius_20 tx-tfm" type="submit">Verify</button>
                :
                <button className="btn btn-block send-button border_radius_20 tx-tfm" disabled="true" type="submit">Verify</button>
                }


{this.state.seconds== 0 ?

<p class="resendotpbtn" >
  <button  onClick={() => this.resenddd()}>Resend Security Code</button>
  {this.state.seconds} sec left


</p>
:
<p class="resendotpbtn" >
  <button type="submit" disabled='true' >Resend Security Code</button>
  {this.state.seconds} sec left
</p>}

{/* <p class="resendotpbtn" type="submit" >
                  <span>
                  Resend Security Code
                  </span>
                  <span>
                  {this.timer()} sec left
                  </span> 
                </p> */}




{/* <p class="resendotpbtn" >


{this.state.time.s != 0 ?
  <>
    {this.state.time.s} sec left
</>
  : null}


</p>
{this.state.time.s == 0 && this.state.times.s != 0 ?

<p class="resendotpbtn" >
  <span  onClick={() => this.sendOtp()}>Resend Security Code</span>
  {this.state.times.s} sec left


</p>
:
<p class="resendotpbtn" >
  <span type="submit" disabled='true' >Resend Security Code</span>
</p>} */}
              </div>
             
              {/* <p class="resendotpbtn" type="submit" ><span>Resend Otp in </span><span> {this.state.time.m}: {this.state.time.s}</span> </p> */}
            
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
<Footer></Footer>
</main>
);
}
}
export default Mobileverification;