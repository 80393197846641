import React from 'react';
import axios from "axios";
import {API_BASE_URL,BASE_URL} from'../../config/api.js';
import defaultMaleImg from '../../assets/img/default_male.png';
import defaultFemaleImg from '../../assets/img/default_female.png';
import Header from '../common/header';
import Footer from'../common/footer';
import SideBar from './sideBar';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

class Dashboard extends React.Component {
  documentData;
    constructor(props) {
        super(props)
        this.state= {
          PopularSubCategorySlider:[],
          categoryDashboard: [],
          subCategoryLevelList:[],
          subCategoryLevel:[],
          username:'',
          subdata2:"",
          shown: true,
          subjectName:'',
          categoriesid:'',
          profileDataList:[],
          last_sessionDetails:[]
        }
        
        this.handleChange = this.handleChange.bind(this);
        this.handleClick1 = this.handleClick1.bind(this);
        // this.handleClick2=this.handleClick2.bind(this);
      }

  handleClick1 = (id,name,detail) => {
 
    this.setState({username:name})
    this.setState({ subdata2:name})
    this.setState({categoriesid:id})
    localStorage.setItem('cat_id', id);
    localStorage.setItem('name',name)
    localStorage.setItem('detail',detail)
            
  axios({
      method: 'get',
      url: API_BASE_URL + `sub_category_list/?category_id=` + id,
      }).then(response => {
  
      this.setState({ PopularSubCategorySlider: response.data.result });
               
      }).catch((error) => {
   
      });
    }
          
  handleChange(evt) {
      const value = evt.target.value;
     
      this.setState({ [evt.target.name]: evt.target.value });
  }

  componentDidMount() {

    axios.get(API_BASE_URL + `category_list/`)
    .then(res => {
    this.setState({ categoryDashboard: res.data.result });
  
  })

  const  UserToken = localStorage.getItem('UserToken');
  if(UserToken!=''){
   
 
     axios({
        method:'GET',
         url: API_BASE_URL +"student_profile/",
          headers:{'Authorization' : "Bearer "+ UserToken}, 
        
          }).then(response => {
         
              this.setState({student:response.data})
              this.setState({student_data:response.data.student_data})
              this.setState({last_sessionDetails:response.data.last_session})
     })
   }
 
}
 
             
  render() { 
      const categoryDashboardData =
      this.state.categoryDashboard.map((data) => {
    
      return (
        <Link to={"/tutorsearch/"+data.category_name.replaceAll(" ","_")+'/'+data.id}>
          <div className="category_dashboard">
            <img className="width20px" src={BASE_URL+data.web_icon} alt=""   />
            <div className="category_imgs">
              <h3 onClick={() => { this.handleClick1(data.id,data.category_name,data.category_description) }} value={data.id,data.category_name} className="">{data.category_name}</h3>
            </div>        
          </div>
        </Link>
        )
      })
     
    const last_sessionDetailsConst= this.state.last_sessionDetails.map((value) => {
     
      return (
      
        <div className="row box_custom">
          <div className="col-md-6">
            <div className="book_again_section">
          
              {value.image==null?
                value.gender == "female" ?
                <img className="booking_user_img" src={defaultFemaleImg} alt="" />
               :<img className="booking_user_img" src={defaultMaleImg} alt="" />
               :<img className="booking_user_img" src={BASE_URL + value.image} alt=""/>}
            <div>
           <div className="pro_detail">
           <h6>{value.fullname}</h6>
           </div> 
         
            
              <span>{value.sub_category_name}</span>
            </div>
          </div>
        </div>
        <div className="col-md-6 my-auto">
          <div className="book_again_section_right">
            {value.tuition_type!='undefined'?
            <span>{value.tuition_type} tution</span>
            :
            null}
            <span>{value.duration_of_session}</span>
           
            </div>
          </div>
        </div> 
      )
    })

 
return (
<main>
<Header></Header>
<section className="">
    <div className="container-fluid margin_top width_custom">
      <div className="row">
        <div className="col-md-3 mt30">
          <SideBar></SideBar>
        </div>
        
        <div className="col-md-9 mt30">
        
          {this.state.last_sessionDetails && this.state.last_sessionDetails.length>0?
          <>

            <div className="row">
            <div className="col-md-6 col-6">
              <h2 className="dashboard_headings">Last Session</h2>
            </div>
            <div className="col-md-6 col-6 text-right">
              {/* <button className="btn_darkk">My Lessons</button> */}
            </div>
          </div> 
            
          {last_sessionDetailsConst}
          </>

          : <div className="col-md-12 welcome_dashboard"> 
              <center><h4>No Last Session</h4> </center>
            </div>       
  }
       
        <div className="row">
          <div className="col-md-12">
            <h2 className="dashboard_headings">What Do You Want To Learn Today ?</h2>
          </div>
        </div> 
        <div className="row">
          <div className="col-md-12">
            <div className="category_sec_dash user_categro_mob">
            {categoryDashboardData}
            </div>
      </div>
    </div>
  </div>
</div>
</div>


</section>
<Footer></Footer>
</main>
   );
  }
}

export default Dashboard;


